/**
 * @ngdoc service
 * @name exagoApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching users data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */

'use strict';
import angular from 'angular';

/// i.e. we return response.data.DataModel so the consumer can work with the data directly
angular
  .module('flowingly.runner.services')
  .factory('exagoApiService', exagoApiService);

exagoApiService.$inject = ['$http', 'APP_CONFIG'];

function exagoApiService($http: angular.IHttpService, APP_CONFIG: AppConfig) {
  const service = {
    exagoSession: null,
    getSessionKey: getSessionKey
  };
  return service;

  //////////// Public API Methods

  function getSessionKey(noSpinner = false) {
    return $http
      .get<IResponseData>(APP_CONFIG.apiBaseUrl + 'bi/getSessionKey', {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        angular.copy(response.data.dataModel, service.exagoSession);
        return response.data.dataModel;
      });
  }
}

export type ExagoApiServiceType = ReturnType<typeof exagoApiService>;
