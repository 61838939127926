/**
 * @ngdoc directive
 * @name runnerSetupCategories
 * @module flowingly.runner.setup
 * @description  This component is the root component for the setup categories area
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 *
 * 
 * ### Properties
 * #### Inputs
 * * categories: the lsit of available categoriess (JSON[])
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';

angular.module('flowingly.runner.setup').component('runnerSetupCategories', {
  bindings: {
    categories: '<'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.categories/runner.setup.categories.tmpl.html',
  controller: [
    'APP_CONFIG',
    'setupApiService',
    'brandingService',

    function (
      APP_CONFIG: SharedAngular.APP_CONFIG,
      setupApiService: SharedAngular.SetupApiService,
      brandingService: SharedAngular.BrandingService
    ) {
      const $ctrl = this;
      $ctrl.showSubCategories = false;
      $ctrl.handleCategoriesUpdated = handleCategoriesUpdated;
      $ctrl.helpUri = brandingService.getBrandedHelpUri(
        'https://help.flowingly.net/administration/how-do-i-create-manage-categories'
      );

      $ctrl.showSubCategories = APP_CONFIG.enableSubCategories;

      function handleCategoriesUpdated() {
        // For an array that is bounded via one-way binding, a watch expression is added that does not check for object equality
        // but uses reference checking. This means that adding an element to the array will never fire the '$onChanges' method,
        // since the watcher will never be 'dirty'. Therefore, we use Angular copy ensure a new reference

        setupApiService.getCategories().then((categories) => {
          $ctrl.categories = categories;
        });
      }
    }
  ]
});
