/**
 * @ngdoc directive
 * @name databasesListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a databases row
 * @usage
 * ```
     <databases-list-actions database='dataItem' on-databases-updated='$ctrl.handleDatabasesUpdated()'></databases-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * database: database object to act upon
 * onDatabasesUpdated: event listener when database is updated
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('databasesListActions', {
    bindings: {
      database: '<',
      onDatabasesUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.list.actions.tmpl.html',
    controller: [
      '$state',
      '$scope',
      'APP_CONFIG',
      'sessionService',
      'dialogService',
      'notificationService',
      'databaseApiService',
      function (
        $state,
        $scope,
        APP_CONFIG,
        sessionService,
        dialogService,
        notificationService,
        databaseApiService
      ) {
        let $ctrl = this;

        const database = 3;

        let isFlowModelAdmin = sessionService.isFlowModelAdmin();
        $ctrl.hideDelete =
          !!APP_CONFIG.disableAdminDatabaseDelete && isFlowModelAdmin;

        $scope.$watch(
          function () {
            return APP_CONFIG.disableAdminDatabaseDelete;
          },
          function (value) {
            $ctrl.hideDelete = !!value && isFlowModelAdmin;
          }
        );

        $ctrl.editClick = editClick;
        $ctrl.deleteClick = deleteClick;
        $ctrl.showDelete = showDelete;

        function showDelete() {
          return (
            $ctrl.database.name !== 'Users' &&
            $ctrl.database.name !== 'Teams' &&
            !$ctrl.hideDelete
          );
        }

        function editClick() {
          if ($ctrl.database.name === 'Users') {
            $state.go('app.runner.setup.users');
          } else if ($ctrl.database.name === 'Teams') {
            $state.go('app.runner.setup.teams');
          } else {
            $state.go('app.runner.edit-database', {
              dbName: $ctrl.database.name
            });
          }
        }

        function deleteClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
              controller: 'setupDeleteDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: {
                itemToDelete: $ctrl.database,
                entityType: database
              }
            })
            .then(function (response) {
              if ($ctrl.hideDelete) return;

              if (dialogService.isCloseModalWithCancelAction(response)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }

              databaseApiService
                .deleteDatabase($ctrl.database.name)
                .then(function () {
                  notificationService.showSuccessToast(
                    `${$ctrl.database.name} successfully Deleted.`,
                    1000
                  );
                  //raise an 'event' to notify the parent
                  $ctrl.onDatabasesUpdated();
                });
            });
        }
      }
    ]
  });
})();
