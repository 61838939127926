import React, { useState, useEffect } from 'react';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';
import { Box } from '@mui/material';
import {
  CommentType,
  IComment
} from '@Shared.Angular/interfaces/comment.interface';
import CommentHistory from './CommentHistory/CommentHistory';
import CustomnMention from './CustomMention/CustomMention';
import { ICommentMention } from '@Shared.Angular/interfaces/comment-mention.interface';
import { ActorType } from '@Shared.Angular/interfaces/actor-type.enum';
import FileProp from '@Client/runner.processmapsv2/@types/fileProp';

export type Props = {
  commentTargetId: string;
  commentTargetType: number;
  editableByCurrentUser: boolean;
  commentAdded: boolean;
  onCommentsUpdate: (totalComments: number) => void;
};

const Feedback = (props: Props) => {
  const { commentTargetId, commentTargetType, editableByCurrentUser } = props;
  const [commentAdded, setCommentAdded] = useState(false);
  const [hasXssVulnerableString, setHasXssVulnerableString] = useState(false);
  const [everyoneMentioned, setEveryoneMentioned] = useState(false);
  const [comments, setComments] = useState<IComment[]>([]);
  const [filesPendingForUpload, setFilesPendingForUpload] = useState(false);
  const [lastAddedComment, setLastAddedComment] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedFileList, setUploadedFileList] = useState<FileProp[]>([]);
  const [newCommentText, setNewCommentText] = useState('');
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [flowOwnerOnly, setFlowOwnerOnly] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ICommentMention[]>([]);
  const sessionService =
    useService<SharedAngular.SessionService>('sessionService');
  const fileService = useService<SharedAngular.FileService>('fileService');
  const tokenService = useService<SharedAngular.TokenService>('tokenService');
  const notificationService = useService<SharedAngular.NotificationService>(
    'notificationService'
  );
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');
  const flowinglyMentionService =
    useService<SharedAngular.FlowinglyMentionService>(
      'flowinglyMentionService'
    );
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const validationService =
    useService<SharedAngular.ValidationService>('validationService');
  const commentApiService =
    useService<SharedAngular.CommentApiService>('commentApiService');
  const appConfig = useService<AppConfig>('APP_CONFIG');

  const handleFileChange = (event) => {
    const files = event.target.files;
    setFileList((prevFiles) => [...prevFiles, ...files]);
    setFilesPendingForUpload(true);
    pubSubService.publish('FILEUPLOAD_UPLOAD_STARTED', {});
    setIsAddButtonDisabled(true);
  };

  const uploadFiles = async () => {
    let hasException = false;
    const indexOfLastUploadedFile =
      uploadedFileList.length > 0 ? uploadedFileList.length : 0;
    const filesToUpload = fileList.slice(indexOfLastUploadedFile);
    let updatedFileList = uploadedFileList;
    const uploadPromises = filesToUpload.map(async (file) => {
      try {
        const response = await fileService.uploadCommentFile(file);
        const uploadedFile: FileProp = {
          filename: file.name,
          id: response.data,
          downloadLink: await createDownloadLink(response.data),
          size: Math.floor(file.size / 1000)
        };
        updatedFileList = [...updatedFileList, uploadedFile];
        updatedFileList.sort((a, b) => a.filename.localeCompare(b.filename));
        pubSubService.publish('FILEUPLOAD_UPLOAD_COMPLETED', {});
        notificationService.showSuccessToast(file.name + ' uploaded');
        return { success: true, file };
      } catch (error) {
        return { success: false, reason: error, file };
      }
    });
    const results = await Promise.all(uploadPromises);
    results.forEach((result) => {
      if (result.success !== true) {
        hasException = true;
        const fileIndex = fileList.findIndex(
          (f) => f.name === result.file.name
        );
        if (fileIndex > -1) {
          fileList.splice(fileIndex, 1);
        }
      }
    });
    setUploadedFileList(updatedFileList);
    setFilesPendingForUpload(false);
    setIsAddButtonDisabled(false);
    if (hasException) {
      pubSubService.publish('FILEUPLOAD_UPLOAD_FAILED', {});
    }
  };

  const handleClick = async (event: MouseEvent, fileId: string) => {
    event.preventDefault();
    const url = fileService.getDownloadLink(fileId);
    return await fileService.getFileByUrl(url, sessionService.getToken());
  };

  const createDownloadLink = async (fileId: string) => {
    return await fileService.getDownloadLink(fileId);
  };

  const addComment = async () => {
    if (!newCommentText.trim()) {
      const warningText = 'Cannot add empty feedback';
      notificationService.showWarningToast(warningText);
      return;
    }

    const resultString = await Promise.all(
      selectedUser.map(async (user) => {
        const result = await flowinglyMentionService.transformMentionForDisplay(
          user
        );
        return result;
      })
    ).then((results) => results.join(' '));

    const mentions = flowinglyMentionService.extractMentionsFromCommentText(
      resultString || ''
    );

    const commentContents = transformMentionDisplayToStore(
      newCommentText || ''
    );

    const isFlowOrStepTaskComment =
      commentTargetType === flowinglyConstants.commentTargetType.FLOW ||
      commentTargetType === flowinglyConstants.commentTargetType.STEP_TASK;

    const confirmText = isFlowOrStepTaskComment
      ? 'Comment added'
      : 'Feedback added';
    const warningText = isFlowOrStepTaskComment
      ? 'Cannot add empty comment'
      : 'Cannot add empty feedback';

    if (flowinglyMentionService.trimSpaces(commentContents) === '') {
      notificationService.showWarningToast(warningText);
      return;
    } else if (validationService.isXssVulnerableString(commentContents)) {
      notificationService.showErrorToast(
        'The comment field contains invalid text. Please remove any HTML or JavaScript text from the field.'
      );
      return;
    }

    const comment = {
      comment: commentContents,
      mentions: mentions,
      fileIds: uploadedFileList.map((f) => f.id),
      userId: sessionService.getUser().id,
      flowOwnerOnly: flowOwnerOnly,
      commentType: CommentType.UserComment
    };

    try {
      await commentApiService.addFlowComment(
        commentTargetType,
        commentTargetId,
        comment
      );
      setLastAddedComment(comment.comment);
      setFileList([]);
      setUploadedFileList([]);
      notificationService.showSuccessToast(confirmText);
    } catch (error) {
      notificationService.showErrorToast('Failed to add comment');
    }
    setEveryoneMentioned(false);
    setNewCommentText('');
    setSelectedUser([]);
    setCommentAdded(true);
  };

  const transformMentionDisplayToStore = (text: string) => {
    const mentionRegex = /@\[\|([^|]+)\|\]@/g;
    const lineBreakRegex = /\n/g;

    const replacedText = text
      .replace(mentionRegex, (_, mention) => {
        return `[~${mention}]`;
      })
      .replace(lineBreakRegex, '<br>');

    return replacedText;
  };

  const removeFile = async (originalIndex: number) => {
    try {
      if (originalIndex === -1) {
        return;
      }
      setIsAddButtonDisabled(true);
      const file = uploadedFileList[originalIndex];
      const updatedFileList = [...fileList];
      const updatedUploadedFileList = uploadedFileList.filter(
        (_, index) => index !== originalIndex
      );
      setUploadedFileList(updatedUploadedFileList);
      updatedFileList.splice(originalIndex, 1);
      setFileList(updatedFileList);

      const user = sessionService.getUser();
      fileService.setUser(user.id, tokenService.getTenant().id);
      await fileService.removeFileForId(file.id);
      notificationService.showSuccessToast(file.filename + ' Removed');
    } catch (error) {
      setIsAddButtonDisabled(false);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  const removeAllFiles = async () => {
    if (!fileList || fileList.length === 0) return;
    try {
      const fileIds = fileList.map((f) => f.id);
      await Promise.all(fileIds.map((id) => fileService.removeFileForId(id)));
      setFileList([]);
      setUploadedFileList([]);
    } catch (error) {
      return;
    }
  };

  const isFlowOwnerOnly = () => {
    return appConfig.flowModelFeedbackVisibility.toLowerCase() === 'flowowners';
  };

  const handleValueChange = (value: string) => {
    const isXssVulnerable = validationService.isXssVulnerableString(value);
    setHasXssVulnerableString(isXssVulnerable);
    if (commentAdded) {
      setCommentAdded(false);
    }
    setNewCommentText(value);
  };

  const handleSelectedMentions = (value: ICommentMention[]) => {
    const isEveryoneMentioned = value.find(
      (item) =>
        item.actorName === 'Everyone' && item.actorTypeId === ActorType.Team
    );
    setSelectedUser(value);
    isEveryoneMentioned
      ? setEveryoneMentioned(true)
      : setEveryoneMentioned(false);
  };

  useEffect(() => {
    try {
      if (commentTargetType == null || !commentTargetId) {
        return;
      }
      const getComments = async () => {
        const data: IComment[] = await commentApiService.getFlowComments(
          commentTargetType,
          commentTargetId,
          false
        );
        setComments(data);
        props.onCommentsUpdate(data?.length);
      };
      getComments();
    } catch (error) {
      return;
    }
  }, [commentTargetType, commentTargetId, lastAddedComment]);

  useEffect(() => {
    if (filesPendingForUpload) {
      uploadFiles();
    }
  }, [filesPendingForUpload]);

  useEffect(() => {
    const flowOwner = isFlowOwnerOnly();
    setFlowOwnerOnly(flowOwner);
    const handleCompleteStep = () => {
      removeAllFiles();
    };
    pubSubService.subscribe(
      'SIGNALR_RUNNER_COMPLETE_STEP',
      handleCompleteStep,
      'runner.processmapsv2-feedback-comment'
    );

    return () => {
      pubSubService.unsubscribeAll('runner.processmapsv2-feedback-comment');
    };
  }, []);

  return (
    <Box className={'v2-view-feedback-comments-container'}>
      <CommentHistory comments={comments} />
      <Box className={'v2-view-feedback-add-comment-container'}>
        <div className={'v2-view-feedback-add-comment-container-inner'}>
          <div className={'v2-view-feedback-add-comment-header'}>
            Add feedback
          </div>
          <CustomnMention
            flowOwnerOnly={flowOwnerOnly}
            commentTargetId={commentTargetId}
            commentAdded={commentAdded}
            onValueChange={handleValueChange}
            mentionedUsers={handleSelectedMentions}
          />
          {hasXssVulnerableString && (
            <div className="mention-contents-error">
              This input does not support HTML.
            </div>
          )}
          {everyoneMentioned && (
            <div className="mention-contents-error">
              Mentioning "Everyone" will notify every single User in this
              Business!
            </div>
          )}
          <div className={'v2-view-feedback-add-comment-action'}>
            <div
              className={'v2-view-feedback-add-comment-foot-text'}
              style={{ minWidth: '0%' }}
            >
              <div className={'v2-view-feedback-add-comment-attach-file'}>
                <label htmlFor={`addCommentFile_${props.commentTargetId}`}>
                  <i className="fa-light fa-paperclip fa-lg mr-5"></i>
                  <span className="secondary-text">Attach File</span>
                  <input
                    id={`addCommentFile_${props.commentTargetId}`}
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </label>
                <ul className={'file-list'}>
                  {uploadedFileList.map((file, index) => (
                    <li key={index}>
                      <a
                        href={file.downloadLink}
                        onClick={(e) => handleClick(e, file.id)}
                      >
                        {file.filename}
                      </a>
                      <span>({file.size}K)</span>
                      <i
                        onClick={() => removeFile(index)}
                        title="remove"
                        className={'fa-light fa-xmark'}
                      ></i>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={'secondary-text mt-10'}>
                To mention a user or team, type '@' followed by the name
              </div>
            </div>
            <button
              className={'right btn green'}
              disabled={isAddButtonDisabled}
              onClick={addComment}
            >
              Add
            </button>
          </div>
        </div>
      </Box>
    </Box>
  );
};
export default Feedback;
