import {
  ImportableFlowModel,
  ImportableFlowModelState
} from '@Client/runner.services/flow.model.import.service';
import React, { ChangeEvent, useState } from 'react';

type Props = {
  importable: ImportableFlowModel;
  index: number;
  updateParent: (
    index: number,
    propertyName: string,
    propertyValue: any
  ) => void;
};

const ImportableFlowModels = ({ index, importable, updateParent }: Props) => {
  const [importableFlowModel, setImportable] = useState(importable);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    targetProperty: string
  ) => {
    setImportable({
      ...importableFlowModel,
      [e.target.name]: e.target[targetProperty]
    });
    updateParent(index, e.target.name, e.target[targetProperty]);
  };

  return (
    <div className="importable-flow-model">
      <div className="details">
        <div className="category">
          <label>Category: </label>
          {importable.categoryHierarchy.join(' / ')}
        </div>
        <div className="category">
          <label>Name: </label>
          {importable.name}
        </div>
        {importable.nameClash && (
          <div
            className="name-clash"
            title="You should not import the same Flow Model twice. If you delete the old Flow Model and import it again, any Component Nodes linked to the old Flow Model will have to be manually updated to link the new Flow Model."
          >
            A Flow Model with this name already exists&nbsp;
            <i className="fa-light fa-question-circle"></i>
          </div>
        )}
        {importable.attachments?.length > 0 && (
          <div>{importable.attachments.length} attachments</div>
        )}
      </div>
      {importable.state === ImportableFlowModelState.Pending && (
        <div className="import-option">
          <input
            type="checkbox"
            name="willImport"
            checked={importable.willImport}
            disabled={importable.nameClash}
            onChange={(e) => handleChange(e, 'checked')}
            id={'import-flow-model-' + index}
          ></input>
          <label htmlFor={'import-flow-model-' + index}>Import</label>
        </div>
      )}
      {importable.state === ImportableFlowModelState.Success && (
        <div className="import-result success">Imported</div>
      )}
      {importable.state === ImportableFlowModelState.Failure && (
        <div className="import-result failure" title={importable.importMessage}>
          Failed <i className="fa-light fa-question-circle"></i>
        </div>
      )}
    </div>
  );
};

export default ImportableFlowModels;
