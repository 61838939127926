/**
 * @ngdoc service
 * @name setupDeleteApiService
 * @module flowingly.runner.setup
 *
 * @description A service responsible solely for deleting setup items (users, teams, categories)
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import { DeleteEntityTypes } from '@Shared.Angular/flowingly.services/flowingly.constants';

class SetupDeleteApiService {
  static $inject = ['$http', '$q', 'APP_CONFIG', 'sessionService'];

  constructor(
    private $http,
    private $q,
    private APP_CONFIG,
    private sessionService: SharedAngular.SessionService
  ) {}
  //////////// Public API Methods

  public getTeams() {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'teams?includeDetails=false')
      .then((res) => {
        return res.data.dataModel;
      });
  }

  public getWarnings(entityType, itemId) {
    if (entityType === DeleteEntityTypes.categoryEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'categories/deletewarnings?categoryId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === DeleteEntityTypes.teamEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'teams/deletewarnings?teamId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === DeleteEntityTypes.userEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'users/deletewarnings?userId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === DeleteEntityTypes.flowModelEntityType) {
      const businessName = this.sessionService.getBusinessName();
      return this.$q.when({
        warnings: [
          `The flow model will be removed from ${businessName}. This cannot be undone!`
        ]
      });
    } else if (entityType === DeleteEntityTypes.databaseEntityType) {
      return this.$q.when({
        warnings: [
          'Make sure there are no Workflows using this database, ' +
            'if there are, deleting it might cause them to stop functioning.'
        ]
      });
    } else if (entityType === DeleteEntityTypes.roleEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'roles/deletewarnings?roleId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else {
      console.error('Unknown entity type');
    }
  }

  public getUserAssignedStepTasks(userId) {
    return this.$http.get(
      this.APP_CONFIG.apiBaseUrl + 'users/userStepTasks/' + userId,
      {
        noSpinner: false
      }
    );
  }
}

angular
  .module('flowingly.runner.setup')
  .factory('setupDeleteApiService', SetupDeleteApiService);
