import React, { useState } from 'react';
import { Box } from '@mui/material';
import ModalHeader from '@Client/runner.shared/modal/Header/Header';
import ModalFooter from '@Client/runner.shared/modal/Footer/Footer';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import ICategoryDetail from '@Shared.Angular/@types/core/contracts/queryModel/category/categoryDetail';

type Props = {
  parentId: string;
  closeModal: () => void;
  fetchSubCategories: () => void;
};

const CategoryAddModal = ({
  parentId,
  closeModal,
  fetchSubCategories
}: Props) => {
  const [categoryNameInput, setCategoryNameInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [categoryNameError, setCategoryNameError] = useState(false);
  const notificationService = useService<SharedAngular.NotificationService>(
    'notificationService'
  );

  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');

  const guidService = useService<SharedAngular.GuidService>('guidService');

  const categoryApiService =
    useService<SharedAngular.CategoryApiService>('categoryApiService');

  const save = async () => {
    if (!categoryNameInput) {
      setCategoryNameError(true);
      return;
    }
    const categoryDetails = getCategoryDetails(parentId);
    try {
      appInsightsService.startEventTimer('subcategoryAdded');
      const response = await categoryApiService.addCategory(categoryDetails);
      if (response.data.success) {
        closeModal();
        await fetchSubCategories();
        notificationService.showSuccessToast('Sub Category Added Successfully');
      } else {
        notificationService.showErrorToast(response.data.errorMessage);
      }
    } catch (err) {
      console.error(err);
      notificationService.showErrorToast('Error while adding category');
    }
  };
  const handleCategoryNameChange = (event) => {
    setCategoryNameInput(event.target.value);
    setCategoryNameError(false);
  };

  const handleDescriptionChange = (event) => {
    setDescriptionInput(event.target.value);
  };

  const getCategoryDetails = (parentId) => {
    const category: ICategoryDetail = {
      id: guidService.empty(),
      name: categoryNameInput,
      description: descriptionInput,
      parentId: parentId
    };
    return category;
  };

  return (
    <Box>
      <ModalHeader title={'Add Sub Category'}></ModalHeader>
      <Box>
        <div className="input-field">
          <label>Sub Category Name</label>
          <input
            type="text"
            value={categoryNameInput}
            onChange={handleCategoryNameChange}
            required
          ></input>
          {categoryNameError && (
            <div className="red-text">Sub category name is required</div>
          )}
        </div>
        <div className="input-field">
          <label>
            Description<span className="optional"></span>
          </label>
          <input
            type="text"
            id="sub-category-description"
            value={descriptionInput}
            onChange={handleDescriptionChange}
          ></input>
        </div>
      </Box>
      <ModalFooter
        actionButtonText="Save"
        actionButtonType="Success"
        onCancelClick={closeModal}
        onActionClick={save}
      />
    </Box>
  );
};

export default CategoryAddModal;
