/**
 * @ngdoc module
 * @name flowingly.runner.user.notifications
 * @module flowingly.runner.user.notifications
 * @description This module configures the page used to display user notifications.
 */
angular.module('flowingly.runner.user.notifications', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.user.notifications/runner.user.notifications.tmpl.html',
      require('./runner.user.notifications.tmpl.html').default
    );
  }
]);
