/**
 * @ngdoc directive
 * @name teamsHeader
 * @module flowingly.runner.setup
 * @description This component is used to display the header for the teams and will contain controls
 * @usage
 * ```
      <teams-header on-teams-updated="$ctrl.handleTeamsUpdated()"></teams-header>
 * ``` 
 * NOTE: Minimal content for now, but this might grow
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('teamsHeader', {
    bindings: {
      onTeamsUpdated: '&'
    },
    template:
      '<button ng-show="!$ctrl.hideAddTeam" ng-click="$ctrl.onAddTeamClick()" class="btn btn-primary mt-25">Add Team</button>',
    controller: [
      '$scope',
      'dialogService',
      'intercomService',
      'APP_CONFIG',
      function ($scope, dialogService, intercomService, APP_CONFIG) {
        let $ctrl = this;

        $ctrl.onAddTeamClick = onAddTeamClick;
        $ctrl.hideAddTeam = !!APP_CONFIG.disableAdminTeamEdit;

        $scope.$watch(
          function () {
            return APP_CONFIG.disableAdminTeamEdit;
          },
          function (value) {
            $ctrl.hideAddTeam = !!value;
          }
        );

        function onAddTeamClick(scope, team) {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.teams/runner.setup.teams.dialog.tmpl.html',
              controller: 'teamDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: { team: team }
            })
            .then(function (successSaved) {
              if (dialogService.isCloseModalWithCancelAction(successSaved)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }

              if (successSaved === true) {
                intercomService.trackEvent('Added Team');
                //raise an "event" to notify the parent
                $ctrl.onTeamsUpdated();
              }
            });
        }
      }
    ]
  });
})();
