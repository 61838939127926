/**
 * @ngdoc directive
 * @name flowLibraryNoResults
 * @module flowingly.runner.library
 * @description  This comppnent is used to display a message when there are no results
 * @usage
 * ```
     <flow-library-no-results></flow-library-no-results>
 * ``` 
 * NOTE: Minimal content for now, but this might grow
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.library').component('flowLibraryNoResults', {
  template: `<div class="list-no-results">No results.</div>`
});
