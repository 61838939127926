import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';

type Props = {
  fullName: string;
};

const NamedAvatar = ({ fullName }: Props) => {
  const avatarService =
    useService<SharedAngular.AvatarService>('avatarService');
  const [initial, setInitial] = useState<string>('');
  const [avatarBg, setAvatarBg] = useState<string>('');

  useEffect(() => {
    setupStepActorAvatar(fullName);
  }, [fullName]);

  function setupStepActorAvatar(actor: string) {
    if (actor?.length > 0) {
      setAvatarBg(avatarService.getColour(fullName));
      setInitial(avatarService.getUserInitial(actor));
    } else {
      setInitial(avatarService.getUserInitial('?'));
    }
  }

  return (
    <div className="fullname-wrapper">
      <Box
        component="span"
        className="profile-avatar"
        sx={{
          backgroundColor: avatarBg
        }}
      >
        {initial}
      </Box>
      <p>{fullName}</p>
    </div>
  );
};

export default NamedAvatar;
