/**
 * @ngdoc service
 * @name sideMenuService
 * @module flowingly.directives.sidemenu
 *
 * @description A service that maintains a list of menu items, for consumption by a directive that displays them
 *
 * ## Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 *
 * ###API
 * * menu - list of menu items to display
 * * addmenuItem - add a menu item to the menu
 * * clearMenu - remove all menu items
 * * removeMenuItemByName - remove specific menu item
 * * updateItemStatusByName - update statis of specific menu item (e.g. count)
 *
 * ###Usage
 *     Consumed by flowSidemenu directive.
 */
import angular from 'angular';

angular
  .module('flowingly.directives.sidemenu')
  .factory('sideMenuService', sideMenuService);

sideMenuService.$inject = ['lodashService'];

function sideMenuService(lodashService: Lodash) {
  //API
  const service = {
    //menu is a list of sections, that each contain a list of items
    menu: [],
    addmenuItem: addmenuItem,
    clearMenu: clearMenu,
    removeMenuItemByName: removeMenuItemByName,
    updateItemStatusByName: updateItemStatusByName
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////

  function addmenuItem(item) {
    service.menu.push(item);
  }

  function clearMenu() {
    service.menu.splice(0, service.menu.length);
  }

  function removeMenuItemByName(name) {
    lodashService.remove(service.menu, function (i) {
      return i.name === name;
    });
  }

  function updateItemStatusByName(name, status) {
    const item = lodashService.find(service.menu, function (i) {
      return i.name === name;
    });
    item.status = status;
  }
}

export type SideMenuServiceType = ReturnType<typeof sideMenuService>;
