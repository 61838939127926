/**
 * @ngdoc service
 * @name runnerCardService
 * @module flowingly.runner.services
 *
 * @description Helper service for working with card data
 */

import { FormGen } from '@Client/@types/formGen';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { FieldFiles } from '@Shared.FormGen/angular-form-gen/form/files/file.listService';
import IFormInput from '@Shared.Angular/@types/core/contracts/queryModel/card/formInput';
import angular from 'angular';
import { Guid } from '@Shared.Angular/@types/guid';

angular
  .module('flowingly.runner.services')
  .factory('runnerCardService', runnerCardService);

runnerCardService.$inject = [
  'guidService',
  'fgFileListService',
  'flowinglyConstants'
];

function runnerCardService(
  guidService: SharedAngular.GuidService,
  fgFileListService: FormGen.FgFileListService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const service = {
    addFileIdsToForm: addFileIdsToForm,
    createFileUploadData: createFileUploadData,
    formatFieldsWithCustomDBUserTypeToObject:
      formatFieldsWithCustomDBUserTypeToObject
  };

  return service;

  //////////// Public API Methods
  function addFileIdsToForm(
    stepFields: IFormInput[],
    fieldFiles: FieldFiles[]
  ) {
    //
    // The file upload does not update the form model.
    // Therefore we manually assign the ids of any files uploaded to the appropriate form
    // element, identified by the key passed in on the file list
    //

    for (const group of fieldFiles) {
      const field = stepFields.find((field) => {
        return field.key === group.fieldName;
      });
      if (field) {
        const fileIds = group.files
          .map((file) => {
            return file.id;
          })
          .join(',');
        field.value = fileIds;
      }
    }
    return stepFields;
  }

  function createFileUploadData(stepId: Guid, schema) {
    const fileUploadData = {};
    const fieldFiles = fgFileListService.getFilesForStep(stepId) || [];
    for (const field of schema.fields || []) {
      const fieldType = field.type.toLowerCase();
      const isFileUpload =
        fieldType === flowinglyConstants.formFieldType.FILE_UPLOAD;

      if (isFileUpload) {
        const group = fieldFiles.find((g) => g.fieldName === field.name);
        if (group) {
          fileUploadData[field.name] = JSON.stringify(group.files);
        }
      }
    }

    return fileUploadData;
  }

  function formatFieldsWithCustomDBUserTypeToObject(data, schema) {
    (schema.fields || []).forEach((field) => {
      if (
        field.type === 'lookup' &&
        field.lookupConfig.userObject &&
        field.lookupConfig.userObject.length > 0
      ) {
        data[field.name] = JSON.stringify(field.lookupConfig.userObject);
      }

      if (
        field.type === 'selectList' &&
        field.dbDataSource &&
        guidService.isValidGuid(data[field.name])
      ) {
        let selectedUserOption;

        (field.filteredOptions || []).forEach((option) => {
          if (data[field.name] == option.value) {
            selectedUserOption = option;
          }
        });

        data[field.name] = selectedUserOption
          ? JSON.stringify(
              (({ value, text }) => ({ value, text }))(selectedUserOption)
            )
          : null;
      }
    });
    return data;
  }
}

export type RunnerCardServiceType = ReturnType<typeof runnerCardService>;
