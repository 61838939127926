/**
 * @ngdoc directive
 * @name flowReportListHeader
 * @module flowingly.runner.reports
 * @description   Displays the name of the flow.
 *                Clicking this toggles the display of the list of reports.
 * @usage
 * ```
   <flow-report-list-header ng-click="$ctrl.onClickMethod({ id: $ctrl.flow.id })" flow="$ctrl.flow"></flow-report-list-header>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * flow: 
 * * isMobile :show mobile view if set
 */

import angular from 'angular';

angular.module('flowingly.runner.reports').component('flowReportListHeader', {
  bindings: {
    flow: '<', //the flow that contains the list of reports
    isMobile: '<' //show mobile view if set,
  },
  template: `
                <div class="flow-start" ng-class="{'isMobile': $ctrl.isMobile}">
                     <div class="flow-start-header flex flex-row" ng-click="$ctrl.onShowReportClick($ctrl.flow)" ui-sref="app.runner.report({ flowModelId: $ctrl.flow.id, flowModelName: $ctrl.flow.name})">
                        <flow-icon-square name="$ctrl.flow.flowModelName" flow-code=""></flow-icon-square>

                        <div class="flow-list__contents flex flex-row" ng-show="!$ctrl.isMobile">
                            <div class="flow-list__contents__subject">
                                <div class="flow-list__contents__subject__text">
                                    {{::$ctrl.flow.flowModelName}}
                                </div>

                                <div class="flow-list__contents__subject__category">
                                    {{::$ctrl.flow.category}}
                                </div>
                            </div>

                            <div class="flow-list__contents__description">
                                {{::$ctrl.flow.description}}
                            </div>
                            
                            <div class="flow-list__contents__process-owner">
                                <div class="flow-list__contents__process-owner__text">
                                    Process Owner: {{::$ctrl.flow.processOwnerName || '--' }}
                                </div>
                            </div>

                            <div class="tags">
                                <div ng-if="!$ctrl.flow.active" class="chip grey-chip">
                                    Deleted
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            `
});
