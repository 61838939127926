/**
 * @ngdoc directive
 * @name rolesList
 * @module flowingly.runner.setup
 * @description This component is used to display the list of roles
 * @usage
 * ```
     <roles-list></roles-list>
 * ``` 
 */
'use strict';
import angular from 'angular';
import SetupRolesController from './runner.setup.roles.controller';

export default class RolesListComponentController {
  static $inject = ['$scope', 'APP_CONFIG', 'roleApiService'];

  private options;
  private gridNeedResize;

  constructor(private $scope, private APP_CONFIG, private roleApiService) {
    $scope.$on(SetupRolesController.ROLES_UPDATED_EVENT, () =>
      this.handleRolesUpdated()
    );

    this.options = {
      dataSource: {
        transport: {
          read: (params) => {
            roleApiService.searchRoles(params.data).then((roles) => {
              roles.forEach(
                (role) =>
                  (role.permissionNames =
                    role.permissions.length < 4
                      ? role.permissions
                          .map((p) => p.name)
                          .sort()
                          .join(', ')
                      : role.permissions
                          .map((p) => p.name)
                          .sort()
                          .slice(0, 3)
                          .join(', ') +
                        ` (+ ${role.permissions.length - 3} more)`)
              );
              params.success({
                data: roles,
                total: roles.length
              });
              this.gridNeedResize = true;
            });
          }
        },
        schema: {
          data: 'data',
          total: 'total'
        },
        pageSize: APP_CONFIG.gridReportPageSize,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        serverGrouping: true,
        serverAggregates: true
      },
      noRecords: {
        template: '<teams-no-results></teams-no-results>'
      },
      scrollable: true,
      pageable: true,
      sortable: true,
      resizable: true,
      filterable: {
        mode: 'row'
      },
      columns: this.getColumns()
    };
  }

  handleRolesUpdated() {
    this.$scope.rolesGrid.dataSource.read();
  }

  private getColumns() {
    //Note: field needs to match the name (and case) of the returned data
    return [
      {
        field: 'name',
        title: 'Name',
        filterable: {
          cell: {
            operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
            showOperators: false
          }
        }
      },
      {
        field: 'description',
        title: 'Description',
        filterable: false
      },
      {
        field: 'permissionNames',
        title: 'Permissions',
        sortable: false,
        filterable: {
          cell: {
            operator: 'contains',
            showOperators: false,
            template: (args) => {
              this.roleApiService.getPermissions().then((permissions) => {
                args.element.kendoComboBox({
                  dataSource: permissions.map((p) => p.name).sort(),
                  placeholder: `Filter by Permission`
                });
              });
            }
          }
        }
      },
      {
        title: 'Actions',
        width: '120px',
        filterable: false,
        template:
          "<roles-list-actions role='dataItem' on-roles-updated='$ctrl.handleRolesUpdated()'></roles-list-actions>"
      }
    ];
  }
}

export class RolesListComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.setup/runner.setup.roles/runner.setup.roles.list.tmpl.html';
  controller = RolesListComponentController;
}

angular
  .module('flowingly.runner.setup')
  .component('rolesList', new RolesListComponent());
