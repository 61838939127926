/**
 * @ngdoc directive
 * @name flowHistoryFieldTasklistCheckbox
 * @module flowingly.runner.flow
 * @description This comppnent is used to display task list history items.
 * @usage
 * ```
     <flow-history-field-tasklist-checkbox show="isCheckbox" item="item" ></flow-history-field-tasklist-checkbox>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * show: show the checkbox
 * * item: the checklist data to display (JSON)
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .component('flowHistoryFieldTasklistCheckbox', {
    bindings: {
      show: '<',
      item: '<'
    },
    templateUrl:
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.tasklist.component.tmpl.html'
  });
