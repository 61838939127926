import React, { ChangeEvent, useState, useEffect } from 'react';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import {
  IImportable,
  ImportableFlowModel,
  ImportableFlowModelAttachments,
  ImportableFlowModelState
} from '@Client/runner.services/flow.model.import.service';
import { IFlowModelSummary } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/flowModelSummary';
import ImportableFlowModels from './ImportableFlowModels';

export const FlowModelImporter = ({ close }) => {
  const [existingFlowModels, setExistingFlowModels] = useState<
    IFlowModelSummary[]
  >([]);
  const [disableImport, setDisableImport] = useState(true);
  const [importInProgress, setImportInProgress] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [importables, setimportables] = useState<IImportable[]>([]);
  const fileInputRef = React.createRef<HTMLInputElement>();

  const flowModelImportService = useService<FlowModelImportService>(
    'flowModelImportService'
  );
  const flowModelApiService = useService<SharedAngular.FlowModelApiService>(
    'flowModelApiService'
  );
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');

  const notificationService = useService<SharedAngular.NotificationService>(
    'notificationService'
  );

  useEffect(() => {
    flowModelApiService.getFlowModels().then((flowModels) => {
      const processMaps = flowModels.filter(
        (fm) =>
          fm.publishTypeValue ===
          flowinglyConstants.flowModelPublishType.PROCESS_MAP
      );
      setExistingFlowModels(processMaps);
    });
  }, []);

  const fileSelectChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = [...e.target.files].filter(
      (file) => !fileAlreadyExists(file)
    );
    addNewFiles(newFiles);
  };

  const addNewFiles = (newFiles: File[]) => {
    setDisableImport(true);
    const allFiles = newFiles.concat(files);
    setFiles(allFiles);
    flowModelImportService
      .getImportableFlowModels(allFiles)
      .then((newImportables) => {
        importables.forEach((oldImportable) => {
          const newImportable = newImportables.find(
            (i) => i.key === oldImportable.key
          );
          if (newImportable) {
            newImportable.state = oldImportable.state;
            newImportable.willImport = oldImportable.willImport;
          }
        });
        const importableFlowModels = newImportables.filter(
          (importable): importable is ImportableFlowModel =>
            importable instanceof ImportableFlowModel
        );

        importableFlowModels.forEach((importable) => {
          if (existingFlowModels.some((fm) => fm.name === importable.name)) {
            importable.nameClash = true;
            importable.willImport = false;
          }
        });
        newImportables
          .filter(
            (importable): importable is ImportableFlowModelAttachments =>
              importable instanceof ImportableFlowModelAttachments
          )
          .forEach((flowModelAttachments) => {
            if (flowModelAttachments.key.endsWith('.zip')) {
              const fileRegex = /<p>[^|]+\|([^|]+)\|[^|]+<\/p>/g;
              importableFlowModels.forEach((flowModel) => {
                if (!flowModel.flowinglyNodes) {
                  return;
                }
                const fileNames = flowModel.flowinglyNodes.flatMap((node) =>
                  node.Card.formElements.flatMap((field) =>
                    [...field.value.matchAll(fileRegex)].map(
                      (match) => match[1]
                    )
                  )
                );
                const noSpaceFileNames = fileNames.map((fn: string) =>
                  fn.replaceAll(' ', '')
                );
                const files = flowModelAttachments.attachments.filter(
                  (a) =>
                    fileNames.indexOf(a.htmlEncodedName) > -1 ||
                    noSpaceFileNames.indexOf(
                      a.htmlEncodedName.replaceAll(' ', '')
                    ) > -1
                );
                flowModel.attachments = files;
              });
            } else {
              const flowModel = importableFlowModels.find(
                (importableFlowModel) =>
                  importableFlowModel.uniqueId === flowModelAttachments.key
              );
              if (flowModel) {
                flowModel.attachments = flowModelAttachments.attachments;
              }
            }
          });

        setimportables(newImportables);
        const disableImport = !newImportables.some(
          (i) => i.willImport && i.state === ImportableFlowModelState.Pending
        );
        setDisableImport(disableImport);
      });
  };

  const fileAlreadyExists = (file: File) => {
    return files.some(
      (f) =>
        f.name === file.name &&
        f.size === file.size &&
        f.lastModified === file.lastModified
    );
  };

  const importableUpdated = (
    index: number,
    propertyName: string,
    propertyValue
  ) => {
    importables[index][propertyName] = propertyValue;
    setimportables([...importables]);
    const disableImport = !importables.some(
      (importable) =>
        importable.willImport &&
        importable.state === ImportableFlowModelState.Pending
    );
    setDisableImport(disableImport);
  };

  const importSelectedFlowModels = async () => {
    setDisableImport(true);
    setImportInProgress(true);
    const updater = (updatedImportables: ImportableFlowModel[]) =>
      setimportables([...updatedImportables]);
    const importableFlowModels = importables.filter(
      (importable): importable is ImportableFlowModel =>
        importable instanceof ImportableFlowModel
    );
    await flowModelImportService.importFlowModels(
      importableFlowModels,
      updater
    );

    setImportInProgress(false);
    if (
      importables.some(
        (importable) =>
          importable.willImport &&
          importable.state === ImportableFlowModelState.Pending
      )
    ) {
      setDisableImport(false);
    }
  };

  const updateFlowModelOwners = async () => {
    setImportInProgress(true);
    const importableFlowModels = importables.filter(
      (importable): importable is ImportableFlowModel =>
        importable instanceof ImportableFlowModel
    );
    await flowModelImportService.updateFlowModelOwners(importableFlowModels);
    notificationService.showSuccessToast('Owners updated');
    setImportInProgress(false);
  };

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      const newFiles = [...event.dataTransfer.items]
        .filter((item) => item.kind === 'file')
        .map((item) => {
          return item.getAsFile();
        })
        .filter((file) => !fileAlreadyExists(file));
      addNewFiles(newFiles);
    }
  };

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dropZoneClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="flow-models-importer">
      <div hidden={importInProgress}>
        <input
          type="file"
          id="flow-model-importer-file-input"
          ref={fileInputRef}
          multiple
          onChange={fileSelectChanged}
        ></input>
        <div
          id="drop-zone"
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
          onClick={dropZoneClick}
        >
          <p>
            Click here to select files to import, or drag and drop files here.
          </p>
        </div>
      </div>
      <div className="p-30" hidden={!importInProgress}>
        Importing... please wait until this message disappears.
      </div>
      <div>
        <div className="importable-flow-models">
          {importables
            .filter(
              (importable): importable is ImportableFlowModel =>
                importable instanceof ImportableFlowModel
            )
            .map((importable, index) => (
              <ImportableFlowModels
                importable={importable}
                key={importable.key}
                index={index}
                updateParent={importableUpdated}
              />
            ))}
        </div>
        <div className="import-action">
          <button
            className="btn btn-primary right"
            disabled={disableImport}
            onClick={importSelectedFlowModels}
          >
            Import
          </button>
          <button
            className="btn btn-primary--blue right mr-20"
            disabled={importInProgress || importables.length === 0}
            onClick={updateFlowModelOwners}
          >
            Update Owners
          </button>
          <button
            className="btn-text-only right mr-20"
            onClick={close}
            disabled={importInProgress}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlowModelImporter;
