import React, { useEffect, useState } from 'react';

type Props = {
  copyText: string;
  isHtmlContent?: boolean;
  disabled?: boolean;
  copyTargetRef?: React.RefObject<HTMLDivElement>;
};

const QuickCopyButton = ({
  copyText,
  isHtmlContent = false,
  disabled = false,
  copyTargetRef
}: Props) => {
  const [showToolTip, setShowToolTip] = useState(false);
  useEffect(() => {
    if (showToolTip) {
      const timerId = setTimeout(() => {
        setShowToolTip(false);
      }, 1500);

      return () => clearTimeout(timerId);
    }
  }, [showToolTip]);

  const handleTooltipClick = () => {
    if (disabled) {
      return;
    }

    setShowToolTip(true);
    if (isHtmlContent) {
      const formattedText = copyTargetRef?.current?.innerText;
      const clipboardItem: Record<string, Blob> = {
        'text/html': new Blob([copyText], { type: 'text/html' })
      };

      if (formattedText) {
        clipboardItem['text/plain'] = new Blob([formattedText], {
          type: 'text/plain'
        });
      }
      navigator.clipboard.write([new ClipboardItem(clipboardItem)]);
      return;
    }

    navigator.clipboard.writeText(copyText);
  };

  return (
    <div
      className="copy-tooltip-wrapper"
      onClick={handleTooltipClick}
      aria-disabled={disabled}
    >
      <i className="fa-light fa-copy copy-tooltip-icon"></i>
      <p className={`copy-tooltip ${showToolTip ? 'fade-in' : 'fade-out'}`}>
        Copied!
      </p>
    </div>
  );
};

export default QuickCopyButton;
