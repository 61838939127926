/**
 * @ngdoc directive
 * @name libraryStatusBadge
 * @module flowingly.runner.directives
 * @description A simple directive for displaying a unpublished changed warning message
 * @usage
 * ```
 *   <unpublished-changes-message item="dataItem"></unpublished-changes-message>
 * ```
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.library')
  .component('unpublishedChangesMessage', {
    bindings: {
      item: '<'
    },
    controller: [
      function () {
        const $ctrl = this;
        this.$onInit = function () {
          $ctrl.showMessage = $ctrl.item.hasUnPublishedChanges;
        };
        this.$onChanges = function () {
          $ctrl.showMessage = $ctrl.item.hasUnPublishedChanges;
        };
      }
    ],
    template: `<div ng-show="$ctrl.showMessage" class="unpublished-changes-message secondary-text">This flow has unpublished changes</div>`
  });
