'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';

angular
  .module('flowingly.runner.setup')
  .controller('userDialogController', userDialogController);

userDialogController.$inject = [
  '$scope',
  'zxcvbnAdapter',
  'ngDialog',
  'userApiService',
  'roleApiService',
  'notificationService',
  'lodashService',
  'accountApiService',
  'pubsubService',
  'sessionService',
  'authService',
  'avatarService',
  'validationService',
  'APP_CONFIG',
  '$q',
  '$rootScope',
  '$timeout',
  'permissionsService',
  'flowinglyConstants'
];

function userDialogController(
  $scope,
  zxcvbn,
  ngDialog,
  userApiService,
  roleApiService,
  notificationService,
  lodashService,
  accountApiService,
  pubsubService,
  sessionService: SharedAngular.SessionService,
  authService,
  avatarService,
  validationService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  $q,
  $rootScope,
  $timeout,
  permissionsService,
  flowinglyConstants
) {
  const ctrl = this;
  ctrl.showSpinner = false;
  ctrl.user = $scope.ngDialogData;
  ctrl.businessName = sessionService.getBusinessName();
  ctrl.isSsoConnection = sessionService.isSso();
  ctrl.editMode = ctrl.user.id !== undefined;
  ctrl.emailValidationPattern = validationService.getEmailValidationPattern();
  ctrl.errorOnSubmit = false;
  ctrl.errorMessagesOnSubmit = '';
  ctrl.selectedRoles = [];
  ctrl.closeDialog = closeDialog;
  ctrl.passwordStrengthCalculator = passwordStrengthCalculator;
  ctrl.removeAvatar = removeAvatar;
  ctrl.submitChanges = submitChanges;

  ctrl.selectAvatar = selectAvatar;
  const avatarStateEnum = { unchanged: 0, updated: 1, removed: 2 };
  let avatarUpdateRemoveState = avatarStateEnum.unchanged;
  let updatedAvatarDataUrl = null;

  ctrl.isFormReadonly = !!APP_CONFIG.disableAdminUserEdit;

  init();

  ////////// PUBLIC METHODS
  function closeDialog(updated) {
    ngDialog.closeAll(updated);
  }

  function removeAvatar() {
    avatarUpdateRemoveState = avatarStateEnum.removed;
    ctrl.user.avatarUrl = null;
  }

  function submitChanges() {
    if (ctrl.selectedRoles.length === 0) {
      return false;
    }
    ctrl.user.delegateStepUserId =
      ctrl.delegatedStepUser && ctrl.user.inDelegationMode
        ? ctrl.delegatedStepUser.id
        : undefined;
    ctrl.user.delegateApprovalUserId =
      ctrl.delegatedApprovalUser && ctrl.user.inDelegationMode
        ? ctrl.delegatedApprovalUser.id
        : undefined;
    ctrl.user.delegateApprovalUserFullName =
      ctrl.delegatedApprovalUser && ctrl.user.inDelegationMode
        ? ctrl.delegatedApprovalUser.fullName
        : '';
    ctrl.user.delegateStepUserFullName =
      ctrl.delegatedStepUser && ctrl.user.inDelegationMode
        ? ctrl.delegatedStepUser.fullName
        : '';

    $rootScope.globalInDelegationMode = ctrl.user.inDelegationMode;

    if (
      ctrl.user.inDelegationMode &&
      !(ctrl.delegatedStepUser || ctrl.delegatedApprovalUser)
    ) {
      ctrl.delegationSubmitError = true;
      return false;
    }
    ctrl.delegationSubmitError = false;

    ctrl.user.managerUserId = ctrl.selectedManager
      ? ctrl.selectedManager.id
      : undefined;
    delete ctrl.user.roleId; // API will prefer .roleId over .roles for legacy runner compatibility
    ctrl.user.roles = ctrl.selectedRoles;
    ctrl.user.inviteUser = ctrl.sendWelcomeEmail;
    if (ctrl.user.id === undefined) {
      addUser();
    } else if (ctrl.currentUserId !== ctrl.user.id) {
      updateAnotherUserThenExitIfSuccess(); // It is not the local user so we just need to update the user record in the database
    } else if (ctrl.currentUserId === ctrl.user.id) {
      updateLocalUserThenExitIfSuccess(); // The user is the local user so update him/her and notifiy changes
    }
  }

  function selectAvatar(data) {
    const file = data.file;

    //acceptable file types
    const fileTypes = ['jpg', 'jpeg', 'png'];

    const reader = new FileReader();
    reader.onload = function () {
      //check for file type
      if (!lodashService.startsWith(file.type, 'image')) {
        //display error toast
        notificationService.showErrorToast(
          'Only image (jpg/png) is allowed as profile picture.',
          2000
        );
        data.clearInputMethod();
        return false;
      }
      //check for image type
      const imageType = lodashService.split(file.type, '/');
      //if it's not within the valid image file types
      if (lodashService.indexOf(fileTypes, imageType[1]) === -1) {
        //display error toast
        notificationService.showErrorToast(
          'Only *.jpg , *.jpeg, *.png images are allowed as profile picture.',
          2000
        );
        data.clearInputMethod();
        return false;
      }

      //don't allow more than 500 KB
      if (file.size > 500000) {
        //display error toast
        notificationService.showErrorToast(
          'Image should be less than 500 KB.',
          2000
        );
        return false;
      }

      updatedAvatarDataUrl = this.result;
      ctrl.user.avatarUrl = this.result;
      $scope.$apply();
      avatarUpdateRemoveState = avatarStateEnum.updated;

      data.clearInputMethod();
      return false;
    };

    if (file != null) reader.readAsDataURL(file);

    return false;
  }

  ///////// PRIVATE METHODS
  $scope.$on('kendoWidgetCreated', function (ev, widget) {
    if (widget === ctrl.tabStrip) {
      ctrl.tabStrip.select(0);
    }
  });

  function addUser() {
    userApiService.addUser(ctrl.user).then(function () {
      ngDialog.closeAll(true);
      notificationService.showSuccessToast('User Created');
    });
  }

  function clearAndUpdateUserCookie(user) {
    sessionService.clearUser();
    sessionService.setUser(user);
  }

  // We are updating another user - not the locally logged on user
  function updateAnotherUserThenExitIfSuccess() {
    userApiService.editUser(ctrl.user).then(function () {
      closeDialog(true);
      notificationService.showSuccessToast('User Updated');
    });
  }

  function init() {
    const user = $scope.ngDialogData;
    ctrl.currentUserId = sessionService.getUser().id;
    ctrl.showUserSettings =
      ctrl.currentUserId === ctrl.user.id &&
      APP_CONFIG.enableWebPushNotificationsNonMobile;

    return getUserProfileById(user.id)
      .then((user) => {
        return $q.all([
          user, // current user summary
          userApiService.getUserBasicInfoByFieldName(
            user.id,
            'delegateStepUserId'
          ), // get all users who have THIS user as a delegate
          userApiService.getUserBasicInfoByFieldName(user.delegateStepUserId), // get user you are delegating steps to
          userApiService.getUserBasicInfoByFieldName(
            user.delegateApprovalUserId
          ), // get user you are delegating approvals to
          userApiService.getUserBasicInfoByFieldName(user.managerUserId) // get manager user to see if they're deleted
        ]);
      })
      .then(function initializeControls(args) {
        const [
          currentUserSummary,
          delegators,
          myStepDelegatee,
          myApprovalDelegatee,
          myManager
        ] = args;

        ctrl.isWorkflowUser = authService.isWorkflowUser();
        ctrl.canChangeRole = permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.SETUP_USER_ACCESS
        );
        ctrl.canChangeTeam =
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.SETUP_TEAM_ACCESS
          ) && !APP_CONFIG.disableAdminTeamEdit;

        ctrl.user = currentUserSummary;
        if (ctrl.canChangeRole) {
          getRoles().then((roles) => {
            ctrl.multiSelectOptions = {
              dataTextField: 'name',
              dataValueField: 'id',
              dataSource: roles,
              itemTemplate: `<span title="#: name #">#: name #</span>`,
              tagTemplate: '<span title="#: name #">#: name #</span>'
            };
          });
        } else {
          ctrl.selectedRoles = ctrl.user.roles;
        }
        ctrl.usersWhoDelegatedToYou = delegators;
        ctrl.delegatedStepUser = myStepDelegatee;
        ctrl.delegatedApprovalUser = myApprovalDelegatee;
        ctrl.showInviteSection = ctrl.user.inviteUser;
        ctrl.selectedManager = myManager
          ? {
              id: ctrl.user.managerUserId,
              FullName: ctrl.user.managerName || ''
            }
          : {};
        ctrl.modelTitle =
          ctrl.currentUserId !== ctrl.user.id ? 'Edit User' : 'Edit Profile';
        setup();
      })
      .then(() => {
        ctrl.hasFinishedLoading = true;
        $timeout(function () {
          $('.changed-input').removeClass('changed-input');
          $('#body-content').on(
            'change keyup keydown',
            'input, textarea, select',
            function (e) {
              $(this).addClass('changed-input');
            }
          );
        }, 4000);
      });

    function setup() {
      // have to do this to make sure array is in correct format, once object pass from ngDialog data, in this case ctrl.user, eg ctrl.user = $scope.ngDialogData;
      // the object is added in some other unwanted stuff, even for a property with array, eg ctrl.user.teamsList this will break the functionality in teams tab
      const teamList = [];
      if (ctrl.user.teams) {
        for (const key in ctrl.user.teams) {
          const t = ctrl.user.teams[key];

          if (t.id) {
            teamList.push({
              id: t.id,
              name: t.name,
              displayName: t.displayName,
              searchEntityType: t.searchEntityType
            });
          }
        }
      }
      ctrl.user.teams = teamList;
      ctrl.user.avatarUrl = avatarService.getAvatarUrl(ctrl.user.id);
    }
  }

  function getRoles() {
    return roleApiService.getRoles().then(function (response) {
      if (ctrl.editMode) {
        if (ctrl.user.roles === undefined) {
          ctrl.user.roles = [{ id: ctrl.user.roleId }];
        }
        ctrl.selectedRoles = response.filter((role) =>
          ctrl.user.roles.some((userRole) => userRole.id === role.id)
        );
      }
      return lodashService.sortBy(response, 'name');
    });
  }

  /**
   * Given a user Id, get it's complete user summary
   * @param {number} userId
   */
  function getUserProfileById(userId) {
    if (!userId) {
      return undefined;
    }

    return userApiService.getUserProfileUsingId(userId).then((user) => {
      if (user != null) {
        return user;
      }
      throw new Error('User not found');
    });
  }

  function passwordStrengthCalculator() {
    const passScore = {
      0: { description: 'Very Weak', color: 'red', percent: '20%' },
      1: { description: 'Weak', color: 'red', percent: '40%' },
      2: { description: 'Fair', color: 'yellow', percent: '60%' },
      3: { description: 'Good', color: 'green', percent: '80%' },
      4: { description: 'Strong', color: 'green', percent: '100%' }
    };

    const pwd = ctrl.user.newPassword || '';
    const result = zxcvbn(pwd);
    const passResult = passScore[result.score];

    ctrl.passwordCalculator = {
      show: !!ctrl.user.newPassword,
      color: passResult.color,
      strength: passResult.percent,
      strengthText: passResult.description,
      suggestions: result.feedback.suggestions
    };
  }

  function updateLocalUserThenExitIfSuccess() {
    accountApiService.updateUserDetails(ctrl.user).then(function (response) {
      if (response.data.success) {
        if (avatarUpdateRemoveState === avatarStateEnum.unchanged) {
          completeLocalUserUpdate();
        } else {
          completeLocalUserUpdate();
        }
      } else {
        ctrl.errorOnSubmit = true;
        ctrl.errorMessagesOnSubmit =
          response.data.errorMessage || 'Profile updated fail.';
      }
    });
  }

  function completeLocalUserUpdate() {
    clearAndUpdateUserCookie(ctrl.user);
    pubsubService.publish(
      'CLIENT_USER_PROFILE_UPDATED',
      sessionService.getUser()
    );
    closeDialog(true);
    notificationService.showSuccessToast('Profile Updated');
  }
}
