(function () {
  'use strict';

  // Register the application's routes configuration.
  angular.module('flowingly.runner.setup').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.setup.users', {
      url: '/users',
      params: { flowId: null, title: 'Setup - Users' },
      views: {
        childcontent: {
          //if / when we upgrade to ui-router v1 this two controller lines can be replaced with
          //component: 'flowUsers',
          controllerAs: '$ctrl',
          controller: [
            '$state',
            'userApiService',
            'permissionsService',
            'flowinglyConstants',
            function (
              $state,
              userApiService,
              permissionsService,
              flowinglyConstants
            ) {
              if (
                !permissionsService.currentUserHasPermission(
                  flowinglyConstants.permissions.SETUP_USER_ACCESS
                )
              ) {
                $state.go('app.runner.flowsactive');
              }

              this.users = userApiService.users;
            }
          ],
          template: '<flow-users users="$ctrl.users"></flow-users>'
        }
      }
    });
  }
})();
