/**
 * @ngdoc directive
 * @name flowHistoryFieldSignature
 * @module flowingly.runner.flow
 * @description This comppnent is used to display signature history items.
 * @usage
 * ```
     <flow-history-field-signature item="item" ></flow-history-field-signature>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * item: the optionlist data to display (JSON)
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryFieldSignature', {
  bindings: {
    signedBy: '<',
    signedAt: '<',
    item: '<'
  },
  controller: [
    'dateService',
    function (dateService: DateService) {
      const $ctrl = this;

      $ctrl.$onInit = function () {
        $ctrl.formValue = $ctrl.item.Text;

        if (isFieldEmpty()) {
          $ctrl.noValueText = true;
          $ctrl.formValue = 'No information was entered.';
        }
        $ctrl.signedAtMessage = `Signed by ${
          $ctrl.signedBy
        } at ${dateService.formatUtcToLocal($ctrl.signedAt)}`;
      };

      function isFieldEmpty() {
        return !$ctrl.item.Text;
      }
    }
  ],
  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.field.signature.component.tmpl.html'
});
