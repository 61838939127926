/**
 * @ngdoc directive
 * @name categoryHeader
 * @module flowingly.runner.setup
 * @description  This comppnent is used to display the header for the category and will contain controls
 * @usage
 * ```
       <category-header on-categories-updated="$ctrl.handleCategoriesUpdated()"></category-header>
 * ``` 
 * * #### Outputs
 * * onCategoriesUpdated: this method is called when the category list is updated.
 * 
 * NOTE: Minimal content for now, but this might grow
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('categoryHeader', {
    bindings: {
      onCategoriesUpdated: '&'
    },
    template:
      '<button ng-if="!$ctrl.hideActions" ng-click="$ctrl.onAddCategoryClick()" class="btn btn-primary mt-25">Add Category</button>',
    controller: [
      'dialogService',
      'APP_CONFIG',
      'sessionService',
      'intercomService',
      'pubsubService',
      '$scope',
      function (
        dialogService,
        APP_CONFIG,
        sessionService,
        intercomService,
        pubsubService,
        $scope
      ) {
        var $ctrl = this;

        $ctrl.onAddCategoryClick = onAddCategoryClick;

        let isFlowModelAdmin = sessionService.isFlowModelAdmin();
        $ctrl.hideActions =
          !!APP_CONFIG.disableAdminCategoryEdit && isFlowModelAdmin;

        $scope.$watch(
          function () {
            return APP_CONFIG.disableAdminCategoryEdit;
          },
          function (value) {
            $ctrl.hideActions = !!value && isFlowModelAdmin;
          }
        );

        function onAddCategoryClick() {
          var category = {
            name: '',
            description: ''
          };

          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.categories/runner.setup.category.dialog.tmpl.html',
              controller: 'categoryDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: category
            })
            .then(function (result) {
              if (dialogService.isCloseModalWithCancelAction(result)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              if (result) {
                intercomService.trackEvent('Added Category');
                //raise an "event" to notify the parent
                $ctrl.onCategoriesUpdated();
                pubsubService.publish('CATEGORY_WITH_SUBCATEGORY_ADDED');
              }
            });
        }
      }
    ]
  });
})();
