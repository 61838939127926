/**
 * @ngdoc module
 * @name flowingly.runner.report
 * @module flowingly.runner.report
   @requires kendo.directives
 * @description This module configures the report state - used to display the details of a report.
 * 
 * ###Notes
 * Had to set the reports up as a separate module as for some reason I could not
 * get the UI ROUTER to work otherwise.
 */

'use strict';
import angular, { ITemplateCacheService } from 'angular';

const reportModule = angular.module('flowingly.runner.report', [
  'kendo.directives'
]);

reportModule.run([
  '$templateCache',
  function ($templateCache: ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.report/runner.report.tmpl.html',
      require('./runner.report.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.report/v1/runner.report.alldataitems.component.tmpl.html',
      require('./v1/runner.report.alldataitems.component.tmpl.html').default
    );
  }
]);

export default reportModule;
