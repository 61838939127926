/**
 * @ngdoc type
 * @module flowingly.runner.templates
 * @name RunnerTemplatesTenantsController
 *
 * @description Controller for admin Template Tenants page
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import {
  ITemplatePackForUi,
  ITemplateTenantForUi
} from '@Shared.Angular/@types/templates';
import * as angular from 'angular';

export default class RunnerTemplatesTenantsController {
  static $inject = ['templateService', 'notificationService'];

  private tenants: ITemplateTenantForUi[];
  private packs: ITemplatePackForUi[];
  private selectedTenant: ITemplateTenantForUi;
  private searchTerm = '';
  private requestPending = false;
  private dataLoaded = false;

  constructor(
    private templateService: SharedAngular.TemplateService,
    private notificationService: SharedAngular.NotificationService
  ) {
    const whenHaveTenants = this.loadTenants();
    const whenHavePacks = this.loadPacks();

    Promise.all([whenHaveTenants, whenHavePacks]).then(() => {
      this.dataLoaded = true;
      if (this.tenants.length > 0) {
        this.selectTenant(this.tenants[0]);
      }
    });
  }

  loadTenants() {
    return this.templateService.getTenants().then((tenants) => {
      tenants ||= [];
      this.sortByName(tenants);
      (tenants as ITemplateTenantForUi[]).forEach((t) => (t.visible = true));
      this.tenants = tenants as ITemplateTenantForUi[];
    });
  }

  loadPacks() {
    return this.templateService.getPacks().then((packs) => {
      packs ||= [];
      this.sortByName(packs);
      (packs as ITemplatePackForUi[]).forEach((t) => (t.visible = true));
      this.packs = packs as ITemplatePackForUi[];
    });
  }

  selectTenant(tenant: ITemplateTenantForUi) {
    tenant.packs ||= [];
    tenant.packs = this.packs.filter((pack) => {
      const tenantHasPack = tenant.packs.some((p) => p.id === pack.id);
      pack.visible = !tenantHasPack;
      return tenantHasPack;
    });

    this.selectedTenant = tenant;
  }

  search() {
    if (this.searchTerm === '') {
      this.tenants.forEach((t) => (t.visible = true));
      return;
    }
    const searchTerms = this.searchTerm.toLowerCase().trim().split(/\s+/);
    this.tenants.forEach((tenant) => {
      tenant.visible = searchTerms.every((term) => {
        return tenant.name.toLowerCase().indexOf(term) >= 0;
      });
    });
  }

  addPack(pack: ITemplatePackForUi) {
    pack.visible = false;
    this.selectedTenant.packs.push(pack);
    this.selectedTenant.hasUnsavedChanges = true;
    this.sortByName(this.selectedTenant.packs);
  }

  removePack(pack: ITemplatePackForUi) {
    this.selectedTenant.packs = this.selectedTenant.packs.filter(
      (p) => p !== pack
    );
    this.selectedTenant.hasUnsavedChanges = true;
    pack.visible = true;
  }

  saveTenant() {
    this.requestPending = true;
    this.templateService
      .saveTenant(this.selectedTenant)
      .then((tenantId) => {
        this.requestPending = false;
        this.notificationService.showSuccessToast(
          `Changes to '${this.selectedTenant.name}' saved`
        );
        this.tenants.find((t) => t.id === tenantId).hasUnsavedChanges = false;
      })
      .catch(() => {
        this.requestPending = false;
        this.notificationService.showErrorToast(
          'An error occurred while saving the Tenant'
        );
      });
  }

  private sortByName(data) {
    data.sort((a, b) => a.name.localeCompare(b.name));
  }
}

angular
  .module('flowingly.runner.templates')
  .controller(
    'runnerTemplatesTenantsController',
    RunnerTemplatesTenantsController
  );
