/**
 * @ngdoc directive
 * @name flow
 * @module flowingly.runner.flow
 * @description  This is used to show the flow header only in a list of flows.
 * @usage
 * ```
     <flow flow="flow" on-click-method="$ctrl.showFlowDetails(flow)" is-mobile="$ctrl.isMobile"></flow>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 * 
 * #### Events (Outputs)
 * * onClickMethod: method to call when flow header clicked
 */

import angular from 'angular';
angular.module('flowingly.runner.flow').component('flow', {
  bindings: {
    flow: '<',
    isMobile: '<', //show mobile view if set,
    onClickMethod: '&' //method to call when clicked
  },

  controllerAs: '$ctrl',
  template: `<flow-header ng-click="$ctrl.onClickMethod({ flow: $ctrl.flow })" flow="$ctrl.flow" is-mobile="$ctrl.isMobile"></flow-header>`
});
