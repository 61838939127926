'use strict';
import angular from 'angular';
angular
  .module('flowingly.runner.flow')
  .controller('flowCancelController', flowCancelController);

flowCancelController.$inject = ['$scope'];

function flowCancelController($scope: IScopeNgDialog) {
  const $ctrl = this;

  $ctrl.entity = $scope.ngDialogData.entity;
  $ctrl.stepTaskId = $scope.ngDialogData.stepTaskId;
  $ctrl.stepId = $scope.ngDialogData.stepId;
  $ctrl.flowId = $scope.ngDialogData.flowId;
  $ctrl.selectedList = [];
  $ctrl.showSelectUserOrTeams = false;

  // Cancel button event.
  $ctrl.cancelEntity = () => {
    if ($ctrl.currentForm.$valid) {
      switch ($ctrl.entity) {
        case 'flow': {
          $scope.closeThisDialog({
            flowId: $ctrl.flowId,
            stepId: $ctrl.stepId,
            comment: $ctrl.comment,
            selectedActors: $ctrl.selectedList,
            entity: $ctrl.entity
          });
          break;
        }
        case 'task': {
          $scope.closeThisDialog({
            stepTaskId: $ctrl.stepTaskId,
            comment: $ctrl.comment,
            selectedActors: $ctrl.selectedList,
            entity: $ctrl.entity
          });
          break;
        }
      }
    }
  };

  // Close button event.
  $ctrl.close = () => {
    $scope.closeThisDialog();
  };

  // Hide/show the cc Users/Teams controls.
  $ctrl.selectActors = () => {
    $ctrl.showSelectUserOrTeams = !$ctrl.showSelectUserOrTeams;
  };
}
