/**
 * @ngdoc service
 * @name webPushService
 * @module flowingly.runner.services
 *
 * @description A service for Web Push Notifications
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import { IConfirmDialogOptions } from '@Shared.Angular/flowingly.services/dialog.service';
import angular, {
  IHttpService,
  ILocationService,
  IQService,
  IScope
} from 'angular';

export enum WebPushPermission {
  Default = 'default',
  Granted = 'granted',
  Denied = 'denied'
}

export default class WebPushService {
  public static $inject = [
    '$q',
    '$http',
    '$location',
    'APP_CONFIG',
    'dialogService',
    'browserUtilsService',
    'accountApiService'
  ];

  private USER_PUSH_PERMISSION_NAME = 'userWebPushNotificationsEnabled';

  constructor(
    private $q: IQService,
    private $http: IHttpService,
    private $location: ILocationService,
    private APP_CONFIG: SharedAngular.APP_CONFIG,
    private dialogService: SharedAngular.DialogService,
    private browserUtilsService: SharedAngular.BrowserUtilsService,
    private accountApiService: AccountApiService
  ) {}

  public trySetupWebPush($scope: IScope) {
    if (this.$location.search().webPushNotification) {
      return;
    }

    if (this.browserUtilsService.isMobileDevice()) {
      if (this.browserUtilsService.isCordovaApp()) {
        return;
      } else if (!this.APP_CONFIG.enableWebPushNotificationsMobile) {
        this.removeUsersLocalSubscription();
        return;
      }
    } else if (!this.APP_CONFIG.enableWebPushNotificationsNonMobile) {
      this.removeUsersLocalSubscription();
      return;
    }

    if (!('serviceWorker' in navigator)) {
      return;
    }

    const userHasEnabledWebPush =
      this.APP_CONFIG.userWebPushNotificationsEnabled;
    const notificationExists = 'Notification' in window;
    if (!notificationExists) {
      if (userHasEnabledWebPush) {
        this.showWebAppInstallHelp($scope);
      }
      return;
    }

    if (userHasEnabledWebPush) {
      this.setupWebPush($scope);
    } else {
      this.removeAllUsersSubscriptions();
    }
  }

  private setupWebPush($scope: IScope) {
    if (Notification.permission === WebPushPermission.Default) {
      this.showFlowinglyPushNotificationRequest($scope)
        .then(() => {
          const settingChanged = this.accountApiService.changeUserSetting(
            this.USER_PUSH_PERMISSION_NAME,
            true.toString(),
            true
          );
          const permissionResult = Notification.requestPermission();
          return this.$q.all([settingChanged, permissionResult]);
        })
        .then((results) => {
          const permission = results[1];
          if (permission === WebPushPermission.Granted) {
            this.subscribeUserToWebPush();
          } else {
            this.accountApiService.changeUserSetting(
              this.USER_PUSH_PERMISSION_NAME,
              false.toString(),
              true
            );
          }
        })
        .catch((result) => {
          const wasDismissed = result === '$document';
          if (wasDismissed) {
            return;
          }
          this.accountApiService.changeUserSetting(
            this.USER_PUSH_PERMISSION_NAME,
            false.toString(),
            true
          );
        });
      return;
    }

    if (Notification.permission === WebPushPermission.Granted) {
      this.subscribeUserToWebPush();
      return;
    }

    if (Notification.permission === WebPushPermission.Denied) {
      const browserIsChrome = /CriOS\/|Chrome\//i.test(navigator.userAgent);
      const browserIsSafari = /Version\//i.test(navigator.userAgent);
      const isIos = this.browserUtilsService.isIos();
      const isWebApp = this.browserUtilsService.isWebApp();
      const dialogOptions = {
        template:
          'Client/runner.user.settings/webPush.permission.reset.tmpl.html',
        brandName: this.APP_CONFIG.brandingName,
        isWebApp: isWebApp,
        browserIsChrome: browserIsChrome,
        browserIsSafari: browserIsSafari,
        isIos: isIos
      };
      this.dialogService
        .showConfirmDialog($scope, dialogOptions)
        .catch((result) => {
          const wasDismissed = result === '$document';
          if (wasDismissed) {
            return;
          }
          this.accountApiService.changeUserSetting(
            this.USER_PUSH_PERMISSION_NAME,
            false.toString(),
            true
          );
        });
      return;
    }
  }

  private showFlowinglyPushNotificationRequest($scope: IScope) {
    const dialogOptions: IConfirmDialogOptions = {
      title: 'Push Notifications',
      message:
        'Would you like to enable push notifications? ' +
        'You can change this preference from the Settings page.'
    };
    return this.dialogService.showConfirmDialog($scope, dialogOptions);
  }

  private showWebAppInstallHelp($scope: IScope) {
    this.showFlowinglyPushNotificationRequest($scope)
      .then(() => {
        const isIos = this.browserUtilsService.isIos();
        const dialogOptions = {
          template: 'Client/runner.user.settings/webApp.install.tmpl.html',
          brandName: this.APP_CONFIG.brandingName,
          isIos: isIos
        };
        return this.dialogService.showConfirmDialog($scope, dialogOptions);
      })
      .catch((result) => {
        const wasDismissed = result === '$document';
        if (wasDismissed) {
          return;
        }
        this.accountApiService.changeUserSetting(
          this.USER_PUSH_PERMISSION_NAME,
          false.toString(),
          true
        );
      });
  }

  private subscribeUserToWebPush() {
    return navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        return registration.update().then(() => registration);
      })
      .then((registration) => {
        return registration.pushManager
          .getSubscription()
          .then((existingSubscription) => {
            if (existingSubscription) {
              const subscriptionKey = this.arrayBufferToUriSafeBase64(
                existingSubscription.options.applicationServerKey
              );
              if (subscriptionKey !== this.APP_CONFIG.webPushPublicKey) {
                return existingSubscription
                  .unsubscribe()
                  .then(() => registration);
              }
            }
            return Promise.resolve(registration);
          });
      })
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: this.APP_CONFIG.webPushPublicKey
        };
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then((pushSubscription) => {
        const p256dh = this.arrayBufferToUriSafeBase64(
          pushSubscription.getKey('p256dh')
        );
        const auth = this.arrayBufferToUriSafeBase64(
          pushSubscription.getKey('auth')
        );
        return this.$http.post(
          this.APP_CONFIG.apiBaseUrl +
            `account/webpushsubscriptions?p256dh=${p256dh}&auth=${auth}&endpoint=${pushSubscription.endpoint}`,
          null
        );
      });
  }

  public removeAllUsersSubscriptions() {
    return this.$http.delete(
      this.APP_CONFIG.apiBaseUrl + `account/webpushsubscriptions`
    );
  }

  public removeUsersLocalSubscription() {
    if (!navigator.serviceWorker) {
      return Promise.resolve();
    }
    return navigator.serviceWorker
      .getRegistration()
      .then((registration) => {
        if (!registration) {
          return;
        }
        return registration.pushManager.getSubscription();
      })
      .then((subscription) => {
        if (!subscription) {
          return;
        }
        return this.$http.delete(
          this.APP_CONFIG.apiBaseUrl +
            `account/webpushsubscriptions/${window.btoa(subscription.endpoint)}`
        );
      });
  }

  private arrayBufferToUriSafeBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window
      .btoa(binary)
      .replaceAll('=', '')
      .replaceAll('/', '_')
      .replaceAll('+', '-');
  }
}

angular
  .module('flowingly.runner.services')
  .service('webPushService', WebPushService);

export type WebPushServiceType = InstanceType<typeof WebPushService>;
