/**
 * @ngdoc directive
 * @name flowsTodoGroups
 * @module flowingly.runner.flows.todo
 * @description  This component is used to display the groups of flows that the user need to work on.
 *               This is the component you put on the main page to display the group list.
 * @usage
 * ```
     <flows-todo-groups groups="ctrl.categories"></flows-todo-groups>
 * ``` 
 * ### Notes
 *  Module structure:
 *    route (app.runner.flowstodo) --> runner.flows.todo.tmpl
 *      flowsTodoGroups                   // Handles the list of groups
 *        flowsTodoGroup                 // Handles each group (which contains a group.name and the group.flows)
 *          flowsTodoGroupHeader          // Handles the group header for a group of flows
 *          flowsTodoGroupFlows            // Handles the flow list for a group
 *              --> runner.flows.todo.group.flows.tmpl.html //uses the flow-list directive to present all the flows in the group
 * 
 * ### Properties
 * #### Inputs
 * * groups:  list of groups where each group contains the list of flows
 * * isMobile: show mobile view if set
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.flows.todo').component('flowsTodoGroups', {
  bindings: {
    groups: '<', // list of flows, grouped
    isMobile: '<' // show mobile view if set
  },
  controller: [
    'lodashService',
    'pubsubService',
    'avatarService',
    'flowinglyDiagramService',
    function (
      lodashService: Lodash,
      pubsubService: SharedAngular.PubSubService,
      avatarService: SharedAngular.AvatarService,
      flowinglyDiagramService: SharedAngular.FlowinglyDiagramService
    ) {
      const $ctrl = this;
      const subscriberId = 'runner.flows.todo.groups.component';

      this.$onInit = function () {
        pubsubService.subscribe(
          'CLIENT_USER_PROFILE_UPDATED',
          onClientUserProfileUpdatedReceived,
          subscriberId
        );

        function onClientUserProfileUpdatedReceived(event, data) {
          if (data.id !== undefined) {
            lodashService.forEach($ctrl.groups, function (flows) {
              lodashService.forEach(flows.Flows, function (flow) {
                if (flow.expanded) {
                  flowinglyDiagramService.generateProcessModel({
                    flow: flow,
                    applyAvatar: false
                  });
                }

                if (flow.Steps) {
                  lodashService.forEach(flow.Steps, function (step) {
                    step.ActorAssignedAvatarUrl = avatarService.getAvatarUrl(
                      step.ActorAssignedId
                    );
                  });
                }

                if (flow.IsStartedByYou === 1) {
                  flow.StartedByUserAvatarUrl = avatarService.getAvatarUrl(
                    data.id
                  );
                }
                lodashService.forEach(flow.Steps, function (step) {
                  if (
                    step.IsWaitingForYou === 1 &&
                    step.WhoIsAssignedStep === data.fullName
                  ) {
                    step.ActorAssignedAvatarUrl = avatarService.getAvatarUrl(
                      data.id
                    );
                  }
                  if (step.WhoCompletedStep === 'You') {
                    step.CompletedByAvatarUrl = avatarService.getAvatarUrl(
                      data.id
                    );
                  }
                });
              });
            });
          }
        }
      };

      this.$onDestroy = function () {
        pubsubService.unsubscribeAll(subscriberId);
      };
    }
  ],
  templateUrl: 'Client/runner.flows.todo/runner.flows.todo.groups.tmpl.html'
});
