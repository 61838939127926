// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/Client/assets/mobile/pwa_install_android_chrome_1.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/Client/assets/mobile/pwa_install_android_chrome_2.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/Client/assets/mobile/pwa_install_ios_safari_1.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/Client/assets/mobile/pwa_install_ios_safari_2.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"row\" id=\"web-app-install-dialog\"> <div class=\"col s12\"> <div class=\"title\"> <h3>Install the web app</h3> </div> <div class=\"content\"> <div> <p> Your device requires {{ctrl.options.brandName}} to be installed as a web app before it will allow push notifications. </p> <div ng-if=\"!ctrl.options.isIos\"> <p> To install, open the browser menu (the <i class=\"fa-light fa-ellipsis-vertical\"></i> icon, highlighted in the image below): </p> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <p class=\"mt-20\"> From the menu select <i>Install</i>, <i>Install App</i>, or <i>Add to Home screen</i>: </p> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> <p class=\"mt-20\"> Once the web app is installed, close this browser, then open the {{ctrl.options.brandName}} web app using the icon on your home screen, or from your apps list. </p> </div> <div ng-if=\"ctrl.options.isIos\"> <p> To install, open the browser share menu (the <i class=\"fa-light fa-arrow-up-from-square\"></i> icon): </p> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> <p class=\"mt-20\"> From the share menu select <i>Add to Home Screen</i>: </p> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"/> <p class=\"mt-20\"> Once the web app is installed, close this browser, then open the {{ctrl.options.brandName}} web app using the icon on your home screen, or from your apps list. </p> </div> </div> <br/> <div class=\"dialog-actions\"> <button ng-click=\"closeThisDialog()\" class=\"btn-text-only right mr-40\"> Ignore </button> <button ng-click=\"confirm()\" class=\"btn\">Done</button> </div> </div> </div> </div> ";
// Exports
export default code;