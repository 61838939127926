import angular from 'angular';

(function () {
  'use strict';

  angular.module('flowingly.runner').config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.iframe', {
      url: 'iframe/',
      params: { title: 'Flowingly' },
      template: ''
    });
  }
})();
