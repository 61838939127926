/**
 * @ngdoc directive
 * @name flowStatus
 * @module flowingly.runner.flow
 * @description  This component is used to display the status (progress) of a flow. 
 * @usage
 * ```
    <flow-status></flow-status>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 */
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowStatus', {
  bindings: {
    progress: '<' //width of progress bar (0 to 100)%
  },
  controller: [
    function () {
      const $ctrl = this;

      $ctrl.$onChanges = function (changes) {
        if (changes.progress && changes.progress.currentValue) {
          $ctrl.progress = changes.progress.currentValue;
          $ctrl.progressAlt = `${$ctrl.progress}%`;
        }
      };
    }
  ],
  controllerAs: '$ctrl',
  templateUrl:
    'Client/runner.flow/runner.flow.status/runner.flow.status.component.tmpl.html'
});
