/**
 * @ngdoc directive
 * @name databasesList
 * @module flowingly.runner.setup
 * @description This comppnent is used to display the list of databases
 * @usage
 * ```
     <databases-list databases="$ctrl.databases" on-databases-updated="$ctrl.handleDatabasesUpdated()"></databases-list>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * databases: the list of available databases (JSON[])
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('databasesList', {
    bindings: {
      databases: '<',
      onDatabasesUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.list.tmpl.html',
    controller: [
      '$scope',
      'APP_CONFIG',
      'databaseApiService',
      function ($scope, APP_CONFIG, databaseApiService) {
        let $ctrl = this;

        $ctrl.options = {
          dataSource: {
            data: $ctrl.databases,
            pageSize: APP_CONFIG.gridReportPageSize,
            sort: {
              field: 'name',
              dir: 'asc'
            }
          },
          noRecords: {
            template: '<databases-no-results></databases-no-results>'
          },
          scrollable: true,
          pageable: true,
          sortable: true,
          resizable: true,
          filterable: {
            mode: 'row'
          },
          columns: getColumns()
        };

        $ctrl.handleDatabasesUpdated = () => {
          databaseApiService.getDatabases().then((databases) => {
            angular.copy(databases, $ctrl.databases);
            //refresh the grid (read updates data source and refreshs grid)
            $scope.databasesGrid.dataSource.read();
          });
        };

        $ctrl.$onChanges = function (changes) {
          if (!changes.databases.isFirstChange()) {
            $scope.databasesGrid.dataSource.read();
            $ctrl.gridNeedResize = true;
          }
        };

        $ctrl.gridInitDone = true;

        /// PRIVATE METHODS //////////////////////////////////

        function getColumns() {
          //Note: field needs to match the name (and case) of the returned data
          return [
            {
              field: 'name',
              title: 'Name',
              filterable: {
                cell: {
                  operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
                  showOperators: false,
                  template: function (args) {
                    args.element.kendoAutoComplete({
                      dataSource: new kendo.data.DataSource({
                        data: $ctrl.databases
                      }),
                      dataTextField: 'name', //enables filtering on specified column
                      placeholder: 'Search by Name',
                      valuePrimitive: true, //placeholder for text input
                      filter: 'contains' //default autocomplete uses 'starts with'
                    });
                  }
                }
              }
            },
            {
              field: 'isUserExtension',
              fieldType: 'CheckBox',
              title: 'Enable User Extension?',
              width: '200px',
              filterable: false,
              template: (record) => {
                return record.isUserExtension === true ? 'Yes' : '';
              }
            },
            {
              title: 'Actions',
              width: '120px',
              filterable: false,
              template:
                "<databases-list-actions database='dataItem' on-databases-updated='$ctrl.handleDatabasesUpdated()'></databases-list-actions>"
            }
          ];
        }
      }
    ]
  });
})();
