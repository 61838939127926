/**
 * @ngdoc directive
 * @name flowReportGroupContainer
 * @module flowingly.runner.reports
 * @description  This component is used to group the content displayed below the group header.
 *               It is responsible for opening and closing the report groups within the list
 * @usage
 * ```
    <flow-report-group-container group="$ctrl.group" is-mobile="$ctrl.isMobile"></flow-report-group-container>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * group: this group
 * * isMobile :show mobile view if set,
 */
'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.reports')
  .component('flowReportGroupContainer', {
    bindings: {
      group: '<', //this group
      isMobile: '<' //show mobile view if set,
    },
    controller: [
      'flowsUtilityService',
      function (flowsUtilityService: SharedAngular.FlowsUtilityService) {
        const $ctrl = this;

        this.$onInit = function () {
          $ctrl.group.flowModels = flowsUtilityService.orderItemsAlphabetically(
            $ctrl.group.flowModels,
            'flowModelName'
          );
        };
      }
    ],
    controllerAs: '$ctrl',
    template: `
                <div class="flow-list" ng-class ="{'isMobile': $ctrl.isMobile}">
                    <ul>
                        <li ng-repeat="flow in $ctrl.group.flowModels">
                            <flow-report-list-header flow="flow" is-mobile="$ctrl.isMobile"></flow-report-list-header>
                        </li>
                    </ul>
                </div>
            `
  });
