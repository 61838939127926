import React from 'react';
import { ProcessMapStepForm } from '@Client/runner.processmapsv2/bridges/ProcessMapStepForm';

type Props = {
  selectedNode: unknown;
};

const StepForm = (props: Props) => {
  const { selectedNode } = props;

  return (
    <ProcessMapStepForm
      selectedNode={selectedNode}
      dynamicHeight={false}
    ></ProcessMapStepForm>
  );
};
export default StepForm;
