import { react2angular } from '@Shared.Angular/flowingly.helper/react2angular';
import DeleteModal from '../delete/DeleteModal';

const DeleteModalBridge = react2angular(DeleteModal, [
  'isOpen',
  'title',
  'subject',
  'closeModal',
  'deleteAction',
  'confirmationWord'
]);

export default DeleteModalBridge;
