import { react2angular } from '@Shared.Angular/flowingly.helper/react2angular';
import CategoryNavigation from '../components/CategoryNavigation';

const CategoryNavigationBridge = react2angular(CategoryNavigation, [
  'categories',
  'categoryClicked',
  'expandedCategory',
  'selectedIndex',
  'selectedId'
]);
export default CategoryNavigationBridge;
