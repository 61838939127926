/**
 * @ngdoc directive
 * @name flowTimelineFile
 * @module flowingly.runner.directives
 * @description Directive for displaying the readonly details for a file previously uploaded.
 * https://bizflo.atlassian.net/wiki/display/TECH/Notes+On+File+Upload
 * @usage
 * ```
 *   <timeline-file filemeta="file" ng-repeat="file in $ctrl.files">
 * ```
 */
'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.directives').component('flowTimelineFile', {
  bindings: {
    //the metadata of the file to display
    filemeta: '<'
  },
  controller: [
    'pubsubService',
    'fileService',
    function (
      pubsubService: SharedAngular.PubSubService,
      fileService: SharedAngular.FileService
    ) {
      const $ctrl = this;

      $ctrl.downloadClick = downloadClick;

      this.$onInit = function () {
        if ($ctrl.filemeta.fileId !== undefined) {
          fileService
            .getFileMetaData($ctrl.filemeta.fileId)
            .then((metaData) => {
              $ctrl.filename = metaData.filename;
              pubsubService.publish('FILEUPLOAD_FILE_READY', undefined);
            });
        }
      };

      function downloadClick() {
        fileService.downloadById($ctrl.filemeta.fileId);
      }
    }
  ],
  templateUrl: 'Client/runner.directives/flow/flow.timeline.file.tmpl.html'
});
