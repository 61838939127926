/**
 * @ngdoc module
 * @name flowingly.runner.processmap
 * @module flowingly.runner.processmap
 * @description This module configures the page used to processmap flows.
 */
const m = angular.module('flowingly.runner.processmap', []);
m.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.processmaps/runner.processmap.component.tmpl.html',
      require('./runner.processmap.component.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.processmaps/runner.processmap.list.component.tmpl.html',
      require('./runner.processmap.list.component.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.processmaps/runner.processmaps.tmpl.html',
      require('./runner.processmaps.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.processmaps/tab/left-pane/runner.processmap.tab.process-details.tmpl.html',
      require('./tab/left-pane/runner.processmap.tab.process-details.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.processmaps/tab/right-pane/runner.processmap.tab.step-details.tmpl.html',
      require('./tab/right-pane/runner.processmap.tab.step-details.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.processmaps/tab/right-pane/runner.processmap.tab.step-form.tmpl.html',
      require('./tab/right-pane/runner.processmap.tab.step-form.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.processmaps/view/runner.processmap.view.tmpl.html',
      require('./view/runner.processmap.view.tmpl.html').default
    );
  }
]);
