import React, { useState, useEffect } from 'react';
import ProcessMap from './ProcessMap/ProcessMap';
import ProcessDetails from './ProcessDetails/ProcessDetails';
import Feedback from './Feedback/Feedback';
import Header from './Header/Header';
import { useService } from '@Client/runner.hooks/useService';
import { Box } from '@mui/material';
import CustomTabs from '../CustomTab/CustomTabs';
import CustomTabPanel from '../CustomTab/CustomTabPanel';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { CustomTab } from '@Client/runner.processmapsv2/@types/customTab';
import IProcessMap from '@Shared.Angular/@types/processMap';

type Props = {
  processMapId: string;
  processMapHeight: number;
  onProcessMapRetrieved: (processMap: IProcessMap) => void;
  onSelectedNodeDataChange: (data: unknown) => void;
  hideFeedBack: boolean;
  hideFlowModelOpenButton: boolean;
};

const LeftPanel = (props: Props) => {
  const {
    processMapId,
    processMapHeight,
    onSelectedNodeDataChange,
    onProcessMapRetrieved,
    hideFeedBack,
    hideFlowModelOpenButton
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [processMap, setProcessMap] = useState<IProcessMap>({} as IProcessMap);
  const [commentTargetType, setCommentTargetType] = useState(null);
  const [commentLength, setCommentLength] = useState(0);
  const [editableByCurrentUser, setEditableByCurrentUser] = useState(false);
  const appConfig = useService<AppConfig>('APP_CONFIG');
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');

  useEffect(() => {
    // set ProcessMap, the default tab when switching between components using breadcrumbs
    handleChange(null, 0);
  }, [processMapId]);

  const shouldFeedbackBeVisible = (editableByCurrentUser: boolean) => {
    return (
      appConfig.flowModelFeedbackVisibility.toLowerCase() === 'all' ||
      (appConfig.flowModelFeedbackVisibility.toLowerCase() === 'flowowners' &&
        editableByCurrentUser)
    );
  };

  const handleChange = (
    _event: Event,
    newValue: React.SetStateAction<number>
  ) => {
    setSelectedTab(newValue);
    onSelectedNodeDataChange(null);
  };

  const handleSelectedNodeDataChange = (data) => {
    onSelectedNodeDataChange(data);
  };

  const handleProcessMapLoad = (data) => {
    setProcessMap(data);
    if (shouldFeedbackBeVisible(data.editableByCurrentUser)) {
      setCommentTargetType(flowinglyConstants.commentTargetType.FLOW_MODEL);
    }
    setEditableByCurrentUser(data.editableByCurrentUser);
  };

  const handleCommentsUpdate = (totalComments: number) => {
    setCommentLength(totalComments);
  };

  const processMapTabIndex = 0;
  const processDetailsTabIndex = 1;
  const feedbackTabIndex = 2;

  const tabs: CustomTab[] = [
    { label: 'Process Map' },
    { label: 'Process Details' },
    {
      label: `${
        commentLength > 0 ? `Feedback (${commentLength})` : 'Feedback'
      }`,
      isDisabled: !shouldFeedbackBeVisible(editableByCurrentUser),
      isHidden: hideFeedBack
    }
  ];

  return (
    <Box width={'100%'} height={'100%'}>
      <Header
        processOwner={processMap.processOwner}
        processDescription={processMap.description}
      />
      <CustomTabs value={selectedTab} onChange={handleChange} tabs={tabs} />
      <CustomTabPanel value={selectedTab} index={processMapTabIndex}>
        <ProcessMap
          hideFlowModelOpenButton={hideFlowModelOpenButton}
          processMapHeight={processMapHeight}
          isVisible={selectedTab === processMapTabIndex}
          processMapId={processMapId}
          onProcessMapRetrieved={onProcessMapRetrieved}
          onSelectedNodeDataChange={handleSelectedNodeDataChange}
          onProcessMapLoad={handleProcessMapLoad}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={processDetailsTabIndex}>
        <ProcessDetails
          hideFlowModelOpenButton={hideFlowModelOpenButton}
          {...processMap}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={feedbackTabIndex}>
        <Feedback
          commentAdded
          commentTargetId={processMapId}
          commentTargetType={commentTargetType}
          editableByCurrentUser={editableByCurrentUser}
          onCommentsUpdate={handleCommentsUpdate}
        ></Feedback>
      </CustomTabPanel>
    </Box>
  );
};

export default LeftPanel;
