/**
 * @ngdoc directive
 * @name flowNothingTodoMessage
 * @module flowingly.runner.directives
 * @description A simple directive for displaying an (approporiate) empty state message.
 * @usage
 * ```
 *   <flow-nothing-todo-message items="ctrl.flows"></flow-nothing-todo-message>
 * ```
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.directives')
  .component('flowNothingTodoMessage', {
    bindings: {
      //the list of items on the page
      items: '<',
      //the page type
      page: '<'
    },
    controller: [
      function () {
        const $ctrl = this;
        this.$onInit = function () {
          switch (this.page) {
            case 'flowsIn':
              $ctrl.message = 'You have not completed any flows yet.';
              return;

            case 'flowsStart':
              $ctrl.message =
                'You have not started any flows that have been completed yet.';
              return;

            default:
              $ctrl.message =
                'You are all caught up!  You are not participating in any flow.';
              return;
          }
        };
      }
    ],
    template: [
      '<div class="row mt-50" ng-if="$ctrl.items && $ctrl.items==0">',
      '<div class="col s12">',
      '<h5 class="align-center">{{$ctrl.message}}</h5>',
      '</div>',
      '</div>'
    ].join('')
  });
