angular.module('flowingly.runner.services').filter('ellipsis', [
  function () {
    return function (input: string, params: string) {
      const maxCount: number = +params;
      if (+params) {
        if (input.length > maxCount) {
          return input.substr(0, maxCount - 3) + '...';
        } else {
          return input;
        }
      } else {
        console.error(
          'Ellipsis filter received a non-positive number as a character limit'
        );
        return input;
      }
    };
  }
]);
