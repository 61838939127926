import React, { useEffect, useState } from 'react';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { Dialog } from '@mui/material';
import CategoryEditModal from '../Modals/Edit/EditModal';
import CategoryDeleteModal from '../Modals/Delete/DeleteModal';
import CategoryAddModal from '../Modals/Add/AddModal';
import { CategoryDeleteWarningStatus } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { Guid } from '@Shared.Angular/@types/guid';

type Props = {
  id: string;
  categoryName: string;
  description: string;
  approverId?: Guid;
  parentId: string;
  numberOfFlows: number;
  level: number;
  fetchSubCategories: () => Promise<void>;
};

const CategoryListActions = (props: Props) => {
  const {
    id,
    categoryName,
    description,
    approverId,
    parentId,
    numberOfFlows,
    level,
    fetchSubCategories
  } = props;

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] =
    useState<CategoryDeleteWarningStatus>();
  const [subCategoriesToBeDeleted, setSubCategoriesToBeDeleted] = useState([]);

  const categoryApiService =
    useService<SharedAngular.CategoryApiService>('categoryApiService');

  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');

  const appConfig = useService<SharedAngular.APP_CONFIG>('APP_CONFIG');

  const [maxSubCategories, setMaxSubCategories] = useState(
    appConfig.maxSubCategories
  );

  const isSubcategoryAllowed = level < maxSubCategories;

  useEffect(() => {
    setMaxSubCategories(appConfig.maxSubCategories);
  }, []);

  const handleEditClose = () => setEditOpen(false);
  const handleAddClose = () => setAddOpen(false);
  const handleDeleteClose = () => setDeleteOpen(false);

  const editClick = () => {
    setEditOpen(true);
  };

  const deleteClick = async () => {
    try {
      appInsightsService.startEventTimer('subcategoryDeleteWarning');
      const response = await categoryApiService.getSubCategoryWarning(id);
      setDeleteStatus(response.status);
      setSubCategoriesToBeDeleted(response.subCategoryFlowModels);
      setDeleteOpen(true);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const addSubCategory = async (event) => {
    setAddOpen(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly'
      }}
    >
      <span
        onClick={addSubCategory}
        title="Add Subcategory"
        className={`list-actions__action ${
          isSubcategoryAllowed ? '' : 'hidden-button'
        }`}
      >
        <i className="fa-light fa-plus" id={`add-subcategory-button-${id}`}></i>
      </span>

      <span
        onClick={editClick}
        title="Edit Category"
        className="list-actions__action"
      >
        <i
          className="fa-light fa-pencil"
          id={`edit-subcategory-button-${id}`}
        ></i>
      </span>

      <Dialog
        open={addOpen}
        onClose={handleAddClose}
        PaperProps={{ className: 'category-dialog' }}
      >
        <CategoryAddModal
          parentId={id}
          closeModal={handleAddClose}
          fetchSubCategories={fetchSubCategories}
        />
      </Dialog>

      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        PaperProps={{ className: 'category-dialog' }}
      >
        <CategoryEditModal
          categoryId={id}
          categoryName={categoryName}
          description={description || ''}
          approverId={approverId}
          parentId={parentId || ''}
          closeModal={handleEditClose}
          fetchSubCategories={fetchSubCategories}
        />
      </Dialog>
      <span
        onClick={deleteClick}
        title="Delete Category"
        className="list-actions__action"
      >
        <i
          className="fa-light fa-trash-can"
          id={`delete-subcategory-button-${id}`}
        ></i>
      </span>

      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        PaperProps={{ className: 'category-dialog' }}
      >
        <CategoryDeleteModal
          deleteStatus={deleteStatus}
          categoryName={categoryName}
          numberOfFlows={numberOfFlows}
          id={id}
          subCategoriesToBeDeleted={subCategoriesToBeDeleted}
          closeModal={handleDeleteClose}
          fetchSubCategories={fetchSubCategories}
        />
      </Dialog>
    </div>
  );
};

export default CategoryListActions;
