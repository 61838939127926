/**
 * @ngdoc module
 * @name flowingly.signup
 * @module flowingly.signup
 * @description This module allows a business to signup to Flowingly.
 */
const m = angular.module('flowingly.signup', []);
m.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/signup/signup.tmpl.html',
      require('./signup.tmpl.html').default
    );
  }
]);
