(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller(
      'setupDeleteComponentWarningDialogController',
      setupDeleteComponentWarningDialogController
    );

  setupDeleteComponentWarningDialogController.$inject = ['$scope', 'ngDialog'];

  function setupDeleteComponentWarningDialogController($scope, ngDialog) {
    var ctrl = this;
    ctrl.closeDialog = closeDialog;
    ctrl.paragraphs = [];
    ctrl.paragraphs.push(
      `This ${$scope.ngDialogData.componentTypeName} Component cannot be deleted as it's used by the following published Flow Models.`
    );
    ctrl.paragraphs.push($scope.ngDialogData.flowModelNames);

    function closeDialog() {
      // cancel the delete
      ngDialog.closeAll(undefined);
    }
  }
})();
