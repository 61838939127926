import angular, { IScope } from 'angular';

class ImportDDZoneController {
  static $inject = ['$scope', 'runnerImportService'];
  private file;
  private onFileUpdated;
  private ddZone = angular.element('.import-modal__file-to-import');

  constructor(
    private $scope: IScope,
    private runnerImportService: RunnerImportService
  ) {}

  $onInit() {
    this.ddZone
      .on('drag dragstart dragend dragover dragenter dragleave drop', (e) => {
        e.preventDefault();
        e.stopPropagation();
      })
      .on('dragover dragenter', () => {
        this.ddZone.addClass('import-modal__file-to-import--dragover');
      })
      .on('dragleave dragend drop', () => {
        this.ddZone.removeClass('import-modal__file-to-import--dragover');
      })
      .on('drop', (e: any) => {
        this.file = e.originalEvent.dataTransfer.files[0];

        if (this.file && !this.isWrongFormat()) {
          this.onFileUpdated({ needDigest: true });
        }

        if (!this.$scope.$$phase) {
          this.$scope.$digest(); // need notify Angular there are some changes outside of the Angular context
        }
      });
  }

  $onDestroy() {
    this.ddZone.unbind();
  }

  deleteFile() {
    this.file = undefined;
    this.onFileUpdated({ needDigest: false });
  }

  isWrongFormat() {
    if (this.file) {
      return !this.runnerImportService.isCsvFile(this.file.name);
    }

    return false;
  }
}

class RunnerImportDDZone implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      file: '=',
      onFileUpdated: '&',
      onCloudClick: '&'
    };

    this.templateUrl =
      'Client/runner.import/runner.import.drag-drop-zone.tmpl.html';
  }

  controller = ImportDDZoneController;
}

angular
  .module('flowingly.runner.import')
  .component('runnerImportDragDropZone', new RunnerImportDDZone());
