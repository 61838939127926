import React, { useEffect, useState } from 'react';
import {
  Typography,
  TextField,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import ModalFooter from '@Client/runner.shared/modal/Footer/Footer';
import ModalHeader from '@Client/runner.shared/modal/Header/Header';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import {
  DropDownTree,
  DropDownTreeChangeEvent,
  DropDownTreeExpandEvent,
  DropDownTreeFilterChangeEvent
} from '@progress/kendo-react-dropdowns';
import { mapTree, extendDataItem } from '@progress/kendo-react-common';
import { CategoryDeleteWarningStatus } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { FilterDescriptor } from '@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor';

type Props = {
  deleteStatus: number;
  closeModal: () => void;
  categoryName: string;
  id: string;
  numberOfFlows: number;
  fetchSubCategories: () => void;
  subCategoriesToBeDeleted: object[];
};

const warningText = (
  deleteStatus: number,
  categoryName: string,
  deleteText: string,
  handleInputChange,
  numberOfFlows: number
) => (
  <div>
    <fieldset
      className="categoriesv2-delete-modal-fieldset"
      style={{ padding: '15px 15px 30px' }}
    >
      <legend className="categoriesv2-delete-modal-legend">
        <b>Warning</b>
      </legend>
      <div>
        You are about to delete <b>{categoryName}</b>.
      </div>
      <div>This cannot be undone!</div>
      <br />
      <div>
        {deleteStatus === CategoryDeleteWarningStatus.subCategoriesWithFlows ||
        deleteStatus === CategoryDeleteWarningStatus.reassignCategory ? (
          <span>
            <i
              className="fa-light fa-triangle-exclamation categoriesv2-delete-modal-warning-icon"
              aria-hidden="true"
            ></i>
            &nbsp;{numberOfFlows} Flow Models will be reassigned to a new
            category.
          </span>
        ) : (
          ''
        )}
      </div>
      <div>
        Type <b>DELETE</b> to continue
      </div>
      <input
        id="delete-modal"
        className="categoriesv2-delete-modal-textfield"
        type="text"
        value={deleteText}
        onChange={handleInputChange}
      />
    </fieldset>
  </div>
);

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'name';
const subCategoriesField = 'subCategories';
const fields = {
  selectField,
  expandField,
  dataItemKey,
  subItemsField: subCategoriesField
};

const CategoryDeleteModal = (props: Props) => {
  const {
    deleteStatus,
    closeModal,
    categoryName,
    id,
    numberOfFlows,
    fetchSubCategories,
    subCategoriesToBeDeleted
  } = props;

  const [backData, setBackData] = useState([]);
  const [deleteText, setDeleteText] = useState('');

  const [replacementCategory, setReplacementCategory] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [deleteButtonEnabled, setDeleteButtonEnabledStatus] = useState(false);
  const [filter, setFilter] = React.useState<FilterDescriptor>({
    value: '',
    operator: 'contains'
  });
  const runnerCategoryFilterService = useService<RunnerCategoryFilterService>(
    'runnerCategoryFilterService'
  );

  const onNameFilterChange = (event: DropDownTreeFilterChangeEvent) =>
    setFilter(event.filter);

  const onChange = (event: DropDownTreeChangeEvent) => {
    setReplacementCategory(event.value);
  };

  const processTreeData = (data, state, fields) => {
    const { selectField, expandField, dataItemKey, subItemsField } = fields;
    const { expanded, value, filter } = state;
    const filtering = Boolean(filter && filter.value);

    return mapTree(
      filtering
        ? runnerCategoryFilterService.customFilterByName(
            data,
            filter,
            subItemsField
          )
        : data,
      subItemsField,
      (item) => {
        const props = {
          [expandField]: expanded?.includes(item[dataItemKey]),
          [selectField]: value && item[dataItemKey] === value[dataItemKey]
        };
        return filtering
          ? extendDataItem(item, subItemsField, props)
          : { ...item, ...props };
      }
    );
  };

  const expandedState = (item, dataItemKey, expanded) => {
    const nextExpanded = expanded.slice();
    const itemKey = item[dataItemKey];
    const index = expanded.indexOf(itemKey);
    index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

    return nextExpanded;
  };

  const onExpandChange = React.useCallback(
    (event: DropDownTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = React.useMemo(
    () =>
      processTreeData(
        backData,
        { expanded, replacementCategory, filter },
        fields
      ),
    [expanded, replacementCategory, backData, filter]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteText(event.target.value);
  };

  useEffect(() => {
    setDeleteButtonStatus(deleteStatus);
  }, [replacementCategory, deleteText]);

  const setDeleteButtonStatus = (status: number) => {
    if (status === CategoryDeleteWarningStatus.subCategoriesWithFlows) {
      setDeleteButtonEnabledStatus(false);
    } else if (status === CategoryDeleteWarningStatus.reassignCategory) {
      setDeleteButtonEnabledStatus(
        deleteText.toLowerCase() === 'delete' && replacementCategory
      );
    } else {
      setDeleteButtonEnabledStatus(deleteText.toLowerCase() === 'delete');
    }
  };

  const categoryApiService =
    useService<SharedAngular.CategoryApiService>('categoryApiService');

  const notificationService = useService<SharedAngular.NotificationService>(
    'notificationService'
  );

  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');

  const deleteCategory = async () => {
    appInsightsService.startEventTimer('subcategoriesdeleted');
    if (id === replacementCategory?.id) {
      notificationService.showErrorToast(
        'Category to reassign to cannot be the one you are deleting'
      );
      setDeleteText('');
      return;
    }
    try {
      await categoryApiService.deleteCategory(id, replacementCategory?.id);
      closeModal();
      await fetchSubCategories();
      notificationService.showSuccessToast('Category Deleted Successfully');
    } catch (err) {
      console.error(err);
      notificationService.showErrorToast('Error while deleting category');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await categoryApiService.getCategories();
        setBackData(response);
      } catch (error) {
        setBackData([]);
      }
    };
    fetchData();
  }, []);

  return (
    <Box>
      <ModalHeader title="Delete Category"></ModalHeader>
      <br />
      {deleteStatus === CategoryDeleteWarningStatus.canBeDeleted ? (
        <Box>
          {warningText(
            deleteStatus,
            categoryName,
            deleteText,
            handleInputChange,
            numberOfFlows
          )}
        </Box>
      ) : deleteStatus === CategoryDeleteWarningStatus.reassignCategory ? (
        <Box className="categoriesv2-delete-modal-outer-content-container">
          <Box className="categoriesv2-delete-modal-content-container">
            <Box className="categoriesv2-delete-modal-inner-content-container">
              <Typography className="categoriesv2-delete-modal-inner-content-container-text">
                <b>Reassign Category</b>
              </Typography>
              <br />
              <Typography className="categoriesv2-delete-modal-inner-content-container-text">
                To ensure in progress & published flows continue to work, search
                for a category to replace <b>{categoryName}</b>
              </Typography>
            </Box>
            <Box className="categoriesv2-delete-modal-inner-content-container">
              <fieldset className="categoriesv2-delete-modal-fieldset-dropdown">
                <DropDownTree
                  style={{ width: '250px' }}
                  data={treeData}
                  value={replacementCategory}
                  onChange={onChange}
                  placeholder="Select Category"
                  textField={textField}
                  dataItemKey={dataItemKey}
                  selectField={selectField}
                  expandField={expandField}
                  subItemsField={subCategoriesField}
                  onExpandChange={onExpandChange}
                  filterable={true}
                  onFilterChange={onNameFilterChange}
                  filter={filter.value}
                />
              </fieldset>
            </Box>
          </Box>
          <br />
          <Typography className="categoriesv2-delete-modal-inner-content-container-text">
            When you delete a parent category, all of its sub-categories will be
            deleted as well.
          </Typography>
          <br />
          <Box>
            {warningText(
              deleteStatus,
              categoryName,
              deleteText,
              handleInputChange,
              numberOfFlows
            )}
          </Box>
        </Box>
      ) : (
        <Box className="categoriesv2-delete-modal-last-content-container">
          <Typography className="categoriesv2-delete-modal-inner-content-container-text">
            <b> {categoryName}</b> contains sub-categories with flow models
            published to them.
            <br />
            <br />
            Before deleting, you must first reassign the flow models that are
            published in these sub-categories to another category.
          </Typography>
          <br />
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 550 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Sub-Category</TableCell>
                  <TableCell>Flow Models Published</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subCategoriesToBeDeleted.map((subCategory) => (
                  <TableRow
                    key={subCategory.subCategoryName}
                    className="v2-categories-row"
                  >
                    <TableCell component="th" scope="row">
                      {subCategory.subCategoryName}
                    </TableCell>
                    <TableCell>{subCategory.flowModelCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <ModalFooter
        actionButtonText="Delete Category"
        actionButtonType="Danger"
        isActionDisabled={!deleteButtonEnabled}
        onCancelClick={closeModal}
        onActionClick={deleteCategory}
      ></ModalFooter>
    </Box>
  );
};

export default CategoryDeleteModal;
