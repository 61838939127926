(function () {
  'use strict';

  // Register the application's routes configuration.
  angular.module('flowingly.runner.setup').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.setup.teams', {
      url: '/teams',
      params: { flowId: null, title: 'Setup - Teams' },
      views: {
        childcontent: {
          //if / when we upgrade to ui-router v1 this two controller lines can be replaced with
          //component: 'flowTeams',
          controllerAs: '$ctrl',
          controller: [
            '$state',
            'permissionsService',
            'teamApiService',
            'flowinglyConstants',
            function (
              $state,
              permissionsService,
              teamApiService,
              flowinglyConstants
            ) {
              if (
                !permissionsService.currentUserHasPermission(
                  flowinglyConstants.permissions.SETUP_TEAM_ACCESS
                )
              ) {
                $state.go('app.runner.flowsactive');
              }

              this.teams = teamApiService.teams;
            }
          ],
          template: `<flow-teams teams="$ctrl.teams"></flow-teams>`
        }
      }
    });
  }
})();
