/**
 * @ngdoc directive
 * @name flowSidemenuItem
 * @module flowingly.directives.sidemenu
 * @description A directive that represents an item in the side menu
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 * 
   ####Attributes
   * item : The item to render
 * 
 * @usage
 * ```
      <flow-sidemenu-item ng-repeat="item in ctrl.menu" item="::item"></flow-sidemenu-item>
 * ```
 */
import angular, { IRootScopeService, IScope, ui } from 'angular';

angular.module('flowingly.directives.sidemenu').directive('flowSidemenuItem', [
  function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        item: '=',
        isMobile: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'lodashService',
        function (
          $rootScope: IRootScopeService,
          $scope: IScope,
          $state: ui.IStateService,
          $stateParams: ui.IStateParamsService,
          lodashService: Lodash
        ) {
          setHighlight(
            $state.current.name,
            $scope.item.target,
            $scope.item.data,
            $stateParams
          );

          $scope.mobileVer = $scope.isMobile ? '.mobile' : '';

          const stateListener = $rootScope.$on(
            '$stateChangeSuccess',
            function (event, toState, toParams) {
              setHighlight(
                $scope.item.target,
                toState.name,
                $scope.item.data,
                toParams
              );
            }
          );

          // Highlight or unhighlight menu item
          function setHighlight(name, toName, params, toParams) {
            if (!lodashService.isEmpty(toParams) && params != undefined) {
              $scope.item.active =
                name === toName &&
                JSON.stringify(params) === JSON.stringify(toParams);
            } else {
              if (name === 'app.runner.report') {
                name = 'app.runner.reports';
              }

              if (
                name &&
                (name.indexOf('app.runner.setup.') === 0 ||
                  name.indexOf('database') > -1)
              ) {
                name = 'app.runner.setup.users';
              }

              $scope.item.active = name === toName;
            }
          }

          // Called when user clicks on menu item (ng-click)
          $scope.navigate = function () {
            letsGo();
          };

          // Lets go to the state
          function letsGo() {
            const { target, data } = $scope.item;

            if (target === undefined) return;
            if (target === $state.current.name) return;

            if (data) {
              $state.go(target, data);
            } else {
              $state.go(target);
            }
          }

          // Performs this action when clicked
          $scope.action = function () {
            $scope.item.action($scope.item.param);
          };

          // Toggles open and close of dropdown (if dropdown menu)
          $scope.toggleOpenClose = function () {
            $scope.item.open = !$scope.item.open;
          };

          $scope.$on('$destroy', function () {
            //stop watching when scope is destroyed
            stateListener();
          });
        }
      ],
      template:
        `<li if-permitted="item.permissions" ng-class="{'label' : item.type=='label', active: item.active }" title="{{item.hovertext}}"> ` +
        `<div ng-include="'sidemenu-' + item.type + mobileVer +'.tmpl.html'"></div>` +
        `</li>`
    };
  }
]);
