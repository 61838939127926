'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, {
  IQService,
  IScope,
  ITimeoutService,
  IWindowService
} from 'angular';

angular.module('flowingly.runner.exago').component('exagoReport', {
  bindings: {
    reportMode: '<',
    reportFolder: '<',
    reportName: '<'
  },
  controller: [
    '$scope',
    '$window',
    'APP_CONFIG',
    'exagoJsApiResource',
    'usSpinnerService',
    '$q',
    '$timeout',
    function (
      $scope: IScope,
      $window: IWindowService,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      exagoJsApiResource: SharedAngular.ExagoJsApiResource,
      usSpinnerService: IUsSpinnerService,
      $q: IQService,
      $timeout: ITimeoutService
    ) {
      const $ctrl = this;
      const maxRetry = 10;
      let currentRetry = 0;
      let API = null;
      let waitingForExagoTimer = null;

      initialise();

      function initialise() {
        if (!APP_CONFIG.enableExago) {
          return;
        }

        GetOrWaitForExago()
          .then(() => {
            API = exagoJsApiResource.GetActiveSession();
            switch ($ctrl.reportMode) {
              case 'FullUI':
                ShowFullUI();
                break;
              case 'ExecuteReport':
                ShowReport();
                break;
              default:
                throw new Error(`undefined report mode (${$ctrl.reportMode})`);
            }
          })
          .catch((err) => {
            console.warn('Unable to load exago: \n' + err.stack);
          });
      }

      $scope.$on('$destroy', () => {
        if ($ctrl.reportMode !== 'ExecuteReport') {
          disposeAll();
          window.isExagoReportLoaded = false;
          window.onbeforeunload = null;
        } else {
          $('#ExagoWrapper-Persistent').hide();
          $('#Container-Report').hide();
        }
        // cancel exago timer
        $timeout.cancel(waitingForExagoTimer);
      });

      // call Exago Javascript API method to dispose (empty) all containers tagged as Exago containers (via exago class)
      function disposeAll() {
        if ($ctrl.reportMode === 'FullUI' && API) {
          (function flow4894HackerLevelFix() {
            /* This is some dodgy crap right here. I had to debug the damn
             * exago minified file in order to find a fix for this.
             *
             * So what exactly happens duuring the DisposeContainerContent?
             * Exago has something they call a master container which contains
             * bits and sorts of different pieces of codes. When you call
             * DisposeContainerContent, it for some reason, reappends this master
             * element into the body (despite it being already a child/grandchild)
             *
             * When you initiate a download, it appends an IFRAME with the
             * src="bla bla bla/DownloadReportFile.aspx?bla" that performs the
             * actual file download but this does not get cleaned up, so
             * when the main element is reappended into the body, the iframe
             * download gets appended again.
             *
             * So what we do is on dispose, we delete the iframe ourselves
             * before the reappending happens.
             */
            $(
              "#exagomastercontainer iframe[src*='DownloadReportFile.aspx']"
            ).remove();
          })();

          const exagoContainers = $('div.exago');

          for (let i = 0; i < exagoContainers.length; i++) {
            API.DisposeContainerContent(exagoContainers[i]);
          }
        }
      }

      /**
       * Show the Exago report in the main display section of the page.  This includes two
       * separate Exago reports and an additional text area.  This ensures that the full UI
       * is hidden, if previously displayed, container contents are reset, reports are
       * executed and displayed.
       */
      function ShowReport() {
        // Set up and display Chart
        const container = $('#SectionChart')[0];

        //var reportPath = "Ivan Reports\\UserList";//  container.getAttribute("data-name");  // Retrieve report name that was embedded in attribute on server side

        if (!window.isExagoReportLoaded) {
          if (window.isExagoReady) {
            usSpinnerService.stop('spinner-exago');
            API.ExecuteReport(
              container,
              'html',
              $ctrl.reportFolder + '\\' + $ctrl.reportName,
              null
            );
            window.isExagoReportLoaded = true;
          }
        } else {
          usSpinnerService.stop('spinner-exago');
          window.isExagoReportLoaded = true;
        }

        $('#ExagoWrapper-Persistent').show(); // Display newly-populated container
        $('#Container-Report').show();
        $('#Container-FullUI').hide();
      }

      /**
       * Show the full Exago UI in the main display section of the page.  This ensures that
       * the Exago report(s) section of the page is hidden and only the Exago UI is in view.
       */
      function ShowFullUI() {
        // Housekeeping
        disposeAll(); // Dispose all Exago-populated containers

        $('#Container-Report').hide(); // Hide Report/Process Dashboard since we are not working with that

        $('#Container-FullUI').show(); // Show Full UI/Process Analytics container; needs to be done before API call

        if (window.isExagoReady) {
          usSpinnerService.stop('spinner-exago');
          API.LoadFullUI($('#Container-FullUI')[0]); // Load Full UI to provided container element
        }
      }

      function GetOrWaitForExago() {
        if ($('#ExagoWrapper').length == 0) {
          throw new Error(
            `Missing element required for Exago to work (#ExagoWrapper)`
          );
        }

        if (currentRetry > maxRetry) {
          usSpinnerService.stop('spinner-exago');
          console.warn('Reached the maximum retry');
          return $q.reject('Reached the maximum retry');
        }

        if ($window.isExagoReady) {
          return $q.resolve();
        } else {
          usSpinnerService.spin('spinner-exago');
          console.info('waiting for Exago');

          waitingForExagoTimer = $timeout(2000).then(() => {
            currentRetry = currentRetry + 1;
            GetOrWaitForExago();
          });
          return waitingForExagoTimer;
        }
      }
    }
  ],
  template: `
                <div id="ExagoWrapper">
                    <div id="Container-FullUI" class ="exago">
                    </div>
                    <div class="row spinner">
                        <span us-spinner="{radius:30, width:8, length: 16}" spinner-key="spinner-exago" spinner-start-active="false"></span>
                    </div>
                </div>
            `
});
