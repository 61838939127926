/**
 * @ngdoc directive
 * @name runner.library.tabs.drafts
 * @module flowingly.runner.library
 * @description  This comppnent is the drafts tab of Library component
 * @usage
 */

import { LibraryDisplayTypes } from '@Shared.Angular/flowingly.services/flowingly.constants';

angular.module('flowingly.runner.library').component('flowLibraryTabsDrafts', {
  bindings: {
    flowModels: '<'
  },
  templateUrl:
    'Client/runner.library/runner.library.draft/runner.library.tabs.drafts.tmpl.html',
  controller: [
    function () {
      const $ctrl = this;
      this.$onInit = function () {
        $ctrl.draftDisplayType = LibraryDisplayTypes.DRAFT;
      };
    }
  ]
});
