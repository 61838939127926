import angular from 'angular';

export class RunnerCategoryFilterService {
  customFilterByName = (data, filter, subItemsField) => {
    const filterValue = filter?.value?.toLowerCase();
    const filterItem = (item) => {
      return (
        item.name.toLowerCase().includes(filterValue) ||
        (item[subItemsField] && item[subItemsField].some(filterItem))
      );
    };
    return data.filter(filterItem);
  };

  customFilterById = (data, filter, subItemsField) => {
    const filterValue = filter?.value;
    const filterItem = (item) => {
      return (
        item.id == filterValue ||
        (item[subItemsField] && item[subItemsField].some(filterItem))
      );
    };
    return data.filter(filterItem);
  };
}
angular
  .module('flowingly.runner.services')
  .factory(
    'runnerCategoryFilterService',
    () => new RunnerCategoryFilterService()
  );

export type RunnerCategoryFilterServiceType = InstanceType<
  typeof RunnerCategoryFilterService
>;
