import { LibraryDisplayTypes } from '@Shared.Angular/flowingly.services/flowingly.constants';

/**
 * @ngdoc directive
 * @name runner.library.tabs.workflows
 * @module flowingly.runner.library
 * @description  This component is the workflows tab of Library component
 * @usage
 */
angular
  .module('flowingly.runner.library')
  .component('flowLibraryTabsWorkflows', {
    bindings: {
      flowModels: '<'
    },
    templateUrl:
      'Client/runner.library/runner.library.workflows/runner.library.tabs.workflows.tmpl.html',
    controller: [
      function () {
        const $ctrl = this;
        this.$onInit = function () {
          $ctrl.workFlowDisplayType = LibraryDisplayTypes.WORKFLOW;
        };
      }
    ]
  });
