(function () {
  'use strict';
  // Register the application's routes configuration.
  angular.module('flowingly.runner.user.accept.invite').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.accept', {
      url: '/acceptInvite',
      params: { title: 'Accept invite' },
      templateUrl:
        'Client/runner.user.accept.invite/runner.user.accept.invite.tmpl.html',
      controller: 'acceptInviteController',
      controllerAs: 'ctrl'
    });
  }
})();
