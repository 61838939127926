/**
 * @ngdoc directive
 * @name usersListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a users row
 * @usage
 * ```
     <users-list-actions user-id='dataItem.id'></users-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * userId: id of user to act upon
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('usersListActions', {
    bindings: {
      user: '<',
      onUsersUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.users/runner.setup.users.list.actions.tmpl.html',
    controller: [
      '$scope',
      'dialogService',
      'notificationService',
      'userApiService',
      'sessionService',
      'setupDeleteApiService',
      function (
        $scope,
        dialogService,
        notificationService,
        userApiService,
        sessionService,
        setupDeleteApiService
      ) {
        var $ctrl = this;

        const user = 1;

        $ctrl.editClick = editClick;
        $ctrl.deleteClick = deleteClick;

        function editClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.tmpl.html',
              controller: 'userDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: $ctrl.user
            })
            .then(function (result) {
              // if result is boolean, true means user updated, false means edit user is cancelled
              if (
                result === false ||
                (result !== undefined &&
                  dialogService.isCloseModalWithCancelAction(result))
              ) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              //raise an 'event' to notify the parent
              $ctrl.onUsersUpdated();
            });
        }

        function deleteClick() {
          var currentUser = sessionService.getUser();
          if ($ctrl.user.email === currentUser.email) {
            notificationService.showErrorToast(
              'Sorry, You cannot delete yourself.'
            );
            return;
          }

          // check if the user has any step tasks assigned
          setupDeleteApiService
            .getUserAssignedStepTasks($ctrl.user.id)
            .then((response) => {
              if (
                response.status !== 200 ||
                !response.data ||
                !response.data.stepTasks
              ) {
                let message =
                  response.message ||
                  'Error occurred while getting user assigned step tasks';
                notificationService.showErrorToast(message);
                return;
              }

              // no assigned step tasks
              if (response.data.stepTasks.length === 0) {
                showDeleteUserDialog();
                return;
              }

              // show the step tasks warning dialog
              showStepTasksWarningDialog(response.data);
            });
        }

        function showDeleteUserDialog() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
              controller: 'setupDeleteDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: {
                itemToDelete: $ctrl.user,
                entityType: user
              }
            })
            .then(function (response) {
              if (dialogService.isCloseModalWithCancelAction(response)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              //here we will call the service to delete
              userApiService
                .deleteUser(
                  $ctrl.user.id,
                  response.actor,
                  response.manager,
                  response.customdatabaseactor
                )
                .then(() => {
                  notificationService.showSuccessToast(
                    `${$ctrl.user.fullName} successfully Deleted.`,
                    1000
                  );
                  //raise an 'event' to notify the parent
                  $ctrl.onUsersUpdated();
                });
            });
        }

        function showStepTasksWarningDialog(tasksObject) {
          dialogService.showDialog({
            template:
              'Client/runner.setup/runner.setup.delete/runner.setup.users.stepTasks.dialog.tmpl.html',
            controller: 'setupUserStepTaskDialogController',
            appendClassName: 'ngdialog-normal',
            scope: $scope,
            data: {
              tasksObject: tasksObject
            }
          });
        }
      }
    ]
  });
})();
