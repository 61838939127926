import React, { useEffect, useState } from 'react';
import {
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
  processTreeViewItems
} from '@progress/kendo-react-treeview';
import CustomTreeListItem from './CustomTreeListItem';

type Props = {
  categories: any;
  categoryClicked(id: string, index: string, name: string, category: any): void;
  expandedCategory?: any;
  selectedIndex?: string;
  selectedId?: string;
};

const CategoryNavigation = (props: Props) => {
  const { categories, categoryClicked, selectedIndex, expandedCategory } =
    props;

  const [expandedCategories, setExpandedCategories] = useState({
    ids: [],
    idField: 'text'
  });
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(
    selectedIndex !== undefined ? [selectedIndex] : ['0']
  );

  useEffect(() => {
    if (expandedCategory !== null && expandedCategory?.ids?.length > 0) {
      setExpandedCategories((prevState) => ({
        ...prevState,
        ids: expandedCategory.ids
      }));
    }
  }, [expandedCategory]);

  useEffect(() => {
    if (selectedIndex !== undefined) {
      setSelectedCategoryIndex([selectedIndex]);
    }
  }, [selectedIndex]);

  const handleItemClick = (event: TreeViewItemClickEvent) => {
    setSelectedCategoryIndex([event.itemHierarchicalIndex]);
    categoryClicked(
      event.item.id,
      event.itemHierarchicalIndex,
      null,
      expandedCategories
    );
  };

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    const ids = expandedCategories.ids.slice();
    const index = ids.indexOf(event.item.text);

    if (index === -1) {
      ids.push(event.item.text);
      expandAllChildren(event.item, ids);
    } else {
      ids.splice(index, 1);
    }

    setExpandedCategories({ ids, idField: 'text' });
  };

  const expandAllChildren = (category: any, expandedIds: string[]) => {
    if (category.items && category.items.length > 0) {
      category.items.forEach((child: any) => {
        expandedIds.push(child.text);
        expandAllChildren(child, expandedIds);
      });
    }
  };

  return (
    <div>
      <div className="category-navigation-outer-container">
        <div className="category-navigation-container">
          <TreeView
            data={processTreeViewItems(categories, {
              select: selectedCategoryIndex,
              expand: expandedCategories
            })}
            item={(props) => (
              <CustomTreeListItem
                {...props}
                isExpanded={expandedCategories.ids.includes(props.item.text)}
              />
            )}
            expandIcons={true}
            onExpandChange={onExpandChange}
            onItemClick={handleItemClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryNavigation;
