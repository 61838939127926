import React, { useEffect, useState } from 'react';
import {
  DropDownTree,
  DropDownTreeExpandEvent,
  DropDownTreeFilterChangeEvent
} from '@progress/kendo-react-dropdowns';

import { FilterDescriptor } from '@progress/kendo-data-query';
import {
  extendDataItem,
  filterBy,
  mapTree
} from '@progress/kendo-react-treelist';

type Props = {
  categories: any[];
  categoryClicked(id: string, index: string, name: string): void;
  selectedIndex?: string;
  selectedId?: string;
  selectedName?: string;
};

const defaultCategoryId = 'all-categories';
const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'text';
const subItemsField = 'items';

const fields = { selectField, expandField, dataItemKey, subItemsField };

const CategoryNavigationMobile = (props: Props) => {
  const { categories, categoryClicked, selectedId, selectedName } = props;

  const [selectedCategoryId] = useState(selectedId);

  const [selectedCategoryName] = useState(selectedName);

  const [value, setValue] = useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const [filter, setFilter] = React.useState<FilterDescriptor>({
    value: '',
    operator: 'contains'
  });

  useEffect(() => {
    if (selectedCategoryName) {
      setValue({
        id: selectedCategoryId,
        text: selectedCategoryName
      });
    }
  }, [selectedCategoryId, selectedCategoryName]);

  const onChange = React.useCallback((event) => {
    const selectedValue = event.value;

    setValue(selectedValue);

    categoryClicked(
      selectedValue == null ? defaultCategoryId : selectedValue.id,
      null,
      selectedValue == null ? null : selectedValue.text
    );
  }, []);

  const onExpandChangeDropdown = React.useCallback(
    (event: DropDownTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const processTreeData = (data, state, fields) => {
    const { selectField, expandField, dataItemKey, subItemsField } = fields;
    const { expanded, value, filter } = state;
    const filtering = Boolean(filter && filter.value);

    return mapTree(
      filtering ? filterBy(data, [filter], subItemsField) : data,
      subItemsField,
      (item) => {
        const props = {
          [expandField]: expanded.includes(item[dataItemKey]),
          [selectField]: value && item[dataItemKey] === value[dataItemKey]
        };
        return filtering
          ? extendDataItem(item, subItemsField, props)
          : { ...item, ...props };
      }
    );
  };

  const treeData = React.useMemo(
    () =>
      processTreeData(
        categories.filter(
          (category: any) => category[dataItemKey] !== defaultCategoryId
        ),
        { expanded, value, filter },
        fields
      ),
    [expanded, value, filter, categories]
  );

  const expandedState = (item, dataItemKey, expanded) => {
    const nextExpanded = expanded.slice();
    const itemKey = item[dataItemKey];
    const index = expanded.indexOf(itemKey);
    index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

    return nextExpanded;
  };

  const onNameFilterChange = (event: DropDownTreeFilterChangeEvent) => {
    setFilter({ value: event.filter, operator: 'contains' });
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://kendo.cdn.telerik.com/themes/7.0.2/fluent/fluent-main.css"
      />
      <div className="category-navigation-mob-view">
        <DropDownTree
          placeholder="All Categories"
          data={treeData}
          onChange={onChange}
          value={value}
          textField={textField}
          subItemsField={subItemsField}
          dataItemKey={dataItemKey}
          expandField={expandField}
          onExpandChange={onExpandChangeDropdown}
          onFilterChange={onNameFilterChange}
          popupSettings={{
            className: 'category-navigation-mob-view-dropdown-popup'
          }}
        />
      </div>
    </div>
  );
};

export default CategoryNavigationMobile;
