/*
 * Changes:
 *   2022-05-31: removed old searchable-dropdown component
 *               and replaced with kendo-combo-box-facade.
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .controller('dynamicActorDialogController', dynamicActorDialogController);

dynamicActorDialogController.$inject = [
  '$scope',
  'lodashService',
  'flowinglyConstants',
  'flowApiService'
];

function dynamicActorDialogController(
  $scope: IScopeNgDialog,
  _: Lodash,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  flowApiService: FlowApiService
) {
  const $ctrl = this;

  $ctrl.saveSelectedDynamicActors = () => {
    const data = [];

    _.forEach($ctrl.assignedDynamicActors, (value, key) => {
      data.push({
        assignedDynamicActorId: value.actorId,
        nodeId: key
      });
    });

    if ($ctrl.currentForm.$valid === true) {
      $scope.closeThisDialog(data);
    }
  };

  $ctrl.cancel = () => {
    $scope.closeThisDialog();
  };

  $ctrl.assignedDynamicActors = {};
  $ctrl.dynamicActorsForSteps = $scope.ngDialogData.dynamicActorsForSteps;

  $ctrl.dataFunctions = {}; // there can be multiple actor selection dropdown boxes - each one gets its own data function
  $ctrl.dynamicActorsForSteps.forEach(({ dynamicActors, stepName, nodeId }) => {
    $ctrl.dataFunctions[nodeId] = ({ term }) => {
      if (!term) {
        return Promise.resolve(dynamicActors);
      }
      const options = {
        modelerNodeIds: JSON.stringify($scope.ngDialogData.nextModelerNodeIds),
        flowId: $scope.ngDialogData.flowId,
        modelerNodeId: $scope.ngDialogData.modelerNodeId,
        maxNumberOfUsersToShow: flowinglyConstants.maxNumberOfUsersToShow,
        searchTerm: term
      };
      return flowApiService
        .getApproversAndDynamicActorsForNodes(options)
        .then((json) => {
          const { dynamicActors } = JSON.parse(json as string);
          return dynamicActors
            .filter(
              (x) =>
                x.nodeId === nodeId ||
                (nodeId === null && stepName === x.stepName)
            )
            .shift().dynamicActors; // only return the dynamic actors for this dropdown
        });
    };
  });
}
