/**
 * @ngdoc type
 * @module flowingly.runner.maintenance
 * @name runnerMaintenanceController
 *
 * @description Controller for Maintenance page.
 */
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import {
  BUSINESS_UPDATED_EVENT,
  BUSINESS_TERMINATED_POSTFIX
} from './business/runner.maintenance.business.controller';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';

angular
  .module('flowingly.runner.login')
  .controller('runnerMaintenanceController', runnerMaintenanceController);

runnerMaintenanceController.$inject = [
  '$state',
  'permissionsService',
  'authService',
  'maintenanceService',
  '$scope'
];

function runnerMaintenanceController(
  $state: IStateService,
  permissionsService: SharedAngular.PermissionsService,
  authService: AuthService,
  maintenanceService: MaintenanceService,
  $scope: IScope
) {
  if ($state.current.name.startsWith('app.runner.maintenance')) {
    if (
      permissionsService.currentUserHasPermission(
        FlowinglyPermissions.ADMIN_ACCESS
      )
    ) {
      if ($state.current.name === 'app.runner.maintenance') {
        $state.go('app.runner.maintenance.settings');
      }
    } else {
      $state.go('app.runner.flowsactive');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const $ctrl = this;
  $ctrl.businesses = [];
  $ctrl.hasActionsPermission = permissionsService.currentUserHasPermission(
    FlowinglyPermissions.ADMIN_ACTIONS
  );
  $ctrl.hasEmailActionPermission =
    permissionsService.currentUserHasAnyOfPermissions([
      FlowinglyPermissions.ADMIN_ACTIONS,
      FlowinglyPermissions.ADMIN_ACTIONS_EMAIL
    ]);
  $scope.$on(
    BUSINESS_UPDATED_EVENT,
    (event: angular.IAngularEvent, updatedBusiness: IBusinessDetail) => {
      event.stopPropagation();
      if (!updatedBusiness) {
        return;
      }
      const businessIndex = $ctrl.businesses.findIndex(
        (b) => b.id === updatedBusiness.id
      );
      if (businessIndex < 0) {
        return;
      }
      $ctrl.businesses.splice(businessIndex, 1, updatedBusiness);
      if ($ctrl.selectedBusiness.id === updatedBusiness.id) {
        $ctrl.selectedBusiness = updatedBusiness;
      }
      angular
        .element(
          document
            .evaluate(
              "//kendo-combo-box-facade[@id='businesses']//select",
              document
            )
            .iterateNext()
        )
        .data('kendoComboBox')
        .search(updatedBusiness.name);
    }
  );

  const businessesPromise = authService.getUserDeferred().then((user) => {
    return maintenanceService.getBusinesses().then((businesses) => {
      for (const business of businesses) {
        if (!business.active) {
          business.name += BUSINESS_TERMINATED_POSTFIX;
        }
      }
      $ctrl.businesses = businesses.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const userBusiness = businesses.find(
        (business) => business.id === user.businessId
      );
      $ctrl.selectedBusiness = userBusiness;
      return businesses;
    });
  });

  $ctrl.getFilteredBusinesses = (search) => {
    const searchTerm = search.term?.toLowerCase();
    if (!searchTerm) {
      return businessesPromise;
    }
    return $ctrl.businesses.filter(
      (business) => business.name?.toLowerCase().indexOf(searchTerm) > -1
    );
  };
}
