/**
 * @ngdoc directive
 * @name flowProcessmapList
 * @module flowingly.runner.processmap
 * @description  This comppnent is used to group a list of flows that can be started.
 *               It is responsible for opening / closing flows when one is clicked.
 *               It is also (currently) responsible for switching between mobile and desktop views.
 * @usage
 * ```
     <flow-processmap-list process-maps="$ctrl.category.flowModels" is-mobile="isMobile"></flow-processmap-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * processMaps: list of available process maps to start
 * * isMobile: show mobile view if set
 * 
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.processmap')
    .component('flowProcessmapList', {
      bindings: {
        processMaps: '<', //list of available flows to start
        isMobile: '<' //show mobile view if set
      },
      controller: [
        '$state',
        function ($state) {
          this.goToProcessMap = function (processMapId) {
            $state.go(
              'app.runner.processmapview',
              { processMapId },
              { notify: true, replace: true, relative: false }
            );
          };
        }
      ],
      controllerAs: '$ctrl',
      templateUrl:
        'Client/runner.processmaps/runner.processmap.list.component.tmpl.html'
    });
})();
