/**
 * @ngdoc type
 * @module flowingly.runner.exago.imin
 * @name runnerExagoController
 *
 * @description
 */

'use strict';
import angular from 'angular';
angular
  .module('flowingly.runner.exago')
  .controller('runnerExagoController', runnerExagoController);
runnerExagoController.$inject = [];

function runnerExagoController() {
  /* empty */
}
