(function () {
  'use strict';

  //
  // This state displays processmap flows
  //

  // Register the application's routes configuration.
  angular.module('flowingly.runner.processmap').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('app.runner.processmap', {
        url: 'processmaps',
        params: { title: 'Process Maps' },
        templateUrl: 'Client/runner.processmaps/runner.processmaps.tmpl.html',
        controller: 'runnerProcessMapsController',
        controllerAs: '$ctrl',
        resolve: {
          userProfile: [
            'authService',
            'accountApiService',
            function (authService, accountApiService) {
              return authService.getUserDeferred().then(() => {
                return accountApiService.getUserProfile().then((response) => {
                  return response;
                });
              });
            }
          ]
        }
      })
      .state('app.runner.processmapview', {
        url: 'processmaps/:processMapId',
        params: { title: 'Process Maps' },
        templateUrl:
          'Client/runner.processmaps/view/runner.processmap.view.tmpl.html',
        controller: 'runnerProcessMapsViewController',
        controllerAs: '$ctrl'
      });
  }
})();
