/**
 * @name templateLibrary
 * @module flowingly.runner.templates
 * @description Displays a library of available Templates
 * 
 * @usage
 * ```
    <template-library></template-library>
 * ```
 */
import angular from 'angular';
import RunnerTemplatesManageController from './admin/templates/runner.templates.admin.templates.controller';
import { SharedAngular } from '@Client/@types/sharedAngular';

export default class TemplateLibraryComponentController {
  static $inject = ['templateService', '$state', '$scope'];

  private templates;
  private categories;
  private selectedCategory: string;
  private visibleTemplates;
  private searchTerm: string;
  private readonly STICKY_CATEGORY_NAME = 'New Templates';

  // Bindings
  private targetState: string;
  private forManagement: boolean;

  constructor(
    private templateService: SharedAngular.TemplateService,
    private $state: angular.ui.IStateService,
    private $scope: angular.IScope
  ) {}

  $onInit() {
    this.loadTemplates();
    this.$scope.$on(
      RunnerTemplatesManageController.IMPORT_COMPLETE_EVENT,
      (event, data) => this.loadTemplates()
    );
  }

  loadTemplates() {
    this.templateService
      .getTemplates(this.forManagement)
      .then((newTemplates) => {
        newTemplates ||= [];
        this.sortByName(newTemplates);
        this.templates = newTemplates;
      })
      .then(() => this.generateCategories())
      .then(() => this.selectCategory(this.categories[0].name));
  }

  generateCategories() {
    const newCategories = [{ name: this.STICKY_CATEGORY_NAME, count: null }];
    this.templates.forEach((template) => {
      const category = newCategories.find(
        (nc) => nc.name === template.categoryName
      );
      if (category) {
        category.count++;
      } else {
        newCategories.push({
          name: template.categoryName,
          count: 1
        });
      }
    });

    newCategories.sort((a, b) => {
      if (a.name === this.STICKY_CATEGORY_NAME) {
        return -1;
      }
      if (b.name === this.STICKY_CATEGORY_NAME) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
    this.categories = newCategories;
  }

  selectCategory(category: string) {
    this.searchTerm = '';
    this.selectedCategory = category;

    if (this.templates.length === 0) {
      return;
    }

    if (category === this.STICKY_CATEGORY_NAME) {
      const templateDates = this.templates.map((t) => t.createdDate).sort();
      const cutoffDate = templateDates.at(-12) ?? templateDates[0];
      this.visibleTemplates = this.templates.filter(
        (t) => t.createdDate >= cutoffDate
      );
    } else {
      this.visibleTemplates = this.templates.filter(
        (t) => t.categoryName === category
      );
    }
  }

  search() {
    if (this.searchTerm == '') {
      this.selectCategory(this.categories[0].name);
      return;
    }
    const searchTerms = this.searchTerm.toLowerCase().trim().split(/\s+/);
    this.selectedCategory = null;
    this.visibleTemplates = this.templates.filter((t) => {
      return searchTerms.every(
        (searchTerm) =>
          t.name.toLowerCase().includes(searchTerm) ||
          t.blurb.toLowerCase().includes(searchTerm) ||
          t.key.toLowerCase().includes(searchTerm)
      );
    });
  }

  selectTemplate(template) {
    this.$state.go(this.targetState, { templateKey: template.key });
  }

  private sortByName(data: Array<any>) {
    data.sort((a, b) => a.name.localeCompare(b.name));
  }
}

export class TemplateLibraryComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.templates/runner.templates.library.component.tmpl.html';
  public bindings = {
    actionText: '@',
    targetState: '@',
    forManagement: '='
  };

  controller = TemplateLibraryComponentController;
}

angular
  .module('flowingly.runner.templates')
  .component('templateLibrary', new TemplateLibraryComponent());
