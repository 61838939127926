import * as React from 'react';
import {
  DropDownTree,
  DropDownTreeExpandEvent,
  DropDownTreeFilterChangeEvent
} from '@progress/kendo-react-dropdowns';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { extendDataItem, mapTree } from '@progress/kendo-react-treelist';
import { useService } from '@Client/runner.hooks/useService';

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'name';
const subItemsField = 'subCategories';

const fields = { selectField, expandField, dataItemKey, subItemsField };

const NameFilter = (props) => {
  const { data } = props;

  const { field, onFilterChange } = props;
  const [value, setValue] = React.useState(null);
  const [expanded, setExpanded] = React.useState([]);
  const runnerCategoryFilterService = useService<RunnerCategoryFilterService>(
    'runnerCategoryFilterService'
  );
  const [filter, setFilter] = React.useState<FilterDescriptor>({
    value: '',
    operator: 'contains'
  });

  const onChange = React.useCallback((event) => {
    setValue(event.value);
    const filter: any[] = [];
    if (event.value) {
      const value: string = event.value.id;
      filter.push({ value, field: 'id', operator: 'eq' });
    }
    onFilterChange({ filter, field, syntheticEvent: event.syntheticEvent });
  }, []);

  const onExpandChange = React.useCallback(
    (event: DropDownTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const processTreeData = (data, state, fields) => {
    const { selectField, expandField, dataItemKey, subItemsField } = fields;
    const { expanded, value, filter } = state;
    const filtering = Boolean(filter && filter.value);
    const filteredData = filtering
      ? runnerCategoryFilterService.customFilterByName(
          data,
          filter,
          subItemsField
        )
      : data;
    return mapTree(filteredData, subItemsField, (item) => {
      const props = {
        [expandField]: expanded.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey]
      };
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : { ...item, ...props };
    });
  };

  const treeData = React.useMemo(
    () => processTreeData(data, { expanded, value, filter }, fields),
    [expanded, value, filter, data]
  );

  const expandedState = (item, dataItemKey, expanded) => {
    const nextExpanded = expanded.slice();
    const itemKey = item[dataItemKey];
    const index = expanded.indexOf(itemKey);
    index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

    return nextExpanded;
  };

  const onNameFilterChange = (event: DropDownTreeFilterChangeEvent) =>
    setFilter(event.filter);

  return (
    <div className="setup-categories-name-filter">
      <DropDownTree
        className="name-filter"
        placeholder="Select Category"
        data={treeData}
        value={value}
        onChange={onChange}
        textField={textField}
        subItemsField={subItemsField}
        dataItemKey={dataItemKey}
        expandField={expandField}
        onExpandChange={onExpandChange}
        filterable={true}
        onFilterChange={onNameFilterChange}
        filter={filter.value}
        popupSettings={{
          className: 'setup-categories-name-filter-dropdown'
        }}
      />
    </div>
  );
};

export default NameFilter;
