import React from 'react';
import { Typography } from '@mui/material';

export type Props = {
  title: string;
};
const ModalHeader = (props: Props) => {
  return (
    <Typography
      fontSize={20}
      fontWeight={600}
      paddingBottom={'20px'}
      fontFamily={'"Open Sans", sans-serif'}
    >
      {props.title}
    </Typography>
  );
};

export default ModalHeader;
