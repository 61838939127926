angular.module('flowingly.runner.user.settings').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.settings', {
    url: 'usersettings',
    params: { title: 'Settings' },
    templateUrl: 'Client/runner.user.settings/runner.user.settings.tmpl.html'
  });
}
