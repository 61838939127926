/**
 * @ngdoc directive
 * @name usersHeader
 * @module flowingly.runner.setup
 * @description This component is used to display the header for the users and will contain controls
 * @usage
 * ```
      <users-header></users-header>
 * ``` 
 * NOTE: Minimal content for now, but this might grow
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('usersHeader', {
    bindings: {
      users: '<',
      onUsersUpdated: '&'
    },
    template: `
                <button ng-hide='$ctrl.hideInviteUsers' ng-click="$ctrl.onImportUsersClick()" class="btn btn-primary mt-25 mr-10 btn-primary--blue">Bulk Invite</button>
                <button ng-hide='$ctrl.hideInviteUsers' ng-click="$ctrl.onAddUsersClick()" class="btn btn-primary mt-25">Invite Users</button>
            `,
    controller: [
      '$scope',
      'dialogService',
      'intercomService',
      'APP_CONFIG',
      '$timeout',
      function ($scope, dialogService, intercomService, APP_CONFIG, $timeout) {
        var $ctrl = this;

        $ctrl.onAddUsersClick = onAddUsersClick;
        $ctrl.onImportUsersClick = onImportUsersClick;
        $ctrl.hideInviteUsers = false;
        $scope.$watch(
          function () {
            return APP_CONFIG.hideInviteUsers;
          },
          function () {
            $ctrl.hideInviteUsers = APP_CONFIG.hideInviteUsers;
          },
          true
        );

        function onAddUsersClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.users/add.users/runner.setup.users.add.tmpl.html',
              controller: 'userAddController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: null
            })
            .then(function (result) {
              if (dialogService.isCloseModalWithCancelAction(result)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              if (result) {
                intercomService.trackEvent('Added Users');
                //raise an "event" to notify the parent
                $ctrl.onUsersUpdated();
              }
            });
        }

        function onImportUsersClick() {
          dialogService
            .showDialog({
              template: 'Client/runner.import/runner.import.dialog.tmpl.html',
              controller: 'runnerImportDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: {
                modalTitle: 'Invite Users',
                instructionText:
                  'Open the file in your favorite spreadsheet editor. Enter first name, last name & email for each user you wish to invite.',
                actionText: 'Invite Users',
                templateUrl: ASSETS_PATH + '/bulk_import_users.csv'
              }
            })
            .then(function (result) {
              if (dialogService.isCloseModalWithCancelAction(result)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              if (result) {
                //raise an "event" to notify the parent
                $ctrl.onUsersUpdated();
              }
            });
        }
      }
    ]
  });
})();
