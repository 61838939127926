'use strict';

import * as angular from 'angular';

const runnerPublicFormModule = angular.module(
  'flowingly.runner.public-form',
  []
);

runnerPublicFormModule.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.public-form/runner.public-form.tmpl.html',
      require('./runner.public-form.tmpl.html').default
    );
  }
]);
export default runnerPublicFormModule;
