/**
 * @ngdoc type
 * @module flowingly.runner.templates
 * @name RunnerTemplatesPacksController
 *
 * @description Controller for admin Template Packs page.
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export default class RunnerTemplatesPacksController {
  static $inject = ['notificationService', 'templateService'];

  private packs: Array<any>;
  private categories: Array<any>;
  private templates: Array<any>;
  private selectedPack;
  private requestPending = false;
  private searchTerm = '';
  private dataLoaded = false;

  constructor(
    private notificationService: SharedAngular.NotificationService,
    private templateService: SharedAngular.TemplateService
  ) {
    const whenHavePacks = this.loadPacks();
    const whenHaveTemplates = this.loadTemplates();
    const whenHaveCategories = this.loadCategories();

    Promise.allSettled([
      whenHavePacks,
      whenHaveTemplates,
      whenHaveCategories
    ]).then(() => {
      this.dataLoaded = true;
      if (this.packs.length > 0) {
        this.selectPack(this.packs[0]);
      }
    });
  }

  private loadPacks() {
    return this.templateService.getPacks().then((packs) => {
      packs ||= [];
      this.sortByName(packs);
      this.packs = packs;
    });
  }

  private loadTemplates() {
    const forManagement = false;
    return this.templateService
      .getTemplates(forManagement)
      .then((templates) => {
        templates ||= [];
        this.sortByName(templates);
        this.templates = templates;
      });
  }

  private loadCategories() {
    return this.templateService.getCategories().then((categories) => {
      categories ||= [];
      categories.forEach((c) => this.sortByName(c.templates));
      this.sortByName(categories);
      this.categories = categories;
    });
  }

  selectPack(pack) {
    if (this.requestPending) {
      return;
    }
    pack.templates ||= [];
    pack.categories ||= [];
    this.selectedPack = pack;
    this.filterCandidates();
  }

  addCategory(category) {
    this.selectedPack.categories.push(category);
    this.sortByName(this.selectedPack.categories);
    this.selectedPack.hasUnsavedChanges = true;
    this.filterCandidates();
  }

  addTemplate(template) {
    this.selectedPack.templates.push(template);
    this.sortByName(this.selectedPack.templates);
    this.selectedPack.hasUnsavedChanges = true;
    template.visible = false;
  }

  removeCategory(categoryId) {
    this.selectedPack.categories = this.selectedPack.categories.filter(
      (c) => c.id !== categoryId
    );
    this.selectedPack.hasUnsavedChanges = true;
    this.filterCandidates();
  }

  removeTemplate(templateId) {
    this.selectedPack.templates = this.selectedPack.templates.filter(
      (t) => t.id !== templateId
    );
    this.selectedPack.hasUnsavedChanges = true;
    this.templates.find((t) => t.id === templateId).visible = true;
  }

  filterCandidates() {
    this.templates.forEach((template) => {
      template.visible =
        !this.selectedPack.templates ||
        !this.selectedPack.templates.some((t) => t.id === template.id);
    });

    this.categories.forEach((category) => {
      const isVisible =
        !this.selectedPack.categories ||
        !this.selectedPack.categories.some((c) => c.id === category.id);
      category.visible = isVisible;
      if (!isVisible) {
        category.templates.forEach((ct) => {
          this.templates.find((t) => t.id === ct.id).visible = false;
        });
      }
    });
  }

  addPack() {
    const newPack = {
      name: 'Template Pack',
      templates: [],
      categories: [],
      hasUnsavedChanges: true
    };
    this.packs.push(newPack);
    this.selectPack(newPack);
  }

  search() {
    this.filterCandidates();
    if (this.searchTerm) {
      const terms = this.searchTerm.toLowerCase().trim().split(/\s+/);
      this.categories.forEach(
        (category) =>
          (category.visible =
            category.visible &&
            terms.every((t) => category.name.toLowerCase().indexOf(t) >= 0))
      );

      this.templates.forEach(
        (template) =>
          (template.visible =
            template.visible &&
            terms.every((t) => template.name.toLowerCase().indexOf(t) >= 0))
      );
    }
  }

  savePack() {
    this.requestPending = true;
    this.templateService
      .savePack(this.selectedPack)
      .then((packId) => {
        this.selectedPack.id = packId;
        this.requestPending = false;
        this.notificationService.showSuccessToast('Pack saved');
        this.selectedPack.hasUnsavedChanges = false;
        this.sortByName(this.packs);
      })
      .catch(() => {
        this.requestPending = false;
        this.notificationService.showErrorToast(
          'An error occurred while saving the Pack'
        );
      });
  }

  private sortByName(data: Array<any>) {
    data.sort((a, b) => a.name.localeCompare(b.name));
  }
}

angular
  .module('flowingly.runner.templates')
  .controller('runnerTemplatesPacksController', RunnerTemplatesPacksController);
