/**
 * @ngdoc service
 * @module flowingly.runner
 * @name runnerSpinInterceptor
 *
 * @description A sservice for intercepting requests / responses and starting / stopping spinner
 *
 * Converted runner.spinInterceptor.js to ts on 18/12/2019
 * To view the original version open this
 * https://bitbucket.org/flowingly-team/flowingly-source-code/src/3a5769f998d23e3923099dcb44996d7cf1dd02b1/src/Flowingly.Angular.Runner/Client/runner.spinInterceptor.js
 *
 */
import angular, {
  IHttpInterceptor,
  IHttpPromiseCallbackArg,
  IQService,
  IRequestConfig
} from 'angular';

runnerSpinInterceptor.$inject = ['$q', 'usSpinnerService'];

function runnerSpinInterceptor(
  $q: IQService,
  usSpinnerService: IUsSpinnerService
): IHttpInterceptor {
  //API
  const service = {
    request: request,
    requestError: requestError,
    response: response,
    responseError: responseError
  };

  return service;

  //////////// Public API Methods
  function request(config: IRequestConfig) {
    if (
      config &&
      (config.url.indexOf('api') > -1 || config.url.indexOf('odata') > -1)
    ) {
      //dont show spinner if downloading image or no spinner requested
      if (
        (config.url.indexOf('files') > -1 && config.method === 'GET') ||
        config.noSpinner
      ) {
        return config;
      }
      usSpinnerService.spin('spinner-main');
    }

    return config;
  }

  function requestError(rejection) {
    usSpinnerService.stop('spinner-main');
    //need to manually reject the promise here, else the error handlers of $http.post etc are never hit!
    return $q.reject(rejection);
  }

  function response<T>(response: IHttpPromiseCallbackArg<T>) {
    if (
      response.config.url.indexOf('api/') > -1 ||
      response.config.url.indexOf('odata') > -1
    )
      usSpinnerService.stop('spinner-main');
    return response;
  }

  function responseError(rejection) {
    usSpinnerService.stop('spinner-main');
    //need to manually reject the promise here, else the error handlers of $http.post etc are never hit!
    return $q.reject(rejection);
  }
}

angular
  .module('flowingly.runner')
  .factory('runnerSpinInterceptor', runnerSpinInterceptor);
