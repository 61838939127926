/**
 * @ngdoc directive
 * @name flowLibraryListActions
 * @module flowingly.runner.library
 * @description  This component is used to display actions button list in a flow library row
 * @usage
 * ```
     <flow-library-list-actions flow-model-id='dataItem.id'></flow-library-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * flowModelId: id of flow model to act upon
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import {
  DeleteEntityTypes,
  FlowModelPublishType
} from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular, { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.library').component('flowLibraryListActions', {
  bindings: {
    flow: '<', //to save having to fetch it from API, pass in the flow
    onFlowListUpdated: '&',
    canCreateFlows: '<'
  },
  templateUrl: 'Client/runner.library/runner.library.list.actions.tmpl.html',
  controller: [
    '$scope',
    'dialogService',
    'APP_CONFIG',
    'tempModelerUrlService',
    'notificationService',
    'flowModelApiService',
    'flowinglyApiService',
    'flowinglyConstants',
    '$state',
    'guidService',
    'permissionsService',
    'runnerLibraryService',
    function (
      $scope: IScope,
      dialogService: SharedAngular.DialogService,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      tempModelerUrlService: SharedAngular.TempModelerUrlService,
      notificationService: SharedAngular.NotificationService,
      flowModelApiService: SharedAngular.FlowModelApiService,
      flowinglyApiService: SharedAngular.FlowinglyApiService,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      $state: IStateService,
      guidService: SharedAngular.GuidService,
      permissionsService: SharedAngular.PermissionsService,
      runnerLibraryService: RunnerLibraryService
    ) {
      const $ctrl = this;

      $ctrl.startFlowClick = startFlowClick;
      $ctrl.viewProcessMapClick = viewProcessMapClick;
      $ctrl.editClick = editClick;
      $ctrl.editOwnerClick = editOwnerClick;
      $ctrl.publishClick = publishClick;
      $ctrl.cloneClick = cloneClick;
      $ctrl.deleteClick = deleteClick;
      $ctrl.editableByCurrentUser = $ctrl.flow.editableByCurrentUser;
      const isProcessMapType =
        $ctrl.flow.publishTypeValue === FlowModelPublishType.PROCESS_MAP ||
        $ctrl.flow.publishTypeValue ===
          FlowModelPublishType.PROCESS_MAP_COMPONENT;
      $ctrl.canDelete = () => {
        return (
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.FLOWMODEL_DELETE
          ) || APP_CONFIG.fmoCanPublish
        );
      };

      $ctrl.canStartFlow = () => {
        return (
          $ctrl.flow.publishTypeValue ===
            flowinglyConstants.flowModelPublishType.WORKFLOW &&
          $ctrl.flow.status === 'Published' &&
          $ctrl.flow.publishedTo !== 'Public User' &&
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.FLOW_START
          )
        );
      };

      $ctrl.canViewProcessMap = () => {
        return (
          $ctrl.flow.publishTypeValue ===
            flowinglyConstants.flowModelPublishType.PROCESS_MAP ||
          $ctrl.flow.publishTypeValue ===
            flowinglyConstants.flowModelPublishType.PROCESS_MAP_COMPONENT
        );
      };

      if ($ctrl.editableByCurrentUser) {
        $ctrl.editOwnerTooltip = getEditOwnerTooltip();
        $ctrl.editOwnerSuccessMessage = getEditOwnerMessage();
        $scope.$watch(
          function () {
            return APP_CONFIG.enableReportOwner;
          },
          function (value) {
            $ctrl.editOwnerTooltip = getEditOwnerTooltip();
            $ctrl.editOwnerSuccessMessage = getEditOwnerMessage();
          }
        );

        $ctrl.startTooltip = 'Start flow';
        $ctrl.editTooltip = 'Edit flow model';
        $ctrl.cloneTooltip = 'Copy flow model';
        $ctrl.deleteTooltip = 'Delete flow model';
        $ctrl.viewTooltip = 'View Process Map';
      } else {
        $ctrl.startTooltip =
          $ctrl.editTooltip =
          $ctrl.cloneTooltip =
          $ctrl.deleteTooltip =
          $ctrl.editOwnerTooltip =
            'You must be an owner to perform this action.';
      }

      /// PUBLIC Methods //////////////////////////////////////////////////////////////////

      function getEditOwnerTooltip() {
        return APP_CONFIG.enableReportOwner
          ? 'Edit Sharing, Flow Model & Report Owners'
          : 'Edit Sharing / Flow Model Owners';
      }

      function getEditOwnerMessage() {
        return APP_CONFIG.enableReportOwner
          ? 'Flow Model Owners / Report Owners Updated'
          : 'Flow Model Owners Updated';
      }

      function editOwnerClick() {
        if (!$ctrl.editableByCurrentUser) {
          return;
        }

        dialogService
          .showDialog({
            template:
              'Client/runner.library/runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.tmpl.html',
            controller: 'flowModelOwnerDialogController',
            appendClassName: 'ngdialog-theme-plain w-700',
            scope: $scope,
            data: {
              flowModelId: $ctrl.flow.id,
              flowModelName: $ctrl.flow.name,
              isPublic: $ctrl.flow.isPublic,
              publicType: $ctrl.flow.publicType,
              isProcessMapType: isProcessMapType
            }
          })
          .then((data) => {
            if (dialogService.isCloseModalWithCancelAction(data)) {
              //user closed modal by clicking on overlay (or cancel or press Esc key)
              return;
            }

            if (data != null) {
              $ctrl.onFlowListUpdated();
              if (data.message)
                notificationService.showSuccessToast(data.message, 4000);
              else
                notificationService.showSuccessToast(
                  $ctrl.editOwnerSuccessMessage,
                  4000
                );
            }
          });
      }

      function editClick() {
        if (!$ctrl.editableByCurrentUser) {
          return;
        }
        tempModelerUrlService.openModeler($ctrl.flow.id);
      }

      function startFlowClick() {
        if (!$ctrl.editableByCurrentUser) {
          return;
        }
        $state.go(
          'app.runner.flow',
          { flowId: guidService.empty(), flowModelId: $ctrl.flow.id },
          { notify: true }
        );
      }

      function viewProcessMapClick() {
        runnerLibraryService.openProcessMap($ctrl.flow.id);
      }

      function cloneClick() {
        if (!$ctrl.editableByCurrentUser) {
          return;
        }
        tempModelerUrlService.openModeler($ctrl.flow.id, true);
      }

      function publishClick() {
        const draft = 'Draft';
        const published = 'Published';

        switch ($ctrl.flow.status) {
          case draft:
            notificationService.showSuccessToast(
              'This flow needs to be validated before it can be published. To do this click edit then validate.'
            );
            break;

          case 'Validated':
            console.error(
              'flowLibraryListActions: validated status no longer user'
            );
            break;

          case published:
            showEditPublishSettingsDialog();
            break;
        }
      }

      function deleteClick() {
        if (!$ctrl.editableByCurrentUser) return;

        if (
          $ctrl.flow.publishTypeValue ===
            flowinglyConstants.flowModelPublishType.WORKFLOW_COMPONENT ||
          $ctrl.flow.publishTypeValue ===
            flowinglyConstants.flowModelPublishType.PROCESS_MAP_COMPONENT ||
          ($ctrl.flow.publishTypeValue ===
            flowinglyConstants.flowModelPublishType.PROCESS_MAP &&
            APP_CONFIG.allowProcessMapsAsComponents)
        ) {
          flowinglyApiService
            .getFlowModelsReferencingComponent($ctrl.flow.id)
            .then((flowModels) => {
              if (
                !flowModels ||
                !flowModels.some((flowModel) => flowModel.id !== $ctrl.flow.id)
              ) {
                showDeleteDialog();
              } else {
                const flowModelNames = flowModels
                  .filter((fm) => fm.id !== $ctrl.flow.id)
                  .map((fm) => fm.name)
                  .join(', ');
                const componentTypeName =
                  $ctrl.flow.publishTypeValue ===
                  flowinglyConstants.flowModelPublishType.WORKFLOW_COMPONENT
                    ? 'Workflow'
                    : 'Process Map';
                dialogService.showDialog({
                  template:
                    'Client/runner.setup/runner.setup.delete/runner.setup.delete.component.warning.dialog.tmpl.html',
                  controller: 'setupDeleteComponentWarningDialogController',
                  appendClassName: 'ngdialog-normal',
                  scope: $scope,
                  data: {
                    flowModelNames: flowModelNames,
                    componentTypeName: componentTypeName
                  }
                });
              }
            });
        } else {
          showDeleteDialog();
        }
      }

      function showDeleteDialog() {
        dialogService
          .showDialog({
            template:
              'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
            controller: 'setupDeleteDialogController',
            appendClassName: 'ngdialog-normal',
            scope: $scope,
            data: {
              itemToDelete: $ctrl.flow,
              entityType: DeleteEntityTypes.flowModelEntityType
            }
          })
          .then(function (response) {
            if (dialogService.isCloseModalWithCancelAction(response)) {
              //user closed modal by clicking on overlay (or cancel or press Esc key)
              return;
            }
            //user confirmed delete
            flowModelApiService.deleteFlowModel($ctrl.flow.id).then((r) => {
              notificationService.showSuccessToast('Flow Model Deleted');
              $ctrl.onFlowListUpdated({ needResizeGrid: true });
            });
          });
      }

      /// PRIVATE Methods //////////////////////////////////////////////////////////////////

      function showEditPublishSettingsDialog() {
        const publishDialogPath =
          'Client/runner.library/runner.library.publish/runner.library.publish.dialog.tmpl.html';

        dialogService
          .showPublishDialog(
            $scope,
            $ctrl.flow,
            'publishDialogController',
            publishDialogPath,
            true /* editMode*/
          )
          .then(() => {
            if ($scope.success) {
              notificationService.showSuccessToast(
                'Flow Model Settings Updated'
              );
              $ctrl.onFlowListUpdated();
            } else if ($scope.success === false) {
              $scope.flowPublished = false;
              notificationService.showErrorToast('Update Settings Failed');
            } else {
              $scope.flowPublished = false;
            }
          });
      }
    }
  ]
});
