import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { ITimeoutService } from 'angular';

angular
  .module('flowingly.runner.flow')
  .controller('flowAddStepTaskController', flowAddStepTaskController);

flowAddStepTaskController.$inject = [
  '$scope',
  'momentService',
  'flowinglyConstants',
  '$timeout'
];

function flowAddStepTaskController(
  $scope: IScopeNgDialog,
  moment: Moment,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  $timeout: ITimeoutService
) {
  const $ctrl = this;

  $ctrl.datePickerOpts = {
    format: 'dd/MM/yyyy hh:mm tt', // This is different to moment. The Kendo datetime picker doesn't like the moment format.
    interval: 5
  };

  $ctrl.controlDateTimeFormat = 'DD/MM/YYYY hh:mm A';
  $ctrl.priorityOptions = flowinglyConstants.stepTaskPriority;

  $ctrl.initializeDueDateField = () => {
    $timeout(function () {
      $('#dueDateTime')
        .kendoDateTimePicker($ctrl.datePickerOpts)
        .prop('readonly', true)
        .attr('placeholder', $ctrl.datePickerOpts.format);
    });
  };

  $ctrl.addStepTask = () => {
    if ($ctrl.currentForm.$valid) {
      // Need to convert the kendo datetime into something the API can accept (.NET DateTime type).
      const apiDueDate = moment($ctrl.dueDateTime, $ctrl.controlDateTimeFormat)
        .utc()
        .format((moment as any).defaultFormatUtc);

      $scope.closeThisDialog({
        name: $ctrl.taskName,
        description: $ctrl.description,
        assignedUserId: $ctrl.taskAssignee.id,
        approverUserId: $ctrl.taskApprover.id,
        dueDateTime: apiDueDate,
        priority: $ctrl.priority
      });
    }
  };

  $ctrl.cancel = () => {
    $scope.closeThisDialog();
  };
}
