/**
 * @ngdoc service
 * @name dateService
 * @module flowingly.runner.services
 *
 * @description A service that wraps MomentJS
 *
 */

'use strict';

angular.module('flowingly.runner.services').factory('dateService', dateService);

dateService.$inject = ['momentService', 'APP_CONFIG'];

function dateService(momentService: Moment, APP_CONFIG: AppConfig) {
  const service = {
    dateIsBefore: dateIsBefore,
    dateIsAfter: dateIsAfter,
    datesAreEqual: datesAreEqual,
    getToday: getToday,
    getTomorrow: getTomorrow,
    getTodayMinusDays: getTodayMinusDays,
    getTodayPlusDays: getTodayPlusDays,
    formatUtcToLocal: formatUtcToLocal,
    formatUtcToLocalForPrinting: formatUtcToLocalForPrinting,
    utcToLocal: utcToLocal
  };

  return service;

  //////////// Public API Methods

  function dateIsBefore(a, b, sortDateFormat) {
    a = parseDateWithFormat(a, sortDateFormat);
    b = parseDateWithFormat(b, sortDateFormat);

    return momentService(a).isBefore(b);
  }

  function dateIsAfter(a, b, sortDateFormat) {
    a = parseDateWithFormat(a, sortDateFormat);
    b = parseDateWithFormat(b, sortDateFormat);

    return momentService(a).isAfter(b);
  }

  function datesAreEqual(a, b) {
    const result = momentService(a).isSame(b, 'day');
    return result;
  }

  function getToday() {
    return new Date();
  }

  function getTomorrow() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return tomorrow;
  }

  function getTodayMinusDays(days) {
    const today = new Date();
    const future = new Date(today);
    future.setDate(today.getDate() - days);
    return future;
  }

  function getTodayPlusDays(days) {
    const today = new Date();
    const future = new Date(today);
    future.setDate(today.getDate() + days);
    return future;
  }

  function formatUtcToLocal(utcString) {
    return momentService(`${utcString}Z`).format(
      APP_CONFIG.localDateTimeFormat
    );
  }

  function formatUtcToLocalForPrinting(utcString, _format) {
    return momentService(`${utcString}Z`).format(_format);
  }

  function utcToLocal(utcDateTime) {
    const localFormat = 'DD-MMM-YYYY HH:mm';
    return momentService.utc(utcDateTime).local().format(localFormat);
  }

  //////////// Private Methods
  function parseDateWithFormat(data, sortDateFormat) {
    if (sortDateFormat !== undefined) {
      data = momentService(data, sortDateFormat);
    }

    return data;
  }
}

export type DateServiceType = ReturnType<typeof dateService>;
