const m = angular.module('flowingly.runner.user.condition', []);
m.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.user-condition/runner.user-condition.dialog.tmpl.html',
      require('./runner.user-condition.dialog.tmpl.html').default
    );
  }
]);
