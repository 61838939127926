import { getInjector } from '@Shared.Angular/flowingly.helper/serviceInjector';

/**
 * Hook to get angularJs service via injector
 * @typedef T - type of the service
 * @param name
 * @returns T - the angularJs service
 */
export const useService = <T>(name: ServiceName): T =>
  getInjector().get(name) as T;
