'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { IStateProvider } from 'angular-ui-router';
///
/// This state displays a list of available reports
///

// Register the application's routes configuration.
angular.module('flowingly.runner.reports').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.reports', {
    url: 'reports',
    params: { title: 'Reports' },
    templateUrl: 'Client/runner.reports/runner.reports.tmpl.html',
    controller: [
      '$scope',
      'lodashService',
      'pubsubService',
      'flowListManager',
      'browserUtilsService',
      'brandingService',
      'APP_CONFIG',
      'runnerCategoryNavigationService',
      function (
        $scope: IScope,
        lodashService: Lodash,
        pubsubService: SharedAngular.PubSubService,
        flowListManager: FlowListManager,
        browserUtilsService: SharedAngular.BrowserUtilsService,
        brandingService: SharedAngular.BrandingService,
        APP_CONFIG: SharedAngular.APP_CONFIG,
        runnerCategoryNavigationService: RunnerCategoryNavigationService
      ) {
        const ctrl = this;

        init();

        $scope.$on('$destroy', () => {
          pubsubService.unsubscribeAll('runnerReportsController');
        });

        function init() {
          pubsubService.subscribe(
            'SIGNALR_WORKFLOW_NAME_CHANGED',
            onFlowModelNameChanged,
            'runnerReportsController'
          );
          pubsubService.subscribe(
            'SIGNALR_SETUP_CATEGORY_DELETED',
            onCategoryDeleted,
            'runnerReportsController'
          );

          ctrl.selectedCategory = null;
          ctrl.allCategoriesId = 'all-categories';
          ctrl.categoryClicked = categoryClicked;
          ctrl.isMobile = browserUtilsService.isMobileDevice();
          ctrl.showSubCategories = APP_CONFIG.enableSubCategories;

          if (ctrl.showSubCategories) {
            ctrl.categories = angular.copy(
              runnerCategoryNavigationService.getFormattedReportTreeData(
                flowListManager.reportFlows
              )
            );
          } else {
            ctrl.categories = flowListManager.reportFlows;
          }
          ctrl.helpUri = brandingService.getBrandedHelpUri(
            'https://help.flowingly.net/getting-started/how-do-i-run-a-report'
          );

          if (ctrl.categories.length === 0) {
            refreshFlows();
          } else {
            addAllReportsCategory(ctrl.categories);
            selectAllOption();
          }
        }

        function onCategoryDeleted() {
          refreshFlows();
        }

        function onFlowModelNameChanged(msg, data) {
          const msgdata = JSON.parse(data);
          flowListManager.updateFlowNames(msgdata);
          angular.copy(flowListManager.reportFlows, ctrl.categories);
        }

        function refreshFlows() {
          return flowListManager
            .refreshReportFlows(false)
            .then((reportCategories) => {
              if (ctrl.showSubCategories) {
                ctrl.categories = angular.copy(
                  runnerCategoryNavigationService.getFormattedReportTreeData(
                    reportCategories
                  )
                );
              } else {
                ctrl.categories = reportCategories;
              }

              addAllReportsCategory(ctrl.categories);
              selectAllOption();
            });
        }

        function categoryClicked(categoryId) {
          if (ctrl.showSubCategories) {
            ctrl.selectedCategory =
              runnerCategoryNavigationService.findNodeById(
                ctrl.categories,
                categoryId
              );
          } else {
            ctrl.selectedCategory = ctrl.categories.find(
              (c) => c.id === categoryId
            );
          }
        }

        function selectAllOption() {
          ctrl.categoryClicked(ctrl.allCategoriesId);
        }

        function addAllReportsCategory(categories) {
          if (
            !categories ||
            categories.find((category) => category.id === ctrl.allCategoriesId)
          ) {
            return;
          }

          let copyOfAllFlows;
          if (ctrl.showSubCategories) {
            copyOfAllFlows =
              runnerCategoryNavigationService.getFlowModelsFromNestedCategories(
                categories
              );
            categories.unshift({
              id: ctrl.allCategoriesId,
              text: 'All',
              flowModels: copyOfAllFlows,
              items: []
            });
          } else {
            copyOfAllFlows = lodashService.flatMap(categories, 'flowModels');
            categories.unshift({
              id: ctrl.allCategoriesId,
              name: 'All',
              flowModels: copyOfAllFlows
            });
          }
        }
      }
    ],
    controllerAs: 'ctrl'
  });
}
