import React from 'react';
import NamedAvatar from '../NamedAvatar/NamedAvatar';

export type HeaderDataItem = {
  title: string;
  value: string;
  showAvatar?: boolean;
};

type Props = {
  data: HeaderDataItem[];
};

const Header = ({ data }: Props) => {
  return (
    <div className={'process-map-v2-view-right-panel-header-container'}>
      {data.map((headerItem) => (
        <div
          className="process-map-v2-view-right-panel-header-row"
          key={headerItem.title}
        >
          <div className="process-map-v2-view-right-panel-header-label-cell">
            {headerItem.title}
          </div>
          <div className="process-map-v2-view-right-panel-header-content-cell">
            {headerItem.showAvatar ? (
              <NamedAvatar fullName={headerItem.value} />
            ) : (
              <p>{headerItem.value}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Header;
