/**
 * @ngdoc type
 * @module flowingly.runner.user.accept.invite
 * @name acceptInviteController
 *
 * @description Controller that allows users to complete their profiles when accepting an invitation
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IController } from 'angular';

interface IError {
  title: string;
  message: string;
}

class AcceptInviteController implements IController {
  private model;
  private blockingError: IError;
  private submissionError: string;
  private passwordCalculator;
  private brandName: string;
  private brandWebsite: string;
  private brandSupportEmail: string;
  private brandLogo: string;
  private profileComplete = false;
  private redirectLink = '/flowsactive';
  private pendingSubmission = false;

  constructor(
    private $location: angular.ILocationService,
    private acceptInviteApiService: AcceptInviteApiService,
    private sessionService: SharedAngular.SessionService,
    private authService: AuthService,
    private $window,
    private $sce: angular.ISCEService,
    private appInsightsService: SharedAngular.AppInsightsService
  ) {
    this.appInsightsService.startEventTimer('userInviteLoaded');
    this.model = {
      isValidToken: false,
      isLoaded: false,
      user: {},
      inviteToken: this.$location.search().inviteToken ?? undefined
    };

    // Public endpoint that will validate the JWT and if successful will use the token to retrieve
    // other information required by the component
    acceptInviteApiService
      .validateSecurityToken(this.model.inviteToken)
      .then(
        (response: {
          user: unknown;
          completedProfile: unknown;
          redirectLink: string;
        }) => {
          this.model.isValidToken = true;
          this.model.user = response.user;

          //Redirect user to login page from old email link
          if (response.completedProfile) {
            this.$window.location.href =
              response.redirectLink || this.redirectLink;
          } else {
            this.appInsightsService.startEventTimer(
              'userInviteBusinessSettingsLoaded'
            );
            acceptInviteApiService
              .getSettingsByBusiness(this.model.inviteToken)
              .then((settings) => {
                this.brandName = settings['Branding:Name'];
                this.brandWebsite = settings['Branding:HomepageUri'];
                this.brandSupportEmail = settings['Branding:SupportEmail'];
                this.brandLogo = settings['Branding:Email:Logo'];
                this.model.isLoaded = true;
              })
              .finally(() => {
                this.appInsightsService.trackMetricIfTimerExist(
                  'userInviteBusinessSettingsLoaded',
                  this.model
                );
              });
          }
        },
        () => {
          this.blockingError = {
            title: 'Your invitation has expired',
            message:
              'Please request a new invitation link from your team, or contact support.'
          };
          this.model.isLoaded = true;
        }
      )
      .finally(() => {
        this.appInsightsService.trackMetricIfTimerExist(
          'userInviteLoaded',
          this.model
        );
      });
  }

  completeUserProfile() {
    this.appInsightsService.startEventTimer('userInviteProfileCompleted');
    this.pendingSubmission = true;
    if (this.profileComplete) {
      this.showPendingConditions();
      return;
    }

    const userToSave = {
      id: this.model.user.id, //only used in angular service
      userManagerId: this.model.user.manager.id,
      teamId: this.model.user.teamId,
      password: this.model.user.password
    };
    this.acceptInviteApiService
      .updateUserProfile(this.model.inviteToken, userToSave)
      .then(
        (response: {
          success: unknown;
          userPermissions: unknown;
          redirectLink: string;
        }) => {
          if (!response.success) {
            this.addSubmissionError(response);
            return;
          }
          this.sessionService.setUser(this.model.user);

          const profile = JSON.stringify({
            email: this.model.user.email,
            name: this.model.user.email,
            permissions: response.userPermissions,
            nameidentifier: this.model.user.id,
            businessidentifier: this.model.user.businessId
          });

          this.sessionService.setProfile(profile);
          this.profileComplete = true;
          this.redirectLink = response.redirectLink || this.redirectLink;
          this.showPendingConditions();
        }
      )
      .catch(this.addSubmissionError)
      .finally(() => {
        this.appInsightsService.trackMetricIfTimerExist(
          'userInviteProfileCompleted',
          (({ password, ...rest }) => rest)(userToSave)
        );
      });
  }

  showPendingConditions() {
    this.authService
      .getUserPendingAcceptCondition(
        () => {
          this.$window.location.href = this.redirectLink;
        },
        () => {
          this.submissionError = 'You must accept the conditions to proceed.';
          this.pendingSubmission = false;
        }
      )
      .catch(() => (this.$window.location.href = this.redirectLink));
  }

  passwordStrengthCalculator() {
    const passScore = {
      0: { description: 'Very Weak', color: 'red', percent: '20%' },
      1: { description: 'Weak', color: 'red', percent: '40%' },
      2: { description: 'Fair', color: 'yellow', percent: '60%' },
      3: { description: 'Good', color: 'green', percent: '80%' },
      4: { description: 'Strong', color: 'green', percent: '100%' }
    };

    const pwd = this.model.user.password || '';
    const result = zxcvbn(pwd);
    const passResult = passScore[result.score];

    this.passwordCalculator = {
      show: true,
      color: passResult.color,
      strength: passResult.percent,
      strengthText: passResult.description,
      suggestions: result.feedback.suggestions
    };
  }

  addSubmissionError = (response) => {
    const defaultMessage =
      'Sorry, there was a problem completing your profile, please try again.' +
      ' <a href="mailto:' +
      this.brandSupportEmail +
      '">Contact support</a> if the problem persists.';
    this.submissionError = this.$sce.trustAsHtml(
      response?.data?.Message || defaultMessage
    );
    this.pendingSubmission = false;
  };

  setLoadUsersError = () => {
    this.blockingError = {
      title: 'Failed to load data',
      message:
        'Please try reloading this page, if the problem persists contact support.'
    };
  };
}

AcceptInviteController.$inject = [
  '$location',
  'acceptInviteApiService',
  'sessionService',
  'authService',
  '$window',
  '$sce',
  'appInsightsService'
];
angular
  .module('flowingly.runner.user.accept.invite')
  .controller('acceptInviteController', AcceptInviteController);
