import angular from 'angular';

const dashboardFilterModule = angular.module(
  'flowingly.runner.dashboard.filter',
  []
);

dashboardFilterModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.dashboard.filter/runner.dashboard.filter.tmpl.html',
      require('../runner.dashboard.filter/runner.dashboard.filter.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.dashboard.filter/runner.dashboard.filter.item/runner.dashboard.filter.item.tmpl.html',
      require('../runner.dashboard.filter/runner.dashboard.filter.item/runner.dashboard.filter.item.tmpl.html')
        .default
    );
  }
]);
export default dashboardFilterModule;
