/**
 * @ngdoc type
 * @module flowingly.runner.templates
 * @name RunnerTemplatesManageController
 *
 * @description Controller for admin Template Management page
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export default class RunnerTemplatesManageController {
  static $inject = ['$scope', 'dialogService', 'templateService'];
  static IMPORT_COMPLETE_EVENT = 'TemplateImportComplete';

  constructor(
    private $scope: angular.IScope,
    private dialogService: SharedAngular.DialogService,
    private templateService: SharedAngular.TemplateService
  ) {}

  exportTemplates() {
    this.templateService.getExportTemplates().then((templates) => {
      const blob = new Blob([JSON.stringify(templates)], {
        type: 'application/json'
      });
      const blobUri = URL.createObjectURL(blob);
      const fileName = `Exported Templates ${new Date().toDateString()}.json`;
      const a = document.createElement('a');
      a.href = blobUri;
      a.target = '_blank';
      a.download = fileName;
      a.click();
    });
  }

  showImportDialog() {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.templates/dialogs/runner.templates.dialogs.import.tmpl.html',
        controller: 'importTemplatesDialogController',
        appendClassName: 'ngdialog-normal'
      })
      .then((didImport: boolean) => {
        if (didImport) {
          this.$scope.$broadcast(
            RunnerTemplatesManageController.IMPORT_COMPLETE_EVENT
          );
        }
      });
  }
}

angular
  .module('flowingly.runner.templates')
  .controller(
    'runnerTemplatesManageController',
    RunnerTemplatesManageController
  );
