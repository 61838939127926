'use strict';

import * as angular from 'angular';

const runnerPublicMapModule = angular.module('flowingly.runner.publicmap', []);

runnerPublicMapModule.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.publicmap/runner.publicmap.tmpl.html',
      require('./runner.publicmap.tmpl.html').default
    );
  }
]);
export default runnerPublicMapModule;
