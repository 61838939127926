import { createContext } from 'react';
/**
 * angularJs create services as singleton
 * to share all existing services to react, and also to make it easy for all react components to use those services that avoid prop drilling
 * pass the services via context
 */
export const FlowinglyDiagramContext =
  createContext<FlowinglyDiagramService | null>(null);
export const GoServiceContext = createContext<GoJS | null>(null);
export const UserApiContext = createContext<UserApiService | null>(null);
export const PubSubContext = createContext<PubSubService | null>(null);
export const AppConfigContext = createContext<AppConfig | null>(null);
