'use strict';
//
// This state displays the admin Template Management page
//
import * as angular from 'angular';

angular.module('flowingly.runner.templates').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.templates.admin.templates', {
    url: '/templates',
    params: { title: 'Manage Templates' },
    templateUrl:
      'Client/runner.templates/admin/templates/runner.templates.admin.templates.tmpl.html',
    controller: 'runnerTemplatesManageController',
    controllerAs: 'ctrl'
  });
}
