'use strict';
import angular from 'angular';
import { IStateProvider } from 'angular-ui-router';
//
// This state displays maintenance task for validating all flows
//

// Register the application's routes configuration.
angular.module('flowingly.runner.maintenance').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance', {
    url: 'maintenance',
    params: { title: 'Maintenance' },
    templateUrl: 'Client/runner.maintenance/runner.maintenance.tmpl.html',
    controller: 'runnerMaintenanceController',
    controllerAs: '$ctrl',
    resolve: {
      userProfile: [
        'authService',
        'accountApiService',
        function (
          authService: AuthService,
          accountApiService: AccountApiService
        ) {
          return authService.getUserDeferred().then(() => {
            return accountApiService.getUserProfile().then((response) => {
              return response;
            });
          });
        }
      ]
    }
  });
}
