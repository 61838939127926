import angular, { ILocationService, ITimeoutService } from 'angular';
import RunnerFlowService from '../runner.flow/runner.flow.service';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { RunnerFlowsFormatterService } from '@Client/runner.services/flows.formatter';

/**
 * @ngdoc directive
 * @name flowFooter
 * @module flowingly.runner.flow
 * @description  This comppnent is used to display the area which contains any buttons related to the flow (Done / Withdraw).
 * @usage
 * ```
     <flow-footer ng-click="$ctrl.onClickMethod({ id: $ctrl.flow.Id })" flow="$ctrl.flow" is-mobile="$ctrl.isMobile"></flow-footer>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 * * canStartFlow: true if the user is able to start this flow (some views are readonly)
 */

angular.module('flowingly.runner.flow').component('flowFooter', {
  bindings: {
    flow: '<',
    step: '<',
    isMobile: '<', //show mobile view if set
    canStartFlow: '<',
    onCommentsClick: '&', //show comments tab on click
    onSaveProgress: '&',
    onCallBoomiProcess: '&',
    disableFooterButtons: '<'
  },
  controller: [
    '$location',
    '$timeout',
    'pubsubService',
    'notificationService',
    'sessionService',
    'runnerFlowService',
    'APP_CONFIG',
    'flowApiService',
    'runnerFlowsFormatter',
    'flowinglyConstants',
    'permissionsService',
    'appInsightsService',
    'busyService',
    function (
      $location: ILocationService,
      $timeout: ITimeoutService,
      pubsubService: SharedAngular.PubSubService,
      notificationService: SharedAngular.NotificationService,
      sessionService: SharedAngular.SessionService,
      runnerFlowService: RunnerFlowService,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      flowApiService: FlowApiService,
      runnerFlowsFormatter: RunnerFlowsFormatterService,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      permissionsService: SharedAngular.PermissionsService,
      appInsightsService: SharedAngular.AppInsightsService,
      busyService: SharedAngular.BusyService
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const $ctrl = this;
      $ctrl.runnerFlowService = runnerFlowService;
      $ctrl.busyService = busyService;
      $ctrl.doneFlow = doneFlow;
      $ctrl.showComments = showComments;
      $ctrl.withdrawFlow = withdrawFlow;
      $ctrl.canWithdrawFlow = canWithdrawFlow;
      $ctrl.canReassignStep = canReassignStep;
      $ctrl.reassignStep = reassignStep;
      $ctrl.nudgeFlowWaitingOnActor = nudgeFlowWaitingOnActor;
      $ctrl.saveProgress = saveProgress;
      $ctrl.callBoomiProcess = callBoomiProcess;
      $ctrl.showWaitingHistory = false;
      $ctrl.showHistoryFooter = false;
      $ctrl.exportToPdf = exportToPdf;
      $ctrl.showStepTaskButton = showStepTaskButton;
      $ctrl.addStepTask = addStepTask;
      $ctrl.isProcessingIntegration = isProcessingIntegration;
      $ctrl.canOverrideProcessingIntegration = canOverrideProcessingIntegration;
      $ctrl.overrideProcessingIntegration = overrideProcessingIntegration;
      $ctrl.timer = null; // timer for footerSubmitButton
      $ctrl.isDeleteFlowModalOpen = false;
      $ctrl.toggleDeleteFlowModal = canDeleteFlow()
        ? toggleDeleteFlowModal
        : null;
      $ctrl.deleteFlow = deleteFlow;
      $ctrl.canDeleteFlow = canDeleteFlow;

      $ctrl.$onChanges = function (changes) {
        //will be called on component creation and any time one-way inputs changed by the parent
        //we need to get the last step so we can check to see if it is waiting on this user
        if (changes && changes.flow && changes.flow.currentValue) {
          $ctrl.showWaitingHistory =
            !$ctrl.step.IsCompleted &&
            !$ctrl.step.IsWaitingForYou &&
            !$ctrl.flow.IsFinalised;
          $ctrl.showHistoryFooter =
            $ctrl.showWaitingHistory || $ctrl.flow.IsFinalised;
        }

        // $timeout to make sure button finished initialization, thus got id
        $ctrl.timer = $timeout(() => {
          const footerSubmitButton = angular.element(
            `#formSubmit_button_${$ctrl.step.FlowId}`
          );
          footerSubmitButton.off('click').click($ctrl.doneFlow); // have to bind in Jquery way, if use ng-click, it will refresh the page instead
        });
      };

      $ctrl.$onDestroy = () => {
        const footerSubmitButton = angular.element(
          `#formSubmit_button_${$ctrl.step.FlowId}`
        );
        if (footerSubmitButton && footerSubmitButton.length > 0) {
          footerSubmitButton.off('click');
        }
        // remove timer
        $timeout.cancel($ctrl.timer);
      };

      this.startFlow = () => {
        // I dont want to do any processing in this direct :/
        // this event's subscriber should be in runner.flow.controller.ts
        pubsubService.publish('FLOW_STARTED');
      };

      this.hasStarted = () => runnerFlowService.hasStarted;
      this.isAwaitingYourInput = () =>
        !this.step.IsCompleted && this.step.IsWaitingForYou;

      /// PUBLIC /////////////////////////////////////////////////
      function showStepTaskButton() {
        if (isProcessingIntegration()) {
          // Cannot add tasks to a step with processing integration.
          return false;
        }

        // Only the step assignee can see the button or if you're in the team assigned to the step.
        return (
          this.isAwaitingYourInput() &&
          isStepTaskSettingEnabled() &&
          this.step.StepTaskEnabled != null &&
          this.step.StepTaskEnabled === true
        );
      }

      function isStepTaskSettingEnabled() {
        const isStepTasksEnabled =
          APP_CONFIG.enableStepTasks != null && APP_CONFIG.enableStepTasks;
        return isStepTasksEnabled;
      }

      function addStepTask() {
        return runnerFlowService.addStepTask({
          stepTaskCreatedByUserId: sessionService.getUser().id,
          stepId: $ctrl.step.Id,
          flowId: $ctrl.flow.FlowId
        });
      }

      function showComments() {
        $ctrl.onCommentsClick();
      }

      function withdrawFlow() {
        runnerFlowService.cancelEntity({
          stepId: $ctrl.step.Id,
          flowId: $ctrl.flow.FlowId,
          stepTaskId: undefined,
          entity: 'flow'
        });
      }

      function toggleDeleteFlowModal() {
        $ctrl.isDeleteFlowModalOpen = !$ctrl.isDeleteFlowModalOpen;
      }

      function deleteFlow() {
        runnerFlowService.deleteFlow($ctrl.flow.FlowId).then(() => {
          pubsubService.publish('NAVIGATE_BACK'); // redirects to the previous page
          toggleDeleteFlowModal();
        });
      }

      function nudgeFlowWaitingOnActor() {
        runnerFlowService.nudgeUser({
          whoIsNudged: $ctrl.step.WhoIsAssignedStep,
          stepName: $ctrl.step.Name,
          stepId: $ctrl.step.Id,
          nudgedById: sessionService.getUser().id,
          nudgedByUserName: sessionService.getUser().fullName,
          flowSubject: $ctrl.flow.Subject,
          flowIdentifier: $ctrl.flow.FlowIdentifier
        });
      }

      function saveProgress() {
        appInsightsService.startEventTimer('stepFormSaved');
        if (isProcessingIntegration()) {
          // The step cannot be save/submitted while there is processing integration for the step.
          return;
        }

        $('.changed-input').removeClass('changed-input');
        $ctrl.onSaveProgress().then(() => {
          notificationService.showSuccessToast('Changes successfully saved');

          appInsightsService.trackMetricIfTimerExist(
            'stepFormSaved',
            getStepFormPropsForAppInsights()
          );
        });
      }

      function callBoomiProcess() {
        $ctrl.onCallBoomiProcess().then(() => {
          notificationService.showSuccessToast('Boomi called');
        });
      }

      /// PRIVATE /////////////////////////////////////////////////
      function doneFlow(e) {
        if (isProcessingIntegration()) {
          // The step cannot be save/submitted while there is processing integration for the step.
          return;
        }

        //remove step querystring value from url so that user don't see completed by user message after done button click.
        $location.search('step', '');
        const formSubmitButton = angular.element(
          `#formSubmit_${$ctrl.step.FlowId}`
        );

        if (formSubmitButton && formSubmitButton.length > 0) {
          e.preventDefault();
          e.stopPropagation();

          formSubmitButton.click(); // trigger the submit button inside the form to correctly function
        }
      }

      function canDeleteFlow() {
        return (
          APP_CONFIG.enableFlowDelete &&
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.FLOW_DELETE
          )
        );
      }

      function canWithdrawFlow() {
        return (
          !$ctrl.step.IsCompleted &&
          ($ctrl.flow.IsStartedByYou ||
            $ctrl.flow.IsFlowModelOwner ||
            permissionsService.currentUserHasPermission(
              flowinglyConstants.permissions.FLOW_CANCEL
            ))
        );
      }

      function canReassignStep() {
        if (isProcessingIntegration()) {
          // Cannot reassign when integration is processing.
          return false;
        }

        return (
          !$ctrl.step.IsCompleted &&
          ($ctrl.flow.IsFlowModelOwner ||
            permissionsService.currentUserHasPermission(
              flowinglyConstants.permissions.FLOW_STEP_REASSIGN
            ))
        );
      }

      function isProcessingIntegration() {
        return (
          $ctrl.step != null &&
          $ctrl.step.IntegrationState != null &&
          $ctrl.step.IntegrationState.State != null &&
          $ctrl.step.IntegrationState.State ===
            flowinglyConstants.stepIntegrationState.Processing
        );
      }

      function canOverrideProcessingIntegration() {
        // Skip button visibility.
        return (
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.FLOW_WEBHOOK_SKIP
          )
        );
      }

      function overrideProcessingIntegration() {
        const user = sessionService.getUser();

        if (user != null) {
          return runnerFlowService.overrideProcessingIntegration({
            currentStep: $ctrl.step,
            stepId: $ctrl.step.Id,
            flowId: $ctrl.flow.FlowId,
            userId: user.id
          });
        }
      }

      function exportToPdf() {
        if (isStepTaskSettingEnabled()) {
          // We need to reload the flow so that any step task details, activities, etc are correct in the report.
          return flowApiService
            .getFlowById($ctrl.flow.FlowId, false)
            .then((flowForUser) => {
              const formattedFlow =
                runnerFlowsFormatter.formatFlow(flowForUser);
              const pdfName = getPdfName(formattedFlow);
              return runnerFlowService.exportToPdf(pdfName, formattedFlow);
            })
            .catch(() => {
              // Fall back to the flow in the browser if we fail to reload it from the API.
              const pdfName = getPdfName($ctrl.flow);
              return runnerFlowService.exportToPdf(pdfName, $ctrl.flow);
            });
        } else {
          // Step tasks are disabled so no need to reload the flow.
          const pdfName = getPdfName($ctrl.flow);
          return runnerFlowService.exportToPdf(pdfName, $ctrl.flow);
        }
      }

      function getPdfName(flow) {
        const { FlowIdentifier, Subject } = flow;
        const SafeSubject = Subject.match(/[a-zA-Z]*/g).join('_');
        const pdfName = `${FlowIdentifier}_${SafeSubject}`;
        return pdfName;
      }

      function reassignStep() {
        return runnerFlowService.reassign({
          currentStep: $ctrl.step,
          stepId: $ctrl.step.Id,
          flowId: $ctrl.flow.FlowId,
          flowIdentifier: $ctrl.flow.FlowIdentifier
        });
      }

      function getStepFormPropsForAppInsights() {
        return {
          flowIdentifier: $ctrl.flow.FlowIdentifier,
          stepName: $ctrl.step.Name,
          stepTypeName:
            flowinglyConstants.stepTypeName[
              flowinglyConstants.taskType[$ctrl.step.StepType]
            ]
        };
      }
    }
  ],
  templateUrl:
    'Client/runner.flow/runner.flow.footer/runner.flow.footer.tmpl.html'
});
