/**
 * @ngdoc service
 * @name reportsUtilityService
 * @module flowingly.runner.services
 *
 * @description A service responsible for helping render report such as transformation and manipulate data from server and to a format can be used by front-end
 *
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { ILocationService } from 'angular';

angular
  .module('flowingly.runner.services')
  .factory('reportsUtilityService', reportsUtilityService);

reportsUtilityService.$inject = [
  'validationService',
  '$location',
  'lodashService',
  'APP_CONFIG',
  'sessionService',
  'fileService',
  'flowinglyStepService',
  'momentService'
];

function reportsUtilityService(
  validationService: SharedAngular.ValidationService,
  $location: ILocationService,
  _: Lodash,
  APP_CONFIG: AppConfig,
  sessionService: SharedAngular.SessionService,
  fileService: SharedAngular.FileService,
  flowinglyStepService: SharedAngular.FlowinglyStepService,
  momentService: SharedAngular.FlowinglyMomentService
) {
  const _fileDownloadDetails = []; // Holds the list of file download details for each file upload field
  const _copyOfFlows = []; // Holds a copy of flow data source for later use, eg filter
  const FIELD_TYPE = {
    CHECKBOX: 'CheckBox',
    CURRENCY: 'Currency',
    EMAIL: 'Email',
    FILE_UPLOAD: 'FileUpload',
    TABLE: 'Table',
    SHORT_TEXT: 'Text',
    TEXT_AREA: 'TextArea',
    NUMBER: 'Number',
    OPTION_LIST: 'RadioButtonList',
    DROPDOWN_LIST: 'SelectList',
    TASK_LIST: 'TaskList',
    MULTISELECT_LIST: 'MultiSelectList',
    DATE: 'Date',
    DATETIME: 'Datetime',
    SIGNATURE: 'Signature',
    APPROVAL_TYPE: 'ApprovalRule'
  };

  const SYSTEM_COLUMNS = [
    'flowId',
    'flowIdentifier',
    'subject',
    'initiatedBy',
    'initiatedDate',
    'currentStep',
    'waitingOn',
    'lastUpdatedBy',
    'lastUpdatedDate',
    'status',
    'flowComments'
  ];
  const DATE_FORMAT = 'dd/MM/yyyy';
  const DATETIME_FORMAT = 'dd/MM/yyyy h:mm:ss tt';
  const TIME_FORMAT = 'h:mm:ss tt';

  const service = {
    FIELD_TYPE: FIELD_TYPE,
    fileDownloadDetails: _fileDownloadDetails,
    generateColumns: generateColumns,
    getDataSourceSchema: getDataSourceSchema,
    getFlatColumns: getFlatColumns,
    transform: transform
  };

  return service;

  //////////// Public API Methods

  // When we transform the report data received from server side we do two things:
  // 1. We create the urls/links for the files
  // 2. We create an html template that uses the flow-timeline-filelist directive
  // 3. We build up a list of the file donload details (for each file upload field) to reference in the report grid
  // TODO this has been done the same way as it has been done before in Runner on client side. It would be a good idea to move
  // this and similar processing (eg see optimusPrime) to server side.
  function transform(flows, flow4312TemporaryWorkDataTransform) {
    for (const flow of flows) {
      for (const prop in flow) {
        // ignore meta data
        if (prop.indexOf('_') < 0 || prop.indexOf('_FieldValue') > 0) {
          if (SYSTEM_COLUMNS.indexOf(prop) >= 0) {
            transformFlowColumns(flow, prop);
          } else {
            // see if we should transform the data
            if (flow4312TemporaryWorkDataTransform) {
              //@TODO FLOW-4314 improve the way this module is handled
              flow[prop] = flow4312TemporaryWorkDataTransform(prop, flow[prop]);
            }
            transformStepDataColumns(flow, prop);
          }
        }
      }
    }

    // Holds a copy of flow data source for later use, eg filter
    angular.copy(flows, _copyOfFlows);
  }

  function getFlowUrl(flowId) {
    return `${$location.protocol()}://${$location.host()}:${$location.port()}/flows/${flowId}`;
  }

  function generateColumns(flow, autoFit) {
    const columns = [];

    // add the flow columns
    for (const sysCol of SYSTEM_COLUMNS) {
      if (sysCol !== 'flowId') {
        columns.push(gridColumnBuilder(flow, sysCol, autoFit));
      }
    }

    const fieldColumns = [];
    // add step grouped columns
    for (const prop in flow) {
      // ignore meta data
      if (prop.indexOf('_') < 0) {
        // only looking for step data
        if (SYSTEM_COLUMNS.indexOf(prop) < 0) {
          let stepGroupCol = getStepGroupCol(
            fieldColumns,
            flow[`${prop}_StepName`]
          );

          if (stepGroupCol === undefined) {
            stepGroupCol = {
              stepName: flow[`${prop}_StepName`],
              title: flow[`${prop}_StepName`],
              stepRefSequence: flow[`${prop}_StepRefSequence`],
              componentStepRefSequence:
                flow[`${prop}_ComponentStepRefSequence`],
              columns: []
            };
            fieldColumns.push(stepGroupCol);
          }

          stepGroupCol.columns.push(gridColumnBuilder(flow, prop, autoFit));
        }
      }
    }
    fieldColumns.push({
      title: 'Link',
      field: 'flowId',
      hidden: true,
      locked: true,
      template: function (e) {
        return `<a href='${APP_CONFIG.runnerUrl}/flows/${e.flowId}'>View Flow</a>`;
      },
      filterable: false,
      width: 100,
      sortable: false
    });
    fieldColumns.sort((a, b) =>
      flowinglyStepService.stepRefSequenceComparer(
        a.stepRefSequence,
        b.stepRefSequence,
        a.componentStepRefSequence,
        b.componentStepRefSequence
      )
    );
    fieldColumns.forEach((fc) => columns.push(fc));
    return columns;
  }

  function getDataSourceSchema(columns) {
    const allColumns = getFlatColumns(columns);
    const schema = {
      total: 'total',
      data: 'payload',
      model: {
        fields: {}
      }
    };
    const fields = {};

    for (const col of allColumns) {
      if (
        col.fieldType === FIELD_TYPE.CURRENCY ||
        col.fieldType === FIELD_TYPE.NUMBER
      ) {
        fields[col.field] = { type: 'number' };
      } else if (col.fieldType === FIELD_TYPE.DATE) {
        fields[col.field] = { type: 'date' };
      } else if (col.fieldType === FIELD_TYPE.DATETIME) {
        fields[col.field] = { type: 'date' };
      }
    }
    schema.model.fields = fields;

    return schema;
  }

  function getFlatColumns(columnsData) {
    let columns = [];

    for (const col of columnsData) {
      // Grouping columns
      if (col.columns) {
        columns = columns.concat(col.columns);
      } else {
        columns.push(col);
      }
    }

    return columns;
  }

  // PRIVATE METHODS /////////////////////////////////////////////////////////////////

  function transformStepDataColumns(flow, propFieldValue) {
    const prop = propFieldValue.substring(
      0,
      propFieldValue.indexOf('_FieldValue')
    );
    flow[prop] = flow[propFieldValue] ? flow[propFieldValue] : ''; // deal with null value
    flow[prop] = _.unescape(flow[prop]);
    flow[`${prop}_Sortable`] = true;
    const fieldType = flow[`${prop}_FieldType`];
    switch (fieldType) {
      case FIELD_TYPE.FILE_UPLOAD:
        transformFileUpload(flow, prop);
        flow[`${prop}_Sortable`] = false;
        break;

      case FIELD_TYPE.CHECKBOX:
        transformCheckbox(flow, prop);
        break;

      case FIELD_TYPE.TABLE:
        if (flow[prop] !== '' && flow[prop] !== undefined) {
          transformToLink(flow, prop);
        }
        flow[`${prop}_Sortable`] = false;
        break;

      case FIELD_TYPE.TASK_LIST:
        transformTaskList(flow, prop);
        break;

      case FIELD_TYPE.SHORT_TEXT:
        flow[prop] = flow[prop];
        break;
      case FIELD_TYPE.TEXT_AREA:
        if (flow[prop] !== '' && flow[prop] !== undefined) {
          //transformToLink(flow, prop);
          transformLongText(flow, prop);
        }
        flow[`${prop}_Sortable`] = false;
        break;

      case FIELD_TYPE.DATE:
        flow[prop] = kendo.parseDate(flow[prop], DATE_FORMAT);
        break;

      case FIELD_TYPE.DATETIME:
        flow[prop] = kendo.parseDate(flow[prop], DATETIME_FORMAT);
        break;

      case FIELD_TYPE.MULTISELECT_LIST:
        transformMultiselectList(flow, prop);
        break;

      case FIELD_TYPE.SIGNATURE:
        transformToLink(flow, prop);
        break;
    }
  }

  function transformFileUpload(flow, prop) {
    const listOfFilePaths = [];
    const fileJson = flow[prop] || flow[prop + '_FieldValue'];

    if (!fileJson) {
      return;
    }

    const fileData = JSON.parse(flow[prop]);
    let invalidData = false;

    _.each(fileData, function (file) {
      //confirm valid file id
      if (
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
          file.id
        )
      ) {
        //and check that it belongs to this field (file control)
        if (file.fieldname === prop) {
          listOfFilePaths.push({
            ...file,
            downloadLink: fileService.getDownloadLink(file.id)
          });
        }
      } else {
        invalidData = true;
      }
    });

    if (!invalidData) {
      //if this is valid filedata, insert a download link directive
      let html = '';
      _.each(listOfFilePaths, function (file) {
        html += `
                            <div>
                                <a flow-download-link="${file.downloadLink}" title="${file.filename}" target="_blank" public-href="${file.publicUrl}"> ${file.filename}</a>
                            </div>`;
      });
      flow[prop] = html;
      flow[`${prop}_ExportDataValue`] = listOfFilePaths
        .map((f) => f.publicUrl)
        .join(';');
    } else {
      flow[prop] = '';
      flow[`${prop}_ExportDataValue`] = '';
    }
  }

  function transformCheckbox(flow, prop) {
    const fieldValueKey = `${prop}_FieldValue`;
    if (prop in flow) {
      let val = flow[fieldValueKey];
      if (val == null || val == undefined) {
        flow[prop] = 'No';
      } else {
        val = val.toLowerCase();
        flow[prop] = val === 'true' || val === 'selected' ? 'Yes' : 'No';
      }
    } else {
      flow[prop] = '';
    }
  }

  function transformToLink(flow, prop) {
    const flowId = flow.flowId;
    const flowImInUrl = getFlowUrl(flowId);
    const displayName = flow[`${prop}_DisplayName`];

    if (flow[`${prop}_FieldValue`] !== '') {
      flow[prop] = `<a href="${flowImInUrl}">View ${displayName}</a>`;
      flow[`${prop}_ExportDataValue`] = flowImInUrl;
    }
  }

  function transformFlowColumns(flow, prop) {
    // insert meta data for sorting
    flow[`${prop}_Sortable`] = prop !== 'flowId';

    switch (prop) {
      case 'subject':
        flow[`${prop}_ExportDataValue`] = flow[prop]; // save the actual subject for exporting purpose
        flow[prop] = createViewFlowButton(flow[prop], flow.flowId);
        break;

      case 'initiatedDate':
      case 'lastUpdatedDate':
        flow[prop] = kendo.parseDate(`${flow[prop]}Z`);
        break;

      case 'flowComments':
        if (flow[prop]) {
          const commentStrings = [];
          const json = JSON.parse(flow[prop]);
          const { isArray } = _;
          const comments = isArray(json) ? json : [json];
          for (let i = 0; i < comments.length; i++) {
            const comment = comments[i];
            const browserTz = momentService.tz.guess();
            const date = momentService
              .tz(comment.CreatedDate, 'UTC')
              .tz(browserTz)
              .format('DD MMM YYYY');
            const commentString = `${date}, ${
              comment.UserName
            }, ${comment.Comment.replace(/(?!<br>|<br\/>)<.+?>/g, '')}`;
            commentStrings.push(commentString);
          }
          flow[prop] = commentStrings.join('<br/>');
          break;
        }
    }
  }

  function transformMultiselectList(flow, prop) {
    if (flow[prop]) {
      const rawData = JSON.parse(flow[prop]);

      if (angular.isArray(rawData)) {
        const selectedItems = [];

        for (const item of rawData) {
          if (item.value === 'true') {
            selectedItems.push(item.key);
          }
        }

        flow[prop] = selectedItems.join(' | ');
      }
    }
  }

  function transformTaskList(flow, prop) {
    if (flow[prop]) {
      const fieldValue = JSON.parse(flow[prop]);
      let taskList = '';

      for (const task of fieldValue) {
        taskList += taskList ? `| ${task.key}` : task.key;
      }

      flow[prop] = taskList;
    }
  }

  function transformLongText(flow, prop) {
    if (flow[prop]) {
      const longText = flow[prop];
      flow[prop] = longText.replace(/<[^>]+>/gm, '');
    }
  }

  function createViewFlowButton(dataValue, flowId) {
    return `<table id="reportView"><tr><td>${validationService.sanitizeString(
      dataValue
    )}</td><td><button ng-click="$ctrl.showFlow('${flowId}','flow_${flowId}')" class="btn blue.lighten-1">View Flow</button></td></tr></table>`;
  }

  // this is to build one Kendo grid column
  function gridColumnBuilder(flow, colName, autoFit = true) {
    let fieldType = flow[`${colName}_FieldType`];
    if (colName === 'initiatedDate' || colName === 'lastUpdatedDate') {
      fieldType = FIELD_TYPE.DATE;
    }

    const kendoGridColumn = {
      title: flow[`${colName}_DisplayName`]
        ? flow[`${colName}_DisplayName`]
        : getColumnTitle(colName),
      field: colName,
      locked: colName === 'subject',
      fieldType: fieldType,
      format: '',
      template: <any>undefined,
      filterable: <any>undefined,
      width: getColumnWidth(colName, autoFit),
      sortable: true,
      encoded: true
    };

    // add column formatting
    if (fieldType === FIELD_TYPE.DATE) {
      kendoGridColumn.format =
        SYSTEM_COLUMNS.indexOf(colName) < 0
          ? '{0:dd MMM yyyy}'
          : '{0:dd MMM yyyy h:mm:ss tt}';
    } else if (fieldType === FIELD_TYPE.DATETIME) {
      kendoGridColumn.format = '{0:dd MMM yyyy h:mm:ss tt}';
    } else if (fieldType === FIELD_TYPE.CURRENCY) {
      kendoGridColumn.format = '{0:c}';
    }

    if (fieldType === FIELD_TYPE.APPROVAL_TYPE || colName === 'flowComments') {
      kendoGridColumn.encoded = false;
    }

    // some columns need template for customized view
    if (
      colName === 'subject' ||
      colName === 'flowComments' ||
      flow[`${colName}_FieldType`] === FIELD_TYPE.FILE_UPLOAD ||
      flow[`${colName}_FieldType`] === FIELD_TYPE.TABLE ||
      flow[`${colName}_FieldType`] === FIELD_TYPE.TASK_LIST ||
      flow[`${colName}_FieldType`] === FIELD_TYPE.SIGNATURE ||
      flow[`${colName}_FieldType`] === FIELD_TYPE.TEXT_AREA
    ) {
      kendoGridColumn.template = (dataItem) => {
        // handle the data which not exist, it is ok for columns not use template, but will throw exception if column does use template property and don't have data present
        return dataItem[colName] ? dataItem[colName] : '';
      };
      kendoGridColumn.filterable = false; // Kendo cannot filter on any columns using template out of box
    } else {
      // setup filter
      let filterableType = 'string';

      // for step data
      switch (flow[`${colName}_FieldType`]) {
        case FIELD_TYPE.CURRENCY:
        case FIELD_TYPE.NUMBER:
          filterableType = 'number';
          break;

        case FIELD_TYPE.DATETIME:
          filterableType = 'datetime';
          break;

        case FIELD_TYPE.DATE:
          filterableType = 'date';
          break;

        case FIELD_TYPE.CHECKBOX:
        case FIELD_TYPE.DROPDOWN_LIST:
        case FIELD_TYPE.OPTION_LIST:
          //filterableType = 'dropdown';
          // @TODO FLOW-4364 no more dropdowns. Change the way this works when weve improved reprts
          filterableType = 'string';
          break;

        default:
          filterableType = 'string';
      }

      // for system columns
      if (SYSTEM_COLUMNS.indexOf(colName) >= 0) {
        if (colName === 'initiatedDate' || colName === 'lastUpdatedDate') {
          filterableType = 'date';
        } else if (colName === 'flowIdentifier') {
          filterableType = 'string';
        } else {
          // @TODO FLOW-4364 no more dropdowns. Change the way this works when weve improved reprts
          filterableType = 'string';
        }
        //else {
        //    filterableType = 'dropdown';
        //}
      }

      kendoGridColumn.filterable = {
        type: filterableType
      };

      setupColumnFilter(kendoGridColumn, flow, colName);
    }

    // setup sorting
    kendoGridColumn.sortable = flow[`${colName}_Sortable`];

    return kendoGridColumn;
  }

  function getStepGroupCol(columns, stepName) {
    return columns.find((col) => {
      return col.stepName === stepName;
    });
  }

  function setupColumnFilter(kendoGridColumn, flow, colName) {
    switch (kendoGridColumn.filterable.type) {
      case 'string':
        setupStringColumnFilter(kendoGridColumn, colName);
        break;

      case 'date':
        setupDateColumnFilter(kendoGridColumn, flow, colName);
        break;

      case 'datetime':
        setupDateTimeColumnFilter(kendoGridColumn, flow, colName);
        break;

      case 'number':
        setupNumberColumnFilter(kendoGridColumn, flow, colName);
        break;

      case 'dropdown':
        setupDropdownColumnFilter(kendoGridColumn, colName);
        break;

      default:
        setupStringColumnFilter(kendoGridColumn, colName);
    }
  }

  function setupStringColumnFilter(kendoGridColumn, colName) {
    //const autoCompleteDataSource = getColumnUniqueValues(colName);

    kendoGridColumn.filterable = {
      cell: {
        showOperators: false,
        operator: 'contains',
        template: function (args) {
          args.element
            .attr('col-name', colName) // used by datepicker binding (see initDatepickerBind in alldataitems.js)
            .addClass('k-textbox')
            .addClass('report-filter-input');
          //args.element.kendoAutoComplete({
          //    dataSource: autoCompleteDataSource,
          //    dataTextField: 'val',
          //    valuePrimitive: true
          //});
        }
      }
    };
  }

  function setupDateColumnFilter(kendoGridColumn, flow, colName) {
    kendoGridColumn.filterable = {
      cell: {
        showOperators: true,
        operator: 'gt',
        template: function (args) {
          args.element.kendoDatePicker({
            value: flow[colName],
            format: DATE_FORMAT
          });
        }
      }
    };
  }

  function setupDateTimeColumnFilter(kendoGridColumn, flow, colName) {
    kendoGridColumn.filterable = {
      cell: {
        showOperators: true,
        operator: 'gt',
        template: function (args) {
          args.element.kendoDateTimePicker({
            value: flow[colName],
            format: DATETIME_FORMAT
          });
        }
      }
    };
  }

  function setupNumberColumnFilter(kendoGridColumn, flow, colName) {
    kendoGridColumn.filterable = {
      cell: {
        showOperators: true,
        operator: 'eq',
        template: function (args) {
          args.element.kendoNumericTextBox({
            value: flow[colName]
          });
        }
      }
    };
  }

  function setupDropdownColumnFilter(kendoGridColumn, colName) {
    const dropDownDataSource = getColumnUniqueValues(colName);

    kendoGridColumn.filterable = {
      cell: {
        showOperators: false,
        operator: 'eq',
        template: function (args) {
          args.element.kendoComboBox({
            dataSource: dropDownDataSource,
            dataTextField: 'val',
            dataValueField: colName
          });
        }
      }
    };
  }

  function getColumnUniqueValues(colName) {
    const dataSource = [];
    const uniqueValues = {};

    // insert unique value list
    for (const row of _copyOfFlows) {
      const value = row[colName];

      if (value) {
        uniqueValues[value] = value;
      }
    }

    // transform to array
    for (const value in uniqueValues) {
      dataSource.push({ val: value });
    }
    dataSource.sort((data1, data2) => {
      if (data1.val > data2.val) {
        return 1;
      }

      if (data1.val < data2.val) {
        return -1;
      }

      return 0;
    });

    return dataSource;
  }

  function getColumnTitle(colName) {
    let title = '';

    switch (colName) {
      case 'subject':
        title = 'Subject';
        break;

      case 'flowIdentifier':
        title = 'Flow ID';
        break;

      case 'initiatedBy':
        title = 'Started By';
        break;

      case 'initiatedDate':
        title = 'Started Date';
        break;

      case 'currentStep':
        title = 'Current Step';
        break;

      case 'waitingOn':
        title = 'Waiting On';
        break;

      case 'lastUpdatedBy':
        title = 'Last Updated By';
        break;

      case 'lastUpdatedDate':
        title = 'Last Updated Date';
        break;

      case 'status':
        title = 'Status';
        break;

      case 'flowComments':
        title = 'Comments';
        break;
    }

    return title;
  }

  function getColumnWidth(colName, autoFit) {
    if (autoFit) {
      return null;
    }

    let width;

    switch (colName) {
      case 'subject':
        width = '350px';
        break;

      case 'initiatedBy':
      case 'lastUpdatedBy':
        width = '180px';
        break;

      case 'initiatedDate':
      case 'lastUpdatedDate':
        width = '180px';
        break;

      case 'flowComments':
        width = '200px';
        break;

      default:
        width = '150px';
        break;
    }
    return width;
  }
}

export type ReportsUtilityServiceType = ReturnType<
  typeof reportsUtilityService
>;
