/*
 * @ngdoc service
 * @name runnerAuthInterceptor
 * @module flowingly.runner
 *
 * @description A Http interceptor used to add an Authorization header and bearer token to each request.
 *
 * ## Notes
    Do not set Accepts or Content-Type here. Angular adds these for us, plus anything set here, will
    override the headers set elsewhere in code. For example in  file.service.js we set the Content-Type to undefined
    to ensure images are uploaded as multipart/form-data
 * 
 * ###Usage
 *    
 *   $httpProvider.interceptors.push('runnerAuthInterceptor');
 *
 * Note:
 *      This file was converted from JS to TS on 28/11/2019. If 
 *      you need the original JS file, you can view it here on this link
 *      https://bitbucket.org/flowingly-team/flowingly-source-code/src/cdbc54397aad293563635dffd7c5bdeaf0369a46/src/Flowingly.Angular.Runner/Client/runner.authInterceptor.js
*/
import angular, {
  IHttpInterceptor,
  IRequestConfig,
  IWindowService
} from 'angular';
import { SharedAngular } from './@types/sharedAngular';

const $inject = ['$window', 'tokenService'];
const ID = 'runnerAuthInterceptor';

export function RunnerAuthInterceptorFactory(
  $window: IWindowService,
  tokenService: SharedAngular.TokenService
): IHttpInterceptor {
  return {
    request(config: IRequestConfig) {
      //Add an authorisation header to every API request
      if (
        config.url &&
        (config.url.indexOf('api') !== -1 || config.url.indexOf('odata') !== -1)
      ) {
        //see if client has very old token which doesn't include permissions, if yes, get new token.
        const isRequestUserCondition =
          config.url.indexOf('api/usercondition') !== -1;

        const setAuthHeader = (response) => {
          if (response && response.error) {
            console.log(response.error);
          }

          return tokenService
            .checkTokenExpire('/flowsactive/')
            .then((token) => {
              if (token === null) {
                // Token had not expired
                token = tokenService.getToken();
              }

              //Do not set Accepts or Content-Type here (see above)
              config.headers['Authorization'] = 'Bearer ' + token;
              return config;
            })
            .catch((e) => {
              console.log(
                'Calling tokenService.checkTokenExpire - in catch',
                e
              );
              return config;
            });
        };

        const rejectHandler = (response) => {
          if (response && response.error) {
            console.log(response.error);
          }

          $window.location = response.redirectUri;
          return config;
        };

        return tokenService
          .refreshTokenIfPermissionsDonotExist(isRequestUserCondition)
          .then(setAuthHeader, rejectHandler);
      } else {
        return config;
      }
    }
  };
}

RunnerAuthInterceptorFactory['$inject'] = $inject;
RunnerAuthInterceptorFactory['ID'] = ID;

angular
  .module('flowingly.runner')
  .factory(RunnerAuthInterceptorFactory['ID'], RunnerAuthInterceptorFactory);
