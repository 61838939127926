/**
 * This is the application's routes configuration.
 * Only configure routes in this file.
 *
 * The main application routes are configured here.
 * Routes for individual modules are within each module. E.g. workflow/workflow.route.js
 */
import angular from 'angular';
import { SharedAngular } from './@types/sharedAngular';
// Register the application's routes configuration.
angular.module('flowingly.runner').config(config);

// Inject the dependencies (we are using UI-Router)
config.$inject = ['$urlRouterProvider', '$stateProvider'];

function config(
  $urlRouterProvider: angular.ui.IUrlRouterProvider,
  $stateProvider: angular.ui.IStateProvider
) {
  // Register default route
  // Set default routes when no path specified
  // Our default route handles authentication correctly
  $urlRouterProvider.when('', 'flowsactive/');
  $urlRouterProvider.when('/', 'flowsactive/');
  $urlRouterProvider.otherwise('/flowsactive/');

  $stateProvider
    //Root state for the entire application
    .state('app', {
      abstract: true,
      template: '<ui-view></ui-view>'
    })

    // Main state fur the runner application, can only enter after authenticated.
    // Note that it is an abstract state and hence we cannot go to it directly. We use dot notation for our
    // heirarchy of states, so we have:
    // app
    // app.login
    // app.runner
    // app.runner.flowsin ...
    // and so app.login is not a child of app.runner
    .state('app.runner', {
      url: '/',
      abstract: true,
      templateUrl: 'Client/runner.html',
      controller: 'runnerController',
      controllerAs: 'rctrl',
      resolve: {
        user: [
          'pubsubService',
          'authService',
          'fileService',
          function (
            pubsubService: SharedAngular.PubSubService,
            authService: AuthService,
            fileService: SharedAngular.FileService
          ) {
            return authService.getUserDeferred().then(function () {
              pubsubService.publish('READY_FOR_SIGNALR', {});

              const user = authService.getUser();
              fileService.setUser(user.id, user.businessId);
              return user;
            });
          }
        ],
        settingsLoaded: [
          'APP_CONFIG',
          function (APP_CONFIG: SharedAngular.APP_CONFIG) {
            return APP_CONFIG.ready;
          }
        ]
      }
    });
}
