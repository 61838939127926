/**
 * @ngdoc directive
 * @name flowStart
 * @module flowingly.runner.start
 * @description  This component is used to display a flowmodel that can be started..
 *               It is responsible for starting the flowmodel.
 * @usage
 * ```
     <flow-start flow-model="flowModel" is-mobile="$ctrl.isMobile"></flow-start>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * flowModel: the flowModel to start
 * * isMobile: show mobile view if set
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.flow.start').component('flowStart', {
  bindings: {
    flowModel: '<',
    isMobile: '<'
  },
  controller: [
    '$state',
    'guidService',
    'APP_CONFIG',
    function (
      $state: IStateService,
      guidService: SharedAngular.GuidService,
      APP_CONFIG: SharedAngular.APP_CONFIG
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const $ctrl = this;
      $ctrl.onFlowHeaderClick = onFlowHeaderClick;
      $ctrl.showConfidentialFlowOption = APP_CONFIG.showConfidentialFlowOption;

      // PUBLIC METHODS /////////////////////////////////////////////////////////////////////
      function onFlowHeaderClick() {
        $state.go(
          'app.runner.flow',
          { flowId: guidService.empty(), flowModelId: $ctrl.flowModel.id },
          { notify: true }
        );
      }
    }
  ],
  templateUrl: 'Client/runner.flow.start/runner.flow.start.component.tmpl.html'
});
