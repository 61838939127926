/**
 * @ngdoc directive
 * @name runnerEditFlowModel
 * @module flowingly.runner.directives
 * @description This is a component for showing edit flow model button if has permission and take user to Modeler site, also will send out intercom track event.
 *
 * Input attributes
 * flowModelId: the flow model id to build url for go to Modeler site
 *
 * @usage
 * ```
 * <runner-edit-flow-model ng-show="!$ctrl.isMobile" flow-model-id="$ctrl.flow.id"></runner-edit-flow-model>
 * ```
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

class EditFlowModelComponentController {
  static $inject = ['tempModelerUrlService'];
  flowModelId: string;

  constructor(
    private tempModelerUrlService: SharedAngular.TempModelerUrlService
  ) {}

  onEditOrViewFlowModelClick() {
    this.tempModelerUrlService.openModeler(this.flowModelId);
  }
}

export class EditFlowModelComponent implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      flowModelId: '<',
      canEdit: '<'
    };

    this.templateUrl =
      'Client/runner.directives/flow/runner.edit-flow-model.tmpl.html';
  }

  controller = EditFlowModelComponentController;
}

angular
  .module('flowingly.runner.directives')
  .component('runnerEditFlowModel', new EditFlowModelComponent());
