'use strict';
import angular from 'angular';

///
/// This state displays flows that the user can initiaite
///

// Register the application's routes configuration.
angular.module('flowingly.runner.exago.single').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.exagosingle', {
    url: 'processdashboard',
    params: { title: 'Flow Dashboard' },
    controller: 'runnerExagoControllerSingle',
    controllerAs: '$ctrl',
    templateUrl: 'Client/runner.exago.single/runner.exago.single.tmpl.html'
  });
}
