import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';

export class RunnerLibraryService {
  constructor(
    private tempModelerUrlService: SharedAngular.TempModelerUrlService
  ) {}
  openProcessMap(flowId) {
    const url = this.getProcessMapUrl(flowId);
    this.tempModelerUrlService.openLinkInNewTab(url);
  }

  getProcessMapUrl(flowId) {
    return `/processmapsv2/${flowId}`;
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerLibraryService', [
    'tempModelerUrlService',
    (tempModelerUrlService) => new RunnerLibraryService(tempModelerUrlService)
  ]);

export type RunnerLibraryServiceType = InstanceType<
  typeof RunnerLibraryService
>;
