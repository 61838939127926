/**
 * ```
     $state.go('app.runner.notifications');
 * ```  
 * ### Properties
 * #### Inputs
 */
(function () {
  'use strict';

  ///
  /// This state displays user notifications
  ///

  // Register the application's routes configuration.
  angular.module('flowingly.runner.user.notifications').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.notifications', {
      url: 'usernotifications',
      params: { userId: null, title: 'Notifications' },
      templateUrl:
        'Client/runner.user.notifications/runner.user.notifications.tmpl.html'
    });
  }
})();
