/**
 * @ngdoc directive
 * @name flowHistoryFieldOptionList
 * @module flowingly.runner.flow
 * @description This comppnent is used to display option list history items.
 * @usage
 * ```
     <flow-history-field-option-list item="item" ></flow-history-field-option-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * item: the optionlist data to display (JSON)
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .component('flowHistoryFieldOptionList', {
    bindings: {
      item: '<'
    },
    controller: function () {
      const $ctrl = this;

      $ctrl.selectedOption = $ctrl.item.Value;
      $ctrl.options = JSON.parse($ctrl.item.Options);
    },
    templateUrl:
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.optionlist.component.tmpl.html'
  });
