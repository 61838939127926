/**
 * @ngdoc directive
 * @name userPasswordStrength
 * @module flowingly.runner.directives
 * @description This component is used to display a users password strength
 * @usage
 * ```
     <user-password-strength password="$ctrl.model.user.password" ></user-password-strength>
 * ``` 
 * ### Notes
 * ### Properties
 * #### Inputs
 * * password: the password the user types in
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';
angular
  .module('flowingly.runner.directives')
  .component('userPasswordStrength', {
    bindings: {
      password: '<'
    },
    controller: [
      function () {
        const $ctrl = this;

        $ctrl.passwordStrengthCalculator = passwordStrengthCalculator;

        const passScore = {
          0: { description: 'Very Weak', color: 'red', percent: '20%' },
          1: { description: 'Weak', color: 'red', percent: '40%' },
          2: { description: 'Fair', color: 'yellow', percent: '60%' },
          3: { description: 'Good', color: 'green', percent: '80%' },
          4: { description: 'Strong', color: 'green', percent: '100%' }
        };

        function passwordStrengthCalculator() {
          const pwd = $ctrl.password || '';
          const result = zxcvbn(pwd);
          const passResult = passScore[result.score];

          $ctrl.passwordCalculator = {
            show: true,
            color: passResult.color,
            strength: passResult.percent,
            strengthText: passResult.description,
            suggestions: result.feedback.suggestions
          };
        }
      }
    ],

    template: `<div class="input-field col s10 offset-s1 m6 offset-m3 l6 offset-l3">
                        <div class="ml-10 mnr-10" ng-show="ctrl.passwordCalculator.show === true">
                            <div class="progress small">
                                <div class="white-text {{ctrl.passwordCalculator.color}}" style="width: {{ctrl.passwordCalculator.strength}}"></div>
                            </div>
                            <div class="mb-10">
                                The password strength is <strong>{{ctrl.passwordCalculator.strengthText}}</strong>
                            </div>
                            <div class="password-calculator-suggestions" ng-repeat="suggestion in ctrl.passwordCalculator.suggestions">
                                {{ suggestion }}
                            </div>
                        </div>
                    </div>`
  });
