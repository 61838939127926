import angular from 'angular';
import { IStateProvider } from 'angular-ui-router';

angular.module('flowingly.runner.processmapsv2').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider): void {
  $stateProvider
    .state('app.runner.processmapv2', {
      url: 'processmapsv2',
      params: {
        title: 'Process Maps',
        backFromState: ''
      },
      controller: 'runnerProcessMapsV2Controller',
      controllerAs: '$ctrl',
      templateUrl: 'Client/runner.processmapsv2/runner.processmapsv2.tmpl.html'
    })
    .state('app.runner.processmapviewv2', {
      url: 'processmapsv2/:processMapId',
      params: {
        title: 'Process Maps',
        backFromState: ''
      },
      templateUrl:
        'Client/runner.processmapsv2/view/runner.processmapsv2.view.tmpl.html',
      controller: 'runnerProcessMapsV2ViewController',
      controllerAs: '$ctrl'
    });
}
