import React from 'react';
import FlowIconSquare from '../FlowIconSquare/FlowIconSquare';
import ProcessMap from '@Client/runner.processmapsv2/@types/processMap';

type Props = {
  isMobile: boolean;
  flowModelSummary: ProcessMap;
  onFlowModelClick(id: string): void;
  resetSearchItem(): void;
};

const FlowModelSummaryComponent = (props: Props) => {
  const { flowModelSummary, isMobile, resetSearchItem } = props;

  return (
    <div className={`flow-start`} id={`flowactive_${flowModelSummary.id}`}>
      <div
        className="flow-start-header flex flex-row"
        onClick={() => {
          resetSearchItem();
          props.onFlowModelClick(flowModelSummary.id);
        }}
      >
        {!isMobile && (
          <FlowIconSquare name={flowModelSummary.name} flowCode="" />
        )}

        {!isMobile && (
          <div className="flow-list__contents flex flex-row">
            <div className="flow-list__contents__subject">
              <div className="flow-list__contents__subject__text flex-column">
                {flowModelSummary.name}
              </div>
              <div className="flow-list__contents__subject__created-by flex-column">
                {flowModelSummary.category}
              </div>
            </div>
            <div className="flow-list__contents__description">
              {flowModelSummary.description}
            </div>
            <div className="flow-list__contents__process-owner">
              <div className="flow-list__contents__process-owner__text flex-column">
                Process Owner: {flowModelSummary.processOwnerName || '--'}
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className="flow-start-mobile">
            <div className="flex flow-header__main">
              <FlowIconSquare name={flowModelSummary.name} flowCode="" />
              <div className="flow-list__contents flex flex-row">
                <div className="flow-list__contents__subject--mobile mr-20">
                  <div className="flow-list__contents__subject__text flex-column">
                    {flowModelSummary.name}
                  </div>
                  <div className="flow-list__contents__subject__started-by flex-column">
                    {flowModelSummary.category}
                  </div>
                </div>
              </div>
            </div>
            <div className="flow-header__extra flow-header__extra--start-flow">
              {flowModelSummary.description}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FlowModelSummaryComponent;
