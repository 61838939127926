'use strict';
//
// This state displays the Template Library
//
import * as angular from 'angular';

angular.module('flowingly.runner.templates').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider
    .state('app.runner.templates', {
      url: 'templates',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.runner.templates.library', {
      url: '/library',
      params: { title: 'Template Library' },
      templateUrl:
        'Client/runner.templates/library/runner.templates.library.tmpl.html',
      controller: 'runnerTemplatesLibraryController',
      controllerAs: 'ctrl'
    });
}
