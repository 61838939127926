/**
 * @ngdoc module
 * @name flowingly.runner.user.accept.invite
 * @module flowingly.runner.user.accept.invite
 * @description This module allows users to complete their profiles when signing up
 */
const m = angular.module('flowingly.runner.user.accept.invite', []);
m.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.user.accept.invite/runner.user.accept.invite.tmpl.html',
      require('./runner.user.accept.invite.tmpl.html').default
    );
  }
]);
