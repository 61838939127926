import {
  LoginAuditAction,
  LoginAuditIdentityProvider
} from '@Shared.Angular/flowingly.services/flowingly.constants';

('use strict');

angular
  .module('flowingly.runner.sso')
  .controller('runnerSSOController', runnerSSOController);

runnerSSOController.$inject = [
  '$stateParams',
  '$state',
  'tokenService',
  'authService',
  'sessionService',
  'userApiService',
  'authLoggingApiService'
];

function runnerSSOController(
  $stateParams,
  $state,
  tokenService,
  authService,
  sessionService,
  userApiService,
  authLoggingApiService
) {
  var $ctrl = this;

  $ctrl.$onInit = function () {
    var token = {
      id_token: $stateParams.id_token,
      state: $stateParams.state
    };

    tokenService.setToken(token.id_token);

    authService.getUserDetails(null, token.id_token).then((response) => {
      if (response) {
        sessionService.setIsSso(true);
        userApiService.userLoginAudit({
          success: true,
          identityProvider: LoginAuditIdentityProvider.SSO, // sso login
          action: LoginAuditAction.Login
        });

        if ($stateParams.redirect) {
          /**
           * Security regex checks if the redirection url is outside the flowingly app.
           * This is a man in the maddle attack prevention.
           *
           * What urls are allowed by this regex?
           *
           *
           * (http:https)://localhost
           * (http:https)://localhost:PORT
           * (http:https)://northeu.flowingly.net // stuff from flowingly.net
           */
          const securityRegex =
            /^https?:\/\/((localhost(:\d+)?)|(\w+?\.flowingly.net))/;
          if (securityRegex.test($stateParams.redirect)) {
            authLoggingApiService.log(
              `runner.sso.controller - authService.getUserDetails.then. About to be navigated to ${$stateParams.redirect}`
            );
            $window.location.href = $stateParams.redirect;
          } else {
            console.warn('Malicious request found! ' + $stateParams.redirect);
            authLoggingApiService.log(
              `runner.sso.controller - authService.getUserDetails.then. Malicious request found! with re-dir-ect-ion url ${$stateParams.redirect}. About to be navigated to app.login`
            );
            $state.go('app.login');
          }
        } else {
          authLoggingApiService.log(
            'runner.sso.controller - authService.getUserDetails.then. About to be navigated to app.runner.flowsactive'
          );
          $state.go('app.runner.flowsactive');
        }
      } else {
        sessionService.setIsSso(false);
        userApiService.userLoginAudit({
          success: false,
          identityProvider: LoginAuditIdentityProvider.SSO, // sso login
          error: 'invalid token',
          action: 1
        });

        authLoggingApiService.log(
          'runner.sso.controller - authService.getUserDetails.then. About to be navigated to app.login'
        );
        $state.go('app.login');
      }
    });
  };
}
