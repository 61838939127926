import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.flows.search
 * @module flowingly.runner.flows.search
 * @description This module configures the page used to display the flow search results.
 */
const m = angular.module('flowingly.runner.flows.search', []);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.flows.search/runner.flows.search.tmpl.html',
      require('./runner.flows.search.tmpl.html').default
    );
  }
]);
