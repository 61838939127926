/**
 * @ngdoc service
 * @name runnerPublicFormApiService
 * @module flowingly.runner.services
 *
 * @description A helper API service for public form
 *
 * ## Notes
 *
 * ###API
 * * getFormById - get the form data by form guid
 * * submitForm - submit the form and then will complete the first step under the hood
 *
 */

import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
import IApiPublicFormModel from '@Shared.Angular/@types/core/contracts/queryModel/api/apiPublicFormModel';

export class RunnerPublicFormApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {}

  getFormById(flowModelId: string, inIframe: boolean, noSpinner: boolean) {
    return this.$http
      .get<IResponseData<IApiPublicFormModel>>(
        `${this.APP_CONFIG.apiBaseUrl}form/${flowModelId}/${inIframe}`,
        {
          noSpinner: noSpinner || true
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  submitForm(flowModelId: string, formData, noSpinner: boolean) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}form/${flowModelId}`,
      formData,
      { noSpinner: noSpinner || false }
    );
  }

  uploadAnonymousFile(
    formData: HTMLFormElement,
    flowModelId: string,
    stepId: string,
    fieldKey: string
  ) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}form/${flowModelId}/step/${stepId}/field/${fieldKey}/file`,
      formData,
      {
        transformRequest: angular.identity,
        timeout: 90000,
        headers: { 'Content-Type': undefined }
      }
    );
  }

  deleteAnonymousFile(fileId: string, stepId: string) {
    return this.$http.delete(
      `${this.APP_CONFIG.apiBaseUrl}form/step/${stepId}/file/${fileId}`
    );
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerPublicFormApiService', [
    '$http',
    'APP_CONFIG',
    ($http: angular.IHttpService, APP_CONFIG: SharedAngular.APP_CONFIG) =>
      new RunnerPublicFormApiService($http, APP_CONFIG)
  ]);

export type RunnerPublicFormApiServiceType = InstanceType<
  typeof RunnerPublicFormApiService
>;
