/**
 * @ngdoc module
 * @name flowingly.runner.flow
 * @module flowingly.runner.flow
 * @description This module contains the components used to generate the flow lists.
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 */
import angular, { ITemplateCacheService } from 'angular';
const m = angular.module('flowingly.runner.flow', []);
m.run([
  '$templateCache',
  function ($templateCache: ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.flow/runner.flow.container/runner.flow.container.tmpl.html',
      require('../runner.flow.container/runner.flow.container.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.footer/runner.flow.addsteptask.dialog.tmpl.html',
      require('../runner.flow.footer/runner.flow.addsteptask.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.footer/runner.flow.cancel.dialog.tmpl.html',
      require('../runner.flow.footer/runner.flow.cancel.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.footer/runner.flow.footer.tmpl.html',
      require('../runner.flow.footer/runner.flow.footer.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.footer/runner.flow.override.integration.dialog.tmpl.html',
      require('../runner.flow.footer/runner.flow.override.integration.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.footer/runner.flow.reassign.dialog.tmpl.html',
      require('../runner.flow.footer/runner.flow.reassign.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.form/runner.flow.dynamic-actor.dialog.tmpl.html',
      require('../runner.flow.form/runner.flow.dynamic-actor.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.form/runner.flow.form.component.tmpl.html',
      require('../runner.flow.form/runner.flow.form.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.form/runner.flow.multiple-approvers.dialog.tmpl.html',
      require('../runner.flow.form/runner.flow.multiple-approvers.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.header/runner.flow.header.component.tmpl.html',
      require('../runner.flow.header/runner.flow.header.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.completed.tmpl.html',
      require('../runner.flow.history/runner.flow.history.completed.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.container.tmpl.html',
      require('../runner.flow.history/runner.flow.history.container.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.checkbox.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.field.checkbox.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.multicheckbox.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.field.multicheckbox.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.optionlist.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.field.optionlist.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.signature.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.field.signature.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.field.tasklist.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.field.tasklist.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.nudge.dialog.tmpl.html',
      require('../runner.flow.history/runner.flow.history.nudge.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.nudge.tmpl.html',
      require('../runner.flow.history/runner.flow.history.nudge.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.step.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.step.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.step.reassignment.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.step.reassignment.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.history/runner.flow.history.steptask.component.tmpl.html',
      require('../runner.flow.history/runner.flow.history.steptask.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.list/runner.flow.list.tmpl.html',
      require('../runner.flow.list/runner.flow.list.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.status/runner.flow.status.component.tmpl.html',
      require('../runner.flow.status/runner.flow.status.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.step.task/runner.flow.step.task.details.tmp.html',
      require('../runner.flow.step.task/runner.flow.step.task.details.tmp.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow.step.task/runner.flow.step.task.list.component.tmpl.html',
      require('../runner.flow.step.task/runner.flow.step.task.list.component.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.flow/runner.flow/runner.flow.tmpl.html',
      require('./runner.flow.tmpl.html').default
    );
  }
]);
