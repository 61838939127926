'use strict';

import angular, { ITemplateCacheService } from 'angular';

const runnerProcessmapsV2Module = angular.module(
  'flowingly.runner.processmapsv2',
  []
);

runnerProcessmapsV2Module.run([
  '$templateCache',
  ($templateCache: ITemplateCacheService) => {
    $templateCache.put(
      'Client/runner.processmapsv2/runner.processmapsv2.tmpl.html',
      require('./runner.processmapsv2.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.processmapsv2/view/runner.processmapsv2.view.tmpl.html',
      require('./view/runner.processmapsv2.view.tmpl.html').default
    );
  }
]);
export default runnerProcessmapsV2Module;
