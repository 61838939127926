import { SharedAngular } from '@Client/@types/sharedAngular';
import * as angular from 'angular';
import { IWindowService } from 'angular';

class ProcessMapTabProcessDetailsController {
  static $inject = ['$window', 'flowinglyMomentService'];

  processOwner: string;
  processCategory: string;
  processReviewDate: string;
  processDescription: string;
  processObjective: string;
  processBackground: string;
  processInput: string;
  processOutput: string;

  constructor(
    private $window: IWindowService,
    private flowinglyMomentService: SharedAngular.FlowinglyMomentService
  ) {}

  private FIXED_ELEMS_TOTAL_HEIGHT = 249;
  private NOT_YET_DEFINED_PROCESS_OWNER = 'Process Owner not yet defined';
  private NOT_YET_DEFINED_PROCESS_REVIEW_DATE =
    'Process Review Date not yet defined';
  private NOT_YET_DEFINED_OBJECTIVE = 'Objective not yet defined';
  private NOT_YET_DEFINED_BACKGROUND = 'Background not yet defined';
  private NOT_YET_DEFINED_INPUT = 'Process Input not yet defined';
  private NOT_YET_DEFINED_OUTPUT = 'Process Output not yet defined';

  public $onInit() {
    //this.$window.addEventListener("resize", this.setDynamicMaxHeightResize);
    //this.setDynamicMaxHeight();
  }

  public updateViewEditFlowModelButtonCssIfNeeded() {
    const FIXED_ELEMS_TOTAL_HEIGHT = 249;
    const onload_ht = window.innerHeight - FIXED_ELEMS_TOTAL_HEIGHT;
    const resized_ht = parseInt($('.pmap-process-details-tab').css('height')); //parseInt to remove the px part
    //console.log('HEIGHT: ' + onload_ht + ', ' + resized_ht);
    const pos = resized_ht >= onload_ht ? 'relative' : 'absolute';
    $('.edit-or-view-flowmodel-btn').css('position', pos);
  }

  public $onChanges() {
    this.processReviewDate =
      this.processReviewDate == null
        ? this.NOT_YET_DEFINED_PROCESS_REVIEW_DATE
        : this.flowinglyMomentService
            .formatUTCToLocal(this.processReviewDate)
            .replace(/ /g, '-');
    this.processObjective =
      this.processObjective == null || this.processObjective == ''
        ? this.NOT_YET_DEFINED_OBJECTIVE
        : this.processObjective;
    this.processBackground =
      this.processBackground == null || this.processBackground == ''
        ? this.NOT_YET_DEFINED_BACKGROUND
        : this.processBackground;
    this.processInput =
      this.processInput == null || this.processInput == ''
        ? this.NOT_YET_DEFINED_INPUT
        : this.processInput;
    this.processOutput =
      this.processOutput == null || this.processOutput == ''
        ? this.NOT_YET_DEFINED_OUTPUT
        : this.processOutput;
  }
}

export class ProcessMapTabProcessDetails implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      processOwner: '<',
      processCategory: '<',
      processReviewDate: '<',
      processDescription: '<',
      processObjective: '<',
      processBackground: '<',
      processInput: '<',
      processOutput: '<'
    };

    this.templateUrl =
      'Client/runner.processmaps/tab/left-pane/runner.processmap.tab.process-details.tmpl.html';
  }

  controller = ProcessMapTabProcessDetailsController;
}

angular
  .module('flowingly.runner.processmap')
  .component('processmapTabProcessDetails', new ProcessMapTabProcessDetails());
