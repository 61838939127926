import * as React from 'react';
import { TreeListCellProps } from '@progress/kendo-react-treelist';

const Count = (props: TreeListCellProps) => {
  const calculateTotalCount = (category, isExpanded) => {
    let count = category.numberOfFlows;
    if (!isExpanded && category.subCategories && category.subCategories.length > 0) {
      category.subCategories.forEach((nestedItem) => {
        count += calculateTotalCount(nestedItem, isExpanded);
      });
    }
    return count;
  };

  const count = calculateTotalCount(props.dataItem, props.expanded);
  return (
    <td>
      <span>{count}</span>
    </td>
  );
};
export default Count;
