/**
 * @ngdoc directive
 * @name flowReportList
 * @module flowingly.runner.reports
 * @description  This component is used to display a list of reports
 * @usage
 * ```
    <flow-report-list ng-show="$ctrl.flow.expanded" flow="$ctrl.flow"></flow-report-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * flow: 
 * * isMobile :show mobile view if set,
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.reports').component('flowReportList', {
  bindings: {
    flow: '<',
    isMobile: '<' //if isMobile then show mobile view
  },
  template: [
    '<div class="report-list" ng-class="{\'isMobile\': $ctrl.isMobile}">',
    '<flow-report ng-repeat="report in $ctrl.flow.reports" report="report" flow="$ctrl.flow"></flow-report>',
    '</div>'
  ].join('')
});
