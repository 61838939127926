import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope, IComponentOptions, IController } from 'angular';

export interface BulkActorItem {
  id: string;
  displayName: string;
  avatarUrl: string;
  searchEntityType: any;
}

export class BulkActorsInput implements IComponentOptions {
  public controller = BulkActorsInputController;
  public templateUrl =
    'Client/runner.directives/input/bulk-actor-selector/bulk.select.actors.tmpl.html';
  public bindings = {
    ngModel: '=',
    ngChange: '&',
    isRequired: '<',
    ngDisabled: '<'
  };
}

export class BulkActorsInputController implements IController {
  constructor(
    private $scope: IScope,
    private dialogService: SharedAngular.DialogService
  ) {}

  public ngModel: BulkActorItem[];
  public ngChange: () => unknown;
  public isRequired: boolean;
  public ngDisabled: boolean;
  private hasUpdated: boolean;

  public $onInit() {
    if (this.ngModel == null) {
      this.ngModel = [];
    }
  }

  public selectActors() {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.directives/input/bulk-actor-selector/dialogue/bulk.select.actor.dialog.tmpl.html',
        controller: 'bulkSelectActorDialogController',
        appendClassName: 'ngdialog-normal dynamic-actors-dialog',
        scope: this.$scope,
        data: {
          selectedBulkStartActors: this.ngModel,
          isRequired: this.isRequired
        }
      })
      .then((selectedBulkActors: BulkActorItem[]) => {
        if (
          this.dialogService.isCloseModalWithCancelAction(selectedBulkActors)
        ) {
          //user closed modal by clicking on overlay (or cancel or press Esc key)
          return;
        } else {
          this.ngModel = selectedBulkActors;
          this.ngChange();

          this.hasUpdated = true; // controlls the add|edit button state
        }
      });
  }
}
// for some unknown reason have to move $inject here to resolve runtime error Class constructor cannot be invoked without new
BulkActorsInputController.$inject = ['$scope', 'dialogService'];
angular
  .module('flowingly.runner.directives')
  .component('bulkSelectActors', new BulkActorsInput());
