'use strict';
import angular from 'angular';

///
/// This state displays flows that the user can initiaite
///

// Register the application's routes configuration.
angular.module('flowingly.runner.exago').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.exago', {
    url: 'processanalytics',
    params: { title: 'Process Analytics' },
    controller: 'runnerExagoController',
    controllerAs: '$ctrl',
    templateUrl: 'Client/runner.exago/runner.exago.tmpl.html'
  });
}
