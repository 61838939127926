/**
 * @ngdoc directive
 * @name categoryListActions
 * @module flowingly.runner.setup
 * @description  This component is used to display actions button list in a category row
 * @usage
 * ```
     <category-list-actions category-id='dataItem.id'></category-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * category: category to act upon
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('categoryListActions', {
    bindings: {
      category: '<',
      onCategoriesUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.categories/runner.setup.category.list.actions.tmpl.html',
    controller: [
      '$scope',
      'APP_CONFIG',
      'sessionService',
      'dialogService',
      'notificationService',
      'categoryApiService',
      function (
        $scope,
        APP_CONFIG,
        sessionService,
        dialogService,
        notificationService,
        categoryApiService
      ) {
        var $ctrl = this;

        const category = 4;

        $ctrl.editClick = editClick;

        $ctrl.deleteClick = deleteClick;

        let isFlowModelAdmin = sessionService.isFlowModelAdmin();

        function editClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.categories/runner.setup.category.dialog.tmpl.html',
              controller: 'categoryDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: $ctrl.category
            })
            .then(function (data) {
              if (!!APP_CONFIG.disableAdminCategoryEdit && isFlowModelAdmin)
                return;

              if (dialogService.isCloseModalWithCancelAction(data)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              //raise an 'event' to notify the parent
              $ctrl.onCategoriesUpdated();
            });
        }

        function deleteClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
              controller: 'setupDeleteDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: {
                itemToDelete: $ctrl.category,
                entityType: category
              }
            })
            .then(function (response) {
              if (!!APP_CONFIG.disableAdminCategoryEdit && isFlowModelAdmin)
                return;

              if (dialogService.isCloseModalWithCancelAction(response)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }
              if (response !== undefined) {
                categoryApiService
                  .deleteCategory($ctrl.category.id, response.actor)
                  .then(() => {
                    notificationService.showSuccessToast(
                      `${$ctrl.category.name} successfully Deleted.`,
                      1000
                    );
                    $ctrl.onCategoriesUpdated({ deleted: true });
                  });
              }
            });
        }
      }
    ]
  });
})();
