import angular, { IController } from 'angular';

class MaintenanceFlowActionDialogController implements IController {
  static $inject = ['$scope'];

  public isFlow = false;
  public flowIdentifier: string;
  public stepName: string;
  public action: string;
  public message: string;

  constructor(private $scope: IScopeNgDialog) {
    this.isFlow = $scope.ngDialogData.isFlow;
    this.flowIdentifier = $scope.ngDialogData.flowIdentifier;
    this.stepName = $scope.ngDialogData.stepName;
    this.action = $scope.ngDialogData.action;
    this.message = $scope.ngDialogData.message;
  }

  public doAction() {
    return this.$scope.closeThisDialog(true);
  }

  public closeDialogue() {
    this.$scope.closeThisDialog();
  }

  private get title(): string {
    if (this.isFlow) {
      return `${this.action} Flow "${this.flowIdentifier}"`;
    }
    return `${this.action} Step "${this.stepName}"`;
  }
}

angular
  .module('flowingly.runner.maintenance')
  .controller(
    'maintenanceFlowActionDialogController',
    MaintenanceFlowActionDialogController
  );
