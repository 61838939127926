/**
 * @ngdoc directive
 * @name flowHistoryStepTask
 * @module flowingly.runner.flow
 * @description This component is used to display information about a completed step task.
 * #### Inputs
 * * stepTask: the step task data to display.
 * * isMobile: show mobile view if set.
 * * flow: The flow model.
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryStepTask', {
  bindings: {
    stepTask: '<',
    isMobile: '<',
    flow: '<'
  },
  controller: [
    'flowinglyMomentService',
    'fileService',
    function (
      flowinglyMomentService: SharedAngular.FlowinglyMomentService,
      fileService: SharedAngular.FileService
    ) {
      const $ctrl = this;

      $ctrl.formatStepTaskDateTime = function (dateTimeString) {
        if (dateTimeString != null) {
          const formattedDateTime = dateTimeString.replace('Z', '');
          return formattedDateTime;
        }
        return dateTimeString;
      };

      $ctrl.StepTaskCommentMomentFromDate = function (dateTimeString) {
        if (dateTimeString != null) {
          return flowinglyMomentService.calculateRelativeTimeOrFullDate(
            dateTimeString
          );
        }
        return dateTimeString;
      };

      $ctrl.StepTaskFileDownloadLink = function (fileId) {
        if (fileId != null) {
          return fileService.getDownloadLink(fileId);
        }
        return fileId;
      };
    }
  ],

  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.steptask.component.tmpl.html'
});
