/**
 * ```
     $state.go('app.runner.flowsin');
 * ``` 
 * ### Notes
 *  Module structure:
 *    route (app.runner.flowsin) --> runner.flows.imin.tmpl
 *      flowsIminGroups                   // Handles the list of groups
 *        flowsIminGroup               // Handles each group (which contains a group.name and the group.flows)
 *          flowsIminGroupHeader          // Handles the group header for a group of flows
 *          flowsIminGroupFlows            // Handles the flow list for a group
 *            flowsIminGroupFlow          // Handles each flow in the group
 *              --> runner.flows.imin.group.flow.tmpl.html //uses the flow-list directive to present all the flows in the group
 * ### Properties
 * #### Inputs
 */

'use strict';
import angular from 'angular';

///
/// This state displays flows that the user has action to do on
///

// Register the application's routes configuration.
angular.module('flowingly.runner.flows.imin').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.flowsin', {
    url: 'flowsin',
    params: { data: {}, title: "Flows I'm In" },
    controller: 'runnerFlowsIminController',
    controllerAs: 'ctrl',
    templateUrl: 'Client/runner.flows.imin/runner.flows.imin.tmpl.html'
  });
}
