'use strict';

angular.module('flowingly.runner.setup').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.setup.roles', {
    url: '/roles',
    params: { title: 'Setup - Roles' },
    views: {
      childcontent: {
        controllerAs: '$ctrl',
        controller: 'setupRolesController',
        templateUrl:
          'Client/runner.setup/runner.setup.roles/runner.setup.roles.tmpl.html'
      }
    }
  });
}
