/**
 * @ngdoc directive
 * @name flowLibrary
 * @module flowingly.runner.library
 * @description  This is the ROOT level SMART comppnent.
 *               It is responsible for updating the data in the list
 * @usage
 * ```
      <flow-library></flow-library>
 * ``` 
 */

import angular from 'angular';

angular.module('flowingly.runner.library').component('flowLibrary', {
  templateUrl: 'Client/runner.library/runner.library.tmpl.html'
});
