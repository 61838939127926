/**
 * @ngdoc type
 * @module flowingly.runner.flows.search
 * @name runnerFlowsSearchController
 *
 * @description
 */

'use strict';
import angular, { IRootScopeService, IScope } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';

angular
  .module('flowingly.runner.flows.search')
  .controller('runnerFlowsSearchController', runnerFlowsSearchController);

runnerFlowsSearchController.$inject = [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  'flowApiService',
  'runnerFlowsFormatter'
];

function runnerFlowsSearchController(
  $scope: IScope,
  $rootScope: IRootScopeService,
  $state: IStateService,
  $stateParams: IStateParamsService,
  flowApiService: FlowApiService,
  runnerFlowsFormatter: RunnerFlowsFormatterService
) {
  const ctrl = this;

  ctrl.onInitiating = true;

  ctrl.$onInit = () => {
    init();
  };

  //-----------------PRIVATE METHODS----------------------------
  function init() {
    ctrl.searchTerm = $stateParams.term;
    ctrl.pageHeaderText = 'Search Results';
    ctrl.pageDescription = `for "${ctrl.searchTerm}"`;

    loadSearchResults(ctrl.searchTerm);
  }

  function loadSearchResults(term) {
    flowApiService.getFlowSearchResults(term).then(function (response) {
      const groups = response;
      // [FLOW-5699] Remove groups that doesn't have flows in it.
      runnerFlowsFormatter.removeGroupsWithoutFlows(groups);
      runnerFlowsFormatter.format(groups);
      ctrl.groups = groups;

      if (ctrl.groups.length !== 1 || ctrl.groups[0].Flows.length !== 1) {
        if (ctrl.groups.length === 0) {
          ctrl.pageHeaderText = 'Sorry,';
          ctrl.pageDescription = `we can't seem to find any flows that match your search for "${ctrl.searchTerm}"`;
        }
        ctrl.onInitiating = false;
      } else $state.go('app.runner.flow', { flowId: ctrl.groups[0].Flows[0].FlowId, searchJump: true });
    });
  }
}
