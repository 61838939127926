/**
 * @ngdoc type
 * @module flowingly.runner.flow
 * @name flowOverrideIntegrationController
 *
 * @description Controller for webhook override dialog.
 */
'use strict';

import { IWebhookOverride } from '@Client/runner.services/flow.api.service';
import angular from 'angular';

class FlowOverrideIntegrationController {
  static $inject = ['$scope'];

  public currentForm;
  public reason: string;

  constructor(private $scope: IScopeNgDialog) {}

  override() {
    if (this.currentForm.$valid) {
      const result: IWebhookOverride = {
        stepId: undefined,
        flowId: undefined,
        userId: undefined,
        reason: this.reason
      };
      this.$scope.closeThisDialog(result);
    }
  }

  cancel() {
    this.$scope.closeThisDialog();
  }
}

angular
  .module('flowingly.runner.flow')
  .controller(
    'flowOverrideIntegrationController',
    FlowOverrideIntegrationController
  );
