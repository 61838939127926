/**
 * @ngdoc directive
 * @name flowProcessmap
 * @module flowingly.runner.processmap
 * @description  This component is used to display a processmap
 * @usage
 * ```
     <flow-process-map flow="flow" on-click-method="$ctrl.toggleExpandedItem(id)" is-mobile="$ctrl.isMobile"></flow-process-map>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * processMap: the process map to show
 * * isMobile: show mobile view if set
 * 
 * #### Events (Outputs)
 * * onClickMethod: method to call when clicked
 */
(function () {
  'use strict';
  angular.module('flowingly.runner.processmap').component('flowProcessmap', {
    bindings: {
      processMap: '<', //the flow to start
      isMobile: '<', //show mobile view if set,
      onClickMethod: '&' //method to call when clicked
    },
    controller: function () {
      var $ctrl = this;
      $ctrl.onFlowHeaderClick = onFlowHeaderClick;
      // PUBLIC METHODS /////////////////////////////////////////////////////////////////////
      function onFlowHeaderClick() {
        $ctrl.onClickMethod({ id: $ctrl.processMap.id });
      }
    },
    templateUrl:
      'Client/runner.processmaps/runner.processmap.component.tmpl.html'
  });
})();
