import React, { useState } from 'react';
import Box from '@mui/material/Box';
import StepForm from './StepForm/StepForm';
import StepDetails from './StepDetails/StepDetails';
import EmailContent from './EmailContent/EmailContent';
import EmailDetails from './EmailDetails/EmailDetails';
import TabPanelContainer from './TabPanelContainer/TabPanelContainer';
import { TaskType } from '@Shared.Angular/flowingly.services/flowingly.constants';
import DecisionGateway from './DecisionGateway/DecisionGateway';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';
import { ProcessMapNodeCateories } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNodeCateories';

type Props = {
  selectedNode: IProcessMapNodeViewModel;
};

const RightPanel = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const { selectedNode } = props;

  const decisionGateway = selectedNode?.decisionGateway;

  const stepName = selectedNode && selectedNode.stepName;
  const stepAssignee = selectedNode && selectedNode.stepActor;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getTabsConfig = () => {
    if (selectedNode?.stepType === TaskType.CUSTOM_EMAIL) {
      return {
        tabs: [{ label: 'Recipients' }, { label: 'Template' }],
        tabPanels: [{ component: EmailDetails }, { component: EmailContent }],
        headerData: [{ title: 'Step Name', value: stepName }],
        defaultTab: 1
      };
    }
    if (selectedNode?.category === ProcessMapNodeCateories.DecisionGateway) {
      return {
        tabPanels: [{ component: DecisionGateway }],
        headerData: [
          {
            title: 'Decision Name',
            value: decisionGateway?.name || 'None specified'
          }
        ]
      };
    }
    return {
      tabs: [{ label: 'Step Details' }, { label: 'Step Form' }],
      tabPanels: [{ component: StepDetails }, { component: StepForm }],
      headerData: [
        {
          title: 'Step Assignee',
          value: stepAssignee,
          showAvatar: true
        },
        { title: 'Step Name', value: stepName }
      ],
      defaultTab: 1
    };
  };

  const { tabs, tabPanels, headerData, defaultTab } = getTabsConfig();

  return (
    <Box width={'100%'} padding={'2px'}>
      <TabPanelContainer
        tabs={tabs}
        tabPanels={tabPanels}
        headerData={headerData}
        selectedTab={selectedTab}
        onTabChange={handleChange}
        selectedNode={selectedNode}
        defaultTab={defaultTab}
      />
    </Box>
  );
};

export default RightPanel;
