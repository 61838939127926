'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import { IQService } from 'angular';
import { PublicType } from '@Shared.Angular/@types/core/contracts/constants/publicType';

angular
  .module('flowingly.runner.library')
  .controller('flowModelOwnerDialogController', flowModelOwnerDialogController);

flowModelOwnerDialogController.$inject = [
  '$scope',
  '$q',
  'APP_CONFIG',
  'flowModelOwnerDialogService',
  'userApiService',
  'flowinglyConstants',
  'brandingService'
];

function flowModelOwnerDialogController(
  $scope: IScopeNgDialog,
  $q: IQService,
  APP_CONFIG: AppConfig,
  flowModelOwnerDialogService: FlowModelOwnerDialogService,
  userApiService: SharedAngular.UserApiService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  brandingService: SharedAngular.BrandingService
) {
  const ctrl = this;

  ctrl.submitOwners = submitOwners;
  ctrl.isMapPubliclyShareable = $scope.ngDialogData.isPublic;
  ctrl.publicType = $scope.ngDialogData.publicType || PublicType.Public;
  ctrl.selectedFlowModelOwnerList = [];
  ctrl.selectedReportOwnerList = [];
  ctrl.flowModelId = $scope.ngDialogData.flowModelId;
  ctrl.flowModelName = $scope.ngDialogData.flowModelName;
  ctrl.isProcessMapType = $scope.ngDialogData.isProcessMapType;
  ctrl.publicShareableUrl = `${window.location.origin}/map/${ctrl.flowModelId}`;
  ctrl.iframeTag = `<iframe width="650" height="800" src="${ctrl.publicShareableUrl}" frameborder="0"></iframe>`;
  ctrl.disabledPublicSharingMessage =
    'Public sharing is not enabled for this process map.';
  ctrl.publicShareableDisplayValue = ctrl.isMapPubliclyShareable
    ? ctrl.publicShareableUrl
    : ctrl.disabledPublicSharingMessage;
  ctrl.embedCodeDisplayValue = ctrl.isMapPubliclyShareable
    ? ctrl.iframeTag
    : ctrl.disabledPublicSharingMessage;

  ctrl.helpUri = brandingService.getBrandedHelpUri(
    'https://help.flowingly.net/administration/flow-model-owner'
  );

  ctrl.savePublicSharing = savePublicSharing;
  ctrl.showTabGroups = showTabGroups;
  ctrl.showPublicSharing = showPublicSharing;
  ctrl.publicTypePublic = PublicType.Public; 
  ctrl.publicTypeIntranet = PublicType.Intranet
  ctrl.expandGroupMembersFilter = (user, roles) => {
    return (
      user &&
      roles.some((role) =>
        user.roles.some((userRole) => userRole.name === role)
      )
    );
  };

  ctrl.cancel = () => {
    $scope.closeThisDialog();
  };

  ctrl.enableReportOwner = APP_CONFIG.enableReportOwner;
  ctrl.enablePublicProcessMaps = APP_CONFIG.enablePublicProcessMaps;

  ctrl.tabWidth =
    ctrl.isProcessMapType &&
    ctrl.enableReportOwner &&
    ctrl.enablePublicProcessMaps
      ? '33%'
      : '50%';

  $scope.$on('kendoWidgetCreated', function (ev, widget) {
    if (widget === ctrl.tabStrip) {
      ctrl.tabStrip.select(0);
    }
  });

  ctrl.onToggleChange = () => {
    const publicShareableTextAreaContainer =
      document.getElementById('publicUrlContainer');
    const embedCodeTextAreaContainer =
      document.getElementById('embedCodeContainer');

    if (ctrl.isMapPubliclyShareable) {
      if (publicShareableTextAreaContainer) {
        publicShareableTextAreaContainer.classList.remove(
          'public-sharing-disabled'
        );
      }
      if (embedCodeTextAreaContainer) {
        embedCodeTextAreaContainer.classList.remove('public-sharing-disabled');
      }
      ctrl.publicShareableDisplayValue = ctrl.publicShareableUrl;
      ctrl.embedCodeDisplayValue = ctrl.iframeTag;
    } else {
      if (publicShareableTextAreaContainer) {
        publicShareableTextAreaContainer.classList.add(
          'public-sharing-disabled'
        );
      }
      if (embedCodeTextAreaContainer) {
        embedCodeTextAreaContainer.classList.add('public-sharing-disabled');
      }
      ctrl.publicShareableDisplayValue = ctrl.embedCodeDisplayValue =
        ctrl.disabledPublicSharingMessage;
    }
  };

  init();

  function init() {
    ctrl.loading = true;
    const flowModelOwnersPromise = getFlowModelOwnerUsers();
    const reportOwnersPromise = getReportOwnerUsers();
    const existingFlowModelOwnersPromise = loadExistingFlowModelOwners();
    const existingReportOwnersPromise = loadExistingReportOwners();

    $q.all([
      flowModelOwnersPromise,
      reportOwnersPromise,
      existingFlowModelOwnersPromise,
      existingReportOwnersPromise
    ]).then(() => (ctrl.loading = false));
  }

  function submitOwners() {
    const flowModelAdminUserIds = ctrl.selectedFlowModelOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.USER
      )
      .map((item) => item.id);
    const flowModelAdminGroupIds = ctrl.selectedFlowModelOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.GROUP
      )
      .map((item) => item.id);
    const reportAdminUserIds = ctrl.selectedReportOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.USER
      )
      .map((item) => item.id);
    const reportAdminnGroupIds = ctrl.selectedReportOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.GROUP
      )
      .map((item) => item.id);
    flowModelOwnerDialogService
      .saveFlowModelOwners({
        flowModelId: ctrl.flowModelId,
        userIds: flowModelAdminUserIds,
        groupIds: flowModelAdminGroupIds
      })
      .then(() =>
        $scope.closeThisDialog({
          success: true
        })
      );

    if (ctrl.enableReportOwner) {
      flowModelOwnerDialogService
        .saveReportOwners({
          flowModelId: ctrl.flowModelId,
          userIds: reportAdminUserIds,
          groupIds: reportAdminnGroupIds
        })
        .then(() =>
          $scope.closeThisDialog({
            success: true
          })
        );
    }
  }

  function getFlowModelOwnerUsers() {
    return userApiService
      .getUsersWithPermission(
        flowinglyConstants.permissions.FLOWMODEL_OWNER_CANDIDATE
      )
      .then((users) => {
        ctrl.flowModelOwnerUsers = users.sort((a, b) =>
          a.fullName.localeCompare(b.fullName)
        );
      });
  }

  function getReportOwnerUsers() {
    if (!ctrl.enableReportOwner) {
      return $q.defer().resolve();
    }

    return userApiService
      .getUsersWithPermission(
        flowinglyConstants.permissions.FLOWMODEL_REPORTOWNER_CANDIDATE
      )
      .then(
        (users) =>
          (ctrl.reportOwnerUsers = users.sort((a, b) =>
            a.fullName.localeCompare(b.fullName)
          ))
      );
  }

  function loadExistingFlowModelOwners() {
    return flowModelOwnerDialogService
      .getExistingFlowModelOwners(ctrl.flowModelId)
      .then(function (response) {
        if (response && response.data && response.data.dataModel)
          ctrl.selectedFlowModelOwnerList = response.data.dataModel;
      });
  }

  function loadExistingReportOwners() {
    if (!ctrl.enableReportOwner) return $q.defer().resolve();

    return flowModelOwnerDialogService
      .getExistingReportOwners(ctrl.flowModelId)
      .then(function (response) {
        if (response && response.data && response.data.dataModel)
          ctrl.selectedReportOwnerList = response.data.dataModel;
      });
  }

  function showTabGroups() {
    if (
      ctrl.isProcessMapType &&
      !ctrl.enablePublicProcessMaps &&
      !ctrl.enableReportOwner
    ) {
      return false;
    }
    if (!ctrl.isProcessMapType && !ctrl.enableReportOwner) return false;
    return true;
  }

  function showPublicSharing() {
    return ctrl.isProcessMapType && ctrl.enablePublicProcessMaps;
  }

  function savePublicSharing() {
    return flowModelOwnerDialogService
      .modifyPublicMapSharing(
        ctrl.flowModelId,
        ctrl.isMapPubliclyShareable,
        ctrl.publicType
      )
      .then(() => {
        $scope.closeThisDialog({
          message: ctrl.isMapPubliclyShareable
            ? 'Map now set to be publicly shareable'
            : 'Public sharing disabled for the Process Map'
        });
      });
  }
}
