import { Guid } from '@Shared.Angular/@types/guid';

export interface ISearchOptions {
  page?: number;
  pageSize?: number;
  take?: number;
  skip?: number;
  sort?: ISearchSort[];
  filter?: ISearchFilters;
}

export interface ISearchSort {
  dir: string;
  field: string;
}

export interface ISearchFilters {
  filters: ISearchFilter[];
  /**
   * @deprecated
   */
  logic: string;
}

export interface ISearchFilter {
  type: ReportColumnDatatypesEnum;
  operator: UserSearchFilterOptionsEnum;
  value: string;
  field: string;
}

export enum UserSearchFilterOptionsEnum {
  contains = 'contains',
  eq = 'eq',
  neq = 'neq',
  lt = 'lt',
  lte = 'lte',
  gt = 'gt',
  gte = 'gte'
}

export enum ReportColumnDatatypesEnum {
  date = 'date',
  string = 'string',
  number = 'number'
}

export interface IReportFlowData {
  id: Guid;
  flowCategory: string;
  flowModel: string;
  flowId: string;
  flowSubject: string;
  status: string;
  overdue: number;
  waitingOn: string;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  startedBy: string;
  startedDate: Date;
  pendingSteps: number;
  currentSteps: string;
  nextStepDueDate: Date;
  flowProgress: number;
  numberOfAttachements: number;
  numberOfComments: number;
}
