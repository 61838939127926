/**
 * @ngdoc directive
 * @name flowReportGroup
 * @module flowingly.runner.reports
 * @description  This componenet is the root report componenet.
 *               It groups reports available for a category of flows
 * @usage
 * ```
    <flow-report-group ng-repeat="group in $ctrl.groups" group="group" groups="$ctrl.groups"></flow-report-group>
 * ``` 
 * ### Notes
 * It encapsulates all other components:
 * * flow-report-group-header - the report list header - clicking this expands the,..
 * * flow-report-group-container - contains content only shown when expanded
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * group: this group
 * * groups: all groups
 * * expand: set to true to open this category.
 * * isMobile :show mobile view if set,
 * * userId : userid of the logged in user 
 * * #### Events (Outputs)
 *  * onClickMethod : method to call when clicked
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.reports').component('flowReportGroup', {
  bindings: {
    group: '<', //this group
    groups: '<', //all groups
    expand: '<', //set to true to open this category.
    isMobile: '<', //show mobile view if set,
    userId: '<', //userid of the logged in user
    onClickMethod: '&' //method to call when clicked
  },
  template: `
                <div class="flow-block" ng-class ="{'isMobile': $ctrl.isMobile}" data-flow-category="{{$ctrl.group.name}}">
                    <flow-report-group-container group="$ctrl.group" groups="$ctrl.groups" is-mobile="$ctrl.isMobile" user-id="$ctrl.userId"></flow-report-group-container>
                </div>
            `
});
