/**
 * @ngdoc module
 * @name flowingly.runner.start
 * @module flowingly.runner.start
 * @description This module contains the components used to generate the start flow lists.
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 */
import angular from 'angular';
const m = angular.module('flowingly.runner.flow.start', []);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.flow.start/runner.flow.start.component.tmpl.html',
      require('./runner.flow.start.component.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flow.start/runner.flow.start.list.component.tmpl.html',
      require('./runner.flow.start.list.component.tmpl.html').default
    );
  }
]);
