import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.flows.active
 * @module flowingly.runner.flows.active
 * @description This module configures the page used to display flows that can be started.
 */
const m = angular.module('flowingly.runner.flows.active', []);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.flows.active/runner.flows.active.tmpl.html',
      require('./runner.flows.active.tmpl.html').default
    );
  }
]);
