/**
 * @ngdoc module
 * @name flowingly.directives.sidemenu
 * @module flowingly.directives.sidemenu
 * @requires flowingly.user.menu templates-directives
 * @description Sidemenu Directives shared across our websites
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 */
import angular from 'angular';

const sidemenuModule = angular.module('flowingly.directives.sidemenu', [
  'flowingly.user.menu',
  'templates-directives'
]);
sidemenuModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'sidemenu-dropdown.tmpl.html',
      require('./sidemenu-dropdown.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-label.mobile.tmpl.html',
      require('./sidemenu-label.mobile.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-label.tmpl.html',
      require('./sidemenu-label.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-action.mobile.tmpl.html',
      require('./sidemenu-link-with-action.mobile.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-action.tmpl.html',
      require('./sidemenu-link-with-action.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-action-count.tmpl.html',
      require('./sidemenu-link-with-action-count.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-action-count.mobile.tmpl.html',
      require('./sidemenu-link-with-action-count.mobile.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-count.mobile.tmpl.html',
      require('./sidemenu-link-with-count.mobile.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-count.tmpl.html',
      require('./sidemenu-link-with-count.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-status.tmpl.html',
      require('./sidemenu-link-with-status.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link-with-status.mobile.tmpl.html',
      require('./sidemenu-link-with-status.mobile.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link.mobile.tmpl.html',
      require('./sidemenu-link.mobile.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-link.tmpl.html',
      require('./sidemenu-link.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-subitem-with-action.tmpl.html',
      require('./sidemenu-subitem-with-action.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-subitem-with-event.tmpl.html',
      require('./sidemenu-subitem-with-event.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu-subitem.tmpl.html',
      require('./sidemenu-subitem.tmpl.html').default
    );

    $templateCache.put(
      'sidemenu.tmpl.html',
      require('./sidemenu.tmpl.html').default
    );
  }
]);
