import { RedirectServiceType } from '@Client/runner.redirect/runner.redirect.service';
import IProcessMapNestDetails from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNestDetails';
import { HttpStatusCodes } from '@Shared.Angular/@types/core/contracts/constants/httpStatusCodes';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

export class RunnerPublicMapApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: AppConfig,
    private redirectService: RedirectServiceType,
    private $state: IStateService
  ) {}

  getPublicProcessMap(flowModelId: string) {
    return this.$http
      .get<IProcessMapNestDetails>(
        `${this.APP_CONFIG.apiBaseUrl}map/${flowModelId}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err.status === HttpStatusCodes.Unauthorized) {
          this.redirectService.setPendingRequestAsUrl(
            `/map/${this.$state.params.id}`
          );
          this.$state.go('app.login');
        }
      });
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerPublicMapApiService', [
    '$http',
    'APP_CONFIG',
    'redirectService',
    '$state',
    (
      $http: angular.IHttpService,
      APP_CONFIG: AppConfig,
      redirectService: RedirectServiceType,
      $state: IStateService
    ) =>
      new RunnerPublicMapApiService($http, APP_CONFIG, redirectService, $state)
  ]);

export type RunnerPublicMapApiServiceType = InstanceType<
  typeof RunnerPublicMapApiService
>;
