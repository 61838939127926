import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IController, IQService, ITimeoutService } from 'angular';
import { ImportError } from './runner.import.error.service';
import IUserImportRequest, {
  UserImportRequestStatus
} from '@Shared.Angular/@types/core/contracts/queryModel/user/userImportRequest';

class RunnerImportDialogController implements IController {
  private fileToImport;
  private importFileGotError = false;
  private dataToImport;
  private importing = false;
  public fileList;
  public helpLink: string;
  public options = {
    modalTitle: '',
    templateUrl: '',
    instructionText: 'Modify the file in your favorite spreadsheet editor.',
    actionText: 'Import',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getCSVTemplate: () => {},
    isImportUser: true,
    customDbName: '',
    columns: []
  };

  constructor(
    private $scope: IScopeNgDialog,
    private $q: IQService,
    private $timeout: ITimeoutService,
    private ngDialog: angular.dialog.IDialogService,
    private notificationService: SharedAngular.NotificationService,
    private userApiService: SharedAngular.UserApiService,
    private databaseApiService: DatabaseApiService,
    private runnerImportService: RunnerImportService,
    private importErrorService: ImportErrorService,
    private sessionService: SharedAngular.SessionService,
    private brandingService: SharedAngular.BrandingService
  ) {
    this.onInit();
    this.helpLink = this.brandingService.getBrandedHelpUri(
      'https://help.flowingly.net/en/articles/489179-how-do-i-invite-users-into-flowingly'
    );
  }

  onInit() {
    const bindings = angular.copy(this.$scope.ngDialogData, {});
    angular.extend(this.options, bindings);
  }

  browseFile() {
    angular.element('#fileToImport').click();
  }

  fileNameChanged() {
    this.importFileGotError = !this.runnerImportService.isCsvFile(
      this.fileList[0].name
    );
    this.fileToImport = this.fileList ? this.fileList[0] : undefined;
  }

  import() {
    this.importing = true;
    let isNewerImportVariant = false; // @TODO make custom db have the same output
    let apiPromise;

    if (this.options.isImportUser) {
      apiPromise = this.handleUserImport();
      isNewerImportVariant = true;
    } else {
      apiPromise = this.handleCustomDbImport();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const timerPromise = this.$timeout(() => {}, 2000); // ensure at least display importing dialog for 2 seconds

    this.$q
      .all([apiPromise, timerPromise])
      .then(([results]) => {
        this.importing = false;
        if (isNewerImportVariant) {
          if (results.error == 0) {
            this.ngDialog.closeAll(true);
          }
        } else {
          this.ngDialog.closeAll(true);
        }
      })
      .catch(() => {
        // Error has occurred while importing.
        this.ngDialog.closeAll(true);
      });
  }

  handleCustomDbImport() {
    this.dataToImport.forEach((record) => {
      Object.keys(record).forEach((key) => {
        record[key] = record[key].replace(/\\/g, '\\\\');
      });
    });
    const apiPromise = this.databaseApiService
      .saveRecords(this.options.customDbName, JSON.stringify(this.dataToImport))
      .then(() => {
        this.notificationService.showSuccessToast('Import Success!');
      });

    return apiPromise;
  }

  handleUserImport() {
    const emailRownumMap = {}; // row id is not gauranteed when coming back from the endpoint
    const userImportRequest = this.dataToImport.map((row, index) => {
      emailRownumMap[row.email] = index;

      let connectionType = 0;
      if (row.connection_type) {
        const connectionStr = row.connection_type.toLowerCase();
        connectionType =
          connectionStr == 'pmi' || connectionStr == 'sso' ? 1 : 0;
      }

      const user: IUserImportRequest = {
        user: {
          firstName: row.first_name,
          lastName: row.last_name,
          email: row.email
        },
        manager: {
          email: row.manager_email
        },
        connectionType: connectionType
      };
      return user;
    });

    const results = {
      error: 0,
      success: 0
    };

    return this.userApiService
      .addUsers(userImportRequest, true)
      .then((importResults) => {
        for (let i = 0; importResults.length > i; i++) {
          const row = importResults[i];
          if (row.requestStatus >= UserImportRequestStatus.FAILED) {
            this.importErrorService.addError(
              new ImportError({
                issueId: row.requestStatus,
                affectedRows: [emailRownumMap[row.user.email] + 2],
                details: [row.requestMessage]
              })
            );
            results.error++;
          } else if (row.requestStatus == UserImportRequestStatus.DONE) {
            results.success++;
          }
        }
      })
      .catch(() => {
        results.error = this.dataToImport.length;
      })
      .then(() => {
        const businessName = this.sessionService.getBusinessName();
        if (results.success == 0) {
          this.notificationService.showWarningToast(
            `No user(s) added to ${businessName}`
          );
        } else {
          this.notificationService.showSuccessToast(
            `${results.success} user(s) successfully invited to ${businessName}`
          );
        }

        if (results.error) {
          this.notificationService.showErrorToast(
            `${results.error} records were not added`
          );
        }
        return results;
      });
  }

  closeDialog() {
    this.ngDialog.closeAll();
  }

  onFileUpdated(needDigest) {
    this.importFileGotError = false;
    angular.element('#fileToImport').val(undefined);
    if (needDigest) {
      this.digest();
    }
  }

  fileHasError() {
    this.importFileGotError = true;
    this.digest();
  }

  updateDataToImport(data) {
    this.dataToImport = data;
  }

  allowImport() {
    return this.fileToImport !== undefined && !this.importFileGotError;
  }

  digest() {
    if (!this.$scope.$$phase) {
      this.$scope.$digest(); // need notify Angular there are some changes outside of the Angular context
    }
  }
}

RunnerImportDialogController.$inject = [
  '$scope',
  '$q',
  '$timeout',
  'ngDialog',
  'notificationService',
  'userApiService',
  'databaseApiService',
  'runnerImportService',
  'importErrorService',
  'sessionService',
  'brandingService'
];
angular
  .module('flowingly.runner.import')
  .controller('runnerImportDialogController', RunnerImportDialogController);
