/**
 * @ngdoc directive
 * @name flowStartList
 * @module flowingly.runner.start
 * @description  This comppnent is used to group a list of flowmodels that can be started.
 *               It is responsible for opening / closing flowmodels when one is clicked.
 *               It is also (currently) responsible for switching between mobile and desktop views.
 * @usage
 * ```
     <flow-start-list flow-models="$ctrl.category.flowModels" is-mobile="isMobile"></flow-start-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * flowmodels: list of available flowmodels to start
 * * isMobile: show mobile view if set
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';

angular.module('flowingly.runner.flow.start').component('flowStartList', {
  bindings: {
    flowModels: '<',
    isMobile: '<'
  },
  controller: [
    'flowsUtilityService',
    'APP_CONFIG',
    function (
      flowsUtilityService: SharedAngular.FlowsUtilityService,
      APP_CONFIG: SharedAngular.APP_CONFIG
    ) {
      const $ctrl = this;
      this.showDashboardsV1 = APP_CONFIG.showDashboardsV1;
      this.selectedTab = 0;
      this.searchText = '';
      this.noFlowDisplayText = '';
      this.noFlowsFoundInFrequentlyStarted = false;
      this.noFlowsFoundInAllFlows = false;
      this.$onChanges = function () {
        if (!this.flowModels) {
          return;
        }
        this.flowModelsToDisplay = flowsUtilityService.orderItemsAlphabetically(
          this.flowModels
        );
        this.allFlowModels = this.flowModelsToDisplay;
        this.onFilterAction();
      };

      this.onSelectTab = function (index) {
        this.selectedTab = index;
        this.onFilterAction();
      };

      this.onChangeInSearchText = () => {
        this.onFilterAction();
      };

      this.resetSearchInput = () => {
        this.searchText = '';
        this.onChangeInSearchText();
      };

      this.onFilterAction = () => {
        const searchTextLower = this.searchText.toLowerCase();

        const filteredFlows = this.allFlowModels.filter(
          (x) =>
            (x.description?.toLowerCase().includes(searchTextLower) ||
              (x.name && x.name.toLowerCase().includes(searchTextLower))) &&
            (this.selectedTab === 1 ? x.countOfFlowStarted > 0 : true)
        );

        if (this.selectedTab === 1) {
          this.flowModelsToDisplay = filteredFlows
            .slice()
            .sort((a, b) => b.countOfFlowStarted - a.countOfFlowStarted);
          this.noFlowsFoundInFrequentlyStarted =
            this.flowModelsToDisplay.length === 0;
          this.noFlowsFoundInAllFlows = false;
          this.noFlowDisplayText = this.noFlowsFoundInFrequentlyStarted
            ? 'You have yet to start any flows.'
            : '';
        } else {
          this.flowModelsToDisplay =
            flowsUtilityService.orderItemsAlphabetically(filteredFlows);
          this.noFlowsFoundInAllFlows = this.flowModelsToDisplay.length === 0;
          this.noFlowsFoundInFrequentlyStarted = false;
          this.noFlowDisplayText = '';
        }
      };
    }
  ],
  controllerAs: '$ctrl',
  templateUrl:
    'Client/runner.flow.start/runner.flow.start.list.component.tmpl.html'
});
