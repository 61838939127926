/**
 * @ngdoc directive
 * @name runner.flow.databases
 * @module flowingly.runner.setup
 * @description  This comppnent is the root for databases
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * databases: the list of available databases (JSON[])
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';

angular.module('flowingly.runner.setup').component('flowDatabases', {
  bindings: {
    databases: '<'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.databases/runner.setup.databases.tmpl.html',
  controller: [
    'databaseApiService',
    'brandingService',
    function (
      databaseApiService: DatabaseApiService,
      brandingService: SharedAngular.BrandingService
    ) {
      const $ctrl = this;

      $ctrl.helpUri = brandingService.getBrandedHelpUri(
        'https://help.flowingly.net/administration/databases'
      );
      $ctrl.handleDatabasesUpdated = handleDatabasesUpdated;

      function handleDatabasesUpdated() {
        // For an array that is bounded via one-way binding, a watch expression is added that does not check for object equality
        // but uses reference checking. This means that adding an element to the array will never fire the '$onChanges' method,
        // since the watcher will never be 'dirty'. Therefore, we use Angular copy ensure a new reference

        //get latest databases list
        $ctrl.databases = angular.copy(databaseApiService.databases);
      }
    }
  ]
});
