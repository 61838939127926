import angular, { IScope } from 'angular';
import { IStateProvider } from 'angular-ui-router';

angular.module('flowingly.runner.maintenance').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance.users', {
    url: '/users',
    params: { flowId: null, title: 'Maintenance - Users' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/users/runner.maintenance.users.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          'maintenanceService',
          'APP_CONFIG',
          '$scope',

          function (
            maintenanceService: MaintenanceService,
            appConfig: AppConfig,
            $scope: IScope
          ) {
            const $ctrl = this;

            $ctrl.businesses = [];
            $ctrl.selectedBusinessId = null;

            $scope.$parent.$watch('$ctrl.selectedBusiness', (value) => {
              $ctrl.selectedBusinessId = value?.id;
              updateGrid();
            });

            $ctrl.options = {
              dataSource: {
                transport: {
                  read: function (options) {
                    if ($ctrl.selectedBusinessId != null) {
                      maintenanceService
                        .getUsersWithOptions(
                          options.data,
                          $ctrl.selectedBusinessId
                        )
                        .then((response) => {
                          if (!response.errorMessage) {
                            options.success({
                              data: response.users,
                              total: response.total
                            });
                            $ctrl.gridNeedResize = true;
                          }
                        });
                    } else {
                      options.success({ data: [], total: 0 });
                    }
                  }
                },
                schema: {
                  data: 'data',
                  total: 'total'
                },
                pageSize: appConfig.gridReportPageSize,
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                serverGrouping: true,
                serverAggregates: true
              },
              noRecords: {
                template: '<users-no-results></users-no-results>'
              },
              pageable: true,
              sortable: true,
              filterable: {
                mode: 'row'
              },
              columns: getColumns()
            };

            $ctrl.gridInitDone = true;

            $ctrl.handleUserUpdated = () => {
              updateGrid();
            };

            function getColumns() {
              return [
                {
                  field: 'id',
                  title: 'Id',
                  width: '300px',
                  filterable: getAutoCompleteFilter('id', 'Id')
                },
                {
                  field: 'fullName',
                  title: 'Name',
                  filterable: getAutoCompleteFilter('fullName', 'Name')
                },
                {
                  field: 'email',
                  title: 'Email',
                  filterable: getAutoCompleteFilter('email', 'Email')
                },
                {
                  field: 'managerName',
                  title: 'Manager',
                  filterable: getAutoCompleteFilter('managerName', 'Manager')
                },
                {
                  field: 'inviteUser',
                  title: 'Invite User',
                  width: '150px',
                  filterable: getComboBoxFilter(
                    'inviteUser',
                    'Invite User',
                    'true',
                    'false'
                  )
                },
                {
                  field: 'delete',
                  title: 'Delete',
                  width: '150px',
                  filterable: getComboBoxFilter(
                    'delete',
                    'Delete',
                    'true',
                    'false'
                  )
                },
                {
                  field: 'active',
                  title: 'Active',
                  width: '150px',
                  filterable: getComboBoxFilter(
                    'active',
                    'Active',
                    'true',
                    'false'
                  )
                },
                {
                  field: 'loginPossible',
                  title: 'Login Possible',
                  width: '150px',
                  filterable: getComboBoxFilter(
                    'loginPossible',
                    'Login Possible',
                    'Yes',
                    'No'
                  )
                },
                {
                  title: 'Actions',
                  width: '100px',
                  filterable: false,
                  template:
                    "<maintenance-user-list-actions user='dataItem' on-user-updated='$ctrl.handleUserUpdated()'></maintenance-user-list-actions>"
                }
              ];
            }

            function getAutoCompleteFilter(fieldName, displayName) {
              return {
                cell: {
                  operator: 'contains',
                  showOperators: false,
                  template: function (args) {
                    args.element.kendoAutoComplete({
                      dataSource: args.dataSource,
                      dataTextField: fieldName,
                      placeholder: `Search by ${displayName}`,
                      valuePrimitive: true,
                      filter: 'contains'
                    });
                  }
                }
              };
            }

            function getComboBoxFilter(fieldName, displayName, val1, val2) {
              return {
                cell: {
                  showOperators: false,
                  template: function (args) {
                    args.element.kendoComboBox({
                      dataSource: [{ val: val1 }, { val: val2 }],
                      dataTextField: 'val',
                      placeholder: `Search by ${displayName}`,
                      dataValueField: fieldName
                    });
                  }
                }
              };
            }

            function getColumnUniqueValues(rolesList) {
              const dataSource = [];
              const uniqueValues = {};
              for (const row of rolesList) {
                const value = row['name'];

                if (value) {
                  uniqueValues[value] = value;
                }
              }
              for (const value in uniqueValues) {
                dataSource.push({ val: value });
              }

              dataSource.sort((data1, data2) => {
                if (data1.val > data2.val) {
                  return 1;
                }

                if (data1.val < data2.val) {
                  return -1;
                }

                return 0;
              });

              return dataSource;
            }

            function updateGrid() {
              $scope.usersGrid.dataSource.read();
              $ctrl.gridNeedResize = true;
            }
          }
        ]
      }
    }
  });
}
