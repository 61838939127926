import angular from 'angular';

angular.module('flowingly.runner.library').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.library.drafts', {
    url: '/drafts',
    params: { flowModels: [], flowId: null, title: 'Library -  Drafts' },
    views: {
      childcontent: {
        controllerAs: '$ctrl',
        controller: [
          '$stateParams',
          function ($stateParams: any) {
            this.flowModels = $stateParams.flowModels;
          }
        ],
        template:
          '<flow-library-tabs-drafts flow-models="$ctrl.flowModels"></flow-library-tabs-drafts>'
      }
    }
  });
}
