/**
 * @ngdoc type
 * @name ImportTemplatesDialogController
 * @module flowingly.runner.templates
 * @description Import Templates exported from some other environment
 */
'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export default class ImportTemplatesDialogController {
  static $inject = ['$scope', 'templateService', 'notificationService'];

  private importFiles: FileList;
  private templates;
  private existingTemplates;
  private requestPending = false;

  constructor(
    private $scope: IScopeNgDialog,
    private templateService: SharedAngular.TemplateService,
    private notificationService: SharedAngular.NotificationService
  ) {
    this.requestPending = true;
    const forManagement = true;
    this.templateService.getTemplates(forManagement).then((templates) => {
      this.existingTemplates = templates;
      this.requestPending = false;
    });
  }

  fileSelected() {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      this.templates = JSON.parse(event.target.result as string);
      this.templates.forEach((template) => {
        template.import = true;
        template.override = this.existingTemplates.some(
          (t) => t.key.toLowerCase() === template.key.toLowerCase()
        );
      });
      this.$scope.$apply();
    });
    if (this.importFiles.length > 0) {
      reader.readAsText(this.importFiles[0]);
    }
  }

  import() {
    this.requestPending = true;
    const templatesToImport = this.templates.filter(
      (template) => template.import
    );

    this.templateService
      .importTemplates(templatesToImport)
      .then(() => {
        this.notificationService.showSuccessToast('Templates imported');
        this.$scope.closeThisDialog(true);
      })
      .catch(() => {
        this.requestPending = false;
        this.notificationService.showErrorToast(
          'An error occurred while attempting import'
        );
      });
  }

  close() {
    this.$scope.closeThisDialog(false);
  }
}

angular
  .module('flowingly.runner.templates')
  .controller(
    'importTemplatesDialogController',
    ImportTemplatesDialogController
  );
