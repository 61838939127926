'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';

import angular, { IScope } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';

class RunnerActiveController {
  static $inject = [
    '$scope',
    '$stateParams',
    'flowListManager',
    'pubsubService',
    'flowsUtilityService',
    'lodashService',
    'permissionsService',
    'flowinglyConstants',
    '$state',
    'appInsightsService',
    'APP_CONFIG',
    'runnerCategoryNavigationService',
    'browserUtilsService'
  ];

  public categories = [];
  public selectedCategory = {};
  public expandedCategory = [];
  public isMobile = false;
  public isReady = false;
  public showSubCategories = false;
  private allCategoriesId = 'all-categories';
  public selectedIndex = '0';

  constructor(
    private $scope: IScope,
    private $stateParams: IStateParamsService,
    private flowListManager: FlowListManager,
    private pubsubService: SharedAngular.PubSubService,
    private flowsUtilityService: SharedAngular.FlowsUtilityService,
    private _: Lodash,
    private permissionsService: SharedAngular.PermissionsService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private $state: IStateService,
    private appInsightsService: SharedAngular.AppInsightsService,
    private APP_CONFIG: SharedAngular.APP_CONFIG,
    private runnerCategoryNavigationService: RunnerCategoryNavigationService,
    private browserUtilsService: SharedAngular.BrowserUtilsService
  ) {
    this.onFlowModelNameChanged = this.onFlowModelNameChanged.bind(this);
    this.onCategoryDeleted = this.onCategoryDeleted.bind(this);
    this.onFlowModelDeleted = this.onFlowModelDeleted.bind(this);
    this.refreshFlows = this.refreshFlows.bind(this);
    this.removeDeletedActor = this.removeDeletedActor.bind(this);

    if (
      !this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.FLOW_START
      )
    ) {
      this.$state.go('app.runner.flowsin');
      return;
    }
    this.refreshFlows().then(() => {
      this.init();
    });
    this.isMobile = this.browserUtilsService.isMobileDevice();
    this.selectCategory = this.selectCategory.bind(this);
    this.showSubCategories = this.APP_CONFIG.enableSubCategories;
  }

  init() {
    const activeFlowsSubscriberId = 'flowingly.runner.flows.active';
    this.pubsubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      this.onFlowModelNameChanged,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_SETUP_CATEGORY_DELETED',
      this.onCategoryDeleted,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_SETUP_FLOW_MODEL_DELETED',
      this.onFlowModelDeleted,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_WORKFLOW_PUBLISHED',
      this.refreshFlows,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_WORKFLOW_UNPUBLISHED',
      this.refreshFlows,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_ACTOR_DELETED',
      (event, params) => this.removeDeletedActor(params),
      activeFlowsSubscriberId
    );

    this.$scope.$on('$destroy', () => {
      this.pubsubService.unsubscribeAll(activeFlowsSubscriberId);
    });
    this.isReady = true;

    this.appInsightsService.trackMetricIfTimerExist('flowsActiveEntered');
    if (this.$stateParams.backFromState === 'app.runner.flow') {
      if (
        !this.runnerCategoryNavigationService.findNodeById(
          this.categories,
          this.flowListManager.getActiveFlowCategoryId()
        )
      ) {
        this.flowListManager.setActiveFlowCategoryIndex('0');
        this.flowListManager.setActiveFlowCategoryId(this.allCategoriesId);
        this.flowListManager.setExpandedCategoriesList(null);
      }
      this.selectCategory(
        this.flowListManager.getActiveFlowCategoryId(),
        this.flowListManager.getActiveFlowCategoryIndex(),
        null,
        this.flowListManager.getExpandedCategoriesList()
      );
      this.expandedCategory = this.flowListManager.getExpandedCategoriesList();
      this.selectedIndex = this.flowListManager.getActiveFlowCategoryIndex();
    }
  }

  selectCategory(
    categoryId: string,
    categoryIndex: string,
    categoryName: string,
    expandedCategory: Array<string>
  ) {
    if (expandedCategory) {
      this.flowListManager.setExpandedCategoriesList(expandedCategory);
    }
    if (categoryIndex != null) {
      this.flowListManager.setActiveFlowCategoryId(categoryId);
      this.flowListManager.setActiveFlowCategoryIndex(categoryIndex);
    }

    if (this.showSubCategories) {
      this.selectedCategory = this.runnerCategoryNavigationService.findNodeById(
        this.categories,
        categoryId
      );
    } else {
      this.selectedCategory = this.categories.find((c) => c.id === categoryId);
    }
  }

  selectAllCategory() {
    this.selectCategory(this.allCategoriesId, null, null, null);
  }

  onCategoryDeleted() {
    this.refreshFlows();
  }

  onFlowModelDeleted() {
    this.refreshFlows();
  }

  onFlowModelNameChanged(msg, data) {
    const parsedData = JSON.parse(data);
    const flowUpdated = this.categories.some((category) =>
      category.flowModels.some((flow) => {
        if (flow.id === parsedData.id) {
          flow.name = parsedData.name;
          return true;
        }
        return false;
      })
    );
    if (flowUpdated) {
      this.triggerRenderOfUpdatedFlows();
      this.flowListManager.updateFlowNames(parsedData);
    }
  }

  addAllFlowsCategory(categories) {
    if (!categories || categories.find((c) => c.id === this.allCategoriesId)) {
      return;
    }
    let copyOfAllFlows;
    if (this.showSubCategories)
      copyOfAllFlows =
        this.runnerCategoryNavigationService.getFlowModelsFromNestedCategories(
          categories
        );
    else copyOfAllFlows = this._.flatMap(categories, 'flowModels');
    categories.unshift({
      name: 'All',
      id: this.allCategoriesId,
      flowModels: copyOfAllFlows
    });
  }

  refreshFlows() {
    return this.flowListManager.getCategorizedWorkflows().then((categories) => {
      if (!this.showSubCategories) {
        this.flowsUtilityService.orderItemsAlphabetically(categories);
      }
      this.addAllFlowsCategory(categories);
      this.categories = categories;
      this.formatCatgories();
      if (
        !this.runnerCategoryNavigationService.findNodeById(
          this.categories,
          this.selectedCategory.id
        )
      ) {
        this.selectAllCategory();
        this.selectedIndex = '0';
      } else {
        this.selectCategory(this.selectedCategory.id, null, null, null);
      }
    });
  }

  formatCatgories = () => {
    if (this.showSubCategories) {
      this.categories =
        this.runnerCategoryNavigationService.getFormattedTreeData(
          this.categories
        );
    }
  };

  removeDeletedActor(jsonParams) {
    if (!Array.isArray(this.categories)) {
      return;
    }

    const params = JSON.parse(jsonParams);
    const updatedFlowModelIds = [];
    this.categories.forEach((category) => {
      category.flowModels.forEach((flowModel) => {
        if (flowModel.processOwnerName === params.actorName) {
          flowModel.processOwnerName = null;
          updatedFlowModelIds.push(flowModel.id);
        }
      });
    });

    if (updatedFlowModelIds.length > 0) {
      this.triggerRenderOfUpdatedFlows();
      this.flowListManager.removeDeletedProcessOwner(updatedFlowModelIds);
    }
  }

  triggerRenderOfUpdatedFlows() {
    this.categories = angular.copy(this.categories);
    this.$scope.$digest();
  }
}

angular
  .module('flowingly.runner.flows.active')
  .controller('runnerActiveController', RunnerActiveController);
