import React, { useEffect } from 'react';

import CustomTabs from '../../CustomTab/CustomTabs';
import Header, { HeaderDataItem } from '../Header/Header';
import CustomTabPanel from '../../CustomTab/CustomTabPanel';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';

type TabPanel = {
  component: React.ElementType;
};

type TabPanelContainerProps = {
  tabs: { label: string }[];
  tabPanels: TabPanel[];
  headerData: HeaderDataItem[];
  selectedTab: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  selectedNode: IProcessMapNodeViewModel;
  defaultTab?: number;
};

const TabPanelContainer = ({
  tabs,
  tabPanels,
  headerData,
  selectedTab,
  onTabChange,
  selectedNode,
  defaultTab = 0
}: TabPanelContainerProps) => {
  useEffect(() => {
    onTabChange(null, defaultTab);
  }, [selectedNode]);

  return (
    <>
      <Header data={headerData} />
      {tabs?.length > 0 ? (
        <CustomTabs value={selectedTab} onChange={onTabChange} tabs={tabs} />
      ) : (
        <div className="process-map-v2-view-tabpanel-notabs-container"></div>
      )}
      {tabPanels.map(({ component: Component }, index) => (
        <CustomTabPanel key={index} value={selectedTab} index={index}>
          <Component selectedNode={selectedNode} />
        </CustomTabPanel>
      ))}
    </>
  );
};

export default TabPanelContainer;
