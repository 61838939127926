/**
 * @ngdoc directive
 * @name runner.library.tabs.processmaps
 * @module flowingly.runner.library
 * @description  This comppnent is the Process Maps tab of Library component
 * @usage
 */

import { LibraryDisplayTypes } from '@Shared.Angular/flowingly.services/flowingly.constants';

angular
  .module('flowingly.runner.library')
  .component('flowLibraryTabsProcessmaps', {
    bindings: {
      flowModels: '<'
    },
    templateUrl:
      'Client/runner.library/runner.library.processmaps/runner.library.tabs.processmaps.tmpl.html',
    controller: [
      function () {
        const $ctrl = this;
        this.$onInit = function () {
          $ctrl.processMapDisplayType = LibraryDisplayTypes.PROCESS_MAP;
        };
      }
    ]
  });
