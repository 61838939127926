/**
 * @ngdoc service
 * @module runner.adapters
 *
 * @description Wraps the global fflate Object.
 *
 * ## Notes
 * fflate is a global object, to make unit testing easier this adapter is used.
 * It simply wraps the global fflate object and allows it to be injected as a depdency into Angular controllers / services.
 * Assumes fflate has already been loaded on the page.
 */

angular.module('flowingly.runner.adapters').factory('fflate', [
  '$window',
  function ($window) {
    if ($window.fflate == undefined) {
      console.error('fflate is not loaded');
    }
    return $window.fflate;
  }
]);
