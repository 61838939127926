(function () {
  'use strict';

  angular
    .module('flowingly.runner.user.condition')
    .controller('userConditionDialogController', userConditionDialogController);

  userConditionDialogController.$inject = ['$scope', '$sce', 'ngDialog'];

  function userConditionDialogController($scope, $sce, ngDialog) {
    let ctrl = this;
    ctrl.userCondition = $scope.ngDialogData;
    ctrl.userCondition.userAcceptanceText = $sce.trustAsHtml(
      ctrl.userCondition.userAcceptanceText
    );
    ctrl.accept = (accepted) => {
      ngDialog.closeAll({ accepted: accepted });
    };
  }
})();
