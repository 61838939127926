import { SharedAngular } from '@Client/@types/sharedAngular';
import { IHttpService, IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import ICustomDataTypeListItem from '@Shared.Angular/@types/customDb/services/customDataTypes/customDataListItem';

angular
  .module('flowingly.runner.setup')
  .controller(
    'runnerSetupCreateDatabaseController',
    runnerSetupCreateDatabaseController
  );

runnerSetupCreateDatabaseController.$inject = [
  '$http',
  '$scope',
  '$state',
  'APP_CONFIG',
  'notificationService',
  'databaseApiService',
  'validationService',
  'brandingService',
  'sessionService'
];

function runnerSetupCreateDatabaseController(
  $http: IHttpService,
  $scope: IScope,
  $state: IStateService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  notificationService: SharedAngular.NotificationService,
  databaseApiService: DatabaseApiService,
  validationService: SharedAngular.ValidationService,
  brandingService: SharedAngular.BrandingService,
  sessionService: SharedAngular.SessionService
) {
  const $ctrl = this;

  $ctrl.helpUri = brandingService.getBrandedHelpUri(
    'https://help.flowingly.net/administration/databases'
  );
  $ctrl.nameIsInvalid = false;
  $ctrl.isMobile = $scope.isMobile;
  $ctrl.data = {
    name: '',
    isUserExtension: false,
    columns: []
  };
  $ctrl.namePattern = '[a-zA-Z_]+[a-zA-Z0-9_]*';
  const businessSchemaName = sessionService
    .getBusinessName()
    .replace(/[^a-zA-Z0-9_]+/g, '');
  const maxLengthOfDatabaseName = 50; // Limited by table CustomDatabaseSchemaAudit
  const sqlMaxNameLength = 128;
  const lengthOfExtraCharsAddedDuringTableCreation = 9; // _ and _Default added in backend
  $ctrl.maxDatabaseNameLength = Math.min(
    maxLengthOfDatabaseName,
    sqlMaxNameLength -
      businessSchemaName.length -
      lengthOfExtraCharsAddedDuringTableCreation
  );

  $http
    .get<ICustomDataTypeListItem[]>(
      `${APP_CONFIG.apiBaseUrl}customdatabase/schema/datatypes`
    )
    .then((result) => {
      // we map adapt this for now since the frontend does not
      // yet use the backend structure
      $ctrl.dataTypeOptions = result.data.map((dataType) => ({
        value: dataType.type,
        text: getUserFriendlyColumnType(dataType.type)
      }));

      $ctrl.options = {
        dataSource: {
          data: $ctrl.data.columns,
          pageSize: APP_CONFIG.gridReportPageSize,
          schema: {
            model: {
              fields: {
                name: { validation: { required: true } },
                dataType: { defaultValue: { value: 'text', text: 'text' } }
              }
            }
          }
        },
        scrollable: true,
        pageable: true,
        sortable: true,
        resizable: true,
        columns: getColumns(),
        toolbar: [{ name: 'create', text: 'Add new column' }],
        editable: {
          createAt: 'bottom'
        },
        dataBound: tryAddDefaultColumn
      };
    });

  $ctrl.onSaveClick = () => {
    const { isEmpty } = validationService;

    $ctrl.errorMsg = '';
    $ctrl.nameIsInvalid = false;

    const grid = angular.element('#newDatabaseGrid').data('kendoGrid');
    const options = grid.getOptions();

    // transform to api ready payload
    $ctrl.data.columns = [];
    let hasInvalidColumns = false;
    const nameRegex = RegExp(`^${$ctrl.namePattern}$`);

    options.dataSource.data.forEach((r) => {
      if (isEmpty(r.name)) {
        $ctrl.errorMsg = 'Column Name cannot be empty';
        $ctrl.nameIsInvalid = true;
        hasInvalidColumns = true;
        return;
      }
      r.name = r.name.replaceAll(' ', '_');
      if (!nameRegex.test(r.name)) {
        $ctrl.errorMsg =
          'Column Name must start with a letter and only contain letters, numbers, or underscore';
        $ctrl.nameIsInvalid = true;
        hasInvalidColumns = true;
        return;
      }

      $ctrl.data.columns.push({
        name: r.name,
        dataType: r.dataType.value
      });
    });

    if (hasInvalidColumns) {
      return;
    }

    if ($ctrl.data.columns.length === 0) {
      $ctrl.errorMsg = 'The Database cannot be saved without columns';
      $ctrl.nameIsInvalid = true;
      return;
    }

    databaseApiService.saveDatabase($ctrl.data).then(() => {
      notificationService.showSuccessToast('Database saved');
      $state.go('app.runner.edit-database', { dbName: $ctrl.data.name });
    }); // no catch required, $http already shows notifications on failed requests
  };

  /// PRIVATE METHODS //////////////////////////////////
  function getUserFriendlyColumnType(type: string) {
    switch (type) {
      case 'number':
        return 'whole number';
      case 'currency':
        return 'currency/decimal number';
      default:
        return type;
    }
  }

  function getColumns() {
    const dataTypeEditor = function (container) {
      angular
        .element(
          `<input
            kendo-drop-down-list
            required
            k-data-text-field="'text'"
            k-data-value-field="'value'"
            k-data-source="$ctrl.dataTypeOptions"
            data-bind="value:dataType"
          />`
        )
        .appendTo(container);
    };

    const textTypeEditor = function (container) {
      // length limited by table CustomDatabaseColumnAttributes
      angular
        .element(
          `<input
            name="name"
            type="text"
            maxLength="50"
            data-bind="value:name"
          />`
        )
        .appendTo(container);
    };

    //Note: field needs to match the name (and case) of the returned data
    return [
      {
        field: 'dataType',
        title: 'Data Type',
        editor: dataTypeEditor,
        template: '#=dataType.text#'
      },
      {
        field: 'name',
        title: 'Column Name',
        editor: textTypeEditor
      },
      {
        command: 'destroy',
        title: 'Actions',
        width: '150px'
      }
    ];
  }

  let defaultColumnAdded = false;
  function tryAddDefaultColumn() {
    if (defaultColumnAdded) {
      return;
    }
    const grid = angular.element('#newDatabaseGrid').data('kendoGrid');
    if (grid) {
      defaultColumnAdded = true;
      grid.addRow();
    }
  }
}
