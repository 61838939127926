import React, { useEffect, useState } from 'react';
import { useService } from '@Client/runner.hooks/useService';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';
import { SharedAngular } from '@Client/@types/sharedAngular';

export type StepData = {
  reminder: string;
  deadLine: string;
  costMoney: string;
  calculatedCost: string;
  notificationRequired: boolean;
  plannedTime: string;
  waitingTime: string;
};

type Props = {
  selectedNode: IProcessMapNodeViewModel;
};

const StepDetails = (props: Props) => {
  const { selectedNode: stepData } = props;
  const appConfig = useService<SharedAngular.APP_CONFIG>('APP_CONFIG');

  let stepTimeCostVisibility: string;
  const NONE = 'None';
  const NOT_SPECIFIED = 'Not specified by Process Owner';

  const [derivedStepData, setDerivedStepData] = useState<StepData | null>(null);

  useEffect(() => {
    if (stepData == null) {
      setDerivedStepData(null);
      return;
    }

    const updatedStepData: StepData = {
      reminder: stepData.reminder ?? '',
      deadLine: stepData.deadLine ?? NONE,
      costMoney: stepData.costMoney ?? NOT_SPECIFIED,
      calculatedCost: stepData.calculatedCost ?? NOT_SPECIFIED,
      notificationRequired: stepData.notificationRequired ?? false,
      plannedTime: stepData.plannedTime ?? NOT_SPECIFIED,
      waitingTime: stepData.waitingTime ?? NOT_SPECIFIED
    };

    stepTimeCostVisibility = appConfig.stepTimeCostVisibility;

    setDerivedStepData(updatedStepData);
  }, [stepData]);

  const isStepTimeCostVisible = (type: string) => {
    if (stepTimeCostVisibility == null) return true;
    else {
      if (type === 'time')
        return (
          stepTimeCostVisibility.toLowerCase() === 'both' ||
          stepTimeCostVisibility.toLowerCase() === 'timeonly'
        );
      if (type === 'cost')
        return (
          stepTimeCostVisibility.toLowerCase() === 'both' ||
          stepTimeCostVisibility.toLowerCase() === 'costonly'
        );
    }
  };

  const isStepTimeVisible = isStepTimeCostVisible('time');
  const isStepCostVisible = isStepTimeCostVisible('cost');

  if (derivedStepData === null) {
    return null;
  }

  return (
    <div className="pmap-step-details-tab">
      {!appConfig.enableModelerFlowModelTypeSelection && (
        <>
          <div>
            <label className="row-title">Step Reminder</label>
          </div>
          <div>
            <p>
              <span>{derivedStepData.reminder}</span>
            </p>
          </div>
        </>
      )}
      <div>
        <label className="row-title">Step Deadline</label>
      </div>
      <div>
        <p>
          <span>{derivedStepData.deadLine}</span>
        </p>
      </div>

      {isStepTimeVisible && (
        <>
          <div>
            <label className="row-title">Active Time</label>
          </div>
          <div>
            <p>
              <span
                className={
                  derivedStepData.plannedTime === NOT_SPECIFIED
                    ? 'process-map-v2-view-step-details-italic-and-grayed'
                    : ''
                }
              >
                {derivedStepData.plannedTime}
              </span>
            </p>
          </div>
          <div>
            <label className="row-title">Waiting Time</label>
          </div>
          <div>
            <p>
              <span
                className={
                  derivedStepData.waitingTime === NOT_SPECIFIED
                    ? 'process-map-v2-view-step-details-italic-and-grayed'
                    : ''
                }
              >
                {derivedStepData.waitingTime}
              </span>
            </p>
          </div>
        </>
      )}

      {isStepCostVisible && (
        <div>
          <label className="row-title">
            {appConfig.enableTimeCost ? (
              <>
                Cost{' '}
                <i
                  title="Cost is calculated as: Active Time × Average Cost Per Hour"
                  className="fa-light fa-circle-info"
                ></i>
              </>
            ) : (
              'Planned Cost'
            )}
          </label>
        </div>
      )}

      {isStepCostVisible && (
        <div>
          <p>
            {appConfig.enableTimeCost ? (
              derivedStepData.calculatedCost === NOT_SPECIFIED ? (
                <span className="process-map-v2-view-step-details-italic-and-grayed">
                  {derivedStepData.calculatedCost}
                </span>
              ) : (
                <span>
                  {parseFloat(derivedStepData.calculatedCost).toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD'
                    }
                  )}
                </span>
              )
            ) : derivedStepData.costMoney === NOT_SPECIFIED ? (
              <span className="process-map-v2-view-step-details-italic-and-grayed">
                {derivedStepData.costMoney}
              </span>
            ) : (
              <span>
                {parseFloat(derivedStepData.costMoney).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </span>
            )}
          </p>
        </div>
      )}

      {!appConfig.enableModelerFlowModelTypeSelection && (
        <>
          <div>
            <label className="row-title">Step Notification</label>
          </div>
          <div>
            <input
              id="step-details-notify"
              type="checkbox"
              disabled
              checked={derivedStepData.notificationRequired}
            />
            <label
              className={`not-clickable fg-checkbox-tick ${
                !derivedStepData.notificationRequired
                  ? 'process-map-v2-view-step-details-grayed'
                  : ''
              }`}
              htmlFor="step-details-notify"
            >
              Notify initiator when this step has been completed
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default StepDetails;
