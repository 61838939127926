import * as angular from 'angular';

const templatesModule = angular.module('flowingly.runner.templates', []);

templatesModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.templates/admin/packs/runner.templates.admin.packs.tmpl.html',
      require('./admin/packs/runner.templates.admin.packs.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/admin/templates/runner.templates.admin.templates.tmpl.html',
      require('./admin/templates/runner.templates.admin.templates.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.templates/admin/templates/edit/runner.templates.admin.templates.edit.tmpl.html',
      require('./admin/templates/edit/runner.templates.admin.templates.edit.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.templates/admin/tenants/runner.templates.admin.tenants.tmpl.html',
      require('./admin/tenants/runner.templates.admin.tenants.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.templates/admin/runner.templates.admin.tabs.component.tmpl.html',
      require('./admin/runner.templates.admin.tabs.component.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/dialogs/runner.templates.dialogs.access.tmpl.html',
      require('./dialogs/runner.templates.dialogs.access.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/dialogs/runner.templates.dialogs.import.tmpl.html',
      require('./dialogs/runner.templates.dialogs.import.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/library/runner.templates.library.tmpl.html',
      require('./library/runner.templates.library.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/template/runner.templates.template.tmpl.html',
      require('./template/runner.templates.template.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/runner.templates.card.component.tmpl.html',
      require('./runner.templates.card.component.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.templates/runner.templates.library.component.tmpl.html',
      require('./runner.templates.library.component.tmpl.html').default
    );
  }
]);
export default templatesModule;
