(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller('databasesDialogAddController', databasesDialogAddController);

  databasesDialogAddController.$inject = [
    '$scope',
    'validationService',
    'userApiService'
  ];

  function databasesDialogAddController(
    $scope,
    validationService,
    userApiService
  ) {
    let ctrl = this;

    ctrl.addCustomDBRow = addCustomDBRow;
    ctrl.rowData = angular.copy($scope.ngDialogData, {});
    ctrl.closeDialog = closeDialog;
    ctrl.emailValidationPattern = validationService.getEmailValidationPattern();
    ctrl.errorOnSubmit = false;
    ctrl.errorMessagesOnSubmit = '';
    ctrl.hasFinishedLoading = true;

    ctrl.rowData.dialogDetails.forEach((row) => {
      if (row.type === 'user' && row.valueFullName) {
        var res = row.valueFullName.split('(');
        row.valueFullName = removeWhiteSpaces(res[0]);
        row.selectedUser.id = row.valueGuid;
        row.selectedUser.fullName = row.valueFullName;
      }
    });

    function closeDialog() {
      $scope.closeThisDialog();
    }

    function addCustomDBRow() {
      ctrl.rowData.dialogDetails.forEach((row) => {
        if (row.type === 'user') {
          row.valueGuid = row.selectedUser ? row.selectedUser.id : null;
          row.valueFullName = row.selectedUser
            ? row.selectedUser.fullNameWithEmail
            : '';

          ctrl.rowData[row.col] = row.valueFullName;
        } else ctrl.rowData[row.col] = row.value;
      });

      $scope.closeThisDialog(ctrl.rowData);
    }

    function removeWhiteSpaces(rowDataString) {
      return rowDataString.replace(/^[ ]+|[ ]+$/g, '');
    }

    function removeDashFromColumnName(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
  }
})();
