import { Box } from '@mui/system';
import BreadcrumbItem from './BreadcrumbItem/BreadcrumbItem';
import React, { useEffect, useRef, useState } from 'react';
import { BreadCrumbType } from '@Client/runner.processmapsv2/@types/breadcrumb';

type Props = {
  currentBreadCrumb: BreadCrumbType;
  handleClick: (mapId: string) => void;
  updateBreadcrumbCount: (count: number) => void;
};

const BreadcrumbsNavigator = (props: Props) => {
  const { currentBreadCrumb, handleClick, updateBreadcrumbCount } =
    props;

  const [breadCrumbElements, setBreadCrumbElements] = useState<
    BreadCrumbType[]
  >([]);
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false);
  const breadcrumbRef = useRef<HTMLElement>(null);

  useEffect(() => {
    addBreadCrumb(currentBreadCrumb);
  }, [currentBreadCrumb]);

  useEffect(() => {
    handleComponentClickScroll();
  }, [breadCrumbElements]);

  useEffect(() => {
    window.addEventListener('resize', toggleScroll);
    return () => {
      window.removeEventListener('resize', toggleScroll);
    };
  }, []);

  const toggleScroll = () => {
    setShowScrollArrow(
      breadcrumbRef?.current?.scrollWidth > breadcrumbRef?.current?.clientWidth
    );
  };

  const addBreadCrumb = (crumb: BreadCrumbType): void => {
    if (crumb && crumb.id) {
      setBreadCrumbElements((crumbs) => {
        if (!crumbs.find((x) => x.id == crumb.id)) {
          updateBreadcrumbCount(crumbs.length + 1);
          return [...crumbs, crumb];
        }
        return crumbs;
      });
    }
    handleComponentClickScroll();
  };

  const removeBreadCrumb = (id: string): void => {
    const filteredBreadCrumb = breadCrumbElements.filter((x) => x.id !== id);
    updateBreadcrumbCount(filteredBreadCrumb.length - 1);
    setBreadCrumbElements(filteredBreadCrumb);

    if (currentBreadCrumb.id === id) {
      const currentBreadcrumb: BreadCrumbType =
        filteredBreadCrumb[filteredBreadCrumb.length - 1];
      handleClick(currentBreadcrumb.id);
    }
  };

  const handleBreadCrumbClick = (breadCrumb: BreadCrumbType) => {
    handleClick(breadCrumb.id);
  };

  const handleComponentClickScroll = () => {
    const targetIndex = breadCrumbElements.findIndex(
      (x) => x.id == currentBreadCrumb.id
    );

    if (
      !(
        breadCrumbElements.length > 0 &&
        currentBreadCrumb &&
        targetIndex > 0 &&
        breadcrumbRef.current.children[targetIndex]
      )
    ) {
      return;
    }

    const targetElement: HTMLElement =
      breadcrumbRef.current.children[targetIndex];

    scrollTo(breadcrumbRef.current, targetElement.offsetLeft - 70, 200);
    toggleScroll();
  };

  const handleHorizontalScroll = (element: HTMLElement, step = 30) => {
    const duration = 500; // You can adjust the duration as needed for scroll animation

    const startTime = performance.now();

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < duration) {
        element.scrollLeft = easeInOutQuad(
          elapsedTime,
          element.scrollLeft,
          step,
          duration
        );
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const scrollTo = (element: HTMLElement, to: number, duration: number) => {
    const start = element.scrollLeft;
    const change = to - start;
    const increment = 10; // Adjust this value for smoother/faster animations
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  };

  // Easing function for smooth animation
  const easeInOutQuad = (
    time: number,
    startValue: number,
    change: number,
    duration: number
  ) => {
    time /= duration / 2;
    if (time < 1) {
      return (change / 2) * time * time + startValue;
    }

    time--;
    return (-change / 2) * (time * (time - 2) - 1) + startValue;
  };

  return (
    <Box className="v2-view-breadcrumbs-outer-container">
      <Box className="v2-view-breadcrumbs-inner-container">
        {showScrollArrow && (
          <>
            <Box className="v2-view-breadcrumbs-button-container">
              <i
                className="fa-solid fa-chevron-left v2-view-breadcrumbs-button-left"
                onClick={() => {
                  handleHorizontalScroll(breadcrumbRef.current, -30);
                }}
              ></i>
            </Box>
            <Box className="v2-view-breadcrumbs-button-container">
              <i
                className="fa-solid fa-chevron-right v2-view-breadcrumbs-button-right"
                onClick={() => {
                  handleHorizontalScroll(breadcrumbRef.current, 30);
                }}
              ></i>
            </Box>
          </>
        )}

        <Box
          className={`v2-view-breadcrumbs-container ${
            showScrollArrow
              ? 'v2-view-breadcrumbs-width-90'
              : 'v2-view-breadcrumbs-width-100'
          }`}
          ref={breadcrumbRef}
        >
          {breadCrumbElements.length > 1 &&
            breadCrumbElements.map((breadCrumb) => {
              return (
                <BreadcrumbItem
                  key={breadCrumb.id}
                  breadCrumb={breadCrumb}
                  activeBreadCrumbId={currentBreadCrumb.id}
                  removeBreadCrumb={removeBreadCrumb}
                  handleBreadCrumbClick={handleBreadCrumbClick}
                />
              );
            })}
        </Box>
      </Box>
      <hr className="v2-view-breadcrumb-hr"></hr>
    </Box>
  );
};

export default BreadcrumbsNavigator;
