'use strict';
import angular from 'angular';
///
/// This state displays flowmodels that the user can initiaite
///

// Register the application's routes configuration.
angular.module('flowingly.runner.flows.active').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.flowsactive', {
    url: 'flowsactive/{categoryId}',
    params: { title: 'Start Flows', backFromState: '' },
    controller: 'runnerActiveController',
    controllerAs: 'ctrl',
    templateUrl: 'Client/runner.flows.active/runner.flows.active.tmpl.html'
  });
}
