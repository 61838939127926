/**
 * @ngdoc directive
 * @name runner.flow.users
 * @module flowingly.runner.setup
 * @description  This comppnent is the root for teams
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * users: the list of available users (JSON[])
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';

angular.module('flowingly.runner.setup').component('flowUsers', {
  bindings: {
    users: '<'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.users/runner.setup.users.tmpl.html',
  controller: [
    'sessionService',
    'userApiService',
    'brandingService',
    function (
      sessionService: SharedAngular.SessionService,
      userApiService: SharedAngular.UserApiService,
      brandingService: SharedAngular.BrandingService
    ) {
      const $ctrl = this;
      $ctrl.businessName = sessionService.getBusinessName();
      $ctrl.helpUri = brandingService.getBrandedHelpUri(
        'https://help.flowingly.net/administration/how-do-i-add-a-user'
      );
      $ctrl.handleUsersUpdated = handleUsersUpdated;

      function handleUsersUpdated() {
        // For an array that is bounded via one-way binding, a watch expression is added that does not check for object equality
        // but uses reference checking. This means that adding an element to the array will never fire the '$onChanges' method,
        // since the watcher will never be 'dirty'. Therefore, we use Angular copy ensure a new reference

        $ctrl.users = angular.copy(userApiService.users);
      }
    }
  ]
});
