'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.redirect')
  .component('flowinglyRedirectBlock', {
    template: `
                <div id="flowingly-redirect-block">
                    <p><b>Redirecting to ...</b></p>
                </div>
            `
  });
