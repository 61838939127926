/**
 * @ngdoc directive
 * @name teamsNoResults
 * @module flowingly.runner.setup
 * @description This component is used to display a message when there are no results
 * @usage
 * ```
     <teams-no-results></teams-no-results>
 * ``` 
 * NOTE: Minimal content for now, but this might grow
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('teamsNoResults', {
    template: `<div class="list-no-results">No results.</div>`
  });
})();
