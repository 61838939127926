/**
 * @ngdoc module
 * @name flowingly.user.menu
 * @module flowingly.user.menu
 * @requires templates-directives
 * @description User Menu
 */
import angular from 'angular';

const userMenuModule = angular.module('flowingly.user.menu', [
  'templates-directives',
  'flowingly.user.notifications',
  'flowingly.delegation.banner'
]);

userMenuModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'usermenu.tmpl.html',
      require('./usermenu.tmpl.html').default
    );
  }
]);
