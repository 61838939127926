/**
 * @ngdoc directive
 * @name flowStartCategoryList
 * @module flowingly.runner.processmap
 * @description  This componenet is used to display the categories of process maps available.
 * @usage
 * ```
     <flow-processmap-category-list categories="ctrl.categories" ></flow-processmap-category-list>
 * ``` 
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.processmap')
    .component('flowProcessmapCategoryList', {
      bindings: {
        categories: '<', // list of flowmodels, grouped by category
        isMobile: '<' // show mobile view if set
      },
      template: [
        '<div class="start-category-list" ng-class="{\'isMobile\': $ctrl.isMobile}">',
        '<flow-processmap-category ng-repeat="category in $ctrl.categories" category="category" is-mobile="$ctrl.isMobile"></flow-processmap-category>',
        '</div>'
      ].join('')
    });
})();
