/**
 * @ngdoc directive
 * @name flowHistoryStepReassignment
 * @module flowingly.runner.flow
 * @description This comppnent is used to display checkbox history items.
 * @usage
 * ```
     <flow-history-step-reassignment></flow-history-step-reassignment>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * 
 * * 
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .component('flowHistoryStepReassignment', {
    bindings: {
      historyItem: '<',
      stepName: '<'
    },
    templateUrl:
      'Client/runner.flow/runner.flow.history/runner.flow.history.step.reassignment.component.tmpl.html'
  });
