import angular, { IScope, ITimeoutService } from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { RunnerFlowsFormatterService } from '@Client/runner.services/flows.formatter';

angular.module('flowingly.runner.flow').component('flowStepTaskDetails', {
  bindings: {
    stepTask: '<',
    flow: '<'
  },
  controller: [
    '$timeout',
    '$scope',
    'runnerFlowService',
    'flowinglyConstants',
    'sessionService',
    'lodashService',
    'commentApiService',
    'avatarService',
    'notificationService',
    'validationService',
    'flowApiService',
    'runnerFlowsFormatter',
    'permissionsService',
    'appInsightsService',
    'flowinglyMomentService',
    'busyService',
    function (
      $timeout: ITimeoutService,
      $scope: IScope,
      runnerFlowService: RunnerFlowService,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      sessionService: SharedAngular.SessionService,
      lodashService: Lodash,
      commentApiService: SharedAngular.CommentApiService,
      avatarService: SharedAngular.AvatarService,
      notificationService: SharedAngular.NotificationService,
      validationService: SharedAngular.ValidationService,
      flowApiService: FlowApiService,
      runnerFlowsFormatter: RunnerFlowsFormatterService,
      permissionsService: SharedAngular.PermissionsService,
      appInsightsService: SharedAngular.AppInsightsService,
      flowinglyMomentService: SharedAngular.FlowinglyMomentService,
      busyService: SharedAngular.BusyService
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const $ctrl = this;
      $ctrl.runnerFlowService = runnerFlowService;
      $ctrl.busyService = busyService;
      $ctrl.cancelStepTask = cancelStepTask;
      $ctrl.reassignStepTask = reassignStepTask;
      $ctrl.printStepTask = printStepTask;
      $ctrl.activityDone = activityDone;
      $ctrl.formattedDateTime = {};
      $ctrl.canCompleteStepTask = canCompleteStepTask;
      $ctrl.canViewTaskActivity = canViewTaskActivity;
      $ctrl.stepTaskStatus = flowinglyConstants.stepTaskStatus;
      $ctrl.canViewTaskApproval = canViewTaskApproval;
      $ctrl.approvalSubmitted = approvalSubmitted;
      $ctrl.canApproveStepTask = canApproveStepTask;
      $ctrl.canReassignStepTask = canReassignStepTask;
      $ctrl.canApproveStepTask = canApproveStepTask;
      $ctrl.approvalComment = null;
      $ctrl.approved = null;
      $ctrl.stepTaskComments = null;
      $ctrl.onAddComment = onAddComment;
      $ctrl.canCancelStepTask = canCancelStepTask;

      $ctrl.$onInit = () => {
        getStepTaskComments();
        hideAddStepTaskControls();
      };

      $ctrl.$onChanges = function (changes) {
        if (changes && changes.stepTask && changes.stepTask.currentValue) {
          getStepTaskComments();
          hideAddStepTaskControls();
          resetForm();
        }
      };

      $ctrl.setFormattedDateTime = function () {
        if ($ctrl.stepTask != null && $ctrl.stepTask.DueDateTime != null) {
          $ctrl.formattedDateTime = flowinglyMomentService.formatFullDate(
            $ctrl.formatDateTime($ctrl.stepTask.DueDateTime),
            'YYYY-MM-DDTHH:mm:ss'
          );
        }
      };

      $ctrl.formatDateTime = function (dateTimeString) {
        if (dateTimeString != null) {
          // If there is suffix Z in datetime string the angularjs date filter cant deal with it
          // and displays incorrect time value. Strip out suffix Z if exists.
          const formattedDateTime = dateTimeString.replace('Z', '');
          return formattedDateTime;
        }
        return dateTimeString;
      };

      function canViewTaskActivity() {
        const currentUserId = getCurrentUserId();
        if (currentUserId == null) {
          return false;
        }

        // The step task activity section is only visible to:
        // 1. The step task creator.
        // 2. The assigned user
        // 3. The approver user
        // 4. Users who get mentioned in a step task comment.
        // 5. Business Admins
        // 6. Flow Model Owner
        return (
          currentUserId === $ctrl.stepTask.AssignedUserId ||
          currentUserId === $ctrl.stepTask.ApproverUserId ||
          currentUserId === $ctrl.stepTask.StartedByUserId ||
          isUserMentionedInStepTaskComments(currentUserId) ||
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.FLOW_STEPTASK_VIEW
          ) ||
          $ctrl.flow.IsFlowModelOwner
        );
      }

      function getCurrentUserId() {
        const user = sessionService.getUser();
        if (user) {
          return user.id;
        }

        // If the user is unauthorized (e.g logging out, expired token, etc) then
        // the user object will be undefined from the session service.
        return null;
      }

      function isUserMentionedInStepTaskComments(userId) {
        let isMentioned = false;

        if ($ctrl.stepTaskComments != null) {
          lodashService.forEach($ctrl.stepTaskComments, (comment) => {
            if (comment.mentionedActors != null) {
              const commentMention = comment.mentionedActors.find(
                (mention) => mention.actorId === userId
              );
              if (commentMention != null) {
                isMentioned = true;
                return;
              }
            }
          });
        }

        return isMentioned;
      }

      function canViewTaskApproval() {
        const currentUserId = getCurrentUserId();

        // Only the approver can view the approval section when the task is
        // in the waiting for approval state.
        return (
          currentUserId != null &&
          $ctrl.stepTask.Status === $ctrl.stepTaskStatus.WaitingForApproval &&
          currentUserId === $ctrl.stepTask.ApproverUserId
        );
      }

      function canCompleteStepTask() {
        const currentUserId = getCurrentUserId();

        // Only the assigned user can complete the task when the task is in progress.
        return (
          currentUserId != null &&
          $ctrl.stepTask.Status === $ctrl.stepTaskStatus.InProgress &&
          currentUserId === $ctrl.stepTask.AssignedUserId
        );
      }

      function canCancelStepTask() {
        const requiredPermissions = [
          flowinglyConstants.permissions.FLOW_STEPTASK_CANCEL
        ];
        const hasPermissionToCancel =
          permissionsService.currentUserHasPermissions(requiredPermissions);
        const step = $ctrl.flow.Steps.find(
          (step) => step.Id === $ctrl.stepTask.ParentStepId
        );

        // Step task can be canceled by Step Assignee, Business Admin and Flow Model Owner.
        // Step task can be canceled while it is in Progress or Waiting For Approval
        return (
          ($ctrl.stepTask.Status === $ctrl.stepTaskStatus.InProgress ||
            $ctrl.stepTask.Status ===
              $ctrl.stepTaskStatus.WaitingForApproval) &&
          (step.IsWaitingForYou ||
            hasPermissionToCancel ||
            $ctrl.flow.IsFlowModelOwner)
        );
      }

      function canApproveStepTask() {
        const currentUserId = getCurrentUserId();

        // Only the approver user can complete the task when the task is waiting for approval.
        return (
          currentUserId != null &&
          $ctrl.stepTask.Status === $ctrl.stepTaskStatus.WaitingForApproval &&
          currentUserId === $ctrl.stepTask.ApproverUserId
        );
      }

      function canReassignStepTask() {
        const currentUserId = getCurrentUserId();

        // Only business admins, step initiator or Flow Model Owners can reassign step task
        const requiredPermissions = [
          flowinglyConstants.permissions.FLOW_STEPTASK_REASSIGN
        ];
        return (
          currentUserId != null &&
          (permissionsService.currentUserHasPermissions(requiredPermissions) ||
            currentUserId === $ctrl.stepTask.StartedByUserId ||
            $ctrl.flow.IsFlowModelOwner)
        );
      }

      function approvalSubmitted() {
        appInsightsService.startEventTimer('stepTaskApprovalSubmitted');
        if (
          $ctrl.approvalComment &&
          validationService.isXssVulnerableString($ctrl.approvalComment)
        ) {
          return false;
        }

        if ($ctrl.approved == null) {
          // An approval is required.
          return false;
        }

        if (
          $ctrl.approved === 'false' &&
          ($ctrl.approvalComment == null || $ctrl.approvalComment === '')
        ) {
          // A comment is required when rejecting.
          return false;
        }

        return runnerFlowService
          .updateStepTaskApproval({
            stepTaskId: $ctrl.stepTask.Id,
            approved: $ctrl.approved === 'true',
            comment: $ctrl.approvalComment
          })
          .then((updatedStepTask) => {
            if (updatedStepTask != null) {
              $ctrl.stepTask = updatedStepTask;
              hideAddStepTaskControls();
              getStepTaskComments();
              resetForm();
            }
            appInsightsService.trackMetricIfTimerExist(
              'stepTaskApprovalSubmitted',
              getStepTaskPropsForAppInsights()
            );
          });
      }

      function activityDone() {
        appInsightsService.startEventTimer('stepTaskDoneSubmitted');
        return runnerFlowService
          .updateStepTaskStatus({
            stepTaskId: $ctrl.stepTask.Id,
            status: $ctrl.stepTaskStatus.WaitingForApproval.toString()
          })
          .then((updatedStepTask) => {
            if (updatedStepTask != null) {
              $ctrl.stepTask = updatedStepTask;
              hideAddStepTaskControls();
              getStepTaskComments();
              resetForm();
            }
            appInsightsService.trackMetricIfTimerExist(
              'stepTaskDoneSubmitted',
              getStepTaskPropsForAppInsights()
            );
          });
      }

      function resetForm() {
        $timeout(function () {
          $ctrl.approvalComment = null;
          $ctrl.approved = null;
          $ctrl.setFormattedDateTime();
          if ($scope.ctrl && $scope.ctrl.currentForm) {
            $scope.ctrl.currentForm.$setPristine();
            $scope.ctrl.currentForm.$setUntouched();
            $scope.ctrl.currentForm.$submitted = false;
          }
        });
      }

      function getStepTaskComments() {
        commentApiService
          .getFlowComments(
            flowinglyConstants.commentTargetType.STEP_TASK,
            $ctrl.stepTask.Id
          )
          .then((data) => {
            $ctrl.stepTaskComments = data;
            if ($ctrl.stepTaskComments) {
              lodashService.forEach($ctrl.stepTaskComments, (comment) => {
                comment.avatarUrl = avatarService.getAvatarUrl(comment.userId);
              });
            }
          });
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      function onAddComment(commentTargetId, count) {}

      function hideAddStepTaskControls() {
        const hide =
          $ctrl.stepTask.Status === $ctrl.stepTaskStatus.Completed ||
          $ctrl.stepTask.Status === $ctrl.stepTaskStatus.WaitingForApproval;

        hideOrShowAddStepTaskCommentSection(hide);
      }

      function hideOrShowAddStepTaskCommentSection(hide) {
        $timeout(() => {
          // There wasn't a nice way to do this with AngularJS bindings and use
          // the add comment component for step task and flow comments.
          const addCommentSection = angular.element(
            `#add-comment-${$ctrl.stepTask.Id}`
          );
          if (addCommentSection != null && hide != null) {
            if (hide === true) {
              addCommentSection.hide();
            } else {
              addCommentSection.show();
            }
          }
        });
      }

      function cancelStepTask() {
        runnerFlowService.cancelEntity({
          stepId: undefined,
          flowId: $ctrl.stepTask.FlowId,
          stepTaskId: $ctrl.stepTask.Id,
          entity: 'task'
        });
      }

      function reassignStepTask() {
        if (!canReassignStepTask()) {
          notificationService.showWarningToast('You cannot reassign step task');
          return;
        }

        return runnerFlowService.reassign({
          currentTask: $ctrl.stepTask,
          taskId: $ctrl.stepTask.Id,
          reassignType: flowinglyConstants.reassignType.StepTask,
          flowId: $ctrl.flow.FlowId,
          flowIdentifier: $ctrl.flow.FlowIdentifier
        });
      }

      function printStepTask() {
        // Reload the flow from the API so that all step task details, activities, etc are up to date.
        return flowApiService
          .getFlowById($ctrl.flow.FlowId, false)
          .then((flowForUser) => {
            const formattedFlow = runnerFlowsFormatter.formatFlow(flowForUser);
            const pdfName = getPdfName(formattedFlow);
            return runnerFlowService.exportToPdf(pdfName, formattedFlow);
          })
          .catch(() => {
            // Fall back to the flow in the browser if we fail to
            // reload it from the API.
            const pdfName = getPdfName($ctrl.flow);
            return runnerFlowService.exportToPdf(pdfName, $ctrl.flow);
          });
      }

      function getPdfName(flow) {
        const { FlowIdentifier, Subject } = flow;
        const SafeSubject = Subject.match(/[a-zA-Z]*/g).join('_');
        const pdfName = `${FlowIdentifier}_${SafeSubject}`;
        return pdfName;
      }

      function getStepTaskPropsForAppInsights() {
        const flowIdentifier = $ctrl.flow.FlowIdentifier;
        const { StepName: stepName, Name: taskName } = $ctrl.stepTask;
        return {
          flowIdentifier,
          stepName,
          taskName
        };
      }
    }
  ],
  templateUrl:
    'Client/runner.flow/runner.flow.step.task/runner.flow.step.task.details.tmp.html'
});
