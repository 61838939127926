'use strict';

import angular from 'angular';

const runnerImportModule = angular.module('flowingly.runner.import', []);

runnerImportModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.import/runner.import.dialog.tmpl.html',
      require('./runner.import.dialog.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.import/runner.import.drag-drop-zone.tmpl.html',
      require('./runner.import.drag-drop-zone.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.import/runner.import.error.tmpl.html',
      require('./runner.import.error.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.import/flowmodels/runner.import.flowmodels.dialog.tmpl.html',
      require('./flowmodels/runner.import.flowmodels.dialog.tmpl.html').default
    );
  }
]);
export default runnerImportModule;
