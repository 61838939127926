/**
 * ```
     $state.go('app.runner.search');
 * ``` 
 * ### Notes
 *  Module structure:
 *    route (app.runner.search) --> runner.flows.search.tmpl
 *      flowsIminGroups                   // Handles the list of groups
 *        flowsIminGroup                 // Handles each group (which contains a group.name and the group.flows)
 *          flowsIminGroupHeader          // Handles the group header for a group of flows
 *          flowsIminGroupFlows            // Handles the flow list for a group
 *            flowsIminGroupFlow          // Handles each flow in the group
 *              --> runner.flows.imin.group.flow.tmpl.html //uses the flow-list directive to present all the flows in the group
 * ### Properties
 * #### Inputs
 */

'use strict';
import angular from 'angular';
///
/// This state displays the flow search result
///

// Register the application's routes configuration.
angular.module('flowingly.runner.flows.search').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.search', {
    url: 'search?:term',
    params: { title: 'Search Results' },
    controller: 'runnerFlowsSearchController',
    controllerAs: 'ctrl',
    templateUrl: 'Client/runner.flows.search/runner.flows.search.tmpl.html'
  });
}
