import { SharedAngular } from '@Client/@types/sharedAngular';
import { Guid } from '@Shared.Angular/@types/guid';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular, { IScope } from 'angular';
import { IStateProvider } from 'angular-ui-router';

// Register the application's routes configuration.
angular.module('flowingly.runner.maintenance').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance.settings', {
    url: '/settings',
    params: { title: 'Maintenance - Settings' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/settings/runner.maintenance.settings.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          'maintenanceService',
          'dialogService',
          '$scope',
          'permissionsService',
          function (
            maintenanceService: MaintenanceService,
            dialogService: SharedAngular.DialogService,
            $scope: IScope,
            permissionsService: SharedAngular.PermissionsService
          ) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const $ctrl = this;

            $ctrl.selectedBusinessName;
            $ctrl.selectedBusinessId;
            $ctrl.updateSetting = updateSetting;
            $ctrl.loadBusinessSettings = loadBusinessSettings;
            $ctrl.populateSettingInputs = populateSettingInputs;
            $ctrl.clearCachedSettings = clearCachedSettings;
            $ctrl.hasActionsPermission =
              permissionsService.currentUserHasAnyOfPermissions([
                FlowinglyPermissions.ADMIN_ACTIONS,
                FlowinglyPermissions.ADMIN_ACTIONS_BUSINESS_SETTING,
                FlowinglyPermissions.ADMIN_ACTIONS_BUSINESS_SETTING_SELF
              ]);

            const scrollOptions: ScrollIntoViewOptions = {
              behavior: 'smooth',
              block: 'center'
            };

            $scope.$parent.$watch('$ctrl.selectedBusiness', (value) => {
              $ctrl.selectedBusinessId = value?.id;
              $ctrl.selectedBusinessName = value?.name;
              loadBusinessSettings($ctrl.selectedBusinessId);
            });

            // Private Methods
            function loadBusinessSettings(businessId: Guid) {
              $ctrl.addSettingName = '';
              $ctrl.addSettingValue = '';
              $ctrl.businessSettings = [];
              if (!businessId) {
                return;
              }
              return maintenanceService
                .getBusinessSettings(businessId)
                .then((settings) => {
                  $ctrl.businessSettings = Object.keys(settings)
                    .map((key) => {
                      return { name: key, value: settings[key] };
                    })
                    .sort((a, b) => {
                      return a.name.localeCompare(b.name);
                    });
                });
            }

            function updateSetting(
              businessId: Guid,
              settingName: string,
              settingValue: string
            ) {
              maintenanceService
                .updateBusinessSetting(businessId, settingName, settingValue)
                .then((success) => {
                  if (success) {
                    $ctrl
                      .loadBusinessSettings($ctrl.selectedBusinessId)
                      .then(() => {
                        $ctrl.businessSettings.find(
                          (setting) => setting.name === settingName
                        ).updated = true;

                        setTimeout(() => {
                          const settings =
                            document.getElementsByClassName('setting-name');
                          Array.from(settings)
                            .find((x) => x.textContent === settingName)
                            .scrollIntoView(scrollOptions);
                        }, 300);
                      });
                  } else {
                    const options = {
                      headerText: 'Failed to update setting',
                      message: `Error while trying to set "${settingName}" to "${settingValue}"`
                    };
                    dialogService.showMessageDialog(options);
                  }
                });
            }

            function clearCachedSettings() {
              maintenanceService.clearCachedSettings().then((success) => {
                if (success) {
                  $ctrl.loadBusinessSettings($ctrl.selectedBusinessId);
                } else {
                  const options = {
                    headerText: 'Failed to clear caches',
                    message: 'Error while clearing setting caches'
                  };
                  dialogService.showMessageDialog(options);
                }
              });
            }

            function populateSettingInputs(name: string, value: string) {
              $ctrl.addSettingName = name;
              $ctrl.addSettingValue = value;
              const settingValueInput = document.getElementById(
                'addSettingValue'
              ) as HTMLInputElement;
              settingValueInput.scrollIntoView(scrollOptions);
              settingValueInput.select();
            }
          }
        ]
      }
    }
  });
}
