/**
 * @ngdoc module
 * @name flowingly.runner.exago.single
 * @module flowingly.runner.exago.single
 * @description This module configures the exago state.
 */
import angular from 'angular';

const m = angular.module('flowingly.runner.exago.single', [
  'flowingly.adapters'
]);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.exago.single/runner.exago.single.tmpl.html',
      require('./runner.exago.single.tmpl.html').default
    );
  }
]);
