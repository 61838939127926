export class Percentage {
  private value: number;
  public constructor(n: number) {
    if (1 >= n && n >= 0) {
      this.value = n;
    } else {
      throw new BadArgumentErroer(
        'Percentage must be a number between 0 and 1'
      );
    }
  }
  public toString(): string {
    return `${this.value * 100}%`;
  }
}

export class BadArgumentErroer extends Error {
  constructor(message) {
    super(message);
  }
}
