import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
angular
  .module('flowingly.runner.directives')
  .component('flowInstructionHistory', {
    bindings: {
      instruction: '<',
      isMobile: '<'
    },
    templateUrl: 'Client/runner.directives/flow/flow.instruction.history.html',
    controller: [
      'validationService',
      '$sce',
      'fileService',
      function (
        validationService: SharedAngular.ValidationService,
        $sce: angular.ISCEService,
        fileService: SharedAngular.FileService
      ) {
        const $ctrl = this;

        $ctrl.instructionText = validationService.sanitizeString(
          $sce.trustAsHtml($ctrl.instruction)
        );

        fileService
          .replaceFileIdsWithImages($ctrl.instruction)
          .then((result) => {
            $ctrl.instructionText = validationService.sanitizeString(
              $sce.trustAsHtml(result)
            );
          });
      }
    ]
  });
