import ProcessMapView from '@Client/runner.processmapsv2/components/View/ProcessMapView';
import React from 'react';

type Props = {
  processMapId: string;
  hideFeedBack: boolean;
  hideFlowModelOpenButton: boolean;
  hideBackToFlowButton: boolean;
};
const PublicMap = (props: Props) => {
  return <ProcessMapView hideFeedBack={true} hideFlowModelOpenButton={true} hideBackToFlowButton={true} processMapId={props.processMapId}></ProcessMapView>;
};

export default PublicMap;
