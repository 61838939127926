/**
 * @ngdoc directive
 * @name runnerPageHeader
 * @module flowingly.runner.directives
 * @description This is a component for displaying the page header text eg. Start Flows
 * @usage
 * ```
 * <runner-page-header page-header-text="Start Flows"></runner-page-header>
 * ```
 */
import angular from 'angular';
class PageHeaderComponent implements angular.IComponentOptions {
  public bindings: Bindings;

  constructor() {
    this.bindings = {
      isShow: '<',
      pageHeaderText: '@'
    };
  }

  transclude = true;

  template = `
            <div ng-if="$ctrl.isShow" class="page-header-component">
                <h4 class="runner-page-header">
                    <ng-transclude></ng-transclude>
                    {{ ::$ctrl.pageHeaderText }}
                </h4>
            </div>
        `;
}

angular
  .module('flowingly.runner.directives')
  .component('runnerPageHeader', new PageHeaderComponent());
