import React from 'react';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { ProcessMapsFilterTabs } from '@Shared.Angular/flowingly.services/flowingly.constants';

interface Props {
  component: string;
  selectedButton: string;
  maxDays: number;
}
const EmptyState = ({ component, selectedButton, maxDays }: Props) => {
  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );

  const canCreateFlowModels = permissionsService.currentUserHasPermission(
    flowinglyConstants.permissions.FLOWMODEL_CREATE
  );

  let summaryText = '';
  let bodyText = '';
  let buttonText = '';

  switch (component) {
    case 'processmapv2':
      if (selectedButton === ProcessMapsFilterTabs.RECENTLY_UPDATED_MAPS_ID) {
        summaryText = `There are no maps updated in the last ${maxDays} days`;
        bodyText = '';
        buttonText = '';
      } else {
        summaryText = canCreateFlowModels
          ? 'You have no Process Maps'
          : 'View Process Maps';
        bodyText = canCreateFlowModels
          ? 'We make it easy for you to create, view and share your organizational Process Maps in one place.<br /> You do not currently have any Process Maps published.'
          : 'You do not currently have any Process Maps available to view. <br /> Please contact your administrator to have relevant Process Maps shared with you.';
        buttonText = canCreateFlowModels ? 'Publish a Process Map' : '';
      }
      break;

    default:
      summaryText = '';
      bodyText = '';
      buttonText = '';
      break;
  }

  const onButtonClick = () => {
    tempModelerUrlService.openModeler();
  };

  return (
    <div className="empty-state-container">
      <div className="empty-state align-center pb-30 pt-50">
        <div className="mt-50">
          <h4 className="empty-state__summary">{summaryText}</h4>
          <h5
            className="empty-state__text"
            dangerouslySetInnerHTML={{ __html: bodyText }}
          />
          <br />
        </div>
        {buttonText !== '' && (
          <button onClick={onButtonClick} className="btn green">
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
