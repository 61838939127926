/**
 * @ngdoc directive
 * @name flowHistoryFieldCheckbox
 * @module flowingly.runner.flow
 * @description This comppnent is used to display checkbox history items.
 * @usage
 * ```
     <flow-history-field-checkbox item="item"></flow-history-field-checkbox>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * show: show the checkbox
 * * item: the checkbox data to display (JSON)
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryFieldCheckbox', {
  bindings: {
    item: '<'
  },
  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.field.checkbox.component.tmpl.html'
});
