import React, { useState, useEffect } from 'react';
import {
  TreeList,
  TreeListColumnProps,
  extendDataItem,
  mapTree,
  orderBy,
  TreeListExpandChangeEvent,
  TreeListPageChangeEvent,
  TreeListSortChangeEvent
} from '@progress/kendo-react-treelist';
import { useService } from '@Client/runner.hooks/useService';
import CategoryListActions from './Actions/CategoryListActions';
import { CategoriesPager } from './Pager/CategoriesPager';
import { SharedAngular } from '@Client/@types/sharedAngular';
import NameFilter from './Filters/NameFilter';
import Count from './Count/Count';
let nameFilterData = [];
const NameFilterCell = (props) => (
  <NameFilter {...props} data={nameFilterData} />
);
const Categories = () => {
  const columns: TreeListColumnProps[] = [
    {
      field: 'name',
      title: 'Name',
      width: '500px',
      filterCell: NameFilterCell,
      expandable: true
    },
    { field: 'description', title: 'Description' },
    {
      field: 'numberOfFlows',
      title: 'Number of Flow Models',
      width: '200px',
      cell: Count
    },
    {
      field: 'actions',
      title: 'Actions',
      width: '50px',
      cell: (props) => (
        <td>
          <CategoryListActions
            id={props.dataItem.id}
            categoryName={props.dataItem.name}
            description={props.dataItem.description}
            approverId={props.dataItem.approverId}
            parentId={props.dataItem.parentId}
            numberOfFlows={props.dataItem.numberOfFlows}
            fetchSubCategories={fetchSubCategories}
            level={props.level.length}
          />
        </td>
      )
    }
  ];
  const subItemsField = 'subCategories';
  const expandField = 'expanded';
  const skip = 0;
  const take = 25;
  const [state, setState] = useState({
    data: [],
    sort: [],
    expanded: [],
    filter: [],
    skip: skip,
    take: take
  });
  const setupApiService =
    useService<SharedAngular.SetupApiService>('setupApiService');
  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');
  const runnerCategoryFilterService = useService<RunnerCategoryFilterService>(
    'runnerCategoryFilterService'
  );
  const fetchSubCategories = async () => {
    try {
      appInsightsService.startEventTimer('subcategoriesFetched');
      const response = await setupApiService.getCategories();
      setState({ ...state, data: response });
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };
  useEffect(() => {
    const subscriberId = 'category-with-subcategories';
    pubSubService.subscribe(
      'CATEGORY_WITH_SUBCATEGORY_ADDED',
      fetchSubCategories,
      subscriberId
    );
    return () => {
      pubSubService.unsubscribeAll(subscriberId);
    };
  }, []);
  useEffect(() => {
    fetchSubCategories();
  }, []);
  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setState({
      ...state,
      expanded: e.value
        ? state.expanded.filter((id) => id !== e.dataItem.id)
        : [...state.expanded, e.dataItem.id]
    });
  };
  const handleFilterChange = (event) => {
    if (event.filter.length > 0) {
      setState({
        ...state,
        filter: event.filter,
        skip: skip,
        take: take
      });
    } else {
      clearFilters();
    }
  };
  const handleSortChange = (event: TreeListSortChangeEvent) => {
    setState({
      ...state,
      sort: event.sort
    });
  };

  const addExpandField = (dataTree, expanded) => {
    const categoryName = new Set();
    const resultTree = mapTree(dataTree, subItemsField, (item) => {
      categoryName.add(item.name);
      return extendDataItem(item, subItemsField, {
        [expandField]: expanded?.includes(item.id)
      });
    });
    return resultTree;
  };
  const processData = () => {
    const { data, filter, sort } = state;
    const filtering = Boolean(filter && filter.length > 0);
    let filteredData = filtering
      ? runnerCategoryFilterService.customFilterById(
          data,
          filter[0],
          subItemsField
        )
      : data;
    filteredData = orderBy(filteredData, sort, subItemsField);
    nameFilterData = data;
    const expanded = state.expanded;
    return addExpandField(filteredData, expanded);
  };
  const onPageChange = (event: TreeListPageChangeEvent) => {
    const { skip, take } = event;
    setState({
      ...state,
      skip,
      take
    });
  };
  const clearFilters = () => {
    setState({ ...state, filter: [] });
  };
  return (
    <div className="categories-with-subcategories-outer-container">
      <link
        href="https://kendo.cdn.telerik.com/themes/7.0.1/material/material-main.css"
        rel="stylesheet"
        type="text/css"
      />
      <div className="categories-with-subcategories-container">
        <TreeList
          columns={columns}
          pager={CategoriesPager}
          onPageChange={onPageChange}
          skip={state.skip}
          take={state.take}
          filter={state.filter}
          sortable={true}
          sort={state.sort}
          data={processData()}
          subItemsField={subItemsField}
          expandField={expandField}
          onExpandChange={onExpandChange}
          onSortChange={handleSortChange}
          onFilterChange={handleFilterChange}
        />
      </div>
    </div>
  );
};
export default Categories;
