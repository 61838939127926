import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

class RunnerProcessMapsV2ViewController {
  processMapId: string;
  allowProcessMap: boolean;
  initialized: boolean;
  processMapName: string;

  constructor(
    private $scope,
    private $state,
    private $stateParams,
    private APP_CONFIG: SharedAngular.APP_CONFIG,
    private pubsubService: SharedAngular.PubSubService
  ) {
    this.initialized = true;
    this.allowProcessMap = this.APP_CONFIG.allowProcessMap;
  }

  $onInit() {
    this.processMapId = this.$stateParams.processMapId;
    const processMapSubscriberId = 'flowingly.runner.processMapV2Controller';
    this.pubsubService.subscribe(
      'PROCESSMAPVIEWV2_LOADED',
      this.onProcessMapLoaded.bind(this),
      processMapSubscriberId
    );
    this.$scope.$on('$destroy', () => {
      this.pubsubService.unsubscribeAll(processMapSubscriberId);
    });
    this.pubsubService.subscribe(
      'BACKTOFLOWS_IMIN',
      this.onBackToFlowImButtonClick.bind(this),
      processMapSubscriberId
    );
  }
  onProcessMapLoaded(event, data) {
    this.processMapName = data.name;
  }
  onBackButtonClick() {
    this.$state.go(
      'app.runner.processmapv2',
      { backFromState: this.$state.current.name },
      { notify: true, replace: true, relative: false }
    );
  }
  onBackToFlowImButtonClick() {
    this.$state.go(
      'app.runner.flowsin',
      {},
      { notify: true, replace: true, relative: false }
    );
  }
}

angular
  .module('flowingly.runner.processmapsv2')
  .controller('runnerProcessMapsV2ViewController', [
    '$scope',
    '$state',
    '$stateParams',
    'APP_CONFIG',
    'pubsubService',
    function ($scope, $state, $stateParams, APP_CONFIG, pubsubService) {
      const controller = new RunnerProcessMapsV2ViewController(
        $scope,
        $state,
        $stateParams,
        APP_CONFIG,
        pubsubService
      );
      controller.$onInit();
      return controller;
    }
  ]);
