import { angular2react } from '@Shared.Angular/flowingly.helper/angular2react';
import { ProcessMapTabStepForm } from '@Client/runner.processmaps/tab/right-pane/runner.processmap.tab.step-form';

type Props = {
  selectedNode: unknown;
  dynamicHeight: boolean;
};

export const ProcessMapStepForm = angular2react<Props>(
  'processmapTabStepForm',
  new ProcessMapTabStepForm()
);
