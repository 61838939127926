/**
 * @name flowUserMenu
 * @module flowingly.user.menu
 * @description A DUMB component is used to display a user menu.
 * ### Notes
 * It is intended that it is rendered by a SMART component that handles interaction with server.
 * Right now, this is the sidemenu.
 * @usage
 * ```
    <flow-user-menu ng-if="ctrl.authenticated"
                    user="ctrl.user"
                    on-logout-method="ctrl.logout()">
        </flow-user-menu>
 * ```
 */
import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';

angular.module('flowingly.user.menu').component('flowUserMenu', {
  templateUrl: 'usermenu.tmpl.html',
  bindings: {
    user: '<',
    iconSmall: '<',
    isMobile: '<',
    onLogoutMethod: '&'
  },

  controller: [
    '$scope',
    '$state',
    'dialogService',
    'APP_CONFIG',
    'tokenService',
    'permissionsService',
    'flowinglyConstants',
    'sessionService',
    'appInsightsService',
    'webPushService',
    function (
      $scope: angular.IScope,
      $state: angular.ui.IStateService,
      dialogService: SharedAngular.DialogService,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      tokenService: SharedAngular.TokenService,
      permissionsService: SharedAngular.PermissionsService,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      sessionService: SharedAngular.SessionService,
      appInsightsService: SharedAngular.AppInsightsService,
      webPushService: WebPushService
    ) {
      const $ctrl = this;
      $ctrl.editProfile = editProfile;
      $ctrl.logout = logout;
      $ctrl.showTenantDialog = showTenantDialog;
      $ctrl.viewTemplatesClicked = viewTemplatesClicked;
      $ctrl.showSupportButton = showSupportButton(APP_CONFIG.hideSupportButton);
      $ctrl.enableTenantSwitching = APP_CONFIG.enableTenantSwitching;
      $ctrl.tenant = tokenService.getTenant();
      $ctrl.tenantName = $ctrl.tenant.name || $ctrl.user.businessName;
      $ctrl.showEditProfile = !sessionService.inTenantBusiness();

      this.templateLibraryEnabled = APP_CONFIG.enableTemplateLibrary;
      $ctrl.helpLink = APP_CONFIG.brandingHelpUri;
      $ctrl.helpTitle = APP_CONFIG.brandingHelpTitle;
      $ctrl.showSupportButton = showSupportButton(APP_CONFIG.hideSupportButton);
      $ctrl.showTenantSwitch =
        APP_CONFIG.enableTenantSwitching &&
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.TENANT_ACCESS
        );

      function showSupportButton(configHide) {
        return (
          !configHide ||
          permissionsService.currentUserHasPermission(
            flowinglyConstants.permissions.SUPPORT_ACCESS
          )
        );
      }

      function editProfile() {
        if (!$ctrl.showEditProfile) {
          return;
        }
        const userData = {};
        angular.copy($ctrl.user, userData);

        dialogService.showDialog({
          template:
            'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.tmpl.html',
          controller: 'userDialogController',
          appendClassName: 'ngdialog-normal',
          scope: $scope,
          data: userData
        });
      }

      function showTenantDialog() {
        const tenant = {};
        angular.copy($ctrl.tenant, tenant);

        dialogService.showDialog({
          template: 'Client/runner.tenant/runner.tenant.dialog.tmpl.html',
          controller: 'tenantDialogController',
          appendClassName: 'ngdialog-normal',
          scope: $scope,
          data: { tenant: tenant, userBusinessId: $ctrl.user.businessId }
        });
      }

      function logout() {
        webPushService.removeUsersLocalSubscription().finally(() => {
          $ctrl.onLogoutMethod();
          $ctrl.openUserMenu = false;
        });
      }

      function viewTemplatesClicked() {
        appInsightsService.trackPageView('template-library', {
          from: 'usermenu'
        });

        $state.go('app.runner.templates.library');
      }
    }
  ]
});
