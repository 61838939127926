/**
 * @ngdoc module
 * @name flowingly.runner.reports
 * @module flowingly.runner.reports
 * @description This module configures the reports state - used to display available reports.
 * It also contains the components used to display reports.
 * https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 */
import angular, { ITemplateCacheService } from 'angular';
const m = angular.module('flowingly.runner.reports', []);
m.run([
  '$templateCache',
  function ($templateCache: ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.reports/runner.reports.tmpl.html',
      require('./runner.reports.tmpl.html').default
    );
  }
]);
