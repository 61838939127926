import { useService } from '@Client/runner.hooks/useService';
import React from 'react';

type Props = {
  name: string;
  flowCode: string;
};

const FlowIconSquareComponent = (props: Props) => {
  const { name, flowCode } = props;
  const avatarService = useService<AvatarService>('avatarService');

  const codeToDisplay = avatarService.getFlowInitials(name, flowCode);

  return (
    <div className="flow-icon-square flow-logo" title={name}>
      {codeToDisplay}
    </div>
  );
};
export default FlowIconSquareComponent;
