import React from 'react';
import { Box, Button } from '@mui/material';

export type Props = {
  isActionDisabled?: boolean;
  actionButtonText: string;
  actionButtonType: 'Success' | 'Danger';
  onCancelClick: () => void;
  onActionClick: () => void;
};
const ModalFooter = (props: Props) => {
  const {
    actionButtonType,
    actionButtonText,
    isActionDisabled,
    onCancelClick,
    onActionClick
  } = props;
  return (
    <Box className="categoriesv2-modal-edit-button-container">
      <Button
        variant="text"
        onClick={onCancelClick}
        className="categoriesv2-modal-cancel-button"
      >
        Cancel
      </Button>
      <Button
        className={
          actionButtonType == 'Success'
            ? 'footer-action-success-button btn'
            : 'footer-action-danger-button btn'
        }
        variant="contained"
        onClick={onActionClick}
        disabled={isActionDisabled}
      >
        {actionButtonText}
      </Button>
    </Box>
  );
};

export default ModalFooter;
