import { Button } from '@mui/material';
import React, { MouseEventHandler } from 'react';

type Props = {
  buttonText: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const CustomButton = (props: Props) => {
  const { buttonText, onClick } = props;
  return (
    <Button variant="contained" onClick={onClick} className="v2-custom-button">
      {buttonText}
    </Button>
  );
};

export default CustomButton;
