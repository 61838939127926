import React from 'react';
import { Tabs } from '@mui/material';
import CustomTabHeader from './CustomTabHeader';
import { CustomTab } from '@Client/runner.processmapsv2/@types/customTab';

type props = {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabs: CustomTab[];
};

const CustomTabs = (props: props) => {
  const { value, onChange, tabs } = props;

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="fullWidth"
      className="v2-custom-tabs"
    >
      {tabs?.map(
        (tab, index) =>
          !tab.isHidden && (
            <CustomTabHeader
              key={index}
              label={tab.label}
              isDisabled={tab.isDisabled}
            />
          )
      )}
    </Tabs>
  );
};

export default CustomTabs;
