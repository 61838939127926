'use strict';

import angular, { IHttpService } from 'angular';
import IInsertBusinessCommand from '@Shared.Angular/@types/core/contracts/commands/business/insertBusinessCommand';
import IBusinessDetail from '@Shared.Angular/@types/core/contracts/queryModel/business/businessDetail';
import IBusinessTemplate from '@Shared.Angular/@types/core/contracts/queryModel/business/businessTemplate';
import { SharedAngular } from '@Client/@types/sharedAngular';
import ISettingDetail from '@Shared.Angular/@types/core/contracts/config/ISettingDetail';

export default class SignupService {
  static $inject = ['$http', 'APP_CONFIG'];

  private apiBaseUri: string;

  constructor(
    private $http: IHttpService,
    private APP_CONFIG: SharedAngular.APP_CONFIG
  ) {
    this.apiBaseUri = this.APP_CONFIG.apiBaseUrl + 'business';
  }

  public industries = [
    { name: 'Accounting' },
    { name: 'Construction' },
    { name: 'Consulting' },
    { name: 'Cloud Services Provider' },
    { name: 'Energy & Utilities' },
    { name: 'Engineering & Construction' },
    { name: 'Financial Services' },
    { name: 'Health/Medical' },
    { name: 'Hospitality' },
    { name: 'Human Resources' },
    { name: 'IT' },
    { name: 'Legal Services/Law Practice' },
    { name: 'Manufacturing' },
    { name: 'Marketing, Media & Entertainment' },
    { name: 'Non-Profit Organisation' },
    { name: 'Public Sector' },
    { name: 'Real Estate' },
    { name: 'Recruitment' },
    { name: 'Retail' },
    { name: 'Sales' },
    { name: 'Software Development' },
    { name: 'Tourism' },
    { name: 'Technology' },
    { name: 'Telecommunications' },
    { name: 'Transport & Logistics' },
    { name: 'Other' }
  ];

  public signupBusiness(signup: IInsertBusinessCommand) {
    return this.$http.post(this.apiBaseUri, signup);
  }

  public getTemplateBusinesses() {
    return this.$http
      .get<IBusinessTemplate[]>(this.apiBaseUri + '/template')
      .then(function (response) {
        return response.data;
      });
  }

  public getExistingBusinesses() {
    return this.$http
      .get<IBusinessDetail[]>(this.apiBaseUri, {
        params: {
          active: true
        }
      })
      .then(function (response) {
        return response.data;
      });
  }

  public getSignupSettings(businessId: string) {
    return this.$http
      .get<ISettingDetail[]>(`${this.apiBaseUri}/${businessId}/settings/signup`)
      .then(function (response) {
        return response.data;
      });
  }
}

angular.module('flowingly.signup').service('signupService', SignupService);
