import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { dialog } from 'angular';
import IUserMaintenanceModel from '@Shared.Angular/@types/core/contracts/queryModel/user/userMaintenanceModel';

angular
  .module('flowingly.runner.setup')
  .controller(
    'maintenanceUserDetailController',
    maintenanceUserDetailController
  );

maintenanceUserDetailController.$inject = [
  '$scope',
  'ngDialog',
  'maintenanceService',
  'notificationService'
];

function maintenanceUserDetailController(
  $scope: IScopeNgDialog,
  ngDialog: dialog.IDialogService,
  maintenanceService: MaintenanceService,
  notificationService: SharedAngular.NotificationService
) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const ctrl = this;
  ctrl.hasFinishedLoading = false;
  ctrl.selectedManager = null;
  ctrl.changeRequestId = null;
  ctrl.closeDialog = closeDialog;
  ctrl.update = update;
  ctrl.loadUser = maintenanceService.getUserBasicInfo;

  init();

  function closeDialog(updated) {
    ngDialog.closeAll(updated);
  }

  function update() {
    const updateModel: IUserMaintenanceModel = {
      userId: ctrl.user.id,
      businessId: ctrl.user.businessId,
      managerId: ctrl.selectedManager.id,
      inviteUser: ctrl.user.inviteUser,
      delete: ctrl.user.delete,
      active: ctrl.user.active,
      email: ctrl.user.email,
      changeRequestId: ctrl.changeRequestId
    };
    maintenanceService.updateUser(updateModel).then(() => {
      closeDialog(true);
      notificationService.showSuccessToast('User Updated');
    });
  }

  function init() {
    const user = $scope.ngDialogData;
    ctrl.userFullName = user.fullName;
    return getUserSummaryById(user.id, user.businessId)
      .then((currentUserSummary) => {
        ctrl.user = currentUserSummary;
        ctrl.selectedManager = {
          id: ctrl.user.managerUserId,
          FullName: ctrl.user.managerName || ''
        };
      })
      .then(() => {
        ctrl.hasFinishedLoading = true;
      });
  }

  function getUserSummaryById(userId, businessId) {
    if (!userId || !businessId) {
      return undefined;
    }

    const filterParams = {
      filter: {
        filters: [{ field: 'id', value: userId }]
      }
    };

    return maintenanceService
      .getUsersWithOptions(filterParams, businessId)
      .then((searchUserResult) => {
        return searchUserResult.users[0];
      });
  }
}
