import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.login
 * @module flowingly.runner.login
 * @description This module configures the login state.
 */
const m = angular.module('flowingly.runner.login', []);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.login/login.tmpl.html',
      require('./login.tmpl.html').default
    );
  }
]);
