import { SharedAngular } from '@Client/@types/sharedAngular';
import { BpmnModeler } from '@Shared.Angular/flowingly.bpmn.modeler/@types/services';
import angular, { IScope, ITimeoutService } from 'angular';
import { IStateProvider } from 'angular-ui-router';

declare const _: Lodash;
// Register the application's routes configuration.
angular.module('flowingly.runner.maintenance').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance.validation', {
    url: '/validation',
    params: { flowId: null, title: 'Maintenance - Validation' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/validation/runner.maintenance.validation.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          'maintenanceService',
          'APP_CONFIG',
          '$timeout',
          '$scope',
          'flowinglyDiagramService',
          'BpmnDiagramService',
          function (
            maintenanceService: MaintenanceService,
            appConfig: AppConfig,
            $timeout: ITimeoutService,
            $scope: IScope,
            flowinglyDiagramService: SharedAngular.FlowinglyDiagramService,
            BpmnDiagramService: BpmnModeler.BpmnDiagramService
          ) {
            const $ctrl = this;

            $ctrl.flowModels = [];
            $ctrl.runStarted = false;
            $ctrl.run = run;
            $ctrl.gridNeedResize = true;
            $ctrl.fileName = 'Export';

            let timeInterval = 0;
            init();
            // Public Methods
            function run(isAll) {
              if (isAll) {
                $ctrl.selectedBusinessId = undefined;
                $ctrl.fileName = 'Export';
              }
              if ($ctrl.selectedBusinessId != undefined) {
                $ctrl.fileName = $ctrl.selectedBusinessId;
              }
              return maintenanceService
                .getPublishedFlows($ctrl.selectedBusinessId)
                .then(function (models) {
                  $ctrl.runStarted = true;
                  angular.copy(models, $ctrl.flowModels);
                  $scope.flowGrid.dataSource.read();
                  $ctrl.gridNeedResize = true;
                  if ($ctrl.flowModels.length > 0) {
                    $ctrl.timeStarted = new Date();
                    timeInterval = window.setInterval(calcElapsedTime, 1000);
                    $ctrl.currentRecord = 0;
                    generateProcessModel();
                  } else {
                    clear();
                  }
                  return;
                });
            }
            // Private Methods

            function generateProcessModel() {
              const flow = $ctrl.flowModels[$ctrl.currentRecord];
              $ctrl.flow = flow;
              $timeout(() => {
                try {
                  const diagram = flowinglyDiagramService.generateProcessModel({
                    flow: flow,
                    focusId: flow.id
                  });
                  //store the diagram so that we can later retrieve it in the overview directive
                  BpmnDiagramService.storeDiagram('main', diagram);
                } catch (e) {
                  console.log('Error occured while geneating diagram');
                }
                maintenanceService
                  .validateWorkFlow(flow.businessId, flow.id)
                  .then((errors) => {
                    let errMessages = '';
                    let isServerValidation = false;
                    _.forEach(errors, function (msg) {
                      errMessages += msg.message;
                      if (msg.isServerError) {
                        isServerValidation = true;
                      }
                    });
                    $ctrl.flowModels[$ctrl.currentRecord].error = errMessages;
                    $ctrl.flowModels[$ctrl.currentRecord].status =
                      errors.length === 0
                        ? 'Valid'
                        : isServerValidation
                        ? 'Server validation failed'
                        : 'Invalid';
                  })
                  .catch((res) => {
                    if (res['status']) {
                      $ctrl.flowModels[
                        $ctrl.currentRecord
                      ].error = `${res.data.Message}`;
                      $ctrl.flowModels[$ctrl.currentRecord].status =
                        'Server Error';
                      $ctrl.flowModels[$ctrl.currentRecord].stack = '';
                    } else {
                      $ctrl.flowModels[$ctrl.currentRecord].error =
                        res['message'];
                      $ctrl.flowModels[$ctrl.currentRecord].stack =
                        res['stack'];
                      $ctrl.flowModels[$ctrl.currentRecord].status =
                        'Client Side Error';
                    }
                  })
                  .finally(() => {
                    document.getElementById('barwidth').style.width =
                      parseFloat(
                        (($ctrl.currentRecord + 1) / $ctrl.flowModels.length) *
                          100
                      ) + '%';
                    $scope.flowGrid.dataSource.read();
                    $ctrl.currentRecord++;
                    if ($ctrl.currentRecord < $ctrl.flowModels.length) {
                      generateProcessModel();
                    } else {
                      $scope.flowGrid.saveAsExcel();
                    }
                  });
              }, 500);
            }

            function getColumns() {
              return [
                {
                  field: 'businessName',
                  title: 'Business name'
                },
                {
                  field: 'name',
                  title: 'Flow Model name'
                },
                {
                  field: 'status',
                  title: 'Result'
                },
                {
                  field: 'error',
                  title: 'Errors'
                },
                {
                  field: 'stack',
                  title: 'Stack Trace'
                },
                {
                  field: 'id',
                  title: 'Flow Id'
                },
                {
                  field: 'businessId',
                  title: 'BusinessId'
                }
              ];
            }

            function calcElapsedTime() {
              const difference = new Date() - $ctrl.timeStarted;

              const seconds = Math.floor((difference / 1000) % 60);
              const minutes = Math.floor((difference / 60000) % 60);
              const hours = Math.floor(difference / 3600000);
              let timestr = '';
              if (hours > 0) timestr += hours + ' hour(s) ';
              if (minutes > 0) timestr += minutes + ' minute(s) ';
              if (seconds > 0) timestr += seconds + ' seconds';
              $ctrl.elapsedTime = timestr;
            }

            function clear() {
              $ctrl.runStarted = false;
              window.clearInterval(timeInterval);
              if ($ctrl.flowModels.length > 0) {
                document.getElementById(
                  $ctrl.flowModels[$ctrl.currentRecord - 1].id
                ).innerHTML = '';
              }
              document.getElementById('barwidth').style.width = '0';
              $ctrl.elapsedTime = '';
              $ctrl.flow = {};
              $ctrl.currentRecord = 0;
            }

            function init() {
              $ctrl.options = {
                dataSource: {
                  data: $ctrl.flowModels,
                  pageSize: appConfig.gridReportPageSize
                },
                noRecords: {
                  template: 'No Data'
                },
                scrollable: {
                  virtual: true
                },
                resizable: true,
                columns: getColumns(),
                excel: {
                  allPages: true
                },
                excelExport: function (e) {
                  e.workbook.fileName = `${$ctrl.fileName} - ${kendo.toString(
                    new Date(),
                    'dd/MM/yyyy HH:mm'
                  )}.xlsx`;
                  $ctrl.runStarted = false;
                  clear();
                }
              };
              $ctrl.noRows = !$ctrl.flowModels[0];
              $timeout(() => ($ctrl.gridInitDone = true), 100);
              $scope.$parent.$watch('$ctrl.selectedBusiness', (value) => {
                $ctrl.selectedBusinessId = value?.id;
              });
            }
          }
        ]
      }
    }
  });
}
