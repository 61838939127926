/**
 * @ngdoc directive
 * @name flowTimelineFilelist
 * @module flowingly.runner.directives
 * @description A directive for displaying a readonly list of files previously uploaded
 * @usage
 * ```
 * <timeline-filelist files="ctrl.filelist"></timeline-filelist
 * ```
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.directives')
  .component('flowTimelineFilelist', {
    bindings: {
      files: '<'
    },
    template: `
                    <div ng-repeat="file in $ctrl.files">
                        <a flow-download-link="{{::file.downloadLink}}" title="{{::file.filename}}" target="_blank"> {{::file.filename}}</a>
                    </div>
                    <flow-history-field-value ng-if="!$ctrl.files || $ctrl.files.length === 0" field-value="!item.Value ? '' : ' '" field-type="'fileupload'"></flow-history-field-value>
                `
  });
