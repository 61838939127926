import React, { useState, useEffect, useCallback } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import debounce from 'lodash.debounce';
import { IUserBasicInfo } from '@Client/interfaces/user.interface';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';
import { Guid } from '@Shared.Angular/@types/guid';

type Props = {
  businessId?: string;
  permissionSecureCodes?: string[];
  selectedUserId?: Guid;
  onUserSelect?: (userId: string) => void;
};

const SelectUser = (props: Props) => {
  const { businessId, permissionSecureCodes, selectedUserId, onUserSelect } =
    props;

  const [users, setUsers] = useState<IUserBasicInfo[]>([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const currentSearchTermRef = React.useRef('');

  const userApiService =
    useService<SharedAngular.UserApiService>('userApiService');
  const sessionService =
    useService<SharedAngular.SessionService>('sessionService');

  const fetchUsers = async (searchTerm: string) => {
    const businessIdFilter = businessId || sessionService.getBusinessId();
    try {
      const searchUsersResult = await userApiService.searchUsers({
        term: searchTerm,
        clientId: businessIdFilter,
        permissionSecureCodes
      });

      if (searchTerm !== currentSearchTermRef.current) {
        return;
      }

      const updatedUsers = selectedUser
        ? [
            selectedUser,
            ...searchUsersResult.filter((user) => user.id !== selectedUser.id)
          ]
        : searchUsersResult;

      setUsers(updatedUsers || []);
    } catch (err) {
      setUsers([]);
    }
  };

  const fetchSelectedUser = async () => {
    if (!selectedUserId) return;
    try {
      const selectedUserResult =
        await userApiService.getUserBasicInfoByFieldName(selectedUserId);
      if (selectedUserResult) {
        setSelectedUser(selectedUserResult);
      }
    } catch (err) {
      setSelectedUser(null);
    }
  };

  const debouncedFetchUsers = useCallback(
    debounce((term: string) => {
      currentSearchTermRef.current = term;
      fetchUsers(term);
    }, 500),
    [businessId, permissionSecureCodes, selectedUser]
  );

  useEffect(() => {
    debouncedFetchUsers(searchTerm);
    return () => {
      debouncedFetchUsers.cancel();
    };
  }, [searchTerm, debouncedFetchUsers]);

  useEffect(() => {
    fetchSelectedUser();
  }, [selectedUserId]);

  const handleSelectionChange = (event) => {
    const selectedUser = event.value;
    setSelectedUser(selectedUser);
    onUserSelect?.(selectedUser?.id);
  };

  const handleFilterChange = (event) => {
    setSearchTerm(event.filter.value);
  };

  return (
    <ComboBox
      className="select-user-combobox"
      data={users}
      textField="fullName"
      dataItemKey="id"
      placeholder="Select User"
      value={selectedUser}
      onChange={handleSelectionChange}
      filterable={true}
      onFilterChange={handleFilterChange}
      clearButton={false}
    />
  );
};

export default SelectUser;
