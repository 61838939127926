(function () {
  'use strict';

  ///
  /// This state presents the signup form
  ///

  // Register the application's routes configuration.
  angular.module('flowingly.runner.sso').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.sso', {
      url: '/ssocallback?id_token&state&redirect',
      params: {},
      controller: 'runnerSSOController',
      controllerAs: 'ctrl'
    });
  }
})();
