/**
 * @ngdoc directive
 * @name flowReport
 * @module flowingly.runner.reports
 * @description  This componenet is used to display a report (within a list).
 *               It will trigger a report when clicked
 * @usage
 * ```
    <flow-report ng-repeat="report in $ctrl.flow.reports" report="report"></flow-report>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * flow: The flow for the report to display
 * * isMobile: show mobile view if set
 * * report: the report to display
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.reports').component('flowReport', {
  bindings: {
    flow: '<', //The flow for the report to display
    report: '<', //the report to display
    isMobile: '<' //show mobile view if set
  },

  template: [
    '<div class="runner-report" ng-class="{\'isMobile\': $ctrl.isMobile}">',
    '<div class="text"><span>{{$ctrl.report.name}} - </span> <span>{{$ctrl.report.description}}</span>',
    '<button class="btn green right" ui-sref="app.runner.report({ flowModelId: $ctrl.flow.id, flowModelName: $ctrl.flow.name})" >View</button></div>',
    '</div>'
  ].join('')
});
