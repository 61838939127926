import * as angular from 'angular';
import { DashboardFilterItem } from '../models/runner.dashboard.filter.item.model';

export class DashboardFilterItemComponent implements angular.IComponentOptions {
  public controller = DashboardFilterItemController;
  public templateUrl =
    'Client/runner.dashboard.filter/runner.dashboard.filter.item/runner.dashboard.filter.item.tmpl.html';
  public bindings: any = {
    filterItemData: '<',
    isSelected: '<',
    onSelect: '&'
  };
}

export class DashboardFilterItemController implements angular.IController {
  public filterItemData: DashboardFilterItem;

  public onSelect: (filterItem: DashboardFilterItem) => void;

  public select() {
    this.onSelect(this.filterItemData);
  }
}

DashboardFilterItemController.$inject = [];
angular
  .module('flowingly.runner.dashboard.filter')
  .component('dashboardFilterItem', new DashboardFilterItemComponent());
