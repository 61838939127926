'use strict';
//
// This state displays the admin Template Tenants page
//
import * as angular from 'angular';

angular.module('flowingly.runner.templates').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.templates.admin.tenants', {
    url: '/tenants',
    params: { title: 'Template Tenants' },
    templateUrl:
      'Client/runner.templates/admin/tenants/runner.templates.admin.tenants.tmpl.html',
    controller: 'runnerTemplatesTenantsController',
    controllerAs: 'ctrl'
  });
}
