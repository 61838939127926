import { SharedAngular } from '@Client/@types/sharedAngular';
import WebPushService from '@Client/runner.services/webpush.service';
import angular, { IScope } from 'angular';
/// This component is used to display the user settings interface

angular.module('flowingly.runner.user.settings').component('userSettings', {
  templateUrl: 'Client/runner.user.settings/user.settings.tmpl.html',
  bindings: {
    isMobile: '<'
  },
  controller: [
    '$scope',
    'APP_CONFIG',
    'webPushService',
    'accountApiService',
    function (
      $scope: IScope,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      webPushService: WebPushService,
      accountApiService: AccountApiService
    ) {
      const $ctrl = this;
      this.webPushEnabled =
        APP_CONFIG.enableWebPushNotificationsMobile ||
        APP_CONFIG.enableWebPushNotificationsNonMobile;
      this.userWebPushEnabled = APP_CONFIG.userWebPushNotificationsEnabled;
      this.disableUserWebPushEnabled = false;
      this.browserWebPushPermission;
      if (typeof Notification !== 'undefined') {
        this.browserWebPushPermission = Notification?.permission;
      }

      $scope.$watch(
        function () {
          return APP_CONFIG.userWebPushNotificationsEnabled;
        },
        () => {
          this.userWebPushEnabled = APP_CONFIG.userWebPushNotificationsEnabled;
        }
      );

      this.webPushPermissionChanged = (userEnabled: boolean) => {
        this.disableUserWebPushEnabled = true;
        accountApiService
          .changeUserSetting(
            'userWebPushNotificationsEnabled',
            userEnabled.toString(),
            true
          )
          .then(() => {
            if (this.userWebPushEnabled === true) {
              webPushService.trySetupWebPush($scope);
            } else {
              webPushService.removeAllUsersSubscriptions();
            }

            this.disableUserWebPushEnabled = false;
          })
          .catch(() => (this.disableUserWebPushEnabled = false));
      };

      this.showEnableNotificationHelp = () => {
        webPushService.trySetupWebPush($scope);
      };
    }
  ]
});
