/**
 * @ngdoc module
 * @name flowingly.runner.user.settings
 * @module flowingly.runner.user.settings
 * @description This module configures the page used to display user settings.
 */
angular.module('flowingly.runner.user.settings', []).run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.user.settings/runner.user.settings.tmpl.html',
      require('./runner.user.settings.tmpl.html').default
    );
    $templateCache.put(
      'Client/runner.user.settings/user.settings.tmpl.html',
      require('./user.settings.tmpl.html').default
    );
    $templateCache.put(
      'Client/runner.user.settings/webApp.install.tmpl.html',
      require('./webApp.install.tmpl.html').default
    );
    $templateCache.put(
      'Client/runner.user.settings/webPush.permission.reset.tmpl.html',
      require('./webPush.permission.reset.tmpl.html').default
    );
  }
]);
