/*
 * This definition file is maintained by us in flowingly because we have no access to the older kendo.d.ts
 * Because even if we are using the paid kendo, we arent using the latest kendo and just unearthing that is
 * gargantuan piece of work so I'd rather just annotate as I need it.
 *
 *
 * Note that these interfaces here are almost exactly the same as the Flowingly.Report's and that is because
 * the Reports were developed with the Kendo as a base however I will be keeping both just to to tell
 * when a piece of JSON comes from Kendo or comes from us.
 */

/*
    Based on https://docs.telerik.com/kendo-ui/api/javascript/data/datasource/configuration/schema
    That page may not be reflective of what is in our version so take that document so take it 
    with a grain of salt.
*/
export interface IKendoDsSchema {
  total?: string | ((response: any) => number);
  data?: string | ((response: any) => any[]);
  model?: IKendoDsSchemaModel;
}

export interface IKendoDsSchemaModel {
  fields: any;
}

export interface IKendoDsTransportResult {
  data: IKendoDsFilter;
  success: Function;
  error: Function;
}

export interface IKendoDsFilter {
  filter: IKendoDsExpression;
  page: number;
  pageSize: number;
  skip: number;
  take: number;
  sort: IKendoDsFilterSort[];
}

export interface IKendoDsExpression {
  filters: IKendoDsFilterItem[];
  logic: KendoFilterLogic;
}

export interface IKendoDsFilterSort {
  field: string;
  dir: KendoFilterSort;
}

export interface IKendoDsFilterItem {
  field: string;
  operator: KendoFilterOps;
  value: string;
}

/**
 * These operators are taken from
 * https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/filterable.operators.string
 * We don't support all of these, but I have recorded them for reference.
 */
export enum KendoFilterOps {
  lt = 'lt',
  lte = 'lte',
  gt = 'gt',
  gte = 'gte',
  eq = 'eq',
  neq = 'neq',
  isnull = 'isnull',
  isnotnull = 'isnotnull',
  isempty = 'isempty',
  isnotempty = 'isnotempty',
  startswith = 'startswith',
  doesnotstartwith = 'doesnotstartwith',
  contains = 'contains',
  doesnotcontain = 'doesnotcontain',
  endswith = 'endswith',
  doesnotendwith = 'doesnotendwith'
}

export enum KendoFilterLogic {
  and = 'and',
  or = 'or'
}

export enum KendoFilterSort {
  desc = 'desc',
  asc = 'asc'
}

/**
 * Kendo Directive specific interfaces
 */

export interface KendoGridColDef {
  /**
   * Field name as string to read the value from
   */
  field: string;
  locked?: boolean;
  /**
   * The width of the column. Numeric values are treated as pixels.
   */
  width?: string | number;
  title?: string;
  template?: (row: any) => string;
  filterable?: boolean | KendoFilterableConfig;
  sortable?: boolean;
  headerTemplate?: (row: any) => any;
  headerAttributes?: any;
}

export interface KendoFilterableConfig {
  cell?: KendoFilterableConfigCell;
}

export interface KendoFilterableConfigCell {
  template?: (row: any) => any;
  showOperators?: boolean;
}
