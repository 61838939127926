import React, { useEffect, useRef, useState } from 'react';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';
import ICustomEmailProcessMapNodeElement from '@Shared.Angular/@types/customEmailProcessMapNodeElement';

type Props = {
  selectedNode: IProcessMapNodeViewModel;
};

function EmailContent(props: Props) {
  const [subject, setSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  const validationService =
    useService<SharedAngular.ValidationService>('validationService');

  useEffect(() => {
    const emailField: ICustomEmailProcessMapNodeElement =
      props.selectedNode.fields[0];

    setSubject(emailField.subject);

    const htmlContent = emailField.templateHtml;
    if (htmlContent) {
      const sanitizedHTMLContent =
        validationService.sanitizeString(htmlContent);
      setEmailContent(sanitizedHTMLContent);
    }
  }, [props]);

  useEffect(() => {
    if (containerRef.current) {
      const tables = containerRef.current.querySelectorAll('table');

      tables.forEach((table) => {
        const scrollableDiv = document.createElement('div');
        scrollableDiv.style.overflowX = 'auto';
        scrollableDiv.style.display = 'block';
        scrollableDiv.style.maxWidth = '100%';

        table.style.borderCollapse = 'collapse';
        table.style.width = '100%';
        table.style.border = '1px dotted #ccc';

        const cells = table.querySelectorAll('td, th');
        cells.forEach((cell) => {
          cell.style.border = '1px dotted #ccc';
          cell.style.padding = '8px';
        });

        table.parentNode.insertBefore(scrollableDiv, table);
        scrollableDiv.appendChild(table);
      });
    }
  }, [emailContent]);

  return (
    <>
      <div>
        <label className="row-title">Subject</label>
      </div>
      <div>
        <p>
          <span>{subject}</span>
        </p>
      </div>
      <div className="process-map-v2-email-content-wrapper">
        <span>
          <label className="row-title">Email Content</label>
        </span>
        <span>
          <ClipboardCopy
            copyText={emailContent}
            isHtmlContent
            copyTargetRef={containerRef}
          />
        </span>
      </div>
      <div className="process-map-v2-email-content-text">
        <p>
          <span
            dangerouslySetInnerHTML={{ __html: emailContent }}
            ref={containerRef}
          ></span>
        </p>
      </div>
    </>
  );
}

export default EmailContent;
