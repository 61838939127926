import angular, { IController } from 'angular';

class BulkSelectActorDialogController implements IController {
  public selectedDynamicActors: [];
  public isRequired: boolean;

  constructor(private $scope: IScopeNgDialog) {
    this.isRequired = $scope.ngDialogData.isRequired;
    this.selectedDynamicActors = angular.copy(
      $scope.ngDialogData.selectedBulkStartActors,
      []
    );
  }

  public saveActors() {
    if (this.isRequired) {
      if (this.selectedDynamicActors.length > 0) {
        return this.$scope.closeThisDialog(this.selectedDynamicActors);
      }
    } else {
      return this.$scope.closeThisDialog(this.selectedDynamicActors);
    }
  }

  public closeDialogue() {
    this.$scope.closeThisDialog();
  }
}
// for some unknown reason have to move $inject here to resolve runtime error Class constructor cannot be invoked without new
BulkSelectActorDialogController.$inject = ['$scope'];
angular
  .module('flowingly.runner.flow.start')
  .controller(
    'bulkSelectActorDialogController',
    BulkSelectActorDialogController
  );
