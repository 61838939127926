/**
 * @ngdoc directive
 * @name rolesHeader
 * @module flowingly.runner.setup
 * @description This component is used to display the header for the roles and will contain controls
 * @usage
 * ```
      <roles-header on-roles-updated="$ctrl.handleRolesUpdated()"></roles-header>
 * ``` 
 */

'use strict';
import angular from 'angular';

export default class RolesHeaderComponentController {
  static $inject = [
    '$scope',
    'dialogService',
    'intercomService',
    'guidService',
    'permissionsService',
    'flowinglyConstants'
  ];

  // bindings
  private onRolesUpdated;

  private showAddRoleButton = false;

  constructor(
    private $scope,
    private dialogService,
    private intercomService,
    private guidService,
    private permissionsService,
    private flowinglyConstants
  ) {
    this.showAddRoleButton = this.permissionsService.currentUserHasPermission(
      this.flowinglyConstants.permissions.SETUP_ROLE_ADD
    );
  }

  showRoleDialog() {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.setup/runner.setup.roles/runner.setup.roles.dialog.tmpl.html',
        controller: 'roleDialogController',
        appendClassName: 'ngdialog-normal',
        scope: this.$scope,
        data: { role: { id: this.guidService.empty() } }
      })
      .then((successSaved) => {
        if (this.dialogService.isCloseModalWithCancelAction(successSaved)) {
          return;
        }

        if (successSaved === true) {
          this.intercomService.trackEvent('Added Role');
          this.onRolesUpdated();
        }
      });
  }
}

export class RolesHeaderComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.setup/runner.setup.roles/runner.setup.roles.header.tmpl.html';
  public bindings = {
    onRolesUpdated: '&'
  };
  controller = RolesHeaderComponentController;
}

angular
  .module('flowingly.runner.setup')
  .component('rolesHeader', new RolesHeaderComponent());
