import React from 'react';
import { Box } from '@mui/material';
import {
  BreadCrumbType,
  HandleBreadCrumbClick,
  RemoveBreadCrumb
} from '@Client/runner.processmapsv2/@types/breadcrumb';

export type Props = {
  breadCrumb: BreadCrumbType;
  activeBreadCrumbId: string;
  removeBreadCrumb: RemoveBreadCrumb;
  handleBreadCrumbClick: HandleBreadCrumbClick;
};

const BreadcrumbItem = (props: Props) => {
  const {
    breadCrumb,
    removeBreadCrumb,
    activeBreadCrumbId,
    handleBreadCrumbClick
  } = props;

  return (
    <Box className="v2-view-breadcrumb-element-container">
      <Box className="v2-view-breadcrumb-element-container-inner">
        <Box
          className={
            activeBreadCrumbId === breadCrumb.id
              ? 'v2-view-breadcrumb-element-container-inner-item active'
              : 'v2-view-breadcrumb-element-container-inner-item'
          }
        >
          <div
            className="v2-view-breadcrumb-text-wrapper"
            onClick={() => {
              handleBreadCrumbClick(breadCrumb);
            }}
          >
            <p
              className="v2-view-breadcrumb-element-container-inner-heading v2-view-breadcrumb-heading-bold-text"
              data-slide-index="0"
            >
              {breadCrumb.isParent ? 'Parent Process' : 'Component'}
            </p>
            <p
              className="v2-view-breadcrumb-element-container-inner-link"
              data-slide-index="0"
              title={breadCrumb.name}
            >
              {breadCrumb.name}
            </p>
          </div>
          {!breadCrumb.isParent && (
            <i
              className="fa-solid fa-circle-xmark v2-view-breadcrumb-element-container-inner-icon"
              onClick={() => removeBreadCrumb(breadCrumb.id)}
            ></i>
          )}
          <Box className="v2-view-breadcrumb-element-container-inner-arrow"></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BreadcrumbItem;
