/**
 * @ngdoc service
 * @name teamApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching teams data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

/// i.e. we return response.data.DataModel so the consumer can work with the data directly
angular
  .module('flowingly.runner.services')
  .factory('approversApiService', approversApiService);

approversApiService.$inject = ['$http', 'APP_CONFIG'];

function approversApiService(
  $http: angular.IHttpService,
  APP_CONFIG: SharedAngular.APP_CONFIG
) {
  const service = {
    approvers: [],
    saveSelectedApprovers: saveSelectedApprovers
  };

  return service;

  //////////// Public API Methods

  function saveSelectedApprovers(currentStepId, approvers) {
    return $http.post(
      APP_CONFIG.apiBaseUrl + 'flows/' + currentStepId + '/stepApprovers',
      approvers
    );
  }
}

export type ApproversApiServiceType = ReturnType<typeof approversApiService>;
