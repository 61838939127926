'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { dialog } from 'angular';

angular
  .module('flowingly.runner.setup')
  .controller(
    'maintenanceFlowDetailController',
    maintenanceFlowDetailController
  );

maintenanceFlowDetailController.$inject = [
  '$scope',
  'ngDialog',
  'maintenanceService',
  'notificationService'
];

function maintenanceFlowDetailController(
  $scope: IScopeNgDialog,
  ngDialog: dialog.IDialogService,
  maintenanceService: MaintenanceService,
  notificationService: SharedAngular.NotificationService
) {
  const ctrl = this;
  ctrl.hasFinishedLoading = false;
  ctrl.selectedManager = null;
  ctrl.changeRequestId = null;
  ctrl.closeDialog = closeDialog;
  ctrl.save = save;
  ctrl.hasAccess = false;
  ctrl.flowId = null;
  ctrl.id = null;
  ctrl.isUser = false;
  ctrl.businessId = null;

  init();

  function closeDialog(updated) {
    ngDialog.closeAll(updated);
  }

  function init() {
    ctrl.hasAccess = $scope.ngDialogData.hasAccess;
    ctrl.flowId = $scope.ngDialogData.flowId;
    ctrl.id = $scope.ngDialogData.id;
    ctrl.isUser = $scope.ngDialogData.isUser;
    ctrl.businessId = $scope.ngDialogData.businessId;
  }

  function save() {
    return maintenanceService
      .setFlowAcess(
        ctrl.id,
        ctrl.flowId,
        ctrl.businessId,
        ctrl.isUser,
        ctrl.hasAccess,
        ctrl.changeRequestId
      )
      .then(
        (response) => {
          if (response.userOrGroupId) {
            $scope.closeThisDialog(true);
            notificationService.showSuccessToast('Flow Access Saved');
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
