import angular from 'angular';
import { IStateProvider } from 'angular-ui-router';

angular.module('flowingly.runner.publicmap').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider): void {
  $stateProvider.state('app.publicmap', {
    url: '/map/{id}',
    params: {
      title: 'Map'
    },
    controller: 'runnerPublicMapController',
    controllerAs: '$ctrl',
    templateUrl: 'Client/runner.publicmap/runner.publicmap.tmpl.html'
  });
}
