import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

class StepTasksListController implements angular.IController {
  public static $inject = [
    'pubsubService',
    'runnerFlowsFormatter',
    'sessionService'
  ];

  public constructor(
    private pubsubService: SharedAngular.PubSubService,
    private runnerFlowsFormatter: RunnerFlowsFormatterService,
    private sessionService: SharedAngular.SessionService
  ) {}

  // Bindings.
  public flow: any = {};
  public onStepTaskClicked;

  private subscriberId = 'stepTasksList';

  public $onInit(): void {
    this.initialize();
  }

  public $onDestroy(): void {
    this.pubsubService.unsubscribeAll(this.subscriberId);
  }

  public stepClicked(stepTask) {
    // Call a function on the parent component with the step task that was selected.
    this.onStepTaskClicked({ stepTask });
  }

  public removeStepTask(data) {
    if (data != null && data.stepTaskId != null && this.flow.Steps) {
      this.flow.Steps.forEach((step) => {
        if (step && step.StepTasks) {
          const stepTaskIndex = step.StepTasks.findIndex(
            (x) => x.Id === data.stepTaskId
          );

          // If step task is found then we need to remove it from the array.
          if (stepTaskIndex !== -1) {
            // Remove the stepTask from step tasks array.
            step.StepTasks.splice(stepTaskIndex, 1);
            // [FLOW-5585] Reorder Step Tasks
            step.StepTasks = this.runnerFlowsFormatter.reorderStepTasks(
              step.StepTasks
            );
          }
        }
      });
    }
  }

  private initialize() {
    // Handle new step tasks created in the UI.
    this.pubsubService.subscribe(
      'STEP_TASK_CREATED',
      (event, stepTask) => {
        if (stepTask != null && this.flow.Steps) {
          const step = this.flow.Steps.find(
            (s) => s.Id === stepTask.ParentStepId
          );
          if (step) {
            if (step.StepTasks == null) {
              step.StepTasks = [];
            }

            // Need to add display properties to the step task.
            this.runnerFlowsFormatter.formatStepTask(stepTask);

            step.StepTasks.push(stepTask);
            // [FLOW-5585] Reorder Step Tasks
            step.StepTasks = this.runnerFlowsFormatter.reorderStepTasks(
              step.StepTasks
            );

            const currentUserId = this.sessionService.getUser().id;

            if (stepTask.StartedByUserId === currentUserId) {
              // Trigger the step task clicked event to select
              // the new step task and show its details for the user
              // who created it.
              this.stepClicked(stepTask);
            }
          }
        }
      },
      this.subscriberId
    );

    // Handle updates to existing step tasks by the UI.
    this.pubsubService.subscribe(
      'STEP_TASK_UPDATED',
      (event, stepTask) => {
        if (stepTask != null && this.flow.Steps) {
          const step = this.flow.Steps.find(
            (s) => s.Id === stepTask.ParentStepId
          );
          if (step) {
            if (step.StepTasks == null) {
              step.StepTasks = [];
            }

            // Need to add display properties to the step task.
            this.runnerFlowsFormatter.formatStepTask(stepTask);

            const stepTaskIndex = step.StepTasks.findIndex(
              (st) => st.Id === stepTask.Id
            );
            if (stepTaskIndex >= 0) {
              step.StepTasks[stepTaskIndex] = stepTask;
            } else {
              // This should not happen as it means something is out of sync
              // but lets still handle it gracefully if it does.
              step.StepTasks.push(stepTask);
            }
            // [FLOW-5585] Reorder Step Tasks
            step.StepTasks = this.runnerFlowsFormatter.reorderStepTasks(
              step.StepTasks
            );
          }
        }
      },
      this.subscriberId
    );

    // Handle step tasks being canceled.
    this.pubsubService.subscribe(
      'STEP_TASK_CANCELLED',
      (event, data) => {
        this.removeStepTask(data);
      },
      this.subscriberId
    );
  }
}
class StepTaskList implements angular.IComponentOptions {
  public bindings = {
    flow: '<',
    onStepTaskClicked: '&',
    parentStep: '<'
  };

  public templateUrl =
    'Client/runner.flow/runner.flow.step.task/runner.flow.step.task.list.component.tmpl.html';

  public controller = StepTasksListController;
}

angular
  .module('flowingly.runner.flow')
  .component('stepTaskList', new StepTaskList());
