/**
 * @ngdoc service
 * @name runnerStartFlowsFormatter
 * @module flowingly.runner.services
 *
 * @description Service repsonsible for formatting flows available (can start)
 *
 *
 * ###API
 * * groupFlowModelsByCategory - format the server response into something the client side can consume.
 */
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export class RunnerStartFlowsFormatter {
  public static ID = 'runnerStartFlowsFormatter';
  static $inject = ['APP_CONFIG'];

  constructor(private APP_CONFIG: SharedAngular.APP_CONFIG) {}

  public groupFlowModelsByCategory(flowModels) {
    if (flowModels.length === 0) {
      return flowModels;
    }
    if (this.APP_CONFIG.enableSubCategories) {
      return this.doGroupingForNestedCategories(flowModels);
    } else {
      return this.doGrouping(flowModels);
    }
  }

  private doGrouping(flowModels) {
    const categories = [];
    flowModels.forEach((flowModel) => {
      const category = categories.find((c) => c.name === flowModel.category);
      if (category == undefined) {
        categories.push({
          name: flowModel.category,
          id: flowModel.categoryId,
          flowModels: [flowModel]
        });
      } else {
        category.flowModels.push(flowModel);
      }
    });
    return categories;
  }

  private doGroupingForNestedCategories(flowModels) {
    const topLevelCategories = new Map();
    const noCategoryId = 0;
    flowModels.forEach((flowModel) => {
      let categoryFromTree = flowModel.categoryTree;
      if (categoryFromTree === null) {
        if (!topLevelCategories.has(noCategoryId)) {
          const category = {
            name: 'No Category',
            id: noCategoryId,
            flowModels: [],
            subCategories: []
          };
          topLevelCategories.set(noCategoryId, category);
        }
        topLevelCategories.get(noCategoryId).flowModels.push(flowModel);
        return;
      }
      if (!topLevelCategories.has(categoryFromTree.categoryId)) {
        const category = {
          name: categoryFromTree.categoryName,
          id: categoryFromTree.categoryId,
          flowModels: [],
          subCategories: []
        };
        topLevelCategories.set(categoryFromTree.categoryId, category);
      }
      let parentCategory = topLevelCategories.get(categoryFromTree.categoryId);
      categoryFromTree = categoryFromTree.category;
      while (categoryFromTree) {
        const existingCategory = parentCategory.subCategories.find(
          (c) => c.id === categoryFromTree.categoryId
        );
        if (existingCategory) {
          parentCategory = existingCategory;
        } else {
          const category = {
            name: categoryFromTree.categoryName,
            id: categoryFromTree.categoryId,
            flowModels: [],
            subCategories: []
          };
          parentCategory.subCategories.push(category);
          parentCategory.subCategories = orderItemsAlphabetically(
            parentCategory.subCategories
          );
          parentCategory = category;
        }
        categoryFromTree = categoryFromTree.category;
      }
      parentCategory.flowModels.push(flowModel);
    });
    return orderItemsAlphabetically(Array.from(topLevelCategories.values()));
  }
}
function orderItemsAlphabetically(categories) {
  return categories.sort(function (a, b) {
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
}

angular
  .module('flowingly.runner.services')
  .service(RunnerStartFlowsFormatter.ID, RunnerStartFlowsFormatter);

export type RunnerStartFlowsFormatterType = InstanceType<
  typeof RunnerStartFlowsFormatter
>;
