/**
 * @ngdoc directive
 * @name flowsTodoGroup
 * @module flowingly.runner.flows.todo
 * @description  This component is used to display a single group of flows.
 *               Will display the group header and list of flows.
 * @usage
 * ```
     <flows-todo-group ng-repeat="group in $ctrl.groups" group="group" groups="$ctrl.groups"></flows-todo-group>
 * ``` 
 * ### Notes
 *  Module structure:
 *    route (app.runner.flowstodo) --> runner.flows.todo.tmpl
 *      flowsTodoGroups                  // Handles the list of groups
 *        flowsTodoGroup                 // Handles each group (which contains a group.name and the group.flows)
 *          flowsTodoGroupHeader          // Handles the group header for a group of flows
 *          flowsTodoGroupFlows            // Handles the flow list for a group
 *              --> runner.flows.todo.group.flows.tmpl.html //uses the flow-list directive to present all the flows in the group
 * ### Properties
 * #### Inputs
 * * group: the group data (including the flows in the group) to display (JSON)
 * * groups: lsit of all groups
 * * isMobile: show mobile view if set
 * * userId: userid of the logged in user (SMEG)
 * 
 * #### Events (Outputs)
 * * onStartMethod: method to call when flow header clicked
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flows.todo').component('flowsTodoGroup', {
  bindings: {
    group: '<', //this category
    groups: '<', //all categories
    isMobile: '<', //show mobile view if set
    userId: '<'
  },
  templateUrl: 'Client/runner.flows.todo/runner.flows.todo.group.tmpl.html'
});
