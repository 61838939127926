import React, { useState } from 'react';
import { Box, Dialog, TextField } from '@mui/material';
import ModalFooter from '../Footer/Footer';
import ModalHeader from '../Header/Header';

type props = {
  isOpen: boolean;
  title: string;
  subject: string;
  closeModal: () => void;
  deleteAction: () => void;
  confirmationWord: string;
};

function DeleteModal(props: props) {
  const {
    isOpen = false,
    title,
    subject,
    closeModal,
    deleteAction,
    confirmationWord = 'delete'
  } = props;

  const [deleteText, setDeleteText] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteText(event.target.value);
  };

  const isDeleteDisabled = () => {
    return !(
      deleteText.toLowerCase().trim() === confirmationWord.toLowerCase()
    );
  };

  return (
    <Dialog open={isOpen} PaperProps={{ className: 'delete-dialog' }}>
      <Box>
        <ModalHeader title={title}></ModalHeader>
      </Box>
      <div>
        <fieldset
          className="delete-modal-fieldset"
          style={{ padding: '15px 15px 30px' }}
        >
          <legend className="delete-modal-legend">
            <b>Warning</b>
          </legend>
          <div>
            You are about to delete <b>{subject}</b>.
          </div>
          <div>This cannot be undone!</div>
          <br />
          <div>
            Type <b>{confirmationWord.toUpperCase()}</b> to continue
          </div>
          <TextField
            id="delete-modal"
            className="delete-modal-textfield"
            variant="standard"
            value={deleteText}
            onChange={handleInputChange}
          />
        </fieldset>
      </div>
      <ModalFooter
        actionButtonText="Delete"
        actionButtonType="Danger"
        onCancelClick={closeModal}
        onActionClick={deleteAction}
        isActionDisabled={isDeleteDisabled()}
      ></ModalFooter>
    </Dialog>
  );
}

export default DeleteModal;
