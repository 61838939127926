/**
 * @ngdoc directive
 * @name flowSidemenuSubitem
 * @module flowingly.directives.sidemenu
 * @description A directive that represents a sub item of side menu item
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Sidemenu+Directive
 * 
   ####Attributes
   * subitem : The subitem to render
 * 
 * @usage
 * ```
        <flow-sidemenu-subitem ng-repeat="subitem in item.subMenu " subitem="::subitem"></flow-sidemenu-subitem>
 * ```
 */
import angular from 'angular';

angular
  .module('flowingly.directives.sidemenu')
  .directive('flowSidemenuSubitem', [
    '$templateCache',
    function ($templateCache: angular.ITemplateCacheService) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          subitem: '='
        },
        controller: [
          '$scope',
          '$rootScope',
          '$timeout',
          function (
            $scope: angular.IScope,
            $rootScope: angular.IRootScopeService,
            $timeout: angular.ITimeoutService
          ) {
            //This method is called when the user clicks on an item in the dropdown and type is item-with-command
            $scope.raiseEvent = function () {
              $timeout(function () {
                // TODO: review use of $broadcast
                $rootScope.$broadcast(
                  $scope.subitem.event,
                  $scope.subitem.eventData
                );
              }, 0);
            };
            //This method is called when the user clicks on an item in the dropdown and type is item-with-action
            $scope.action = function () {
              $timeout(function () {
                $scope.subitem.action($scope.subitem.param);
              }, 0);
            };
          }
        ],
        template: '<li ng-include="itemTemplate"></li>',
        link: function ($scope) {
          $scope.itemTemplate = $templateCache.get(
            'sidemenu-' + $scope.subitem.type + '.tmpl.html'
          );
        }
      };
    }
  ]);
