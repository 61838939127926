import IGroupDetail from '@Shared.Angular/@types/core/contracts/queryModel/group/groupDetail';
import TeamEmailTarget from '@Shared.Angular/@types/core/contracts/queryModel/group/teamEmailTarget';
import IUserDetail from '@Shared.Angular/@types/core/contracts/queryModel/user/userDetail';
import { Services } from '@Shared.Angular/@types/services';
import { IController, IScope } from 'angular';

/**
 * @ngdoc type
 * @name TeamDetailController
 * @module flowingly.runner.setup
 * @description This controller is used to control add/edit team modal dialog
 */
class TeamDialogController implements IController {
  private team: IGroupDetail;
  private isNewTeam: boolean;
  private emailValidationPattern: RegExp;
  private isFormReadonly: boolean;
  private modalTitle: string;
  private selectedList: IUserDetail[];
  private currentForm: angular.IFormController;
  private errorOnSubmit: boolean;
  private errorMessagesOnSubmit: string;
  private emailTargetUsers = TeamEmailTarget.Users;
  private emailTargetTeam = TeamEmailTarget.Team;

  constructor(
    private $scope: IScope,
    private APP_CONFIG: Services.APP_CONFIG,
    private teamApiService: Services.TeamApiService,
    private notificationService: Services.NotificationService,
    private validationService: Services.ValidationService,
    private flowinglyConstants: Services.FlowinglyConstants
  ) {
    this.team = {
      id: null,
      businessId: null,
      name: '',
      email: '',
      emailTarget: TeamEmailTarget.Users,
      ppoc: '',
      users: [],
      usersCount: 0,
      isPrivate: false
    };

    angular.extend(this.team, $scope.ngDialogData.team);

    this.isNewTeam = this.team.id == undefined;
    this.emailValidationPattern =
      this.validationService.getEmailValidationPattern();
    this.isFormReadonly = !!APP_CONFIG.disableAdminTeamEdit;
    this.modalTitle = this.isNewTeam
      ? 'Add'
      : this.isFormReadonly
      ? 'View'
      : 'Edit';
    this.selectedList = [];
    if (this.team.id) {
      teamApiService.getUsersInTeam(this.team.id).then((users) => {
        this.team.users = users;
        this.selectedList = this.decorateUserForSmartList(this.team.users);
      });
    }
  }

  editTeam = () => {
    if (this.APP_CONFIG.disableAdminTeamEdit) {
      return;
    }

    this.team.users = this.selectedList; // Recap with latest selected users list

    this.teamApiService.saveTeam(this.team).then(
      () => {
        this.$scope.closeThisDialog(true);
        this.notificationService.showSuccessToast('Team saved');
      },
      (response) => {
        this.currentForm.$invalid = true;
        this.errorOnSubmit = true;
        this.errorMessagesOnSubmit =
          response.data && response.data.message
            ? response.data.message
            : response.statusText;
        this.notificationService.showErrorToast('Error while saving Team');
      }
    );
  };

  cancelEditTeam = () => {
    this.$scope.closeThisDialog();
  };

  decorateUserForSmartList = (users) => {
    const usersWithDisplayName = [];

    users.forEach((u) => {
      u.displayName = u.fullName;
      u.searchEntityType = this.flowinglyConstants.searchEntityType.USER;
      usersWithDisplayName.push(u);
    });

    return usersWithDisplayName;
  };
}

TeamDialogController.$inject = [
  '$scope',
  'APP_CONFIG',
  'teamApiService',
  'notificationService',
  'validationService',
  'flowinglyConstants'
];
angular
  .module('flowingly.runner.setup')
  .controller('teamDialogController', TeamDialogController);
