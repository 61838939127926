'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.maintenance').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.maintenance.emails', {
    url: '/emails',
    params: { title: 'Maintenance - Emails' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/emails/runner.maintenance.emails.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          'maintenanceService',
          'authService',
          'dialogService',
          'notificationService',
          function (
            maintenanceService: MaintenanceService,
            authService: AuthService,
            dialogService: SharedAngular.DialogService,
            notificationService: SharedAngular.NotificationService
          ) {
            const $ctrl = this;

            $ctrl.emailMethods = [];
            $ctrl.disableSendButton = false;

            authService.getUserDeferred().then((user) => {
              maintenanceService.getEmailMethods().then((methods) => {
                $ctrl.emailMethods = methods;
              });
            });

            $ctrl.sendEmail = function () {
              $ctrl.disableSendButton = true;
              maintenanceService
                .sendEmail($ctrl.selectedEmailMethod)
                .then((response) => {
                  $ctrl.disableSendButton = false;
                  if (response.success === false) {
                    return;
                  }
                  notificationService.showSuccessToast(
                    `Invoked ${$ctrl.selectedEmailMethod}`,
                    4000
                  );
                });
            };
          }
        ]
      }
    }
  });
}
