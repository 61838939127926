'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { IStateProvider } from 'angular-ui-router';
///
/// This state displays user profile
///

// Register the application's routes configuration.
angular.module('flowingly.runner.user.profile').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.profile', {
    url: 'profile',
    controller: [
      '$scope',
      'accountApiService',
      'lodashService',
      'pubsubService',
      function (
        $scope: IScope,
        accountApiService: SharedAngular.AccountApiService,
        lodashService: Lodash,
        pubsubService: SharedAngular.PubSubService
      ) {
        const ctrl = this;
        const subscriberId = 'app.runner.profile';

        ctrl.user = tranform(accountApiService.userProfile);

        pubsubService.subscribe(
          'SIGNALR_USER_PROFILE_UPDATED',
          updateUserProfile,
          subscriberId
        );

        function tranform(json) {
          return lodashService.mapKeys(json, function (v, k) {
            return lodashService.camelCase(k);
          });
        }

        function updateUserProfile() {
          accountApiService.getUserProfile().then(function (response) {
            ctrl.user = tranform(angular.extend(ctrl.user, response));
          });
        }

        $scope.$on('$destroy', () => {
          pubsubService.unsubscribeAll(subscriberId);
        });
      }
    ],
    controllerAs: 'ctrl',
    resolve: {
      userProfile: [
        'accountApiService',
        function (accountApiService: SharedAngular.AccountApiService) {
          return accountApiService.getUserProfile();
        }
      ]
    },
    template: '<user-profile user="ctrl.user"></user-profile>'
  });
}
