import React, { ChangeEvent, useEffect, useState } from 'react';
import ModalHeader from '@Client/runner.shared/modal/Header/Header';
import ModalFooter from '@Client/runner.shared/modal/Footer/Footer';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import {
  TreeView,
  TreeViewItemClickEvent
} from '@progress/kendo-react-treeview';
import ICategoryDetail from '@Shared.Angular/@types/core/contracts/queryModel/category/categoryDetail';
import { Guid } from '@Shared.Angular/@types/guid';
import SelectUser from '../../SelectUser/SelectUser';

export type Props = {
  categoryId: string;
  categoryName: string;
  description: string;
  approverId?: Guid;
  parentId: string;
  closeModal: () => void;
  fetchSubCategories: () => Promise<void>;
};

type TreeViewItem = {
  id: Guid;
  text: string;
  expanded: boolean;
  selected: boolean;
  items: TreeViewItem[];
};

const CategoryEditModal = (props: Props) => {
  const {
    categoryName,
    description,
    approverId,
    closeModal,
    fetchSubCategories
  } = props;

  const [categoryNameInput, setCategoryNameInput] = useState(categoryName);
  const [descriptionInput, setDescriptionInput] = useState(description);
  const [approverIdInput, setApproverIdInput] = useState(approverId);
  const [categories, setCategories] = useState<TreeViewItem[]>([]);
  const [parent, setParent] = useState<ICategoryDetail>({
    id: props.parentId,
    name: !props.parentId ? 'Root' : ''
  });
  const [showApprover, setShowApprover] = useState(false);
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');

  const notificationService = useService<SharedAngular.NotificationService>(
    'notificationService'
  );

  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');

  const categoryApiService =
    useService<SharedAngular.CategoryApiService>('categoryApiService');

  const appConfig = useService<SharedAngular.APP_CONFIG>('APP_CONFIG');

  const permissionCodes = [flowinglyConstants.permissions.FLOWMODEL_PUBLISH];

  useEffect(() => {
    setShowApprover(appConfig.enableProcessApprovals);
    categoryApiService.getCategories().then((results) => {
      const treeViewCategories = results
        .map((category) =>
          mapCategoryToTreeItem(category, props.categoryId, props.parentId)
        )
        .filter((category) => category !== null);

      setCategories([
        {
          id: null,
          text: 'Root',
          expanded: true,
          selected: !props.parentId,
          items: treeViewCategories
        }
      ]);
    });
  }, []);

  const mapCategoryToTreeItem = (
    category: ICategoryDetail,
    targetCategoryId: Guid,
    targetParentId: Guid
  ) => {
    if (category.id === targetParentId) {
      setParent({
        id: category.id,
        name: category.name
      });
    }
    if (category.id === targetCategoryId) {
      return null;
    }
    const treeItem: TreeViewItem = {
      id: category.id,
      text: category.name,
      items: category.subCategories
        .map((child) =>
          mapCategoryToTreeItem(child, targetCategoryId, targetParentId)
        )
        .filter((child) => child !== null),
      selected: category.id === targetParentId,
      expanded: false
    };
    treeItem.expanded = treeItem.items.some(
      (item) => item.selected || item.expanded
    );
    return treeItem;
  };

  const setParentCategory = (event: TreeViewItemClickEvent) => {
    setParent({
      id: event.item.id,
      name: event.item.text
    });
  };

  const save = async () => {
    if (!categoryNameInput) {
      return;
    }
    const editedCategory: ICategoryDetail = {
      id: props.categoryId,
      name: categoryNameInput,
      description: descriptionInput,
      approverId: approverIdInput,
      parentId: parent.id
    };
    try {
      appInsightsService.startEventTimer('categoryEdited');
      const response = await categoryApiService.editCategory(editedCategory);
      if (response.data.success) {
        closeModal();
        await fetchSubCategories();
        notificationService.showSuccessToast('Category updated successfully');
      } else {
        notificationService.showErrorToast(response.data.errorMessage);
      }
    } catch (err) {
      console.error(err);
      notificationService.showErrorToast('Error while updating Category');
    }
  };

  const handleCategoryNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryNameInput(event.target.value);
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescriptionInput(event.target.value);
  };

  const handleApproverChange = (userId?: Guid) => {
    setApproverIdInput(userId);
  };

  return (
    <div id="category-edit-modal">
      <ModalHeader
        title={props.parentId ? 'Edit Sub Category' : 'Edit Category'}
      ></ModalHeader>
      <div className="input-field">
        <label htmlFor="category-name">Category Name</label>
        <input
          id="category-name"
          type="text"
          value={categoryNameInput}
          onChange={handleCategoryNameChange}
          required
        />
        {!categoryNameInput && (
          <div className="error-msg">Please enter a Category name</div>
        )}
      </div>
      <div className="input-field">
        <label htmlFor="category-description">
          Description <span className="optional"></span>
        </label>
        <input
          id="category-description"
          type="text"
          value={descriptionInput}
          onChange={handleDescriptionChange}
        />
      </div>
      {showApprover && (
        <div className="input-field">
          <label htmlFor="category-approver">
            Approver
            <i
              className="fa-light fa-circle-info ml-6 mr-1"
              title="User needs to have 'Flow Models - Publish All' permission to be listed here"
            ></i>
            <span className="optional"></span>
          </label>
          <div className="select-approver-container">
            <span id="category-approver" className="select-approver">
              <SelectUser
                permissionSecureCodes={permissionCodes}
                selectedUserId={approverIdInput}
                onUserSelect={handleApproverChange}
              />
            </span>
          </div>
        </div>
      )}
      {appConfig.maxSubCategories > 1 && (
        <div>
          <div className="input-field">
            <label>
              Parent Category
              <i
                className="fa-light fa-circle-info ml-6"
                title="The Parent Category will contain this Category"
              ></i>
            </label>
            <input
              id="category-parent"
              value={parent.name}
              type="text"
              disabled={true}
            />
          </div>
          <TreeView
            data={categories}
            expandIcons={true}
            onExpandChange={function (event) {
              event.item.expanded = !event.item.expanded;
              setParentCategory(event);
            }}
            onItemClick={setParentCategory}
          />
        </div>
      )}
      <ModalFooter
        actionButtonText="Save"
        actionButtonType="Success"
        onCancelClick={closeModal}
        onActionClick={save}
      />
    </div>
  );
};

export default CategoryEditModal;
