'use strict';
import angular from 'angular';

// Register the application's routes configuration.
angular.module('flowingly.runner.login').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.login', {
    url: '/login?redirectUrl&forceRelogin',
    controller: 'runnerLoginController',
    controllerAs: 'ctrl',
    templateUrl: 'Client/runner.login/login.tmpl.html'
  });
}
