import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export class RunnerPublicMapService {
    constructor(
      private $window: angular.IWindowService,
      private runnerPublicFormApiService: RunnerPublicFormApiService,
      private notificationService: SharedAngular.NotificationService,
      private fileService: SharedAngular.FileService
    ) {}
    
  }
  
  angular
    .module('flowingly.runner.services')
    .factory('runnerPublicMapService', [
      '$window',
      'runnerPublicFormApiService',
      'notificationService',
      'fileService',
      ($window, runnerPublicFormApiService, notificationService, fileService) =>
        new RunnerPublicMapService(
          $window,
          runnerPublicFormApiService,
          notificationService,
          fileService
        )
    ]);
  
  export type RunnerPublicMapServiceType = InstanceType<
    typeof RunnerPublicMapService
  >;