/**
 * @name userMenuSearch
 * @module flowingly.user.menu
 * @description display search on user menu
 * ### Notes
 * 
 * @usage
 * ```
    <user-menu-search></user-menu-search>
 * ```
 */
import angular from 'angular';

angular.module('flowingly.user.menu').component('userMenuSearch', {
  bindings: {
    link: '<'
  },
  template: `
    <div class="user-menu-search">
        <i class="fa-light fa-magnifying-glass mr-5" ng-click="$ctrl.searchForTerm()"></i>
        <input type="text" ng-model="$ctrl.searchTerm" ng-keypress="$ctrl.onKeyPressed($event)" class="text left-align" placeholder="Search in progress workflows by flow ID or subject" flowingly-xss-validation />
        <i class="fas fa-times" ng-click="$ctrl.resetSearchInput()" ng-if="$ctrl.searchTerm && $ctrl.searchTerm !==''" title="Clear"></i>
    </div>
`,
  controller: [
    '$state',
    '$location',
    '$window',
    function ($state: angular.ui.IStateService, $location, $window) {
      const $ctrl = this;

      $ctrl.searchForTerm = searchForTerm;
      $ctrl.onKeyPressed = onKeyPressed;
      $ctrl.resetSearchInput = () => {
        if (!$ctrl.searchTerm) return;
        navigateBack();
      };

      const navigateBack = () => {
        if ($location.url().includes('/search?term=')) {
          $window.history.back();
          setTimeout(navigateBack, 10);
        } else {
          $ctrl.searchTerm = '';
        }
      };

      function onKeyPressed(keyEvent) {
        if (keyEvent.which === 13) {
          searchForTerm();
        }
        keyEvent.stopImmediatePropagation();
      }

      function searchForTerm() {
        if ($ctrl.searchTerm) {
          $state.go('app.runner.search', { term: $ctrl.searchTerm });
        }
      }
    }
  ]
});
