import * as angular from 'angular';
import { DashboardFilterItem } from './models/runner.dashboard.filter.item.model';

export class DashboardFilterComponent implements angular.IComponentOptions {
  public controller = DashboardFilterController;
  public templateUrl =
    'Client/runner.dashboard.filter/runner.dashboard.filter.tmpl.html';
  public bindings = {
    filterItems: '<',
    selectedFilterItem: '<',
    onSelect: '&'
  };
}

export class DashboardFilterController implements angular.IController {
  public filterItems: DashboardFilterItem[] = [];
  public selectedFilterItem: DashboardFilterItem;
  public onSelect: (arg: { filterItem: DashboardFilterItem }) => void;

  public select(filterItem: DashboardFilterItem) {
    this.selectedFilterItem = filterItem;
    this.onSelect({ filterItem: filterItem });
  }
}

DashboardFilterController.$inject = [];
angular
  .module('flowingly.runner.dashboard.filter')
  .component('dashboardFilter', new DashboardFilterComponent());
