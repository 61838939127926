import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IComponentController, IWindowService } from 'angular';

class ProcessMapTabStepFormController implements IComponentController {
  static $inject = ['APP_CONFIG', '$window', 'sessionService'];

  selectedNode: any;

  constructor(
    private APP_CONFIG: AppConfig,
    private $window: IWindowService,
    private sessionService: SharedAngular.SessionService
  ) {
    this.user = this.sessionService.getUser();
  }

  private FIXED_ELEMS_TOTAL_HEIGHT = 195;
  private user: any;

  public $onInit() {
    this.$window.addEventListener('resize', this.setDynamicMaxHeightResize);
    this.setDynamicMaxHeight();
  }

  public $onDestroy() {
    this.$window.removeEventListener('resize', this.setDynamicMaxHeight);
  }
  public $onChanges() {
    if (this.selectedNode) {
      this.formatFields(this.selectedNode);
    }
  }

  private setDynamicMaxHeight() {
    const dynamicHeight =
      this.$window.innerHeight - this.FIXED_ELEMS_TOTAL_HEIGHT + 'px';
    $('.pmap-step-form-tab').css('height', dynamicHeight);
  }

  private setDynamicMaxHeightResize() {
    const FIXED_ELEMS_TOTAL_HEIGHT = 249;
    const dynamicHeight = window.innerHeight - FIXED_ELEMS_TOTAL_HEIGHT;

    $('.pmap-step-form-tab').css('height', dynamicHeight + 'px');
    if (dynamicHeight >= 500) {
      $('.pmap-step-form-tab').parent().css('overflow', 'hidden');
    }
  }

  private formatFields(selnode) {
    selnode.fields?.forEach((field) => {
      if (field.type === 'attachdocument') {
        field.attachDocuments.forEach((attachment) => {
          attachment.downloadLink = this.generateDownloadLink(
            attachment.fileId
          );
        });
      }
    });
  }

  private generateDownloadLink(fileId) {
    let downloadUrl = `${this.APP_CONFIG.apiBaseUrl}files/download/${fileId}`;
    if (this.sessionService.getToken() == null) {
      downloadUrl = `${this.APP_CONFIG.apiBaseUrl}files/downloadpublic/${fileId}`;
    }
    return fileId !== undefined ? downloadUrl : undefined;
  }
}

export class ProcessMapTabStepForm implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      selectedNode: '<'
    };

    this.templateUrl =
      'Client/runner.processmaps/tab/right-pane/runner.processmap.tab.step-form.tmpl.html';
  }

  controller = ProcessMapTabStepFormController;
}

angular
  .module('flowingly.runner.processmap')
  .component('processmapTabStepForm', new ProcessMapTabStepForm());
