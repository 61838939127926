import { DeleteEntityTypes } from '@Shared.Angular/flowingly.services/flowingly.constants';

/**
 * @ngdoc directive
 * @name runnerSetupDeleteWarnings
 * @module flowingly.runner.setup
 * @description  This component is used to search through entities (to choose one to replace entity being deleted)
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 *
 * 
 * ### Properties
 * #### Inputs
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .component('runnerSetupDeleteSearch', {
      bindings: {
        entityToDelete: '<',
        entityName: '<',
        entityType: '<',
        categories: '<',
        teams: '<',
        users: '<',
        managers: '<',
        reassignActorRequired: '<',
        onActorChosen: '&',
        reassignCustomDatabaseRequired: '<'
      },
      templateUrl:
        'Client/runner.setup/runner.setup.delete/runner.setup.delete.search.tmpl.html',
      controller: [
        '$scope',
        'lodashService',
        function ($scope, lodashService) {
          const { get } = lodashService;
          var $ctrl = this;

          $ctrl.entityTypeEnums = {
            userEntityType: DeleteEntityTypes.userEntityType,
            teamEntityType: DeleteEntityTypes.teamEntityType,
            categoryEntityType: DeleteEntityTypes.categoryEntityType
          };

          $ctrl.actorTypeEnums = {
            user: 1,
            team: 2,
            category: 3
          };

          $ctrl.actorType = $ctrl.actorTypeEnums.user;
          $ctrl.onRadioChange = onRadioChange;

          this.$onInit = function () {
            initialiseRadio();

            $scope.$watch(
              '$ctrl.selectedActor.id',
              function (newValue, oldValue) {
                //Sometimes newValue and oldValue are null and undefined and we don't want to raise ActorChange event in that senario
                //which is the reason intentionally type comparision has been ignored.
                if (newValue == oldValue) {
                  return;
                }
                raiseActorChange();
              }
            );

            $scope.$watch(
              '$ctrl.selectedManager.id',
              function (newValue, oldValue) {
                if (newValue == oldValue) {
                  return;
                }
                raiseActorChange();
              }
            );

            $scope.$watch(
              '$ctrl.selectedCustomDatabase.id',
              function (newValue, oldValue) {
                if (newValue == oldValue) {
                  return;
                }
                raiseActorChange();
              }
            );

            $scope.$watch(
              '$ctrl.selectedCategoryId',
              function (newValue, oldValue) {
                if (newValue == oldValue) {
                  return;
                }
                raiseActorChange();
              }
            );
          };

          function onRadioChange() {
            $ctrl.selectedActor = undefined;
            $ctrl.actorType = parseInt($ctrl.actorType);
          }

          /// PRIVATE
          function initialiseRadio() {
            switch ($ctrl.entityType) {
              case $ctrl.entityTypeEnums.userEntityType:
                $ctrl.actorType = $ctrl.actorTypeEnums.user;
                break;

              case $ctrl.entityTypeEnums.teamEntityType:
                if ($ctrl.teams.length > 0) {
                  $ctrl.actorType = $ctrl.actorTypeEnums.team;
                } else {
                  //initialise user radio
                  $ctrl.actorType = $ctrl.actorTypeEnums.user;
                }
                break;

              case $ctrl.entityTypeEnums.categoryEntityType:
                $ctrl.actorType = $ctrl.actorTypeEnums.category;
                break;
            }
          }

          function raiseActorChange() {
            switch ($ctrl.entityType) {
              case $ctrl.entityTypeEnums.userEntityType:
                $ctrl.onActorChosen({
                  actors: {
                    actor: get($ctrl.selectedActor, 'id'),
                    manager: get($ctrl.selectedManager, 'id'),
                    customdatabaseactor: get($ctrl.selectedCustomDatabase, 'id')
                  }
                });
                break;

              case $ctrl.entityTypeEnums.categoryEntityType:
                $ctrl.onActorChosen({
                  actors: { actor: $ctrl.selectedCategoryId }
                });
                break;

              default:
                $ctrl.onActorChosen({
                  actors: { actor: get($ctrl.selectedActor, 'id') }
                });
                break;
            }
          }
        }
      ]
    });
})();
