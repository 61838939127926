import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import { STATE_MAPPINGS } from '@Shared.Angular/@types/stateMappings';

class RunnerProcessMapsV2Controller {
  allowProcessMap: boolean;
  hasLoaded: boolean;
  categoriesCount: number;
  helpUri: string;
  backFromState: string;

  constructor(
    private $scope: IScope,
    private $state: IStateService,
    private pubsubService: SharedAngular.PubSubService,
    private APP_CONFIG: SharedAngular.APP_CONFIG,
    private brandingService: SharedAngular.BrandingService
  ) {
    this.allowProcessMap = this.APP_CONFIG.allowProcessMap;
    this.helpUri = this.brandingService.getBrandedHelpUri(
      'https://help.flowingly.net/process-mapping/viewing-a-process-map'
    );
    this.backFromState = '';
    if (!this.allowProcessMap)
      this.$state.go(STATE_MAPPINGS[this.APP_CONFIG.defaultHomePage]);
  }

  $onInit() {
    this.backFromState = this.$state.params.backFromState;

    const processMapSubscriberId = 'flowingly.runner.processMapV2Controller';
    this.pubsubService.subscribe(
      'PROCESSMAPV2_LOADED',
      this.onProcessMapLoaded.bind(this),
      processMapSubscriberId
    );

    this.pubsubService.subscribe(
      'PROCESSMAPV2_CLICKED',
      this.onProcessMapClicked.bind(this),
      processMapSubscriberId
    );
    this.$scope.$on('$destroy', () => {
      this.pubsubService.unsubscribeAll(processMapSubscriberId);
    });
  }
  onProcessMapLoaded(event: Event, data) {
    this.hasLoaded = data.loaded;
    this.categoriesCount = data.count;
  }
  onProcessMapClicked(event: Event, processMapId: string) {
    this.$state.go('app.runner.processmapviewv2', { processMapId }, {});
  }
}

angular
  .module('flowingly.runner.processmapsv2')
  .controller('runnerProcessMapsV2Controller', [
    '$scope',
    '$state',
    'pubsubService',
    'APP_CONFIG',
    'brandingService',
    function ($scope, $state, pubsubService, APP_CONFIG, brandingService) {
      return new RunnerProcessMapsV2Controller(
        $scope,
        $state,
        pubsubService,
        APP_CONFIG,
        brandingService
      );
    }
  ]);
