import React, {
  useState,
  useEffect,
  useRef,
  ClipboardEventHandler
} from 'react';
import Box from '@mui/material/Box';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';
import { IComment } from '@Shared.Angular/interfaces/comment.interface';

type Props = {
  comments: IComment[];
};

const CommentHistory = ({ comments }: Props) => {
  const [processedComments, setProcessedComments] = useState([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const avatarService =
    useService<SharedAngular.AvatarService>('avatarService');
  const mentionService = useService<SharedAngular.FlowinglyMentionService>(
    'flowinglyMentionService'
  );
  const fileService = useService<SharedAngular.FileService>('fileService');

  const processComment = (comment: IComment) => {
    const name = comment.commentedByName;
    const momentCommented = comment.momentCommented;
    const content = mentionService.transformMentionTag(comment.comment);
    const userInitils = avatarService.getUserInitial(comment.fullName);
    const avatarBg = avatarService.getColour(comment.fullName);
    const mentions = convertMentionsToFormat(content);

    return { name, momentCommented, content, userInitils, avatarBg, mentions };
  };

  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };

  const handleCopy: ClipboardEventHandler<HTMLDivElement> = (event) => {
    let currentElement = event.target as HTMLElement;
    while (currentElement) {
      const dataInfo = currentElement.getAttribute('data-info');
      if (dataInfo !== null) {
        event.clipboardData.setData('text/plain', dataInfo);
        event.preventDefault();
        break;
      }
      currentElement = currentElement.parentElement;
    }
  };

  const convertMentionsToFormat = (inputString: string) => {
    const mentionRegex = /<a class="mention-name">(.*?)<\/a>/g;
    const convertedString = inputString
      .replace(mentionRegex, '@[|$1|]@')
      .replace(/<br\s*\/?>/g, '\n');
    return convertedString;
  };

  const handleDownload = async (downloadLink: string) => {
    try {
      fileService.downloadByUrl(downloadLink);
    } catch (error) {
      console.error('Error while downloading the file:', error);
    }
  };

  type CustomDividerProps = {
    height: string;
    top: string;
    bottom: string;
  };

  const CustomDivider = ({ height, top, bottom }: CustomDividerProps) => (
    <div
      className="v2-feedback-commenthistory-custom-divider"
      style={{
        height: height,
        top: top,
        bottom: bottom
      }}
    />
  );

  useEffect(() => {
    const processed = comments.map((comment) => ({
      ...comment,
      ...processComment(comment)
    }));
    setProcessedComments(processed);
  }, [comments]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [processedComments]); 

  return (
    <Box ref={containerRef} className={'v2-feedback-commenthistory-container'}>
      {processedComments.map((comment, index) => (
        <Box key={index} className={'v2-feedback-commenthistory-comment-box'}>
          <Box
            component="span"
            className={'v2-feedback-commenthistory-avatar'}
            sx={{
              backgroundColor: comment.avatarBg
            }}
          >
            {comment.userInitils}
          </Box>
          {index > 0 && index < processedComments.length - 1 && (
            <CustomDivider height={`calc(100% + 30px)`} top="" bottom="" />
          )}
          {index === 0 && processedComments.length > 1 && (
            <CustomDivider height="100%" top="30px" bottom="" />
          )}
          {index == processedComments.length - 1 &&
            processedComments.length > 1 && (
              <CustomDivider height="100%" top="" bottom="50%" />
            )}
          {comment.commentType === 0 && comment.comment !== '' ? (
            <Box className={'v2-feedback-commenthistory-comment-content'}>
              <div className="v2-feedback-commenthistory-comment-header">
                <b>
                  {' '}
                  {comment.commentedByName} commented {comment.momentCommented}{' '}
                </b>
              </div>
              <div
                className="v2-feedback-commenthistory-comment-text"
                dangerouslySetInnerHTML={createMarkup(comment.content)}
                data-info={comment.mentions}
                onCopy={handleCopy}
              ></div>
              <div className="v2-feedback-commenthistory-comment-files">
                {comment.files
                  .sort((a, b) => a.filename.localeCompare(b.filename))
                  .map((file) => (
                    <div
                      key={file.fileId}
                      className="v2-feedback-commenthistory-file-item"
                    >
                      <i className="fa-light fa-paperclip fa-lg mr-3"></i>
                      <a
                        href={file.downloadLink}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(file.downloadLink);
                        }}
                      >
                        {file.filename}
                      </a>
                    </div>
                  ))}
              </div>
            </Box>
          ) : (
            comment.commentType !== 0 && (
              <div className={'flow-comment-history__comment-container'}>
                {comment.commentType === 1 && (
                  <div className="flow-comment-history__comment-content flex-column">
                    <p className="mt-10">
                      <b>
                        Task started {comment.momentCommented} by{' '}
                        {comment.commentedByName}
                      </b>
                    </p>
                  </div>
                )}

                {comment.commentType === 2 && (
                  <div className="flow-comment-history__comment-content flex-column">
                    <p className="mt-10">
                      <b>
                        Task has been marked as Done {comment.momentCommented}{' '}
                        by {comment.commentedByName}
                      </b>
                    </p>
                  </div>
                )}

                {comment.commentType === 3 && (
                  <div className="flow-comment-history__comment-content flex-column">
                    <div className="flow-comment-history__comment-header flex-column">
                      <p className={comment.comment === '' ? 'mt-10' : 'mt-0'}>
                        <b>
                          Task Approved {comment.momentCommented} by{' '}
                          {comment.commentedByName}
                        </b>
                      </p>
                    </div>
                    <div
                      className="flow-comment-history__comment-content flex-column"
                      dangerouslySetInnerHTML={{ __html: comment.comment }}
                    />
                  </div>
                )}

                {comment.commentType === 4 && (
                  <div className="flow-comment-history__comment-content flex-column">
                    <div className="flow-comment-history__comment-header flex-column">
                      <p className="mt-0">
                        <b>
                          Task Rejected {comment.momentCommented} by{' '}
                          {comment.commentedByName}
                        </b>
                      </p>
                    </div>
                    <div
                      className="flow-comment-history__comment-content flex-column"
                      dangerouslySetInnerHTML={{ __html: comment.comment }}
                    />
                  </div>
                )}
              </div>
            )
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CommentHistory;
