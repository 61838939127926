/**
 * @ngdoc directive
 * @name flowHistoryStart
 * @module flowingly.runner.flow
 * @description  This comppnent is used to display information about when the flow was started.
 * @usage
 * ```
     <flow-history-start flow="$ctrl.flow" is-mobile="$ctrl.isMobile"></flow-history-start>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 */
import angular from 'angular';
import { IFormattedFlow } from '../../interfaces/IFormattedFlow';

class FlowHistorytartController {
  public static $inject = ['runnerFlowsFormatter'];
  public flow: IFormattedFlow;
  public isMobile;
  public text = '';

  constructor(private runnerFlowsFormatter) {
    this.runnerFlowsFormatter = runnerFlowsFormatter;
  }

  $onInit() {
    this.text = this.runnerFlowsFormatter.getStartedByText(this.flow, true);
  }
}
class FlowHistorytartComponent implements angular.IComponentOptions {
  public bindings = {
    flow: '<',
    isMobile: '<' //show mobile view if set
  };
  public template = `
    <div ng-if="$ctrl.flow.FlowId != '00000000-0000-0000-0000-000000000000'" class="flow-history-start" ng-class="{'isMobile': $ctrl.isMobile}">    
        <div class="avatar-container">
            <div class="avatar"><flow-avatar-circle name="$ctrl.flow.StartedByName" avatar-url="$ctrl.flow.StartedByUserAvatarUrl"></flow-avatar-circle></div>
        </div>

        <div class ="text single-flow-view__contents" ng-class ="{'mt-10': !$ctrl.isMobile}">
            <p><b>Flow started</b></p>
            <p class ="secondary-text">{{ $ctrl.text }}</p>
            <p class ="secondary-text cc-users" ng-if="$ctrl.flow.CCUsers.length > 0">CC'd to: {{$ctrl.flow.CCUsers}}</p>
            <p class ="secondary-text flow-version-info">{{$ctrl.flow.Name}}, version: V.{{$ctrl.flow.FlowVersionNumber}}</p>
            <p class ="secondary-text pb-15"></p>
        </div>
    </div>`;
  public controller = FlowHistorytartController;
}

angular
  .module('flowingly.runner.flow')
  .component('flowHistoryStart', new FlowHistorytartComponent());
