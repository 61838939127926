/**
 * @ngdoc type
 * @name ImportFlowModelsDialogController
 * @module flowingly.runner.import
 * @description Import FlowModels exported from some other application
 */
'use strict';
import angular from 'angular';

export default class ImportFlowModelsDialogController {
  static $inject = ['$scope'];

  constructor(private $scope: IScopeNgDialog) {}

  close = () => {
    this.$scope.closeThisDialog(false);
  };
}

angular
  .module('flowingly.runner.import')
  .controller(
    'importFlowModelsDialogController',
    ImportFlowModelsDialogController
  );
