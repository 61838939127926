/**
 * @ngdoc module
 * @name flowingly.runner.services
 * @module flowingly.runner.services
 * @description This module defines reusable services shared across the Runner
 */
import angular from 'angular';

const servicesModule = angular.module('flowingly.runner.services', []);

// add flow-to-ppdf.html to templateCache
servicesModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner/services/pdf-templates/flow-to-ppdf.html',
      require('./pdf-templates/flow-to-ppdf.html').default
    );
  }
]);
