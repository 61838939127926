import angular from 'angular';

angular.module('flowingly.runner.maintenance').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.maintenance.flows', {
    url: '/flows',
    params: { title: 'Maintenance - Flows' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/flows/runner.maintenance.flows.tmpl.html',
        controllerAs: '$ctrl',
        controller: 'maintenanceFlowsController'
      }
    }
  });
}
