(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller(
      'setupUserStepTaskDialogController',
      setupUserStepTaskDialogController
    );

  setupUserStepTaskDialogController.$inject = [
    '$scope',
    'ngDialog',
    'lodashService'
  ];

  function setupUserStepTaskDialogController($scope, ngDialog, lodashService) {
    var ctrl = this;

    ctrl.approvals = []; // list of step task approvers assigned to the user
    ctrl.activities = []; // list of step tasks assigned to the user
    ctrl.userName = $scope.ngDialogData.tasksObject.userName;

    ctrl.closeDialog = closeDialog;

    init();

    ////////// PUBLIC METHODS

    function closeDialog() {
      // cancel the delete
      ngDialog.closeAll(undefined);
    }

    ///////// PRIVATE METHODS
    function init() {
      lodashService.forEach(
        $scope.ngDialogData.tasksObject.stepTasks,
        function (stepTask) {
          if (stepTask.type === 'Activity') {
            ctrl.activities.push(stepTask);
          } else if (stepTask.type === 'Approval') {
            ctrl.approvals.push(stepTask);
          }
        }
      );
    }
  }
})();
