(function () {
  'use strict';

  // Register the application's routes configuration.
  angular.module('flowingly.runner.setup').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.setup', {
      abstract: true,
      url: 'setup',
      templateUrl: 'Client/runner.setup/runner.setup.tmpl.html',
      controllerAs: '$ctrl',
      controller: [
        '$state',
        'permissionsService',
        'flowinglyConstants',
        function ($state, permissionsService, flowinglyConstants) {
          const setupAccessPermissions = [
            flowinglyConstants.permissions.SETUP_USER_ACCESS,
            flowinglyConstants.permissions.SETUP_TEAM_ACCESS,
            flowinglyConstants.permissions.SETUP_DATABASE_ACCESS,
            flowinglyConstants.permissions.SETUP_CATEGORY_ACCESS
          ];
          if (
            !permissionsService.currentUserHasAnyOfPermissions(
              setupAccessPermissions
            )
          ) {
            $state.go('app.runner.flowsactive');
          }
        }
      ]
    });
  }
})();
