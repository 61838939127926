import angular, { IWindowService } from 'angular';

class ProcessMapTabStepDetailsController {
  static $inject = ['$window', 'APP_CONFIG'];

  stepDeadline: string;
  plannedTime: string;
  plannedCost: string;
  stepNotification: boolean;
  stepReminder: string;

  constructor(private $window: IWindowService, private APP_CONFIG: AppConfig) {}

  private FIXED_ELEMS_TOTAL_HEIGHT = 249;
  private NONE = 'None';
  private NOT_SPECIFIED = 'Not specified by Process Owner';
  private stepTimeCostVisibility: string;
  private isStepTimeVisible: boolean = this.isStepTimeCostVisible('time');
  private isStepCostVisible: boolean = this.isStepTimeCostVisible('cost');

  public $onInit() {
    this.$window.addEventListener('resize', this.setDynamicMaxHeightResize);
    this.setDynamicMaxHeight();
  }

  $onChanges() {
    if (this.stepDeadline == null) this.stepDeadline = this.NONE;
    if (this.plannedTime == null) this.plannedTime = this.NOT_SPECIFIED;
    if (this.plannedCost == null) this.plannedCost = this.NOT_SPECIFIED;

    if (this.stepReminder == null) {
      this.stepReminder = this.NONE;
    }

    this.updateVisibilitySettings();
  }

  private setDynamicMaxHeight() {
    const dh = this.$window.innerHeight - this.FIXED_ELEMS_TOTAL_HEIGHT + 'px';
    //console.log(dh);
    $('.pmap-step-details-tab').css('height', dh);
  }

  private setDynamicMaxHeightResize() {
    const FIXED_ELEMS_TOTAL_HEIGHT = 249;
    const dh = window.innerHeight - FIXED_ELEMS_TOTAL_HEIGHT;

    //console.log('resize: ' + dh);
    $('.pmap-step-details-tab').css('height', dh + 'px');
    if (dh >= 500)
      $('.pmap-step-details-tab').parent().css('overflow', 'hidden');
  }

  private updateVisibilitySettings() {
    this.stepTimeCostVisibility = this.APP_CONFIG.stepTimeCostVisibility;

    this.isStepTimeVisible = this.isStepTimeCostVisible('time');
    this.isStepCostVisible = this.isStepTimeCostVisible('cost');
  }

  private isStepTimeCostVisible(elem) {
    if (typeof this.stepTimeCostVisibility === 'undefined') return true;
    else {
      if (elem == 'time')
        return (
          this.stepTimeCostVisibility.toLowerCase() === 'both' ||
          this.stepTimeCostVisibility.toLowerCase() === 'timeonly'
        );
      if (elem == 'cost')
        return (
          this.stepTimeCostVisibility.toLowerCase() === 'both' ||
          this.stepTimeCostVisibility.toLowerCase() === 'costonly'
        );
    }
  }
}

export class ProcessMapTabStepDetails implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      stepDeadline: '<',
      plannedTime: '<',
      plannedCost: '<',
      stepNotification: '<',
      stepReminder: '<'
    };

    this.templateUrl =
      'Client/runner.processmaps/tab/right-pane/runner.processmap.tab.step-details.tmpl.html';
  }

  controller = ProcessMapTabStepDetailsController;
}

angular
  .module('flowingly.runner.processmap')
  .component('processmapTabStepDetails', new ProcessMapTabStepDetails());
