/**
 * @ngdoc directive
 * @name flowStartCategory
 * @module flowingly.runner.start
 * @description  This componenet is used to display a single category of flows.
 *               Will display the category header and list of flows.
 * @usage
 * ```
     <flow-start-category ng-repeat="category in $ctrl.categories" category="category" ></flow-start-category>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * category: the category data to display
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flow.start').component('flowStartCategory', {
  bindings: {
    category: '<', // this category
    isMobile: '<' // show mobile view if set
  },
  template: `
                <div class="flow-block" ng-class="{'isMobile': $ctrl.isMobile}" data-flow-category="{{$ctrl.category.name}}">
                    <flow-start-list flow-models="$ctrl.category.flowModels" is-mobile="$ctrl.isMobile"></flow-start-list>
                </div>
            `
});
