import React from 'react';
import ClipboardCopy from '../../../RightPanel/ClipboardCopy/ClipboardCopy';

type Props = {
  label: string;
  textareaId: string;
  textareaValue: string;
  disabled: boolean;
};

const ClipboardTextarea = (props: Props) => {
  const { label, textareaId, textareaValue, disabled } = props;

  return (
    <div>
      <label className="share-modal-label" htmlFor={textareaId}>
        {label}
      </label>
      <div
        style={disabled ? { opacity: 0.5 } : null}
        className="share-modal-textarea-container"
      >
        <div id={textareaId} className="share-modal-textarea">
          {textareaValue}
        </div>
        <span className="share-modal-icon-container tooltip">
          <ClipboardCopy copyText={textareaValue} disabled={disabled} />
        </span>
      </div>
    </div>
  );
};

export default ClipboardTextarea;
