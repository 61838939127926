/**
 * @ngdoc directive
 * @name flowCommentsCount
 * @module flowingly.runner.directives
 * @description This component is used to display a flow comments count.
 * @usage
 * ```
     <flow-comment-count commentCount="$ctrl.flow.CommentCount" ></flow-comment-count>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Comment+Count+Components
 * ### Properties
 * #### Inputs
 * * name: the name of the category for header
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.directives').component('flowCommentCount', {
  bindings: {
    commentCount: '<'
  },
  template:
    'Comments <span ng-if="$ctrl.commentCount > 0">({{$ctrl.commentCount}})</span>'
});
