import angular from 'angular';

angular.module('flowingly.runner.library').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.library.workflows', {
    url: '/workflows',
    params: { flowModels: [], flowId: null, title: 'Library -  Workflows' },
    views: {
      childcontent: {
        controllerAs: '$ctrl',
        controller: [
          '$stateParams',
          function ($stateParams: any) {
            this.flowModels = $stateParams.flowModels;
          }
        ],
        template:
          '<flow-library-tabs-workflows flow-models="$ctrl.flowModels"></flow-library-tabs-workflows>'
      }
    }
  });
}
