import { UserImportRequestStatus } from '@Shared.Angular/@types/core/contracts/queryModel/user/userImportRequest';

export class ImportError {
  public issueId: number;
  public issue: string;
  public howToFix: string;
  public affectedRows: number[];
  public details: string[];

  constructor({
    issue = null,
    howToFix = null,
    issueId = null,
    affectedRows = [],
    details = []
  } = {}) {
    this.issue = issue;
    this.howToFix = howToFix;
    this.issueId = issueId;
    this.affectedRows = affectedRows;
    this.details = details;
  }

  public clone(): ImportError {
    const err = new ImportError();
    err.issue = this.issue;
    err.issueId = this.issueId;
    err.howToFix = this.howToFix;
    return err;
  }
}

const ErrorTypes = {
  [UserImportRequestStatus.FAILED]: new ImportError({
    issueId: UserImportRequestStatus.FAILED,
    issue: 'Import has failed for an unknown reason',
    howToFix: 'Try again after ensuring the csv is correct'
  }),
  [UserImportRequestStatus.FAILED_MANAGER_MISSING]: new ImportError({
    issueId: UserImportRequestStatus.FAILED_MANAGER_MISSING,
    issue: 'Referenced manager does not already exist and is not in the CSV',
    howToFix: 'Manually invite the manager or define them in the CSV'
  }),
  [UserImportRequestStatus.FAILED_DUPLICATE_ROW]: new ImportError({
    issueId: UserImportRequestStatus.FAILED_DUPLICATE_ROW,
    issue: 'Duplicate row of the same email found',
    howToFix: 'Change the email or remove the duplicate row'
  }),
  [UserImportRequestStatus.FAILED_BAD_HEADER]: new ImportError({
    issueId: UserImportRequestStatus.FAILED_BAD_HEADER,
    issue: 'Unknown header found',
    howToFix: 'Please check the spelling or the casing of the csv headers'
  }),
  [UserImportRequestStatus.FAILED_BAD_CONNECTION_TYPE]: new ImportError({
    issueId: UserImportRequestStatus.FAILED_BAD_CONNECTION_TYPE,
    issue: 'Bad connection_type entry',
    howToFix: 'Please either input only sso, password, or leave it blank'
  }),
  [UserImportRequestStatus.FAILED_MISSING_HEADERS]: new ImportError({
    issueId: UserImportRequestStatus.FAILED_MISSING_HEADERS,
    issue: 'Missing column headers',
    howToFix:
      'Please add first_name, last_name & email as column headers to row 1 in your CSV file'
  })
};

class ImportErrorService {
  static $inject = ['lodashService'];

  private errors: ImportError[] = [];
  constructor(private _) {}

  public getErrors() {
    return this.errors;
  }

  public hasErrors(): boolean {
    return this.errors.length > 0;
  }

  /**
   * Try and merge with an existing error first before attempting to
   * push a new error unto the error stack.
   * @param newError
   */
  public addError(newError: ImportError) {
    const existingError = this.errors.find(
      (err) => err.issueId == newError.issueId
    );
    if (existingError == null) {
      const clone = ErrorTypes[newError.issueId].clone();
      clone.details = newError.details || [];
      clone.affectedRows = newError.affectedRows || [];
      clone.affectedRows.sort();
      this.errors.push(clone);
    } else {
      existingError.details.push(...(newError.details || []));
      existingError.details = new Set<string>(existingError.details)
        .values()
        .toArray();
      existingError.affectedRows.push(...newError.affectedRows);
      existingError.affectedRows = new Set<number>(existingError.affectedRows)
        .values()
        .toArray()
        .sort();
    }
  }

  public setEmpty() {
    this.errors = [];
  }

  public removeError(error: ImportError) {
    this._.remove(
      this.errors,
      (err: ImportError) =>
        err.issue == error.issue && err.affectedRows == error.affectedRows
    );
  }
}

angular
  .module('flowingly.runner.import')
  .service('importErrorService', ImportErrorService);

export type ImportErrorServiceType = InstanceType<typeof ImportErrorService>;
