'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.directives')
  .component('flowTimelineFilelistAttachDocument', {
    bindings: {
      files: '<'
    },
    controller: [
      'APP_CONFIG',
      'sessionService',
      function (
        APP_CONFIG: SharedAngular.APP_CONFIG,
        sessionService: SharedAngular.SessionService
      ) {
        const ctrl = this;
        ctrl.user = sessionService.getUser();

        init();

        function init() {
          for (let f = 0; f < ctrl.files.length; f++) {
            ctrl.files[f].DownloadLink = generateDownloadLink(
              ctrl.files[f].FileId
            );
          }
        }

        function generateDownloadLink(fileId) {
          return fileId !== undefined
            ? `${APP_CONFIG.apiBaseUrl}files/download/${fileId}`
            : undefined;
        }
      }
    ],
    template: `
                    <div ng-repeat="file in $ctrl.files">
                        <a flow-download-link="{{::file.DownloadLink}}" title="{{::file.FileName}}" target="_blank"> {{::file.FileName}}</a>                                               
                    </div>
                `
  });
