/**

 * ```
     $state.go('app.runner.flowstodo');
 * ``` 
 * ### Notes
 *  Module structure:
 *    route (app.runner.flowstodo) --> runner.flows.todo.tmpl
 *      flowstodoGroups                   // Handles the list of groups
 *        flowstodoGroup                 // Handles each group (which contains a group.name and the group.flows)
 *          flowstodoGroupHeader          // Handles the group header for a group of flows
 *          flowstodoGroupFlows            // Handles the flow list for a group
 *            flowstodoGroupFlow          // Handles each flow in the group
 *              --> runner.flows.todo.group.flow.tmpl.html //uses the flow-list directive to present all the flows in the group
 * ### Properties
 * #### Inputs
 */

'use strict';
import angular from 'angular';
///
/// This state displays flows that the user has action to do on
///

// Register the application's routes configuration.
angular.module('flowingly.runner.flows.todo').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.flowstodo', {
    url: 'flowstodo',
    params: { flowId: null, title: 'To Do' },
    controller: 'runnerFlowsTodoController',
    controllerAs: 'ctrl',
    templateUrl: 'Client/runner.flows.todo/runner.flows.todo.tmpl.html'
  });
}
