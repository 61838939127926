import angular, {
  ICompileService,
  IRootScopeService,
  ITemplateCacheService,
  ITimeoutService
} from 'angular';
import { Percentage } from '../interfaces/AdvanceTypes';

export class PPDFService {
  public static ID = 'PPDFService';
  public static $inject = [
    '$templateCache',
    '$compile',
    '$rootScope',
    '$timeout',
    'lodashService'
  ];

  constructor(
    private $templateCache: ITemplateCacheService,
    private $compile: ICompileService,
    private $rootScope: IRootScopeService,
    private $timeout: ITimeoutService,
    private lodashService: Lodash
  ) {}

  generatePpdf(templateName, data): angular.IPromise<void> {
    this.generateStepStatus(data);
    const template = this.$templateCache.get(templateName);
    if (!template) {
      throw new TemplateNotFoundError(
        `Template(${templateName}) could not be found`
      );
    }
    const html = `<div>${template}</div>`;
    const scope = angular.extend(this.$rootScope.$new(), data);
    scope.jsonparse = JSON.parse;
    const compiledElem = this.$compile(html)(scope);

    return this.$timeout(() => {
      scope.$digest();
      const newWindow = window.open('', '_blank');
      newWindow.document.write(compiledElem.html());
    }, 1);
  }

  generateStepStatus(data) {
    this.lodashService.forEach(data.completedSteps, (step) => {
      switch (step.Status) {
        case 0:
          step.StepStatus = 'ToDo';
          break;
        case 1:
          step.StepStatus = 'Hold';
          break;
        case 2:
          step.StepStatus = 'Completed';
          break;
        case 3:
          step.StepStatus = 'Abandoned';
          break;
        default:
          step.StepStatus = '';
          break;
      }
    });
  }
}

export class TemplateNotFoundError extends Error {
  constructor(message) {
    super(message);
  }
}

export class FlowHTMLPayload {
  public flowModelName: string;
  public flowIdentifier: string;
  public subject: string;
  public progress: Percentage;
  public inProgressSteps: FlowStepHTMLPayload[];
  public completedSteps: FlowStepHTMLPayload[];
}

export class FlowStepHTMLPayload {
  public dateCompleted: Date;
  public name: string;
}

angular
  .module('flowingly.runner.services')
  .service(PPDFService.ID, PPDFService);

export type PPDFServiceType = InstanceType<typeof PPDFService>;
