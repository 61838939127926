import { react2angular } from '@Shared.Angular/flowingly.helper/react2angular';
import CategoryNavigationMobile from '../components/CategoryNavigationMobile';

const CategoryNavigationMobileBridge = react2angular(CategoryNavigationMobile, [
  'categories',
  'categoryClicked',
  'selectedIndex',
  'selectedId',
  'selectedName'
]);
export default CategoryNavigationMobileBridge;
