import React, { useEffect, useState } from 'react';
import NamedAvatar from '../NamedAvatar/NamedAvatar';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';
import ICustomEmailProcessMapNodeElement from '@Shared.Angular/@types/customEmailProcessMapNodeElement';

type props = {
  selectedNode: IProcessMapNodeViewModel;
};

function EmailDetails(props: props) {
  const [recipients, setRecipients] = useState([]);
  const [additionalRecipients, setAdditionalRecipients] = useState([]);

  useEffect(() => {
    const regex = /[,:;]/;
    const emailField: ICustomEmailProcessMapNodeElement =
      props.selectedNode.fields[0];
    const additionalRecipientList = emailField?.additionalRecipients
      ?.replaceAll(' ', '')
      .split(regex)
      .filter((x) => x.trim() !== '');

    setAdditionalRecipients(additionalRecipientList);
    setRecipients(
      emailField.recipients.filter((x) => !additionalRecipientList.includes(x))
    );
  }, [props]);

  return (
    <div>
      <div>
        <label className="row-title">Recipient(s)</label>
      </div>

      <div>
        {recipients?.map((recipient) => (
          <NamedAvatar key={recipient} fullName={recipient} />
        ))}
      </div>
      <br />
      <div>
        <label className="row-title">Additional Recipient(s)</label>
      </div>
      <div>
        {additionalRecipients?.length > 0 ? (
          additionalRecipients.map((recipient) => (
            <p key={recipient}>{recipient}</p>
          ))
        ) : (
          <p className="process-map-v2-view-email-details-italic-and-grayed">
            None specified
          </p>
        )}
      </div>
    </div>
  );
}

export default EmailDetails;
