import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.flows.imin
 * @module flowingly.runner.flows.imin
 * @description This module configures the page used to display flows that the user is in.
 */
const m = angular.module('flowingly.runner.flows.imin', ['kendo.directives']);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.flows.imin/runner.flows.imin.group.tmpl.html',
      require('./runner.flows.imin.group.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flows.imin/runner.flows.imin.groups.tmpl.html',
      require('./runner.flows.imin.groups.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flows.imin/runner.flows.imin.tmpl.html',
      require('./runner.flows.imin.tmpl.html').default
    );
  }
]);
