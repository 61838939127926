'use strict';

import * as angular from 'angular';

angular.module('flowingly.runner.templates').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.templates.admin.edit', {
    url: '/templates/{templateKey}',
    params: { title: 'Edit Template' },
    templateUrl:
      'Client/runner.templates/admin/templates/edit/runner.templates.admin.templates.edit.tmpl.html',
    controller: 'runnerEditTemplateController',
    controllerAs: 'ctrl'
  });
}
