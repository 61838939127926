import React from 'react';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';
import { FormFieldTypeEnum } from '@Shared.Angular/flowingly.services/flowingly.constants';

type Props = {
  selectedNode: IProcessMapNodeViewModel;
};

function DecisionGateway({ selectedNode }: Props) {
  const decisionStep = selectedNode?.decisionGateway?.decisionStep || '';
  const decisionField = selectedNode?.decisionGateway?.decisionField || '';
  const decisionFieldType = selectedNode?.decisionGateway?.decisionFieldType;
  const decisionPaths = selectedNode?.decisionGateway?.paths || [];
  const customUnits = selectedNode?.decisionGateway?.customUnits || null;

  function convertLogicToText(logic: string): string {
    switch (logic) {
      case '>':
        return 'Greater than';
      case '>=':
        return 'Greater than or equal to';
      case '<':
        return 'Less than';
      case '<=':
        return 'Less than or equal to';
      case '==':
      case '=':
        return 'Equal to';
      case '!=':
        return 'Not equal to';
      default:
        return logic;
    }
  }

  const buildPathValueLabel = (path) => {
    if (path.isDefault) {
      return 'Default Path';
    }

    let pathValue = '';
    if (
      decisionFieldType &&
      (decisionFieldType === FormFieldTypeEnum.CURRENCY ||
        decisionFieldType === FormFieldTypeEnum.NUMBER ||
        decisionFieldType === FormFieldTypeEnum.FORMULA) &&
      path?.logic
    ) {
      pathValue += `${convertLogicToText(path.logic)} ${
        customUnits ? `<b>${path.value} ${customUnits}</b>` : path.value
      }`;
    } else {
      pathValue = path.value || '';
    }

    return pathValue;
  };

  return (
    <div>
      <div>
        <label className="row-title">Decision Step</label>
        <p>{decisionStep || 'None specified'}</p>
      </div>

      <div>
        <label className="row-title">Decision Field</label>
        <p>{decisionField || 'None specified'}</p>
      </div>

      <div>
        <div className="process-map-v2-decision-gateway-label">
          <label className="row-title">Decision Paths</label>
        </div>

        {decisionPaths.length > 0 ? (
          decisionPaths.map((path, index) => (
            <div key={index} className="process-map-v2-decision-gateway">
              {path.pathName && (
                <span>
                  <p className="process-map-v2-decision-gateway-label-name">
                    {path.pathName}
                  </p>
                </span>
              )}
              <div className="process-map-v2-decision-gateway-content-wrapper">
                <div
                  className="process-map-v2-decision-gateway-content-item"
                  dangerouslySetInnerHTML={{
                    __html: buildPathValueLabel(path)
                  }}
                ></div>
                <span className="process-map-v2-decision-gateway-content-item-2">
                  <img
                    src={
                      path.isDefault
                        ? '\\Client\\assets\\gateDefaultPathArrow.png'
                        : '\\Client\\assets\\gatePathArrow.png'
                    }
                    alt="Logo"
                  />
                </span>
                <span className="process-map-v2-decision-gateway-content-item">
                  {path.nextStep || 'None'}
                </span>
              </div>
            </div>
          ))
        ) : (
          <p className="process-map-v2-decision-gateway-italic-and-grayed">
            No paths specified
          </p>
        )}
      </div>
    </div>
  );
}

export default DecisionGateway;
