/**
 * @ngdoc directive
 * @name flowLibraryList
 * @module flowingly.runner.library
 * @description  This comppnent is used to display the list of flow models
 * @usage
 * ```
     <flow-library-list flow-models="$ctrl.flowModels"></flow-library-list>
 * ```
 * ### Notes
 * See Also:
 * ### Properties
 * #### Inputs
 * * flowModels: the lsit of available flow models (JSON[])
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, {
  IFilterDate,
  IFilterService,
  IQService,
  IScope
} from 'angular';

declare const moment: Moment;
angular.module('flowingly.runner.library').component('flowLibraryList', {
  bindings: {
    flowModels: '<',
    gridNeedResize: '<',
    listUpdated: '&',
    canCreateFlows: '<',
    displayType: '<'
  },
  templateUrl: 'Client/runner.library/runner.library.list.tmpl.html',
  controller: [
    '$scope',
    'validationService',
    'categoryApiService',
    'pubsubService',
    'lodashService',
    '$filter',
    'APP_CONFIG',
    'flowinglyConstants',
    'flowModelApiService',
    '$q',
    function (
      $scope: IScope,
      validationService: SharedAngular.ValidationService,
      categoryApiService: SharedAngular.CategoryApiService,
      pubsubService: SharedAngular.PubSubService,
      lodashService: Lodash,
      $filter: IFilterService,
      APP_CONFIG: SharedAngular.APP_CONFIG,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      flowModelApiService: SharedAngular.FlowModelApiService,
      $q: IQService
    ) {
      const ALL = 'All';
      const subscriberId = 'runner.library.list.component';

      const _flowsToRender = [];
      let _categories;
      let _flowOwners;
      let _processOwner;
      let _hasConfidentialFlows;
      let _categoriesWithSubCategories = [];
      const $ctrl = this;
      $ctrl.handleListUpdated = handleListUpdated;

      $scope.$on('$destroy', () => {
        pubsubService.unsubscribeAll(subscriberId);
      });

      this.$onInit = function () {
        updateFlowModels($ctrl.flowModels);

        $ctrl.noRows = true;

        _categories = categoryApiService.cachedCategories;

        if (APP_CONFIG.enableSubCategories)
          _categoriesWithSubCategories = _categories.map(
            convertIntoHierarchicalDataStructure
          );

        _flowOwners = lodashService.uniqBy(
          lodashService.map(_flowsToRender, function (flow) {
            return {
              creatorName: flow.creatorName
            };
          }),
          'creatorName'
        );

        _processOwner = lodashService.uniqBy(
          lodashService.map(_flowsToRender, function (flow) {
            if (flow.processOwner !== null) {
              return {
                processOwner: flow.processOwner
              };
            }
          }),
          'processOwner'
        );

        $ctrl.toolTipOptions = {
          filter: 'td:nth-child(1),td:nth-child(2),td:nth-child(3)',
          position: 'top',
          content: function (e) {
            if (e.target[0].scrollWidth > e.target[0].offsetWidth) {
              return validationService.sanitizeString(e.target[0].innerText);
            } else {
              return '';
            }
          },
          show: function (e) {
            if (this.content[0].scrollWidth < 300) {
              this.popup.element[0].style.width = '300px';
            } else {
              this.popup.element[0].style.width = this.content[0].scrollWidth;
            }

            if (this.content.text() !== '') {
              $('[role="tooltip"]').css('visibility', 'visible');
            }
          },
          hide: function () {
            $('[role="tooltip"]').css('visibility', 'hidden');
          }
        };

        $ctrl.options = {
          dataSource: {
            data: _flowsToRender,
            pageSize: 15,
            schema: {
              model: {
                fields: {
                  lastEditedDate: { type: 'date' },
                  processReviewDate: { type: 'date' }
                }
              }
            },
            sort: {
              field: 'lastEditedDate',
              dir: 'desc'
            }
          },
          noRecords: {
            template: '<flow-library-no-results></flow-library-no-results>'
          },
          scrollable: true,
          pageable: true,
          sortable: true,
          resizable: true,
          filterable: {
            mode: 'row',
            operators: {
              date: {
                gte: 'Is after or equal to',
                lte: 'Is before or equal to'
              }
            }
          },
          columns: getColumns()
        };

        pubsubService.subscribe(
          'SIGNALR_FLOW_MODEL_STATUS_CHANGED',
          onFlowModelStatusChanged,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_NEW_FLOW_MODEL_SAVED',
          onNewFlowModelSaved,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_SETUP_FLOW_MODEL_DELETED',
          onFlowModelDeleted,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_PUBLISHED',
          onFlowModelStatusChanged,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_UNPUBLISHED',
          onFlowModelStatusChanged,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_NAME_CHANGED',
          onFlowModelNameChanged,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_UNPUBLISHED',
          onFlowUnPublished,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_PUBLISHED',
          onFlowPublished,
          subscriberId
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_UNPUBLISHED_CHANGES',
          onFlowUnPublishedChanges,
          subscriberId
        );
      };

      this.$onChanges = function (changesObj) {
        // handle new models
        if (changesObj.flowModels && changesObj.flowModels.currentValue) {
          updateFlowModels(changesObj.flowModels.currentValue);
          if ($scope.libraryGrid) {
            $scope.libraryGrid.dataSource.read();
            $scope.libraryGrid.refresh();
          }
          $ctrl.noRows = !$ctrl.flowModels[0];
        }

        // handle resize request
        if (changesObj.gridNeedResize) {
          if (changesObj.gridNeedResize.currentValue !== $ctrl.doGridResize) {
            $ctrl.doGridResize = changesObj.gridNeedResize.currentValue;
          }
        }
      };

      /// PRIVATE METHODS //////////////////////////////////
      function updateFlowModels(flowModels) {
        let gridData = [];
        const workFlows = flowModels.filter(
          (x) =>
            (x.publishTypeValue ===
              flowinglyConstants.flowModelPublishType.WORKFLOW ||
              x.publishTypeValue ===
                flowinglyConstants.flowModelPublishType.WORKFLOW_COMPONENT) &&
            x.status.toUpperCase() !== 'DRAFT'
        );
        const processMaps = flowModels.filter(
          (x) =>
            (x.publishTypeValue ===
              flowinglyConstants.flowModelPublishType.PROCESS_MAP ||
              x.publishTypeValue ===
                flowinglyConstants.flowModelPublishType
                  .PROCESS_MAP_COMPONENT) &&
            x.status.toUpperCase() !== 'DRAFT'
        );
        if (
          $ctrl.displayType === flowinglyConstants.libraryDisplayTypes.WORKFLOW
        ) {
          gridData = workFlows;
        } else if (
          $ctrl.displayType ===
          flowinglyConstants.libraryDisplayTypes.PROCESS_MAP
        ) {
          gridData = processMaps;
        } else {
          gridData = flowModels.filter(
            (item) => !workFlows.includes(item) && !processMaps.includes(item)
          );
        }
        angular.copy(gridData, _flowsToRender);

        _hasConfidentialFlows = false;
        _flowsToRender.forEach((flow) => {
          /*
           * Drop the hours, minutes, and seconds part of the date so that
           * when kendo performs a GTE and an LTE, it doesn't calculate the minutes as
           * part of filter. This is because of how dates are compared in general.
           *                                                                  - Cassey
           */
          flow.lastEditedDate = $filter<IFilterDate>('utcToLocal')(
            flow.lastEditedDate,
            'yyyy-MM-dd'
          );
          flow.processReviewDate = $filter<IFilterDate>('utcToLocal')(
            flow.processReviewDate,
            'yyyy-MM-dd'
          );
          flow.publishType = flow.workflowType
            ? flow.workflowType
            : flow.publishType;
          if (flow.isConfidential) {
            _hasConfidentialFlows = true;
          }
        });
      }

      function convertIntoHierarchicalDataStructure(node) {
        const convertedNode = {
          id: node.id,
          name: node.name,
          items: node.subCategories.map(convertIntoHierarchicalDataStructure)
        };
        return convertedNode;
      }
      function handleListUpdated(needResizeGrid) {
        $ctrl.listUpdated({ needResizeGrid: needResizeGrid });
        flowModelApiService.getFlowModels().then((flowModels) => {
          updateFlowModels(flowModels);
          if ($scope.libraryGrid) {
            $scope.libraryGrid.dataSource.read();
          }
        });
      }

      // When a flow model is added we need to get it and also may need to increase the height of the grid
      function onNewFlowModelSaved(event, message) {
        handleListUpdated(true);
      }

      // When a flow model is deleted we need to remove it and decrease the height of the grid
      function onFlowModelDeleted(event, message) {
        handleListUpdated(true);
      }

      // When the status of a flow model is changed we need to show the changed status of the Flow Model in the Flow Library
      function onFlowModelStatusChanged(event, message) {
        handleListUpdated(false); // As this is an existing Flow Model we do not need to resize the grid
      }

      function onFlowModelNameChanged(msg, data) {
        handleListUpdated(true);
      }
      function onFlowPublished(msg, data) {
        handleListUpdated(true);
      }
      function onFlowUnPublished(msg, data) {
        handleListUpdated(true);
      }
      function onFlowUnPublishedChanges(msg, data) {
        handleListUpdated(true);
      }

      function getColumns() {
        switch ($ctrl.displayType) {
          case flowinglyConstants.libraryDisplayTypes.DRAFT:
            return getDraftColumns();
          case flowinglyConstants.libraryDisplayTypes.WORKFLOW:
            return getWorkflowColumns();
          case flowinglyConstants.libraryDisplayTypes.PROCESS_MAP:
            return getProcessMapColumns();
          default:
            console.error('Unknown display type');
            break;
        }
      }

      const getBaseColumns = () => {
        //Note: field needs to match the name (and case) of the returned data
        return [
          {
            field: 'name',
            title: 'Name',
            template:
              '{{dataItem.name}}<unpublished-changes-message item="dataItem"></unpublished-changes-message>',
            filterable: {
              cell: {
                operator: 'contains',
                suggestionOperator: 'contains',
                showOperators: false,
                template: function (args) {
                  args.element.kendoAutoComplete({
                    dataSource: new kendo.data.DataSource({
                      data: _flowsToRender
                    }),
                    dataTextField: 'name', //enables filtering on specified column
                    placeholder: 'Search by Name', //placeholder for text input
                    valuePrimitive: true,
                    filter: 'contains' //default autocomplete uses 'starts with'
                  });
                }
              }
            }
          },
          {
            field: 'creatorName',
            title: 'Created By',
            filterable: {
              cell: {
                operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
                showOperators: false,
                template: function (args) {
                  args.element.kendoComboBox({
                    dataSource: _flowOwners,
                    dataTextField: 'creatorName', //enables filtering on specified
                    dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by creator', //placeholder for text input
                    valuePrimitive: true //default autocomplete uses 'starts with'
                  });
                }
              }
            }
          },
          {
            title: 'Actions',
            width: '165px',
            filterable: false,
            template:
              "<flow-library-list-actions flow='dataItem' can-create-flows='$ctrl.canCreateFlows' category='dataItem.category' on-flow-list-updated='$ctrl.handleListUpdated(needResizeGrid)'></flow-library-list-actions>"
          }
        ];
      };

      const getWorkflowColumns = () => {
        const workFlowColumns = getBaseColumns();
        workFlowColumns.splice(2, 0, {
          field: 'processOwner',
          title: 'Process Owner',
          filterable: {
            cell: {
              operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
              showOperators: false,
              template: function (args) {
                args.element.kendoComboBox({
                  dataSource: _processOwner,
                  dataTextField: 'processOwner', //enables filtering on specified
                  dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                  placeholder: 'Search by process owner', //placeholder for text input
                  valuePrimitive: true //default autocomplete uses 'starts with'
                });
              }
            }
          }
        });
        workFlowColumns.splice(3, 0, {
          field: 'lastEditedDate',
          title: 'Last Updated',
          sortable: true,
          template: ({ lastEditedDate }) => {
            return (
              '<span>' + moment(lastEditedDate).format('DD/MM/YYYY') + '</span>'
            );
          },
          filterable: {
            cell: {
              template: function (args) {
                args.element
                  .kendoDatePicker({
                    placeholder: 'Search by last updated date',
                    format: 'dd/MM/yyyy'
                  })
                  .attr('placeholder', 'Search by last updated date');
              }
            }
          }
        });
        workFlowColumns.splice(4, 0, {
          field: 'processReviewDate',
          title: 'Review Date',
          sortable: true,
          template: ({ processReviewDate }) => {
            if (processReviewDate) {
              return (
                '<span>' +
                moment(processReviewDate).format('DD/MM/YYYY') +
                '</span>'
              );
            } else {
              return '';
            }
          },
          filterable: {
            cell: {
              template: function (args) {
                args.element
                  .kendoDatePicker({
                    placeholder: 'Search by review date',
                    format: 'dd/MM/yyyy'
                  })
                  .attr('placeholder', 'Search by review date');
              }
            }
          }
        });
        workFlowColumns.splice(5, 0, {
          field: 'publishType',
          title: 'Type',
          filterable: {
            cell: {
              showOperators: false,
              template: function (args) {
                const publishTypes = ($ctrl.displayType = [
                  { name: 'Workflow' },
                  { name: 'Workflow Component' }
                ]);
                if (_hasConfidentialFlows) {
                  publishTypes.push({ name: 'Confidential Workflow' });
                }
                args.element.kendoComboBox({
                  dataSource: publishTypes,
                  dataTextField: 'name', //enables filtering on specified
                  dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                  placeholder: 'Search by Type', //placeholder for text input
                  valuePrimitive: true //default autocomplete uses 'starts with'
                });
              }
            }
          }
        });
        if (!_categoriesWithSubCategories.length) {
          workFlowColumns.splice(6, 0, {
            field: 'category',
            title: 'Category',
            filterable: {
              cell: {
                showOperators: false,
                template: function (args) {
                  args.element.kendoComboBox({
                    dataSource: _categories,
                    dataTextField: 'name',
                    dataValueField: 'name', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by Category',
                    filter: 'contains',
                    valuePrimitive: true,
                    ignoreCase: true
                  });
                }
              }
            }
          });
        } else {
          workFlowColumns.splice(6, 0, {
            field: 'category',
            title: 'Category',
            filterable: {
              cell: {
                showOperators: false,
                template: function (args) {
                  args.element.kendoDropDownTree({
                    dataSource: _categoriesWithSubCategories,
                    dataTextField: 'name',
                    dataValueField: 'id', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by Category',
                    filter: 'eq',
                    valuePrimitive: true,
                    ignoreCase: true,
                    autoWidth: true,
                    change: function (e) {
                      if (this.value() === '') {
                        $scope.libraryGrid.dataSource.filter({});
                      } else {
                        const filter = {
                          logic: 'or',
                          filters: [
                            {
                              field: 'categoryId',
                              operator: 'eq',
                              value: this.value()
                            }
                          ]
                        };
                        $scope.libraryGrid.dataSource.filter(filter);
                      }
                    }
                  });
                }
              }
            }
          });
        }
        workFlowColumns.splice(7, 0, {
          field: 'publishedTo',
          title: 'Published To',
          filterable: false
        });
        return workFlowColumns;
      };
      const getDraftColumns = () => {
        const draftColumns = getBaseColumns();
        draftColumns.splice(1, 0, {
          field: 'lastEditedDate',
          title: 'Last Updated',
          sortable: true,
          template: ({ lastEditedDate }) => {
            return (
              '<span>' + moment(lastEditedDate).format('DD/MM/YYYY') + '</span>'
            );
          },
          filterable: {
            cell: {
              template: function (args) {
                args.element
                  .kendoDatePicker({
                    placeholder: 'Search by last updated date',
                    format: 'dd/MM/yyyy'
                  })
                  .attr('placeholder', 'Search by last updated date');
              }
            }
          }
        });

        return draftColumns;
      };

      const getProcessMapColumns = () => {
        const workFlowColumns = getWorkflowColumns();
        const typeColumnIndex = workFlowColumns.findIndex(
          (x) => x.field.toUpperCase() === 'PUBLISHTYPE'
        );
        const statusColumnIndex = workFlowColumns.findIndex(
          (x) => x.title.toUpperCase() === 'ACTIONS'
        );

        if (typeColumnIndex !== -1) {
          workFlowColumns[typeColumnIndex] = {
            field: 'publishType',
            title: 'Type',
            filterable: {
              cell: {
                showOperators: false,
                template: function (args) {
                  const publishTypes = [
                    { name: 'Process Map' },
                    { name: 'Process Map Component' }
                  ];
                  args.element.kendoComboBox({
                    dataSource: publishTypes,
                    dataTextField: 'name',
                    dataValueField: 'value',
                    placeholder: 'Search by Type',
                    valuePrimitive: true
                  });
                }
              }
            }
          };
        }

        workFlowColumns.splice(statusColumnIndex, 0, {
          field: 'status',
          title: 'Status',
          template:
            '<library-status-badge status="dataItem.status"></library-status-badge>',
          filterable: {
            cell: {
              showOperators: false,
              template: function (args) {
                args.element.kendoComboBox({
                  dataSource: APP_CONFIG.enablePublicProcessMaps
                    ? [
                        { text: 'All', value: '' },
                        { text: 'Internal' },
                        { text: 'Public' },
                        { text: 'Public SSO' }
                      ]
                    : [{ text: 'All', value: '' }],
                  dataTextField: 'text',
                  dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                  placeholder: 'Search by Status'
                });
              }
            }
          }
        });

        return workFlowColumns;
      };
    }
  ]
});
