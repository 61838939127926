import React from 'react';
import { ItemRenderProps } from '@progress/kendo-react-treeview';
import { Fade, Paper, Popper, Typography } from '@mui/material';

interface CustomItemRenderProps extends ItemRenderProps {
  isExpanded: string;
}

const CustomTreeListItem = (props: CustomItemRenderProps) => {
  const { item } = props;
  const { text } = item;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const calculateTotalCount = (category, isExpanded) => {
    let count = category.count;
    if (!isExpanded && category.items && category.items.length > 0) {
      category.items.forEach((nestedItem) => {
        count += calculateTotalCount(nestedItem, isExpanded);
      });
    }
    return count;
  };

  const count = calculateTotalCount(item, props.isExpanded);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <span>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography sx={{ p: 1 }}>{text}</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <span
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="category-name-count-container"
      >
        <span className="category-name-ellipsis">{text}</span>
        {count > 0 && (
          <span className="category-count green-chip">{count}</span>
        )}
      </span>
    </span>
  );
};

export default CustomTreeListItem;
