'use strict';

import * as angular from 'angular';

angular.module('flowingly.runner.templates').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.templates.template', {
    url: 'templates/{templateKey}',
    params: { title: 'Template Details' },
    templateUrl:
      'Client/runner.templates/template/runner.templates.template.tmpl.html',
    controller: 'runnerTemplateController',
    controllerAs: 'ctrl'
  });
}
