/**
 * @ngdoc directive
 * @name flowProcessmapCategory
 * @module flowingly.runner.processmap
 * @description  This componenet is used to display a single category of process maps.
 *               Will display the category header and list of process maps.
 * @usage
 * ```
     <flow-processmap-category ng-repeat="category in $ctrl.categories" category="category"></flow-processmap-category>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * category: the category data to display (JSON)
 * * isMobile: show mobile view if set
 * 
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.processmap')
    .component('flowProcessmapCategory', {
      bindings: {
        category: '<', // this category
        isMobile: '<' // show mobile view if set
      },
      template: `
                <div class="flow-block" ng-class="{'isMobile': $ctrl.isMobile}">
                    <flow-processmap-list process-maps="$ctrl.category.flowModels" is-mobile="$ctrl.isMobile"></flow-processmap-list>
                </div>
            `
    });
})();
