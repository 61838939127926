import React, { useState, useEffect } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { ICommentMention } from '@Shared.Angular/interfaces/comment-mention.interface';
import { Box } from '@mui/material';

export type Props = {
  commentTargetId: string;
  flowOwnerOnly: boolean;
  commentAdded: boolean;
  onValueChange: (newValue: string) => void;
  mentionedUsers: (mentions: unknown[]) => void;
};

const CustomnMention = (props: Props) => {
  const {
    commentTargetId,
    flowOwnerOnly,
    commentAdded,
    onValueChange,
    mentionedUsers
  } = props;
  const [totalMentions, setTotalMentions] = useState(0);
  const [selectedMentions, setSelectedMentions] = useState([]);
  const [value, setValue] = useState('');
  const [fetchTimeout, setFetchTimeout] = useState<number | null>(null);
  const commentApiService =
    useService<SharedAngular.CommentApiService>('commentApiService');
  const avatarService =
    useService<SharedAngular.AvatarService>('avatarService');
  const [mentionSerchList, setMentionSerchList] = useState<ICommentMention[]>(
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
    newPlainTextValue: string,
    mentions: []
  ) => {
    setValue(e.target.value);
    onValueChange(e.target.value);

    if (mentions.length < totalMentions) {
      const removedMentions = selectedMentions.filter(
        (selectedMention) =>
          !mentions.some(
            (mention) => mention.id.trim() === selectedMention.actorName.trim()
          )
      );

      if (removedMentions.length > 0) {
        removeMentionsFromSelected(removedMentions);
      }
      setTotalMentions(mentions.length);
    }
  };

  const onAdd = (id: string) => {
    const selectedMentionUser = mentionSerchList.find((x) => x.actorId == id);
    if (selectedMentionUser) {
      setSelectedMentions((prevSelectedUsers) => [
        ...prevSelectedUsers,
        selectedMentionUser
      ]);
      const totalMentionCount = totalMentions + 1;
      setTotalMentions(totalMentionCount);
    }
  };

  const removeMentionsFromSelected = (mentionsToRemove) => {
    const updatedSelectedMentions = selectedMentions.filter(
      (selectedMention) =>
        !mentionsToRemove.some(
          (removedMention) => removedMention.actorId === selectedMention.actorId
        )
    );
    setSelectedMentions(updatedSelectedMentions);
  };

  const fetch = async (query: string, callback) => {
    if (!query) return;
    try {
      const formattedData = await getMentions(query);
      callback(formattedData);
    } catch (error) {
      return [];
    }
  };

  const getMentions = async (query: string) => {
    return new Promise((resolve, reject) => {
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }

      const timeout = window.setTimeout(async () => {
        try {
          const data: ICommentMention[] =
            await commentApiService.searchMentions(
              query,
              flowOwnerOnly,
              commentTargetId,
              false
            );
          setMentionSerchList(data);
          const formattedData = data
            .map((user) => ({
              actorId: user.actorId,
              actorTypeId: user.actorTypeId,
              actorname: user.actorName,
              initial: avatarService.getUserInitial(user.actorName),
              bgColor: avatarService.getColour(user.actorName)
            }))
            .map((formattedData) => ({
              id: formattedData.actorId,
              mentionListDisplay: CustomMentionInput(
                formattedData.actorId,
                formattedData.actorTypeId,
                formattedData.actorname,
                formattedData.initial,
                formattedData.bgColor
              ),
              display: formattedData.actorname
            }));

          resolve(formattedData);
        } catch (error) {
          reject(error);
        }
      }, 500);

      setFetchTimeout(timeout);
    });
  };

  const CustomMentionInput = (
    actorId: string,
    actorTypeId: number,
    actorName: string,
    userInitials: string,
    avatarBg: string
  ): JSX.Element => {
    return (
      <div actor-id={actorId} actor-type-id={actorTypeId}>
        <Box className="v2-feedback-avatar-container">
          <Box className="v2-feedback-avatar">
            <Box
              component="span"
              className="v2-feedback-avatar-icon"
              style={{
                backgroundColor: avatarBg
              }}
            >
              {userInitials}
            </Box>
            {actorName}
          </Box>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    if (commentAdded) {
      setValue('');
      onValueChange('');
      setMentionSerchList([]);
      setSelectedMentions([]);
    }
  }, [commentAdded]);

  useEffect(() => {
    mentionedUsers(selectedMentions);
  }, [selectedMentions]);

  return (
    <div className="">
      <MentionsInput
        value={value}
        onChange={onChange}
        placeholder={''}
        className="v2-feedback-mentions-input"
      >
        <Mention
          markup="@[|__display__|]@"
          data={fetch}
          onAdd={onAdd}
          className="v2-feedback-mentions-mention"
          renderSuggestion={(suggestion) => (
            <div>{suggestion.mentionListDisplay}</div>
          )}
        />
      </MentionsInput>
    </div>
  );
};

export default CustomnMention;
