import {
  IFlowModelParser,
  IImportable,
  IImportableFlowModelFile,
  ImportableFlowModelAttachments
} from '../../../../Flowingly.Angular.Runner/Client/runner.services/flow.model.import.service';

export default class FlowModelAttachmentUnsortedZipParser
  implements IFlowModelParser
{
  constructor(private fflate: Fflate) {}

  parseImportables = (importFiles: IImportableFlowModelFile[]) => {
    const imports: Promise<IImportable[]>[] = [];
    importFiles.forEach((importFile) => {
      if (!importFile.file.name.toLowerCase().endsWith('.zip')) {
        return;
      }
      try {
        const whenAttachmentsExtracted = this.getProcessAttachments(
          importFile.file
        );
        imports.push(whenAttachmentsExtracted);
        whenAttachmentsExtracted.then(
          (importables) => (importFile.handled = importables.length > 0)
        );
      } catch {
        /* empty */
      }
    });
    return Promise.allSettled(imports).then((results) => {
      return results
        .filter((result) => result.status === 'fulfilled')
        .map((result: PromiseFulfilledResult<IImportable[]>) => result.value)
        .flat();
    });
  };

  private getProcessAttachments = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const fileData = new Uint8Array(buffer);
    const unzippedData = this.fflate.unzipSync(fileData);
    const importables: ImportableFlowModelAttachments[] = [];
    const importable = new ImportableFlowModelAttachments('');
    importable.key = file.name;
    importables.push(importable);
    for (const [path, attachmentBytes] of Object.entries(unzippedData)) {
      if (attachmentBytes.length < 1) {
        continue;
      }
      const pathParts = path.split('/').reverse();
      const fileName = pathParts[0].trim();
      if (fileName.length < 1) {
        continue;
      }
      importable.attachments.push({
        name: fileName,
        htmlEncodedName: this.escapeHtml(fileName),
        bytes: attachmentBytes
      });
    }
    return importables;
  };

  private escapeHtml(text) {
    const textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML;
  }
}
