'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { IStepReassignment } from '@Client/runner.services/flow.api.service';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .controller('flowReassignController', flowReassignController);

flowReassignController.$inject = [
  '$scope',
  'flowinglyConstants',
  'lodashService'
];

function flowReassignController(
  $scope: IScopeNgDialog,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  lodashService: Lodash
) {
  const $ctrl = this;
  let reassignedFromUserOrGroupId;
  let stepTaskId;

  $ctrl.reassignType =
    $scope.ngDialogData.reassignType || flowinglyConstants.reassignType.Step;
  $ctrl.name =
    $ctrl.reassignType === flowinglyConstants.reassignType.Step
      ? $scope.ngDialogData.currentStep.Name
      : $scope.ngDialogData.currentTask.Name;

  // setting up the parameters for reassign step
  if ($ctrl.reassignType === flowinglyConstants.reassignType.Step) {
    $ctrl.currentStep = $scope.ngDialogData.currentStep;
    $ctrl.isParallelApproverStep =
      $ctrl.currentStep.StepType ===
      flowinglyConstants.taskType.PARALLEL_APPROVAL;
    $ctrl.isSequentialApproverStep =
      $ctrl.currentStep.StepType ===
      flowinglyConstants.taskType.SEQUENTIAL_APPROVAL;

    if ($ctrl.isParallelApproverStep) {
      const approvers = $ctrl.currentStep.StepApprovers;
      $ctrl.approverList = lodashService.uniqBy(
        approvers,
        (a) => a.ApproverUserId
      );
      $ctrl.excludeUsers = $ctrl.approverList.map((u) => u.ApproverUserId);
      // [FLOW-5784] When reassigning parallel approval step user must select an approver to reassign to
      // so at this point in time there wont be any approvers selected so reassignedFromUserOrGroupId is set
      // right before submit.
    } else if ($ctrl.isSequentialApproverStep) {
      $ctrl.excludeUsers = $ctrl.currentStep.StepApprovers[0].ApproverUserId;
      reassignedFromUserOrGroupId =
        $ctrl.currentStep.StepApprovers[0].ApproverUserId;
    } else {
      $ctrl.excludeUsers = $ctrl.currentStep.ActorAssignedId;
      reassignedFromUserOrGroupId = $ctrl.currentStep.ActorAssignedId;
    }
  } else if ($ctrl.reassignType === flowinglyConstants.reassignType.StepTask) {
    stepTaskId = $scope.ngDialogData.currentTask.Id;
  }

  $ctrl.reassign = () => {
    if ($ctrl.currentForm.$valid) {
      // [FLOW-5784] When reassigning parallel approval step user must select an approver to reassign to
      // so this dialog load this would be empty and populated on dialog submit. Set ReassignedFromUserOrGroupId
      // here before submitting the form.
      if ($ctrl.isParallelApproverStep) {
        $ctrl.setReassignedFromUserOrGroupId();
      }

      const result: IStepReassignment = {
        reassignType: $ctrl.reassignType,
        reassignedToUserId: $ctrl.userReassignTo.id,
        reassignedFromUserOrGroupId: reassignedFromUserOrGroupId,
        stepTaskId: stepTaskId,
        reason: $ctrl.reason,
        stepId: undefined,
        flowId: undefined
      };
      $scope.closeThisDialog(result);
    }
  };

  $ctrl.setReassignedFromUserOrGroupId = function () {
    if (
      (typeof reassignedFromUserOrGroupId === 'undefined' ||
        reassignedFromUserOrGroupId === null) &&
      $ctrl.approverToReassign != null &&
      $ctrl.approverToReassign.ApproverUserId != null
    ) {
      reassignedFromUserOrGroupId = $ctrl.approverToReassign.ApproverUserId;
    }
  };

  $ctrl.cancel = () => {
    $scope.closeThisDialog();
  };
}
