/**
 * @ngdoc directive
 * @name runner.flow.roles
 * @module flowingly.runner.setup
 * @description  This component is the root for roles
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';

export default class SetupRolesController {
  static $inject = ['$scope', 'brandingService'];
  static ROLES_UPDATED_EVENT = 'SetupRolesUpdated';
  public helpUri: string;

  constructor(
    private $scope: IScope,
    brandingService: SharedAngular.BrandingService
  ) {
    this.helpUri = brandingService.getBrandedHelpUri(
      'https://help.flowingly.net/en/articles/8715969-custom-roles'
    );
  }

  onRolesUpdated() {
    // called by roles.header when it adds a new role
    // broadcasts to roles list so that it will perform a reload of the roles
    this.$scope.$broadcast(SetupRolesController.ROLES_UPDATED_EVENT);
  }
}

angular
  .module('flowingly.runner.setup')
  .controller('setupRolesController', SetupRolesController);
