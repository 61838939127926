/**
 * @ngdoc directive
 * @name flowLeaving
 * @module flowingly.runner.directives
 * @description A directive that requests file data from the server, attaching appropriate auth headr(s) and token and assigns return data to img tag src atttribute
 * @usage
 * ```
 * ```
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope, IWindowService } from 'angular';

// When the user leaves the curent page, raise an event. I.e when:
// * user opens another browser tab
// * user closes the app tab!
// * user closes the browser!
angular.module('flowingly.runner.directives').directive('flowLeaving', [
  '$window',
  'pubsubService',
  function (
    $window: IWindowService,
    pubsubService: SharedAngular.PubSubService
  ) {
    return {
      restrict: 'A',
      link: function (scope: IScope) {
        const win = angular.element($window).on('blur', handleBlur);

        scope.$on('$destroy', handleDestroy);

        function handleBlur(event) {
          pubsubService.publish('WINDOW_BLUR');
        }

        function handleDestroy() {
          //remove the handler when this directive is destroyed
          win.off('blur', handleBlur);
        }
      }
    };
  }
]);
