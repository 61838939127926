import { SharedAngular } from '../../@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.setup').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.setup.categories', {
    url: '/categories',
    params: { flowId: null, title: 'Setup - Categories' },
    views: {
      childcontent: {
        //if / when we upgrade to ui-router v1 these two controller lines can be replaced with
        //component: 'categorysetupComponent',
        controllerAs: '$ctrl',
        controller: [
          'permissionsService',
          'setupApiService',
          'flowinglyConstants',
          '$state',
          function (
            permissionsService: SharedAngular.PermissionsService,
            setupApiService: SharedAngular.SetupApiService,
            flowinglyConstants: SharedAngular.FlowinglyConstants,
            $state: IStateService
          ) {
            if (
              !permissionsService.currentUserHasPermission(
                flowinglyConstants.permissions.SETUP_CATEGORY_ACCESS
              )
            ) {
              $state.go('app.runner.flowsactive');
            }

            this.categories = setupApiService.cachedCategories;
          }
        ],
        template: `<runner-setup-categories categories="$ctrl.categories"></runner-setup-categories>`
      }
    },
    resolve: {
      //get the list of categories on state load and pass to component BEFORE page loads
      categories: [
        'setupApiService',
        'APP_CONFIG',
        function (setupApiService, APP_CONFIG) {
          if (!APP_CONFIG.enableSubCategories) {
            return setupApiService.getCategories();
          }
        }
      ]
    }
  });
}
