/**
 * @ngdoc directive
 * @name flowHeaderText
 * @module flowingly.runner.directives
 * @description This component is used to display a flow header.
 * @usage
 * ```
     <flow-header-text name="$ctrl.category.name" is-mobile="$ctrl.isMobile"></flow-header-text>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * name: the name of the category for header
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.directives').component('flowHeaderText', {
  bindings: {
    name: '<',
    isMobile: '<' //show mobile view if set,
  },
  template: `
                <div class="flow-block__header" ng-class="{'isMobile': $ctrl.isMobile}">
                    {{$ctrl.name}}
                </div>
            `
});
