/**
 * @ngdoc directive
 * @name flowReportGroupList
 * @module flowingly.runner.reports
 * @description  This componenet is used to display the reports available for a report category container..
 *               This is the component you put on the main page to display the list
 * @usage
 * ```
    <flow-report-group-list groups="ctrl.reports"></flow-report-group-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components
 * ### Properties
 * #### Inputs
 * * groups: list of reports, grouped by category
 * * isMobile :show mobile view if set,
 */

'use strict';
import angular from 'angular';
///
/// This componenet is used to display the reports available for a report category container.
/// This is the component you put on the main page to display the list
///
/// <flow-report-group-list groups="ctrl.reports"></flow-report-group-list>
///
/// See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Report+Components

angular.module('flowingly.runner.reports').component('flowReportGroupList', {
  bindings: {
    groups: '<', // list of reports, grouped by category
    isMobile: '<' // show mobile view if set
  },
  template: [
    '<div class="report-group-list" ng-class="{\'isMobile\': $ctrl.isMobile}">',
    '<flow-report-group ng-repeat="group in $ctrl.groups" group="group" groups="$ctrl.groups" is-mobile="$ctrl.isMobile"></flow-report-group>',
    '</div>'
  ].join('')
});
