/**
 * @ngdoc directive
 * @name flowHistoryWaiting
 * @module flowingly.runner.flow
 * @description This comppnent is used to display information about who a step is waiting on.
 * @usage
 * ```
     <flow-history-waiting ng-if="!step.completed" step="step" is-mobile="$ctrl.isMobile"></flow-history-waiting>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * step: the step data to display (JSON)
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryWaiting', {
  bindings: {
    step: '<',
    isMobile: '<', //show mobile view if set
    onSelectStep: '&'
  },
  template: `
                <div ng-if="!$ctrl.isMobile" class="flow-history-waiting flex flex-row" ng-class ="{'pt-0': $ctrl.step.ActorAssignedAvatarUrl, 'flow-history-waiting--selected': $ctrl.step.IsSelectedStep }">
                    <div class="avatar-container">
                        <!-- who contains "You" when logged user is actor.So to get correct avatar initial fullname property is used. But for Group we still need to use who property as username will have current username. -->

                          <div ng-if="$ctrl.step.IntegrationState == null || $ctrl.step.IntegrationState.State !== 0">
                             <div class="avatar">
                               <flow-avatar-circle name="$ctrl.step.ActorAssignedName" avatar-url="$ctrl.step.ActorAssignedAvatarUrl"></flow-avatar-circle>
                             </div>
                          </div>
                         <div ng-if="$ctrl.step.IntegrationState != null && $ctrl.step.IntegrationState.State === 0">
                            <div class="avatar step-processing-avatar">
                                <i class="fa-light fa-desktop step-processing-icon"></i>                    
                            </div>
                         </div>    
                    </div>

                    <div class="text single-flow-view__contents current-step-display-moment">
                        <p><b>{{$ctrl.step.Name}}</b></p>

                        <div ng-if="$ctrl.step.IntegrationState == null || $ctrl.step.IntegrationState.State !== 0">
                            <div ng-if="$ctrl.step.StepApprovers.length > 0">
                                <p class ="secondary-text">Waiting for approval by {{$ctrl.step.WhoIsAssignedStep}} for {{$ctrl.step.Moment}}</p>
                            </div>
                            <div ng-if="$ctrl.step.StepApprovers.length === 0 || $ctrl.step.StepApprovers === null">
                                <p class ="secondary-text">Waiting for input by {{$ctrl.step.WhoIsAssignedStep}} for {{$ctrl.step.Moment}}</p>
                            </div>
                        </div>

                        <div ng-if="$ctrl.step.IntegrationState != null && $ctrl.step.IntegrationState.State === 0">
                            <p class ="secondary-text">Waiting for processing for {{$ctrl.step.IntegrationState.ProcessingIntegrationMoment}}</p>
                        </div>

                    </div>

                    <div class="text single-flow-view__contents current-step-display-moment-due">
                        <div ng-show="$ctrl.step.IsOverdue" class="flow-current-step__contents__due-date__text color-warning flex-column">
                            <i class="fa-light fa-calendar-xmark" aria-hidden="true"></i> {{$ctrl.step.DeadLineLabel}}
                        </div>
			            <div ng-show="!$ctrl.step.IsOverdue && !$ctrl.flow.IsFinalised" class="flow-current-step__contents__due-date__text flex-column">
                            <i class="fa-light fa-calendar-check" aria-hidden="true"></i> {{$ctrl.step.DeadLineLabel}}
                        </div>
                        <div class="flow-list__contents__due-date__due-by flex-column">
                            <span ng-show="$ctrl.step.DeadLineLabel != 'None'">{{$ctrl.step.MomentFinalisedInLocalFormat}}</span>
                        </div>
                    </div>
                </div>

                <div ng-if="$ctrl.isMobile" class="flow-history-waiting isMobile" ng-class ="{'pt-0': $ctrl.step.ActorAssignedAvatarUrl, 'flow-history-waiting--selected': $ctrl.step.IsSelectedStep }">
                 
                    <div ng-if="$ctrl.step.IntegrationState == null || $ctrl.step.IntegrationState.State !== 0">
                        <div class="avatar-container">
                            <div class="avatar">
                                <flow-avatar-circle name="$ctrl.step.ActorAssignedName" avatar-url="$ctrl.step.ActorAssignedAvatarUrl"></flow-avatar-circle>
                            </div>
                        </div>
                    </div>
                    <div ng-if="$ctrl.step.IntegrationState != null && $ctrl.step.IntegrationState.State === 0">
                        <div class="avatar-container">
                           <div class="avatar step-processing-avatar">
                               <i class="fa-light fa-desktop step-processing-icon"></i>                    
                           </div>
                        </div>
                    </div>    

                    <div class="text flow-history-waiting__contents single-flow-view__contents">
                        <p><b>{{$ctrl.step.Name}}</b></p>
                        <div ng-if="$ctrl.step.IntegrationState == null || $ctrl.step.IntegrationState.State !== 0">
                                <div ng-if="$ctrl.step.StepApprovers.length > 0">
                                    <p class ="secondary-text">Waiting for approval by {{$ctrl.step.WhoIsAssignedStep}} for {{$ctrl.step.Moment}}</p>
                                </div>
                            <div ng-if="$ctrl.step.StepApprovers.length === 0 || $ctrl.step.StepApprovers === null">
                                <p class ="secondary-text">Waiting for input by {{$ctrl.step.WhoIsAssignedStep}} for {{$ctrl.step.Moment}}</p>
                            </div>
                        </div>
                        <div ng-if="$ctrl.step.IntegrationState != null && $ctrl.step.IntegrationState.State === 0">
                            <p class ="secondary-text">Waiting for processing for {{$ctrl.step.IntegrationState.ProcessingIntegrationMoment}}</p>
                        </div>      
                    </div>
                </div>
            `
});
