import { Box, Typography } from '@mui/material';
import React from 'react';

const CustomTabPanel = ({ children, value, index }) => {
  return (
    <Typography
      component="div"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {<Box p={3}>{children}</Box>}
    </Typography>
  );
};
export default CustomTabPanel;
