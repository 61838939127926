import React from 'react';
import { Box } from '@mui/material';
import ClipboardTextarea from './ClipboardTextarea/ClipboardTextarea';
import CustomButton from '../CustomButton/CustomButton';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';

type Props = {
  closeModal: () => void;
  isPublicMap: boolean;
  currentProcessMapId: string;
};

const ShareModal = (props: Props) => {
  const { closeModal, isPublicMap, currentProcessMapId } = props;

  const mapRoute = 'processmapsv2';
  const mapUrl = `${window.location.origin}/${mapRoute}/${currentProcessMapId}`;
  const publicMapUrl = mapUrl.replace(mapRoute, 'map');
  const iframeUrl = `<iframe width="650" height="800" src="${publicMapUrl}" frameborder="0"></iframe>`;

  const defaultTextToDisplay =
    'Public sharing is not enabled for this process map.';

  const { enablePublicProcessMaps } =
    useService<SharedAngular.APP_CONFIG>('APP_CONFIG');

  return (
    <Box className="share-modal-container">
      <p className="share-modal-heading">Share Process Map</p>
      <ClipboardTextarea
        disabled={false}
        label="Share With a Colleague"
        textareaId="shareWithColleague"
        textareaValue={mapUrl}
      />
      <div>
        <ClipboardTextarea
          label="Public Link"
          textareaId="shareAsPublicMap"
          textareaValue={
            isPublicMap && enablePublicProcessMaps
              ? publicMapUrl
              : defaultTextToDisplay
          }
          disabled={!isPublicMap || !enablePublicProcessMaps}
        />
        <ClipboardTextarea
          label="Embed Code"
          textareaId="embeddedIframeUrl"
          textareaValue={
            isPublicMap && enablePublicProcessMaps
              ? iframeUrl
              : defaultTextToDisplay
          }
          disabled={!isPublicMap || !enablePublicProcessMaps}
        />
      </div>

      <div className="share-modal-done-button">
        <CustomButton buttonText="Done" onClick={closeModal} />
      </div>
    </Box>
  );
};

export default ShareModal;
