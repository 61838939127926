/**
 * @ngdoc directive
 * @name runner.flow.teams
 * @module flowingly.runner.setup
 * @description  This comppnent is the root for teams
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * teams: the list of available teams (JSON[])
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';

angular.module('flowingly.runner.setup').component('flowTeams', {
  bindings: {
    teams: '<'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.teams/runner.setup.teams.tmpl.html',
  controller: [
    'teamApiService',
    'brandingService',
    function (
      teamApiService: TeamApiService,
      brandingService: SharedAngular.BrandingService
    ) {
      const $ctrl = this;

      $ctrl.helpUri = brandingService.getBrandedHelpUri(
        'https://help.flowingly.net/administration/how-do-i-create-manage-teams'
      );
      $ctrl.handleTeamsUpdated = handleTeamsUpdated;

      function handleTeamsUpdated() {
        // For an array that is bounded via one-way binding, a watch expression is added that does not check for object equality
        // but uses reference checking. This means that adding an element to the array will never fire the '$onChanges' method,
        // since the watcher will never be 'dirty'. Therefore, we use Angular copy ensure a new reference

        //get latest teams list
        $ctrl.teams = angular.copy(teamApiService.teams);
      }
    }
  ]
});
