import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.flows.todo
 * @module flowingly.runner.flows.todo
 * @description This module configures the page used to display flows that are awaiting action by the user
 */
const m = angular.module('flowingly.runner.flows.todo', []);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.flows.todo/runner.flows.todo.group.tmpl.html',
      require('./runner.flows.todo.group.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flows.todo/runner.flows.todo.groups.tmpl.html',
      require('./runner.flows.todo.groups.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.flows.todo/runner.flows.todo.tmpl.html',
      require('./runner.flows.todo.tmpl.html').default
    );
  }
]);
