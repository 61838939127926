(function () {
  'use strict';

  ///
  /// This state presents the signup form
  ///

  // Register the application's routes configuration.
  angular.module('flowingly.signup').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.signup', {
      url: '/signup',
      params: { title: 'Sign Up' },
      templateUrl: 'Client/signup/signup.tmpl.html',
      controller: 'signupController',
      controllerAs: 'ctrl'
    });
  }
})();
