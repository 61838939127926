/**
 * @ngdoc directive
 * @name databasesHeader
 * @module flowingly.runner.setup
 * @description This component is used to display the header for the databases and will contain controls
 * @usage
 * ```
      <databases-header on-databases-updated="$ctrl.handleDatabasesUpdated()"></databases-header>
 * ``` 
 * NOTE: Minimal content for now, but this might grow
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('databasesHeader', {
    template:
      '<button ng-click="$ctrl.onAddDatabaseClick()" class="btn btn-primary mt-25">Add Database</button>',
    controller: [
      '$state',
      function ($state) {
        let $ctrl = this;

        $ctrl.onAddDatabaseClick = () => {
          $state.go('app.runner.create-database');
        };
      }
    ]
  });
})();
