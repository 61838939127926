import angular from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { STATE_MAPPINGS } from '@Shared.Angular/@types/stateMappings';

angular.module('flowingly.runner.library').config(config);
config.$inject = ['$stateProvider'];

function config($stateProvider) {
  $stateProvider.state('app.runner.library.processmaps', {
    url: '/processmaps',
    params: { flowModels: [], flowId: null, title: 'Library -  Process Maps' },
    views: {
      childcontent: {
        controllerAs: '$ctrl',
        controller: [
          '$stateParams',
          '$state',
          'APP_CONFIG',
          function (
            $stateParams: IStateParamsService,
            $state: IStateService,
            APP_CONFIG: SharedAngular.APP_CONFIG
          ) {
            if (!APP_CONFIG.allowProcessMap) {
              $state.go(STATE_MAPPINGS[APP_CONFIG.defaultHomePage]);
            }
            this.flowModels = $stateParams.flowModels;
          }
        ],
        template:
          '<flow-library-tabs-processmaps flow-models="$ctrl.flowModels"></flow-library-tabs-processmaps>'
      }
    }
  });
}
