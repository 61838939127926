'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.services')
  .service('acceptInviteApiService', acceptInviteApiService);

acceptInviteApiService.$inject = ['$http', 'APP_CONFIG'];

// ReSharper disable once InconsistentNaming
function acceptInviteApiService(
  $http: angular.IHttpService,
  APP_CONFIG: AppConfig
) {
  const service = {
    validateSecurityToken: validateSecurityToken,
    getSettingsByBusiness: getSettingsByBusiness,
    getManagersByBusiness: getManagersByBusiness,
    getTeamsByBusiness: getTeamsByBusiness,
    updateUserProfile: updateUserProfile
  };

  return service;

  //declarations
  function validateSecurityToken(inviteToken) {
    return $http
      .get(
        APP_CONFIG.apiBaseUrl +
          'account/validateToken?inviteToken=' +
          inviteToken
      )
      .then(function (response) {
        return response.data;
      });
  }

  function getSettingsByBusiness(inviteToken) {
    return $http
      .get<IResponseData>(
        APP_CONFIG.apiBaseUrl + 'account/settings',
        getAuthorizationHeader(inviteToken)
      )
      .then(function (response) {
        return response.data.DataModel;
      });
  }

  function getManagersByBusiness(inviteToken) {
    return $http
      .get<IResponseData>(
        APP_CONFIG.apiBaseUrl + 'users/userManagers',
        getAuthorizationHeader(inviteToken)
      )
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function getTeamsByBusiness(inviteToken) {
    return $http
      .get<IResponseData>(
        APP_CONFIG.apiBaseUrl + 'teams',
        getAuthorizationHeader(inviteToken)
      )
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function updateUserProfile(inviteToken, user) {
    return $http
      .put(
        APP_CONFIG.apiBaseUrl + 'account/' + user.id,
        user,
        getAuthorizationHeader(inviteToken)
      )
      .then(function (response) {
        return response.data;
      });
  }

  //Setting up headers manually so we keep the same restirction on the end points for security reasons
  function getAuthorizationHeader(token) {
    return {
      headers: { Authorization: 'Bearer ' + token }
    };
  }
}

export type AcceptInviteApiServiceType = ReturnType<
  typeof acceptInviteApiService
>;
