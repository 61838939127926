/**
 * @name flowHistoryFieldLabel
 * @module flowingly.runner.directives
 * @description display field labels consistently for completed steps
 * ### Notes
 * 
 * @usage
 * ```
    <flow-history-field-label field-value="{{item.Text}}"></flow-history-field-label>
 * ```
 */

'use strict';
import angular from 'angular';
angular
  .module('flowingly.runner.directives')
  .component('flowHistoryFieldLabel', {
    bindings: {
      fieldType: '<',
      fieldValue: '<'
    },
    controller: [
      function () {
        const $ctrl = this;
        $ctrl.formValue = undefined;
        $ctrl.displayOptional = undefined;

        $ctrl.$onInit = function () {
          setLabelText();
        };

        function setLabelText() {
          switch ($ctrl.fieldType) {
            case 'approvecomment':
              $ctrl.formValue = 'Comment';
              break;
            case 'approval':
              $ctrl.formValue = 'Approval Decision';
              break;
            default:
              $ctrl.formValue = $ctrl.fieldValue;
              break;
          }
        }
      }
    ],
    template: `
                {{$ctrl.formValue}}
            `
  });
