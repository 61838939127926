import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.library').config(config);
config.$inject = ['$stateProvider', '$urlRouterProvider'];

function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/library', '/library/workflows');
  $stateProvider.state('app.runner.library', {
    url: 'library',
    abstract: true,
    templateUrl: 'Client/runner.library/runner.library.tmpl.html',
    controllerAs: '$ctrl',
    controller: [function () {}],
    resolve: {
      flowModels: [
        'categoryApiService',
        function (categoryApiService: SharedAngular.CategoryApiService) {
          return categoryApiService.getCategories();
        }
      ]
    }
  });
}
