import angular from 'angular';
import { IStateProvider } from 'angular-ui-router';

angular.module('flowingly.runner.maintenance').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance.business', {
    url: '/business',
    params: { title: 'Maintenance - Business' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/business/runner.maintenance.business.tmpl.html',
        controllerAs: '$ctrl',
        controller: 'maintenanceBusinessController'
      }
    }
  });
}
