import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

class RunnerPublicMapController {
  processMapId: string;
  allowProcessMap: boolean;
  initialized: boolean;
  processMapName: string;

  constructor(
    private $scope,
    private $stateParams,
    private APP_CONFIG: SharedAngular.APP_CONFIG,
    private pubsubService: SharedAngular.PubSubService
  ) {
    this.initialized = true;
    this.allowProcessMap = this.APP_CONFIG.allowProcessMap;
  }

  $onInit() {
    this.processMapId = this.$stateParams.id;
    const processMapSubscriberId = 'flowingly.runner.publicMapController';
    this.pubsubService.subscribe(
      'PROCESSMAPVIEWV2_LOADED',
      this.onProcessMapLoaded.bind(this),
      processMapSubscriberId
    );
    this.$scope.$on('$destroy', () => {
      this.pubsubService.unsubscribeAll(processMapSubscriberId);
    });
  }
  onProcessMapLoaded(event, data) {
    this.processMapName = data.name;
  }
}

angular
  .module('flowingly.runner.publicmap')
  .controller('runnerPublicMapController', [
    '$scope',
    '$stateParams',
    'APP_CONFIG',
    'pubsubService',
    function ($scope, $stateParams, APP_CONFIG, pubsubService) {
      const controller = new RunnerPublicMapController(
        $scope,
        $stateParams,
        APP_CONFIG,
        pubsubService
      );
      controller.$onInit();
      return controller;
    }
  ]);
