import angular, { IHttpService } from 'angular';

export class NonceService {
  public static ID = 'nonceService';
  public static $inject = ['$http', 'APP_CONFIG'];

  public constructor(
    private $http: IHttpService,
    private APP_CONFIG: AppConfig
  ) {}

  // maybe reusable in the future
  private apiEndpoint = 'reports/gettempcode';

  public getToken() {
    return this.$http
      .get<string>(`${this.APP_CONFIG.apiBaseUrl}${this.apiEndpoint}`, {})
      .then((response) => response.data);
  }
}

angular
  .module('flowingly.runner.services')
  .service(NonceService.ID, NonceService);
